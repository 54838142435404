import Header from "components/Atoms/SharedComponents/Details/header";
import { Stack, Typography } from "@mui/material";
import { useThemePalette } from "common/hooks/theme_palette";
import { useState } from "react";
import { useRef } from "react";
import CActions from "components/Molecules/Crud/components/actions";

export default function CustomerDetailsHeader({
  data,
  actionsList,
}) {
  const { lightGreen, red } = useThemePalette();

  const [anchorEl, setAnchorEl] = useState(null);
  const anchorRef = useRef(null);
  const open = Boolean(anchorEl);
  const handleClick = (event, index, data) => {
    anchorEl == index ? setAnchorEl(null) : setAnchorEl(index);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  
  const headerChildren = (
    <Stack direction="row" gap={2}>
      <Stack direction="row">
        <Stack direction="row" gap={1}>
          <Typography sx={{ color: "#9C9C9C" }}>Profile status:</Typography>
          {data?.status == "active" ? (
            <Typography sx={{ color: lightGreen, fontSize: "18px" }}>
              Active
            </Typography>
          ) : (
            <Typography sx={{ color: red, fontSize: "18px" }}>
              {data?.status}
            </Typography>
          )}
        </Stack>
      </Stack>
      {data?.status != 'deleted' && <Stack>
        <CActions
          id={data?.id}
          index={1}
          data={data}
          handleClick={handleClick}
          handleClose={handleClose}
          anchorRef={anchorRef}
          open={open}
          anchorEl={anchorEl}
          actionsList={actionsList}
        />
      </Stack>}
    </Stack>
  );
  return (
    <Header
      title="Customer Profile"
      children={headerChildren}
      deleteBtn={false}
      editBtn={false}
    />
  );
}
