import Header from "components/Atoms/SharedComponents/Details/header";
import { Stack, Typography } from "@mui/material";
import { useThemePalette } from "common/hooks/theme_palette";
import React, { useState } from "react";
import { useRef } from "react";
import CActions from "components/Molecules/Crud/components/actions";
import { useNavigate } from "react-router-dom";
import ModelBox from "../../Atoms/Model/Model";
import ForgetPasswordForm from "../../Organisms/login/forms/forgetPasswordForm";
import VerifyOTPForm from "../../Organisms/login/forms/verifyOtp/verifyOTPForm";
import ResetPasswordForm from "../../Organisms/login/forms/resetPassword/resetPasswordForm";

export default function VendorDetailsHeader({
  data,
  setOpenDeleteModal,
  setStatus,
  setOpenConfirmationModal,
  setApproval,
  actionsList,
  filterList,
  approvalStatusOptions,
}) {
  const navigate = useNavigate();
  const { green } = useThemePalette();

  const [anchorEl, setAnchorEl] = useState(null);
  const anchorRef = useRef(null);
  const open = Boolean(anchorEl);
  const handleClick = (event, index, data) => {
    anchorEl == index ? setAnchorEl(null) : setAnchorEl(index);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [resetPasswordStep, setResetPasswordStep] = useState({
    action: false,
    step: null,
  });
  const handleResetPassword = () => {
    setResetPasswordStep({ action: true, step: 1 });
  };

  const headerChildren = (
    <Stack direction='row' gap={2}>
      <Stack direction='row'>
        <Stack direction='row' gap={1}>
          <Typography sx={{ color: "#9C9C9C" }}>Approval status:</Typography>
          {data?.details?.profile_status == "approved" ? (
            <Typography sx={{ color: green, fontSize: "18px" }}>
              Approved
            </Typography>
          ) : (
            "Disabled"
          )}
        </Stack>
      </Stack>
      <Stack direction='row'>
        <Stack direction='row' gap={1}>
          <Typography sx={{ color: "#9C9C9C" }}>Account Status:</Typography>
          {data?.details?.status === "active" ? (
            <Typography sx={{ color: green, fontSize: "18px" }}>
              Active
            </Typography>
          ) : (
            "Disabled"
          )}
        </Stack>
      </Stack>
      <Stack>
        <CActions
          id={data?.id}
          index={1}
          data={data}
          handleClick={handleClick}
          handleClose={handleClose}
          anchorRef={anchorRef}
          open={open}
          anchorEl={anchorEl}
          actionsList={actionsList}
        />
      </Stack>
      <ModelBox
        openVar={resetPasswordStep.action}
        closeFunc={() =>
          setResetPasswordStep({ action: false, step: resetPasswordStep.step })
        }
        title={"Reset Password"}
        titleStyle={{ fontSize: "28px", fontWeight: 500 }}
      >
        {resetPasswordStep.step === 1 ? (
          <ForgetPasswordForm
            hideIcon={true}
            setResetPasswordStep={setResetPasswordStep}
            customAction={true}
            userType={"store"}
          />
        ) : resetPasswordStep.step === 2 ? (
          <VerifyOTPForm
            hideIcon={true}
            setResetPasswordStep={setResetPasswordStep}
            customAction={true}
            userType={"store"}
          ></VerifyOTPForm>
        ) : (
          <ResetPasswordForm
            hideIcon={true}
            setResetPasswordStep={setResetPasswordStep}
            customAction={true}
            userType={"store"}
          ></ResetPasswordForm>
        )}
      </ModelBox>
    </Stack>
  );
  return (
    <Header
      title='Vendor details'
      children={headerChildren}
      deleteBtn={false}
      editBtn={false}
      approvalStatusOptions={approvalStatusOptions}
      // setOpenDeleteModal={setOpenDeleteModal}
    />
  );
}
