import { useThemePalette } from 'common/hooks/theme_palette';
import ConfirmationModal from 'components/Molecules/Models/ConfirmationModal'
import React from 'react'

export default function ActiveModel({ openModel, setOpenModel, handleUpdate, userType }) {
    const { oliveGreen } = useThemePalette()
    return <>
        <ConfirmationModal
            open={openModel?.state}
            setOpen={setOpenModel}
            handleConfirmation={_ => {
                handleUpdate()
                setOpenModel({ state: false, customerId: false, action: false })
            }}
            text={[
                `Active ${userType} Account`,
                `Are you sure you want to active this ${userType} account?`
            ]}
            textStyle={{ color: '#000', fontSize: '32px', fontWeight: 500 }}
            confirmBtnTitle="Active"
            btnStyle={{
                backgroundColor: oliveGreen,
                "&:hover": {
                    backgroundColor: oliveGreen,
                },
                color: "white",
            }}
        />
    </>
}
