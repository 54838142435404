import Types from "./Types";

const INIT_STATE = {
    userOccasions: [],
    userOccasion:{},
    count: 0,
    load: null,
    error: null,
};
export function userOccasionsReducer(state = INIT_STATE, action) {
    const { type, payload } = action;
    switch (type) {
        case Types.GET_USER_OCCASIONS_SUCCESS:{
            return {
                ...state,
                userOccasions: payload?.data.occasions,
                count: payload?.extra?.total_count
            }
        }
        case Types.USER_OCCASIONS_LOADING:{
            return{
                ...state,
                load: payload
            }
        }
        case Types.GET_USER_OCCASION_DETAILS_SUCCESS:{
            return {
                ...state,
                userOccasion: payload?.data?.occasion

            }
        }
        default:{
        return state
        }
    }
    }