import { Box, Stack, Typography } from '@mui/material'
import React from 'react'

export default function FollowersCart({ data }) {
    return <>
        <Stack
            px='43px'
            py='20px'
            direction='row'
            alignItems='center'
            gap='11px'
        >
            <Box
                component='img'
                src={data?.avatar}
                width='48px'
                height='48px'
                borderRadius='50%'
            />
            <Typography fontSize='20px'>{data?.name}</Typography>
        </Stack>
    </>
}
