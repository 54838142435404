import ModelBox from "components/Atoms/Model/Model";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  isOpenDriverDetails,
  isOpenViewComplain,
} from "services/modules/modal/Actions";
import AvailableDriversCard from "../availableDriversCard";
import { Box, Stack, Typography } from "@mui/material";
import { useThemePalette } from "common/hooks/theme_palette";
import ViewComplain from "../ViewComplain";
import { useNavigate } from "react-router-dom";

export default function DriversDetails({ driversData, setComplainId }) {
  const { driverDetails } = useSelector((state) => state?.modal);
  const { grayFive } = useThemePalette();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <>
      <ModelBox
        openVar={driverDetails}
        closeFunc={() => dispatch(isOpenDriverDetails())}
        title={"Drivers Details"}
        titleStyle={{
          fontSize: { lg: "32px", xs: "18px" },
          fontWeight: "500",
          px: { lg: "36px", xs: "15px" },
        }}
        padding={false}
      >
        <Box>
          {driversData?.map((data, index, array) => (
            <Stack
              px="20px"
              mb="44px"
              sx={{
                borderTop:
                  driversData?.length == array?.length &&
                  `1px solid ${grayFive}`,
              }}
            >
              <Typography my="12px" fontSize={"18px"} fontWeight="500">
                {data?.title}
              </Typography>
              <Stack direction="row">
                <AvailableDriversCard
                  data={data?.driver}
                  displayDistance={false}
                  click={(_) => {
                    // console.log(data);
                    navigate(
                      `/${data?.driver?.driver_type}-drivers?type=details&id=${data?.driver?.id}`
                    );
                  }}
                />
                {data?.route?.route_complaint && (
                  <ViewComplain
                    click={() => {
                      setComplainId(data?.route?.route_complaint?.id);
                      dispatch(isOpenViewComplain());
                      dispatch(isOpenDriverDetails());
                    }}
                  />
                )}
              </Stack>
            </Stack>
          ))}
        </Box>
      </ModelBox>
    </>
  );
}
