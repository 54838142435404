import Types from "./Types";


// ----------------- SUGGESTED WRAPS START-----------------
export const GetSuggestedWrapsSuccess = (payload) => ({
    type: Types.GET_SUGGESTED_WRAPS_SUCCESS,
    payload,
});

export const GetSuggestedWrapsLoading = (payload) => ({
    type: Types.SUGGESTED_WRAPS_LOADING,
    payload,
});


export const AddSuggestedWrapSuccess = (payload) => ({
    type: Types.ADD_SUGGESTED_WRAPS_SUCCESS,
    payload,
});

export const DeleteSuggestedWrapsSuccess = (payload) => ({
    type: Types.DELETE_SUGGESTED_WRAPS_SUCCESS,
    payload,
});


export const UpdateSuggestedWrapsSuccess = (payload) => ({
    type: Types.UPDATE_SUGGESTED_WRAPS_SUCCESS,
    payload,
});

export const GetSuggestedWrapDetailsSuccess = (payload) => ({
    type: Types.GET_SUGGESTED_WRAPS_DETAILS_SUCCESS,
    payload,
});


// -----------------SUGGESTED WRAPS END-----------------
