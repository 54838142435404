import { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  createRoleRequest,
  deleteRoleRequest,
  getRoleDataRequest,
  getRolesRequest,
  updateRoleRequest,
} from "services/modules/users/index";

import RolesTemplate from "templates/roles";
import RolePermissionsForm from "components/Organisms/roles/RolePermissionsForm";
import Loader from "components/Atoms/SharedComponents/Loader/Loader";
import { toast } from "react-toastify";

function RolesPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const type = searchParams.get("type");

  const list = useSelector((state) => state.users.roles);
  const count = useSelector((state) => state.users.count);
  const details = useSelector((state) => state.users.role);
  const load = useSelector((state) => state.users.load);

  //permissions
  const currentUser = useSelector((state) => state.authenticate.currentUserProfile);
  const permissions = useSelector(
    (state) => state.authenticate.currentUserProfile.permissions.occasion_types
  );
  const isSuperAdmin = currentUser?.privilege == "super_admin";

  const [permissionsAttributes, setPermissionsAttributes] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [page, setPage] = useState(1);
  const [filterObj, setFilterObj] = useState(null);

  //details of an item
  const getDetails = async () => {
    const requestData = {
      role_id: id,
    };
    dispatch(getRoleDataRequest(requestData));
  };

  //List to display in the table
  const getList = async (filterObj) => {
    const requestData = {
      params: {
        page_size: 10,
        page_number: 1,
        keyword: "",
        sort_by: "id",
        ...filterObj,
      },
    };
    dispatch(getRolesRequest(requestData));
  };

  // mappedData use for create custom cell to concat multi values or navigate button
  const mappedData = list?.map((data, index) => {
    return {
      ...data,
    };
  });

  const rowData = (data) => {
    setSelectedRow(data);
  };

  const breadcrumbs = [
    {
      active: true,
      path: "/",
      title: "Home",
    },
    {
      active: true,
      path: "/admin-users",
      title: "Users/Admin Users",
    },
    {
      active: type ? true : false,
      path: "/admin-users/roles",
      title: "View Roles",
    },
    type && {
      active: false,
      title: type === "details" ? "Details" : id ? "Edit Role" : "New Role",
    },
  ];

  const handelSubmit = (data, formik) => {
    const action = () => {
      searchParams.delete("id");
      searchParams.delete("type");
      navigate("/admin-users/roles");
      !id && formik?.resetForm();
      toast.success(`Role has been ${id ? "updated" : "created"} successfully`);
    };
    const requestData = {
      body: {
        role: {
          name: data?.name,
          permissions_attributes: permissionsAttributes,
        },
      },
      action,
    };

    if (id) {
      //update call
      dispatch(updateRoleRequest({ ...requestData, id }));
    } else {
      //create call
      dispatch(createRoleRequest(requestData));
    }
  };

  //deleting a specific row in a table
  const handleDelete = (id) => {
    // const id = selectedRow?.rowData?.id;
    //action to be called after successfull deletion
    const action = () => {
      //if list.length == 1 & page !== 1 -> setPage(page-1) & fetch
      if ((list.length === 1) & (page !== 1)) {
        setPage(page - 1);
        setFilterObj({
          ...filterObj,
          page_number: page - 1,
        });
        getList({ ...filterObj, page_number: page - 1 }); //temporary until the backend sends the data correctly so I can update the state instead of re-fetching
      } else {
        getList(filterObj);
      }
      type === "details" && navigate("/roles");
      toast.success("Role has been deleted successfully");
    };
    const requestData = {
      id,
      action, //dispatched on success
    };
    dispatch(deleteRoleRequest(requestData));
    setOpenDeleteModal(false);
  };

  const handlePageChange = (page) => {
    setPage(page);
    setFilterObj((prev) => {
      return { ...prev, page_number: page };
    });
  };

  //list of actions to be displayed when clicked on the 3 dots
  const actionsList = ({ index, data }) => {
    return [
      {
        condition: () => {
          return true;
        },
        click: () => {
          navigate(`?type=form&id=${data?.id}`);
        },
        text: "Edit",
      },
      {
        condition: () => {
          return true;
        },
        click: () => {
          setOpenDeleteModal(true);
        },
        text: "Delete",
      },
    ];
  };

  //if you need more buttons in the header beside the add button
  const headerChildren = <></>;

  //if more modals needed, pass them to the modalComponents as below
  const modalComponents = [];

  const formChildren = () => {
    return (
      <RolePermissionsForm
        id={id}
        permissionsAttributes={permissionsAttributes}
        setPermissionsAttributes={setPermissionsAttributes}
      />
    );
  };

  //listing all roles
  useEffect(() => {
    if (filterObj) getList(filterObj);
  }, [filterObj]);

  useEffect(() => {
    if (id) getDetails(id);
  }, [id]);

  useEffect(() => {
    setPermissionsAttributes([]);
  }, []);

  return (
    <>
      <Loader open={load?.loading} />
      <RolesTemplate
        filterObj={filterObj}
        setFilterObj={setFilterObj}
        data={mappedData}
        count={count}
        rowData={rowData}
        details={details}
        breadcrumbs={breadcrumbs}
        handelSubmit={handelSubmit}
        // handleCancel={handleCancel}
        actionsList={actionsList}
        headerChildren={headerChildren}
        openDeleteModal={openDeleteModal}
        setOpenDeleteModal={setOpenDeleteModal}
        handleDeleteRequest={handleDelete}
        modalComponents={modalComponents}
        formChildren={formChildren()}
        handlePageChange={handlePageChange}
      />
    </>
  );
}

export default RolesPage;
