/* eslint-disable array-callback-return */
import Types from "./Types";

const INIT_STATE = {
  occasionTypes: [],
  filterOccation: [],
  listCount: 0,
  occasionType: {},
  count: 0,
  load: null,
  error: null,
};

export function occasionsTypesReducer(state = INIT_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case Types.GET_OCCASIONS_TYPES_SUCCESS: {
      return {
        ...state,
        occasionTypes: payload?.data?.occasion_types,
        count: payload?.extra?.total_count,
      };
    }
    case Types.OCCASIONS_TYPES_LOADING: {
      return {
        ...state,
        load: payload,
      };
    }
    case Types.DELETE_OCCASIONS_TYPES_SUCCESS: {
      return {
        ...state,
      };
    }
    case Types.ADD_OCCASIONS_TYPES_SUCCESS: {
      return {
        ...state,
      };
    }
    case Types.UPDATE_OCCASIONS_TYPES_SUCCESS: {
      return {
        ...state,
      };
    }
    case Types.GET_OCCASIONS_TYPES_DETAILS_SUCCESS: {
      return {
        ...state,
        occasionType: payload?.data?.occasion_type,
      };
    }
    case Types.GET_SCROLL_OCCASIONS_TYPES_SUCCESS: {
      return {
        ...state,
        filterOccation: payload?.extra?.page_number == 1
          ? payload?.data?.occasion_types
          : [...state.filterOccation, ...payload?.data?.occasion_types],
        listCount: payload?.extra?.total_count,
      };
    }
    default: {
      return state;
    }
  }
}
