import * as Yup from "yup";
export const initialValues = (data) => {
  console.log(data)
  return {
    ...data,
    englishCardName:data?.localized_data?.en?.name,
    arabicCardName:data?.localized_data?.ar?.name,
    priceAfterDiscount:data?.price_after_discount
  };
};
export const structure = () => [
  {
    head: "",
    width: "100%",
    list: [
        {
        name: `image`,
        kind: "upload",
        type: 'image',
        label: "Wrap Photo*",
        placeholder: 'test',
        text: "file size no more than",
        width: "35%",
      },
      {width: "47%",},
        {
        name: `englishCardName`,
        kind: "input",
        type: "text",
        label: "Card Name(EN)*",
        width: "47%",
        placeholder:"Enter the english card name"
      },
      {
        name: `arabicCardName`,
        kind: "input",
        type: "text",
        label: "Card Name(AR)*",
        width: "47%",
        placeholder:"Enter the arabic card name"
      },
      {
        name: `price`,
        kind: "input",
        type: "text",
        label: "Price",
        width: "47%",
        placeholder:"Enter the price",
        EndAdornment: (
          <p style={{ fontSize: '18px', color: "#1A1A1A" }} >SAR</p>
        ),
      },
      {
        name: `priceAfterDiscount`,
        kind: "input",
        type: "text",
        label: "Price after discount",
        width: "47%",
        placeholder:"Enter the price after discount",
        EndAdornment: (
          <p style={{ fontSize: '18px', color: "#1A1A1A" }} >SAR</p>
        ),
      },
    ],
  },
];

export const validationSchema = () =>
  Yup.object({
    image:Yup.string().required("required"),
    englishCardName: Yup.string().required("required"),
    arabicCardName: Yup.string().required("required"),
    price: Yup.number("Invalid Price"),
    priceAfterDiscount: Yup.number("Invalid Price"),
  });
