import { Box, Button, CircularProgress, Stack, Typography } from "@mui/material";
import { useThemePalette } from "common/hooks/theme_palette";
import CActions from "components/Molecules/Crud/components/actions";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

import {
  createVariantBranchRequest,
  createVariantRequest,
  deleteProductVariantRequest,
  deleteVariantBranchRequest,
  getOptionValuesRequest,
  getProductVariantsRequest,
  getVariantDetailsRequest,
  getVariantsBranchesRequest,
  updateVariantBranchRequest,
  updateVariantRequest,
} from "services/modules/products";

import ProductVariantTemplate from "templates/products/productVariant";

export default function ProductVariantsPage({
  isHeaderChildren = true,
  productId,
  optionTypes,
  refOpenVriantForm,
  storeId,
  variantPermissions = {},
  isSuperAdmin = false,
}) {
  const { orange, oliveGreen } = useThemePalette();
  const dispatch = useDispatch();
  const anchorRef = useRef(null);

  let [searchParams] = useSearchParams();
  const id = searchParams.get("id") ?? productId;
  const type = searchParams.get("type");

  const [locations, setLocations] = useState([]);
  const [isOpenForm, setIsOpenForm] = useState(false);
  const [isOpenDetails, setIsOpenDetails] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [tableData, setTableData] = useState({});
  const [selectedRow, setSelectedRow] = useState({});
  const [page, setPage] = useState(1);

  const [optTypesFilter, setOptTypesFilter] = useState([]);
  const [filterObj, setFilterObj] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const list = useSelector((state) => state.products.variants);
  const details = useSelector((state) => state.products.variant);
  const count = useSelector((state) => state.products.variants_count);
  const load = useSelector((state) => state.products.load);
  const productVariantsLoad = useSelector((state) => state.products.variants_loading);
  const branches = useSelector((state) => state.products.branches);

  const option_values = useSelector((state) => state.products.option_values);
  const option_values_loading = useSelector((state) => state.products.option_values_loading);

  const open = Boolean(anchorEl);

  const columns = [
    { accessor: "id", Header: "#" },
    { accessor: "variant", Header: "Variant" },
    { accessor: "sku", Header: "Sku", sort: true },
    { accessor: "quantity", Header: "Quantity", sort: true },
    { accessor: "price", Header: "Price", sort: true },
    { accessor: "price_after_discount", Header: "Price after discount", sort: true },
    { accessor: "location", Header: "Location", sort: true },
    { accessor: "Actions", Header: "Action" },
  ];

  const actionsList = ({ index, data }) => {
    return [
      {
        condition: () => {
          return true;
        },
        click: () => {
          getDetails(data?.id);
          setIsOpenDetails(true);
        },
        text: "View Details",
      },
      {
        condition: () => {
          return isSuperAdmin || variantPermissions?.can_update;
        },
        click: () => {
          getDetails(data?.id);
          setIsOpenForm(true);
        },
        text: "Edit",
      },
      {
        condition: () => {
          return isSuperAdmin || variantPermissions?.can_delete;
        },
        click: () => {
          setOpenDeleteModal(true);
        },
        text: "Delete",
      },
    ];
  };

  const fetchOptionValues = (filterObj, id) => {
    const requestData = {
      params: { option_type_id: id, page_number: 1, ...filterObj },
    };

    dispatch(getOptionValuesRequest(requestData));
  };

  const optionTypesFilter = () => {
    if (!optionTypes) return ["dummy", "dummy"];
    const filterData = optionTypes?.map((option, i) => {
      const filterName = option?.name;
      return {
        type: "filter",
        placeholder: filterName,
        name: filterName,
        id: option?.id,
        onChange: (value) => {
          let newArr = optTypesFilter;
          newArr[i] = value?.value;
          setFilterObj((s) => {
            return {
              ...s,
              option_value_ids: `[${newArr.filter((elem) => elem)}]`,
              page_number: 1,
            };
          });
          setOptTypesFilter(newArr);
        },
        options: option_values[option?.id]?.list,
        isLoading: option_values_loading?.type === `option value ${option?.id}`,
        onMenuOpen: (filterObj) => {
          (option_values[option?.id]?.list <= 1 || !option_values[option?.id]) && fetchOptionValues(filterObj, option?.id);
        },
        applySearchFromRequest: true,
        count: option_values[option?.id]?.count,
        getList: (filterObj) => fetchOptionValues(filterObj, option?.id),
      };
    });
    return filterData;
  };

  const filterList = [
    {
      type: "filter",
      placeholder: "Location",
      onChange: (value) => {
        setFilterObj((s) => {
          return { ...s, city_id: value?.value, page_number: 1 };
        });
      },
      options: branches,
      isLoading: load?.type === `listBranches`,
    },
    ...optionTypesFilter(),
  ];

  const rowData = (data) => {
    setSelectedRow(data);
  };

  const handlePageChange = (page) => {
    setPage(page);
    setFilterObj((prev) => {
      return { ...prev, page_number: page };
    });
  };

  const handleDelete = () => {
    const action = () => {
      //if list.length == 1 & page !== 1 -> setPage(page-1) & fetch
      if ((list.length === 1) & (page !== 1)) {
        setPage(page - 1);
        setFilterObj({
          ...filterObj,
          page_number: page - 1,
        });
        // getList({ ...filterObj, page_number: page - 1 }); //temporary until the backend sends the data correctly so I can update the state instead of re-fetching
      } else {
        // getList(filterObj);
      }
      toast.success("Variant deleted successfully");
    };
    const requestData = {
      id: selectedRow?.rowData?.id,
      action, //dispatched on success
    };
    dispatch(deleteProductVariantRequest(requestData));
    setOpenDeleteModal(false);
  };

  //open action list
  const handleClick = (event, index, data) => {
    anchorEl == index ? setAnchorEl(null) : setAnchorEl(index);
  };

  //close action list
  const handleClose = () => {
    setSelectedRow({});
    setAnchorEl(null);
  };
  const Actions = (id, elmIndex, data) => {
    return (
      <CActions
        id={id}
        index={elmIndex}
        data={data}
        handleClick={handleClick}
        handleClose={handleClose}
        anchorRef={anchorRef}
        open={open}
        anchorEl={anchorEl}
        actionsList={actionsList}
      />
    );
  };

  const mappedData = list?.map((data, index) => {
    const quantity = data?.city_variant?.reduce((acc, current) => acc + current.count_on_hand, 0);
    return {
      ...data,
      variant: (
        <Stack direction="row">
          {data?.option_values?.map((value, i) => (
            <Typography key={i}>
              {value.name}
              {i !== data?.option_values?.length - 1 && "/"}
            </Typography>
          ))}
        </Stack>
      ),
      quantity: <Typography>{quantity}</Typography>,
      price: (
        <Typography>
          {data?.price} {data?.currency?.name}
        </Typography>
      ),
      price_after_discount: (
        <Typography>
          {data?.price_after_discount} {data?.currency?.name}
        </Typography>
      ),
      location: (
        <Stack direction="row">
          {data?.city_variant?.map((city, i) => (
            <Typography key={i}>
              {city?.city?.name}
              {i !== data?.city_variant?.length - 1 && ", "}
            </Typography>
          ))}
        </Stack>
      ),
      Actions: Actions(data?.id, index, data),
    };
  });

  const handleOpenVariantForm = () => {
    setIsOpenForm(true);
  };

  const headerChildren = (
    <Stack sx={{ position: "absolute", mr: 5 }}>
      <Button
        onClick={handleOpenVariantForm}
        ref={refOpenVriantForm}
        sx={{ color: orange, fontWeight: "500", fontSize: "20px", px: 0 }}
      >
        {isHeaderChildren && (isSuperAdmin || variantPermissions?.can_create) && type == "details" && " + Add new variant"}
      </Button>
    </Stack>
  );

  const prepareVariantData = (data) => {
    const optTypes = optionTypes.map((option) => option.name);
    const option_value_ids = optTypes.map((type) => data[type]);
    let media_attributes;
    if (selectedRow?.rowData?.id) {
      const deletedImages = details?.media?.filter((image) => !data?.images.includes(image.url));
      const newImages = [];
      data?.images.forEach((url) => {
        const existsInObjects = details?.media?.some((obj) => obj.url === url);

        if (!existsInObjects) {
          newImages.push(url);
        }
      });
      const images = newImages.length > 0 ? deletedImages.concat(newImages) : deletedImages;

      media_attributes = images?.map((image) => ({
        id: image?.id,
        media_type: 0,
        url: typeof image === "object" ? image.url : image,
        _destroy: image?.id && true,
      }));
    } else {
      media_attributes = data?.images?.map((url) => {
        return {
          media_type: 0,
          url,
        };
      });
    }

    return {
      ...data,
      product_id: id,
      option_value_ids,
      media_attributes,
    };
  };

  const handleSubmit = (data, formik) => {
    const action = (data) => {
      toast.success(selectedRow?.rowData?.id ? "variant updated successfully" : "variant created successfully");
      handleVariantFormClose(formik);
    };
    const requestData = {
      body: {
        variant: {
          ...prepareVariantData(data),
        },
      },
      action,
    };

    if (selectedRow?.rowData?.id) {
      dispatch(updateVariantRequest({ ...requestData, id: selectedRow?.rowData?.id }));
    } else {
      dispatch(createVariantRequest(requestData));
    }
  };

  const handleVariantFormClose = (formik) => {
    setLocations([]);
    setIsOpenForm(false);
    setSelectedRow({});
    formik?.resetForm();
  };

  const getList = async (filterObj) => {
    const requestData = {
      params: {
        page_size: 10,
        page_number: 1,
        keyword: "",
        sort_by: "id",
        product_id: id,
        ...filterObj,
      },
    };
    dispatch(getProductVariantsRequest(requestData));
  };

  const getDetails = async (id) => {
    dispatch(getVariantDetailsRequest({ id: id }));
  };

  const handleSaveVariantBranch = (location) => {
    const action = (data) => {
      toast.success(location.id ? "location updated successfully" : "location added successfully");
      // after adding a new branch
      // we need to update the locations with the city_id that was just created
      // just in case the user decides to delete a city right after creating it
      let updatedLocations = locations?.map((location) => {
        if (location?.city?.id == data?.data?.city_variant?.city?.id) {
          location.id = data?.data?.city_variant?.id;
        }
        return location;
      });
      setLocations(updatedLocations);
    };

    const requestData = {
      body: {
        google_cities_variant: {
          google_city_id: location?.city?.id,
          variant_id: selectedRow?.rowData?.id,
          count_on_hand: location?.count_on_hand,
        },
      },
      action,
    };
    if (!location.id) {
      //add
      dispatch(createVariantBranchRequest(requestData));
    } else {
      //update
      const requestData = {
        body: {
          count_on_hand: location?.count_on_hand,
        },
        variant_id: selectedRow?.rowData?.id,
        google_city_id: location?.city?.id,
        action,
      };
      dispatch(updateVariantBranchRequest({ ...requestData }));
    }
  };

  const handleDeleteVariantBranch = (index, location) => {
    //delete it from local state
    const newLocations = locations.filter((loc, i) => i !== index);
    setLocations(newLocations);

    const action = () => {
      // getList(filterObj);
      toast.success("location deleted successfully");
    };
    if (location?.id) {
      //means that we are deleting a location that already exists
      const requestData = {
        variant_id: selectedRow?.rowData?.id,
        google_city_id: location?.city?.id,
        action,
      };
      dispatch(deleteVariantBranchRequest(requestData));
    }
  };

  const calculateTotalQuantity = () => {
    // calculate total count from list in case of editing a variant count
    const arrayOfVariantsQuantities = list?.map((variant) => {
      return variant?.city_variant?.reduce((acc, current) => acc + current?.count_on_hand, 0);
    });

    const totalQuantity = arrayOfVariantsQuantities?.reduce((acc, current) => acc + current, 0);
    return totalQuantity;
  };

  useEffect(() => {
    if (filterObj) getList(filterObj);
  }, [filterObj]);

  useEffect(() => {
    rowData({ ...tableData });
  }, [tableData]);

  useEffect(() => {
    if (id) {
      setLocations(details?.city_variant || []);
    }
  }, [details]);

  useEffect(() => {
    if (storeId) {
      const requestData = {
        params: {
          store_id: storeId,
        },
      };
      dispatch(getVariantsBranchesRequest(requestData));
    }
  }, [storeId]);

  return (
    <Stack sx={{ position: "relative" }}>
      {(productVariantsLoad?.type == "listVariants" ||
        productVariantsLoad?.type == "deleteVariant" ||
        load?.type == "details") && ( //because we get the optionTypes filter from the details, so we need to wait for it
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            bottom: "0",
            left: "0",
            right: 0,
            top: 0,
            position: "absolute",
            zIndex: 1000,
            bgcolor: "rgba(255, 255, 255, 0.2)",
            backdropFilter: "blur(0.6px)",
          }}
        >
          <CircularProgress sx={{ color: oliveGreen }} />
        </Box>
      )}
      <Stack sx={{ position: "relative" }}>
        {type !== "details" && (
          <Stack sx={{ position: "absolute", right: "15px", top: "15px", zIndex:2 }} direction="row">
            <Typography fontWeight="400" fontSize="18px">
              Total Quantity:
            </Typography>
            <Typography fontWeight="500" fontSize="18px">
              {calculateTotalQuantity()}
            </Typography>
          </Stack>
        )}
        <ProductVariantTemplate
          isOpenForm={isOpenForm}
          isOpenDetails={isOpenDetails}
          setIsOpenDetails={setIsOpenDetails}
          details={details}
          setOpenDeleteModal={setOpenDeleteModal}
          openDeleteModal={openDeleteModal}
          handleDelete={handleDelete}
          handleSubmit={handleSubmit}
          locations={locations}
          setLocations={setLocations}
          tableData={tableData}
          list={mappedData}
          columns={columns}
          setTableData={setTableData}
          setFilterObj={setFilterObj}
          filterList={filterList}
          headerChildren={headerChildren}
          actionsList={actionsList}
          rowData={rowData}
          handlePageChange={handlePageChange}
          page={page}
          count={count}
          optionTypesFilter={optionTypesFilter()}
          handleVariantFormClose={handleVariantFormClose}
          handleSaveVariantBranch={handleSaveVariantBranch}
          handleDeleteVariantBranch={handleDeleteVariantBranch}
          branches={branches}
          selectedRow={selectedRow}
          load={load}
          fetchOptionValues={fetchOptionValues}
          option_values={option_values}
          option_values_loading={option_values_loading}
        />
      </Stack>
    </Stack>
  );
}
