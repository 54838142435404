import React, { useState } from "react";
import CCrud from "components/Molecules/Crud";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Box, Stack } from "@mui/material";
import Breadcrumb from "components/Atoms/SharedComponents/Breadcrumbs/Breadcrumb";
import AddIcon from "@mui/icons-material/Add";
import EmployeeDetailsPage from "pages/users/drivers/employee/employeeDetails";
import FreelancerDetailsPage from "pages/users/drivers/freelancer/freelancerDetails";

const FreelancerTemplate = ({
  handlePageChange,
  data,
  details,
  count,
  handelSubmit,
  deleteFun,
  filterList,
  actionsList,
  breadcrumbs,
  setFilterObj,
  rowData,
  paginationPage,
  headerChildren,
  modalComponents,
  openDeleteModal,
  handleDeleteRequest,
  followers,
  followings,
  cities,
  countries
}) => {
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const [supportedLang, setSupportedLang] = useState("en");
  return (
    <>
      <Box>
        <Stack
          direction="row"
          alignSelf="start"
          justifyContent="start"
          gap={2}
          width="100%"
          mb={1}
        >
          <Breadcrumb data={breadcrumbs} />
        </Stack>
        <CCrud
          table={{
            list: data,
            listTitle: "Freelancers drivers",
            createBtnTitle: false,
            CreateBtnIcon: AddIcon,
            createBtnFun: () => navigate("?type=form"),
            columns: [
              { accessor: "id", Header: "#" },
              { accessor: "name", Header: "Driver name", sort: true, },
              { accessor: "phone_number", Header: "Phone Number", sort: true, },
              { accessor: "state", Header: "Availability" },
              { accessor: "approval_status", Header: "Approval Status" },
              { accessor: "list_profile_status", Header: "Profile status" },
              { accessor: "Actions", Header: "Actions" },
            ],
            isSearch: false,
            searchInputPlaceholder: "Search by  Driver name",
            isChecked: false,
            total_count: count,
            setFilterObj,
            className: "organization-table system-configurations-table",
            paginationPage: paginationPage,
            handlePageChange,
            rowData: rowData,
            filterList,
            className: "main-table",
            actionsList, //if you want to add custom actions list
            headerChildren,
            details,
            openDeleteModal,
            // setOpenDeleteModal,
            handleDeleteRequest,
          }}
          form={{
            initialValues: !id ? {} : {},
            structure: () => { },
            validationSchema: {},
            deleteFun: deleteFun,
            title: id ? "Edit employee driver" : "New employee driver",
            submitTitle: id ? "Update" : "Add",
            onSubmit: handelSubmit,
            className: { form: "main-form", actionButton: "main-form-buttons" },
          }}
          DetailsComponent={
            <FreelancerDetailsPage
              details={details}
              actionsList={actionsList}
            />
          } //to send details component diff from the default
          modalComponents={modalComponents}
        />
      </Box>
    </>
  );
};

export default FreelancerTemplate;
