import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { changePasswordRequest, getAdminUserDetailsRequest } from "services/modules/users";
import ChangePasswordTemplate from "templates/change-password/ChangePassword";
import SuccessModal from "components/Molecules/Models/SuccessModal";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import { getVendorDetailsRequest, updateVendorRequest } from "../../services/modules/vendors";
import Loader from "components/Atoms/SharedComponents/Loader/Loader";

export default function VendorChangePasswordPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const details = useSelector((state) => state.vendors.vendor);
  const load = useSelector((state) => state.vendors.load);
  console.log(load, "load");
  const breadcrumbs = [
    {
      active: true,
      path: "/",
      title: "Home",
    },
    {
      active: true,
      path: "/vendors",
      title: "Vendors",
    },
    {
      active: false,
      path: "",
      title: "Change Password",
    },
  ];

  const handleSubmit = (values) => {
    const action = () => {
      toast.success("Password changed successfully");
      navigate("/vendors");
    };
    const requestData = {
      body: {
        store: {
          password: values?.password,
          password_confirmation: values?.confirmPassword,
        },
      },
      params: {
        user_email: details?.details?.email,
        user_type: "vendor",
      },
      action,
    };
    console.log(requestData);

    dispatch(updateVendorRequest({ ...requestData, id }));
  };

  const modalComponents = [];

  useEffect(() => {
    if (id) dispatch(getVendorDetailsRequest({ id }));
  }, [id]);

  return (
    <>
      <Loader open={load?.type === "update vendor" || load?.type === "details"} />
      <ChangePasswordTemplate
        breadcrumbs={breadcrumbs}
        handleSubmit={handleSubmit}
        user={details}
        modalComponents={modalComponents}
      />
    </>
  );
}
