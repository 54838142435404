import { makeFilterString } from "../../utils/Helper";

import { reduxRequest } from "../../../../common/utils/reduxRequest";
import {
    DriverComplaintsLoading, GetDriverComplaintDetails, GetDriverComplaints, SetDriverComplaints, UpdateDriverComplaints
} from "./Action";

export const getDriverComplaintsRequest = ({ ...variables }) => {
    const requestData = {
        isLoading: DriverComplaintsLoading,
        loadingType: "list",
        successFunction: GetDriverComplaints,
        url: `/admin/complaints/route_complaints?${makeFilterString(
            variables?.params
        )}`,
        method: "get",
    };
    return async (dispatch) => {
        reduxRequest({
            dispatch,
            ...requestData,
        });
    };
}
export const createDriverComplaintsRequest = ({ ...variables }) => {
    const requestData = {
        isLoading: DriverComplaintsLoading,
        loadingType: "add",
        successFunction: SetDriverComplaints,
        action: variables?.action,
        url: `/admin/complaints/route_complaints`,
        method: "post",
        data: variables?.body,
    };

    return async (dispatch) => {
        reduxRequest({
            dispatch,
            ...requestData,
        });
    };
};
export const getDriverComplaintsDetailsRequest = ({ ...variables }) => {
    const requestData = {
        isLoading: DriverComplaintsLoading,
        loadingType: "details",
        successFunction: GetDriverComplaintDetails,
        url: `/admin/complaints/route_complaints/${variables?.id}`,
        action: variables?.action,
        method: "get",
    };

    return async (dispatch) => {
        reduxRequest({
            dispatch,
            ...requestData,
        });
    };
};
export const deleteDriverComplaintsRequest = ({ ...variables }) => {
    const requestData = {
        isLoading: DriverComplaintsLoading,
        loadingType: "delete option type",
        action: variables?.action,
        url: `/admin/complaints/route_complaints/${variables?.id}`,
        method: "delete",
    };

    return async (dispatch) => {
        reduxRequest({
            dispatch,
            ...requestData,
        });
    };
};
export const updateDriverComplaintsRequest = ({ ...variables }) => {
    const requestData = {
        isLoading: DriverComplaintsLoading,
        loadingType: "update option type",
        successFunction: UpdateDriverComplaints,
        url: `/admin/complaints/route_complaints/${variables?.id}`,
        action: variables?.action,
        data: variables?.body,
        method: "put",
    };

    return async (dispatch) => {
        reduxRequest({
            dispatch,
            ...requestData,
        });
    };
};