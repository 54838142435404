import { makeFilterString } from "../utils/Helper";
import {

  CitiesLoading,
  CreateCitySuccess,
  DeleteCitySuccess,
  DeleteZoneSuccess,
  GetBetweenZonesFeeDetailsSuccess,
  GetBetweenZonesFeesSuccess,
  GetCitiesSuccess,
  GetCityDetailsSuccess,
  GetZoneDetailsSuccess,
  GetZonesSuccess,
  UpdateCitySuccess,
  UpdateZoneSuccess,
  GetCountriesSuccess
} from "./Action";

import { reduxRequest } from "common/utils/reduxRequest";

//! CITIES
export const getCitiesRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: CitiesLoading,
    loadingType: "list cities",
    successFunction: GetCitiesSuccess,
    url: `/admin/lookups/google_cities${makeFilterString(variables?.params)}`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const createCityRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: CitiesLoading,
    loadingType: "create city",
    successFunction: CreateCitySuccess,
    action: variables?.action,
    url: `/admin/lookups/google_cities`,
    data: variables?.body,
    method: "post",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const getCityDetailsRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: CitiesLoading,
    loadingType: "get city",
    successFunction: GetCityDetailsSuccess,
    action: variables?.action,
    url: `/admin/lookups/google_cities/${variables?.id}`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const updateCityRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: CitiesLoading,
    loadingType: "update city",
    successFunction: UpdateCitySuccess,
    action: variables?.action,
    url: `/admin/lookups/google_cities/${variables?.id}`,
    data: variables?.body,
    method: "put",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const deleteCityRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: CitiesLoading,
    loadingType: "delete city",
    successFunction: DeleteCitySuccess,
    action: variables?.action,
    url: `/admin/lookups/google_cities/${variables?.id}`,
    method: "delete",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

//! ZONES
export const getZonesRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: CitiesLoading,
    loadingType: "list",
    successFunction: GetZonesSuccess,
    url: `/admin/lookups/zones${makeFilterString(variables?.params)}`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const createZoneRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: CitiesLoading,
    loadingType: "create zone",
    action: variables?.action,
    url: `/admin/lookups/zones`,
    data: variables?.body,
    method: "post",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const getZoneDetailsRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: CitiesLoading,
    loadingType: "get zone",
    successFunction: GetZoneDetailsSuccess,
    action: variables?.action,
    url: `/admin/lookups/zones/${variables?.id}`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const updateZoneRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: CitiesLoading,
    loadingType: "update zone",
    successFunction: UpdateZoneSuccess,
    action: variables?.action,
    url: `/admin/lookups/zones/${variables?.id}`,
    data: variables?.body,
    method: "put",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const deleteZoneRequest = ({ ...variables }) => {
  console.log(variables)
  const requestData = {
    isLoading: CitiesLoading,
    loadingType: "delete zone",
    action: variables?.action,
    url: `/admin/lookups/zones/${variables?.id}`,
    method: "delete",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};


//! BETWEEN ZONES FEES
export const getBetweenZonesFeesRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: CitiesLoading,
    loadingType: "list",
    successFunction: GetBetweenZonesFeesSuccess,
    url: `/admin/lookups/between_zones_fees${makeFilterString(variables?.params)}`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const createBetweenZonesFeeRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: CitiesLoading,
    loadingType: "create between zone fee",
    action: variables?.action,
    url: `/admin/lookups/between_zones_fees`,
    data: variables?.body,
    method: "post",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const getBetweenZonesFeeDetailsRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: CitiesLoading,
    loadingType: "get zone",
    successFunction: GetBetweenZonesFeeDetailsSuccess,
    action: variables?.action,
    url: `/admin/lookups/between_zones_fees/${variables?.id}`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const updateBetweenZonesFeeRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: CitiesLoading,
    loadingType: "update between zones fee",
    action: variables?.action,
    url: `/admin/lookups/between_zones_fees/${variables?.id}`,
    data: variables?.body,
    method: "put",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};


export const deleteBetweenZonesFeeRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: CitiesLoading,
    loadingType: "delete betweeen zones fee",
    action: variables?.action,
    url: `/admin/lookups/between_zones_fees/${variables?.id}`,
    method: "delete",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};