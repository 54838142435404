import { Stack, Typography } from '@mui/material'
import React from 'react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

export default function ViewAllBtn({ addressesToShow, setAddressesToShow, data }) {
    return <>
        {data?.length > 0 && <Stack
            direction='row'
            alignItems='center'
            justifyContent='space-between'
            width='100%'
            onClick={_ => setAddressesToShow({ length: !addressesToShow?.state ? data?.length : 1, state: !addressesToShow?.state })}
        >
            <Typography
                fontSize="26px"
                fontWeight="500"
                fontFamily="Jost"
                color='#1A1A1A'
            >
                Addresses
            </Typography>
            <Stack direction='row' alignItems='center' sx={{ cursor: 'pointer' }} >
                <Typography width='100%' color='#F25500'>{(addressesToShow?.length == data?.length && addressesToShow?.state) ? 'View less' : 'View all'}</Typography>
                <KeyboardArrowDownIcon sx={{ color: '#F25500' }} />
            </Stack>
        </Stack>}
    </>
}
