import DetailsUse from "components/Atoms/SharedComponents/CRUD/details-use";
import { detailsData } from "templates/vendor/vendorListing/details";

export default function Details({ details }) {
    return (
        <DetailsUse
            data={{
                deleteBtn: false,
                editBtn: false,
                data: {
                    list: detailsData({ data: details }),
                },
            }}
        />
    );
}
