import { Box, FormControl, InputLabel, MenuItem, Select, Stack, Typography } from "@mui/material";
import CFormErrors from "./CFormErrors";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useThemePalette } from "common/hooks/theme_palette";
import CircularProgress from "@mui/material/CircularProgress";
import { isScrolledToBottom, removeDuplicateObjects } from "services/modules/utils/Helper";
const SelectOptions = ({
  options,
  changeAction,
  label,
  width = "100%",
  name,
  formik,
  onChange,
  onOpen,
  placeholder,
  isLoading = false,
  height = "auto",
  //test
  count,
  getList,
  disabled,
}) => {
  const value = formik?.values[name];
  const errorsMsg = formik.errors[name];
  const { inputBg, oliveGreen } = useThemePalette();
  options = removeDuplicateObjects(options, "id");
  const handleMenuScrollToBottom = (e) => {
    const isBottom = isScrolledToBottom(e);
    if (isBottom && getList) {
      if (options?.length < count && !isLoading) {
        /* for edit form, initial values has to be a part of the options so they can be displayed,
         so we add the initial values manually to the options[] 
         This might cause the options.length to -sometimes- equal 11/21/31 not 10/20/30 which will mess the pagination
         that's why we have different logic to handle the page_number */
        let page_number;
        if (Number.isInteger(options?.length / 10)) {
          page_number = Math?.ceil(options?.length / 10 + 1);
        } else {
          page_number = Math?.ceil(options?.length / 10);
        }
        getList({
          page_number,
        });
        return;
      }
    }
  };
  
  return (
    <Stack w={width} p="0" justifyContent="center">
      {label && (
        <Typography
          as="span"
          sx={{
            color: "#797979",
            fontSize: "18px",
            fontFamily: "Jost",
            mb: 2,
          }}
        >
          {label}
        </Typography>
      )}
      <FormControl fullWidth>
        <InputLabel // Use InputLabel
          htmlFor="demo-simple-select"
          sx={{ color: "#D7D7D7" }}
        >
          {placeholder}
        </InputLabel>

        <Stack sx={{ position: "relative" }}>
          {isLoading && (
            <Box
              sx={{
                position: "absolute !important",
                zIndex: 100,
                right: 35,
                top: "30%",
              }}
            >
              <CircularProgress
                sx={{
                  color: oliveGreen,
                }}
                size={25}
              />
            </Box>
          )}
          <Select
            IconComponent={KeyboardArrowDownIcon}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={value ? value : ""}
            disabled={disabled}
            onChange={(e) => {
              changeAction(e.target.value);
              onChange && onChange(e.target.value);
            }}
            onOpen={(_) => onOpen && onOpen({ page_number: 1 })}
            sx={{
              boxShadow: "none",
              bgcolor: inputBg,
              ".MuiOutlinedInput-notchedOutline": { border: 0 },
              "& .MuiFormLabel-root-MuiInputLabel-root": {
                display: "none !important",
              },
            }}
            MenuProps={{
              PaperProps: {
                onScroll: (event) => {
                  // onMenuScrollToBottom && onMenuScrollToBottom(event);
                  handleMenuScrollToBottom(event);
                },
                sx: {
                  height: options?.length >= 10 ? "200px" : options?.length === 0 ? "70px" : "auto", // Set your desired height here
                },
              },
            }}
          >
            {options?.map((data, index) => (
              <MenuItem key={index} value={data?.id}>
                {data?.name}
              </MenuItem>
            ))}
          </Select>
        </Stack>
      </FormControl>
      {errorsMsg && <CFormErrors errorsMsg={errorsMsg} />}
    </Stack>
  );
};

export default SelectOptions;
