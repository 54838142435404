import { makeFilterString } from "../utils/Helper";
import {
  CreateVariantBranchSuccess,
  CreateVariantSuccess,
  DeleteProductVariantSuccess,
  DeleteVariantBranchSuccess,
  GetCustomersReviewsSuccess,
  GetOptionValuesLoading,
  GetOptionValuesSuccess,
  GetProductDetailsSuccess,
  GetProductSuccess,
  ProductVariantsLoading,
  GetProductVariantsSuccess,
  GetVariantBranchesSuccess,
  GetVariantDetailsSuccess,
  ProductsLoading,
  UpdateProductSuccess,
  UpdateVariantBranchSuccess,
  UpdateVariantSuccess,
} from "./Action";

import { reduxRequest } from "common/utils/reduxRequest";

export const getProductsRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: ProductsLoading,
    loadingType: "list",
    successFunction: GetProductSuccess,
    url: `/admin/products${makeFilterString(variables?.params)}`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const createProductRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: ProductsLoading,
    loadingType: "create",
    action: variables?.action,
    url: `/admin/products`,
    data: variables?.body,
    method: "post",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const getProductDetailsRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: ProductsLoading,
    loadingType: "details",
    successFunction: GetProductDetailsSuccess,
    action: variables?.action,
    url: `/admin/products/${variables?.id}`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const updateProductRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: ProductsLoading,
    loadingType: "update",
    successFunction: UpdateProductSuccess,
    action: variables?.action,
    url: `/admin/products/${variables?.id}`,
    method: "PUT",
    data: variables?.body,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const deleteProductRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: ProductsLoading,
    loadingType: "delete",
    action: variables?.action,
    url: `/admin/products/${variables?.id}`,
    method: "delete",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

//VARIANTS
export const getProductVariantsRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: ProductVariantsLoading,
    loadingType: "listVariants",
    successFunction: GetProductVariantsSuccess,
    url: `/admin/variants${makeFilterString(variables?.params)}`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const getVariantDetailsRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: ProductVariantsLoading,
    loadingType: "variantsDetails",
    successFunction: GetVariantDetailsSuccess,
    action: variables?.action,
    url: `/admin/variants/${variables?.id}`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const deleteProductVariantRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: ProductVariantsLoading,
    loadingType: "deleteVariant",
    successFunction: DeleteProductVariantSuccess,
    action: variables?.action,
    url: `/admin/variants/${variables?.id}`,
    method: "delete",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const createVariantRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: ProductVariantsLoading,
    loadingType: "createVariant",
    successFunction: CreateVariantSuccess,
    action: variables?.action,
    url: `/admin/variants`,
    data: variables?.body,
    method: "post",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const updateVariantRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: ProductVariantsLoading,
    loadingType: "updateVariant",
    successFunction: UpdateVariantSuccess,
    action: variables?.action,
    url: `/admin/variants/${variables?.id}`,
    method: "PUT",
    data: variables?.body,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

//variants branches
export const getVariantsBranchesRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: ProductsLoading,
    loadingType: "listBranches",
    successFunction: GetVariantBranchesSuccess,
    url: `/admin/google_cities_stores${makeFilterString(variables?.params)}`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
export const getScrollVariantsBranchesRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: ProductVariantsLoading,
    loadingType: "filter-branches-list",
    successFunction: GetVariantBranchesSuccess,
    url: `/admin/google_cities_stores${makeFilterString(variables?.params)}`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const createVariantBranchRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: ProductVariantsLoading,
    loadingType: "createVariantBranch",
    successFunction: CreateVariantBranchSuccess,
    action: variables?.action,
    url: `/admin/google_cities_variants`,
    data: variables?.body,
    method: "post",
    variables,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const updateVariantBranchRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: ProductVariantsLoading,
    loadingType: "updateVariantBranch",
    successFunction: UpdateVariantBranchSuccess,
    action: variables?.action,
    url: `/admin/google_cities_variants/${variables?.variant_id}/${variables?.google_city_id}`,
    method: "PATCH",
    data: variables?.body,
    variables,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const deleteVariantBranchRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: ProductVariantsLoading,
    loadingType: "deleteVariantBranch",
    successFunction: DeleteVariantBranchSuccess,
    action: variables?.action,
    url: `/admin/google_cities_variants/${variables?.variant_id}/${variables?.google_city_id}`,
    method: "delete",
    variables,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const getCustomersReviewsRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: ProductsLoading,
    loadingType: "reviews-list",
    successFunction: GetCustomersReviewsSuccess,
    url: `/admin/reviews${makeFilterString(variables?.params)}`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const getOptionValuesRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: GetOptionValuesLoading,
    loadingType: `option value ${variables?.params?.option_type_id}`,
    successFunction: GetOptionValuesSuccess,
    url: `/admin/product_options/option_values${makeFilterString(variables?.params)}`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
