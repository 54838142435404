import { icons, images } from "assets/AssetHelper";
import dayjs from "dayjs";

export const detailsData = ({ data }) => [
  {
    head: "Details",
    icon: icons?.details,
    details: [
      {
        label: "Origin zone",
        name: data?.origin_zone?.name,
      },
      {
        label: "Destination zone",
        name: data?.destination_zone?.name,
      },
      {
        label: "City",
        name: data?.city?.name, // to be changed
      },
    ],
  },
  {
    head: "Fees",
    icon: icons?.fees,

    details: [
      {
        label: "Delivery fees",
        name: `${data?.delivery_fees} SAR`,
      },
    ],
  },
  {
    head: "Dates",
    icon: icons?.dates,
    details: [
      {
        label: "Created At",
        name: dayjs(data?.created_at).format("DD-MM-YYYY hh:mm"),
      },
      {
        label: "Updated At",
        name: dayjs(data?.updated_at).format("DD-MM-YYYY hh:mm"),
      },
    ],
  },
];
