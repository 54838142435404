const CustomerComplaintReasonsTypes= {
// CUSTOMER COMPLAINT REASONS
    //LOADING
    CUSTOMER_COMPLAINT_REASONS_LOADING:"CUSTOMER_COMPLAINT_REASONS_LOADING",
//list
    GET_CUSTOMER_COMPLAINT_REASONS_SUCCESS:
        "GET_CUSTOMER_COMPLAINT_REASONS_SUCCESS",
    //details
    GET_CUSTOMER_COMPLAINT_REASON_DETAILS_SUCCESS:
        "GET_CUSTOMER_COMPLAINT_REASON_DETAILS_SUCCESS",
    //create
    ADD_CUSTOMER_COMPLAINT_REASON_SUCCESS: "ADD_CUSTOMER_COMPLAINT_REASON_SUCCESS",
    //delete
    DELETE_CUSTOMER_COMPLAINT_REASON_SUCCESS:
        "DELETE_CUSTOMER_COMPLAINT_REASON_SUCCESS",
    //update
    UPDATE_CUSTOMER_COMPLAINT_REASON_SUCCESS:
        "UPDATE_CUSTOMER_COMPLAINT_REASON_SUCCESS"
}
export default CustomerComplaintReasonsTypes