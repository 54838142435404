import Types from "./Types";

export const GetCitiesSuccess = (payload) => ({
  type: Types.GET_CITIES_SUCCESS,
  payload,
});

export const CitiesLoading = (payload) => ({
  type: Types.CITIES_LOADING,
  payload,
});

export const GetCityDetailsSuccess = (payload) => ({
  type: Types.GET_CITY_DETAILS_SUCCESS,
  payload,
});

export const GetScrollCitiesSuccess = (payload) => ({
  type: Types.GET_SCROLL_CITIES_SUCCESS,
  payload,
});