import { Box, CircularProgress, Modal, Stack } from "@mui/material";
import { useThemePalette } from "common/hooks/theme_palette";
import DetailsUse from "components/Atoms/SharedComponents/CRUD/details-use";
import FormHeader from "components/Molecules/Products/Variants/formHeader";
import { detailsData } from "templates/products/productVariant/details";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 650,
  height: 900,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "8px",
  overflow: "hidden",
};

export default function VariantDetails({ open, setOpen, details, optionTypesFilter, load }) {
  const { oliveGreen } = useThemePalette();
  const handleClose = () => {
    setOpen(false);
  };

  const data = {
    pageTitle: "",
    editBtn: false,
    deleteBtn: false,
    data: {
      list: detailsData({ data: { ...details, optionTypesFilter } }),
      images: details?.media?.map((image) => {
        return {
          img: image?.url,
        };
      }),
    },
  };

  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Box sx={style}>
        <FormHeader handleClose={handleClose} title="Variant details" />
        <Stack sx={{ overflowY: "auto", height: 700, p: 2 }}>
          {load?.loading && load?.type == "variantsDetails" ? (
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}>
              <CircularProgress sx={{ color: oliveGreen }} />
            </Box>
          ) : (
            <DetailsUse data={data} />
          )}
        </Stack>
      </Box>
    </Modal>
  );
}
