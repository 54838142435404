import { CircularProgress } from "@mui/material";
import { useThemePalette } from "common/hooks/theme_palette";
import ConfirmationModal from "components/Molecules/Models/ConfirmationModal";
import React from "react";

export default function DeleteReturnRequestModel({
  openModel,
  setOpenModel,
  handleUpdate,
  userType,
  load,
}) {
  const { red } = useThemePalette();

  return (
    <>
      <ConfirmationModal
        open={openModel}
        setOpen={setOpenModel}
        handleConfirmation={(_) => {
          handleUpdate();
        }}
        text={[
          `Cancel  Request`,
          `Are you sure you want to cancel this request?`,
        ]}
        textStyle={{ color: red, fontSize: "32px", fontWeight: 500 }}
        confirmBtnTitle={
          load ? (
            <CircularProgress
              sx={{
                color: "white",
                width: "33px !important",
                height: "33px !important",
              }}
            />
          ) : (
            "Cancel"
          )
        }
        btnStyle={{
          backgroundColor: red,
          "&:hover": {
            backgroundColor: red,
          },
          color: "white",
        }}
      />
    </>
  );
}
