import { icons, images } from 'assets/AssetHelper'
import * as dayjs from "dayjs";

export const detailsData = ({ data }) => [
    {
        head: 'Details',
        icon: icons?.detailsIcon,
        details: [
            {
                label: 'Wrap Name(EN)',
                name: data?.name,
            },
            {
                label: 'Wrap Name(AR)',
                name: data?.name,
            },
            {
                label: 'Wrappable type',
                name: data?.wrappable_type,
            },
            {
                label: 'Wrap variant',
                name: data?.wrap_variant?.name,
            },
            {
                label: 'Ribbon color',
                name: data?.ribbon_color?.name,
            },
            {
                label: 'Wrap extras',
                name: data?.wrap_extras?.map(wrap=>wrap.name).join(', '),
            },
            {
                label: 'Description(EN)',
                name: data?.localized_data?.en?.description,
            },
            {
                label: 'Description(AR)',
                name: data?.localized_data?.ar?.description,
            },

        ]
    },
    {
        head: 'prices',
        icon: icons.priceIcon,
        details: [
            {
                label: 'Prices',
                name: data?.cost+' '+data?.wrap_variant?.currency?.name,
            },
            {
                label: 'Price after discount',
                name: data?.cost_after_discount+' '+data?.wrap_variant?.currency?.name
            },
        ]
    },
];