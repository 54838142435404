import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  changePasswordRequest,
  getAdminUserDetailsRequest,
} from "services/modules/users";
import ChangePasswordTemplate from "templates/change-password/ChangePassword";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";

export default function ChangePasswordPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const details = useSelector((state) => state.users.adminUser);

  const breadcrumbs = [
    {
      active: true,
      path: "/",
      title: "Home",
    },
    {
      active: true,
      path: "/admin-users",
      title: "Users/Admin Users",
    },
    {
      active: false,
      path: "",
      title: "Change Password",
    },
  ];

  const handleSubmit = (values) => {
    const action = () => {
      toast.success("Password changed successfully");
      navigate("/admin-users");
    };
    const requestData = {
      body: {
        user: {
          password: values?.password,
          password_confirmation: values?.confirmPassword,
        },
      },
      params: {
        user_email: encodeURIComponent(details?.email),
        user_type: "admin_user",
      },
      action,
    };

    dispatch(changePasswordRequest(requestData));
  };

  const modalComponents = [];

  useEffect(() => {
    if (id) dispatch(getAdminUserDetailsRequest({ id }));
  }, [id]);
  console.log(encodeURIComponent('abdelrahman.yehia.inovaeg+59@gmail.com'));
  return (
    <>
      <ChangePasswordTemplate
        breadcrumbs={breadcrumbs}
        handleSubmit={handleSubmit}
        user={details}
        modalComponents={modalComponents}
      />
    </>
  );
}
