import Types from "./Types";

export const GetUserOccasionsSuccess = (payload) => ({
    type: Types.GET_USER_OCCASIONS_SUCCESS,
    payload,
});
export const GetUserOccasionDetailsSuccess = (payload) => ({
    type: Types.GET_USER_OCCASION_DETAILS_SUCCESS,
    payload,
});
export const GetUserOccasionsLoading = (payload) => ({
    type: Types.USER_OCCASIONS_LOADING,
    payload,
});