import Logo from "./images/logo.svg";
import Logo2 from "./images/orange_logo.svg";
import Usersetting from "./icons/usersetting.svg";
import Home from "./icons/home.svg";
import User from "./icons/user.svg";
import Assistant from "./icons/assistant.svg";
import Courses from "./icons/courses.svg";
import Setting from "./icons/setting.svg";
import imageIcon from "./icons/image.svg";
import deleteIcon from "./icons/delete.svg";
import editIcon from "./icons/edit.svg";
import videoIcon from "./icons/video.svg";
import exportIcon from "./icons/export.svg";
import plusCircleIcon from "./icons/plus-circle.svg";
import orangeSearchIcon from "./icons/orange_search_icon.svg";
import blackSearchIcon from "./icons/black_search_icon.svg";
import filterIcon from "./icons/filter.svg";
import AddPersonIcon from "./icons/add-person.svg";
import SourceIcon from "./icons/source.svg";
import AttachedIcon from "./icons/attached.svg";
import EmptyQuestionsIcons from "./icons/empty-questions.svg";
import QuestionBankIcon from "./icons/question-bank.svg";
import Delete from "./icons/Delete-icon.svg";
import Download from "./icons/Download.svg";
import Mask from "./dummy/Mask.png";
import loginSideImage from "./images/login-page-side-image.png";
import lockIcon from "./icons/lock.svg";
import notifications from "./icons/notifications.svg";
import resetPassword from "./icons/reset-password.svg";
import errorCircle from "./icons/error-circle.svg";
import check from "./icons/check-one.svg";
import location from "./icons/location.svg";
import templateDeleteIcon from "./icons/deleteIcon.svg";
import editWhite from "./icons/editWhite.svg";
import detailsIcon from "./icons/detailsIcon.svg";
import dates from "./icons/dates.svg";
import fees from "./icons/fees.svg";
import map from "./icons/map.svg";
import details from "./icons/details.svg";
import uploadIcon from "./icons/uploadIcon.svg";
import role from "./icons/role.svg";
import email from "./icons/email.svg";
import phone from "./icons/phone.svg";
import checkMark from "./icons/checkMark.svg"
import checkedfilled from "./icons/checkedfilled.svg"
import complaintsIcon from "./icons/complaintsIcon.svg"
import dateIcon from "./icons/dateIcon.svg"
import timeIcon from "./icons/timeIcon.svg"
import dirverIcon from "./icons/dirverIcon.svg"
import locationIcon from "./icons/locationIcon.svg"
import box from "./icons/box.svg"
import dashboard from "./icons/dashboard.svg";
import users from "./icons/users.svg";
import giftcards from "./icons/gift_cards.svg";
import cities from "./icons/cities.svg";
import gear from "./icons/gear.svg";
import reportData from "./icons/carbon_report-data.svg";
import wrappers from "./icons/wrappers.svg";
import occassions from "./icons/occassions.svg";
import info from "./icons/info.svg";
import phoneIcon from "./icons/phoneIcon.svg";
import driversInfo from "./icons/driversInfo.svg";
import blackRightArrow from "./icons/blackRightArrow.svg";
import redRightArrow from "./icons/redRightArrow.svg";
import checked from "./icons/checked.svg";
import locationOutlined from "./icons/locationOutlined.svg";
import emailIcon from "./icons/emailIcon.svg";
import cancelled from "./icons/cancelled.svg"
import userInfo from "./icons/user_info.svg"
import routes from "./icons/routes.svg"
import  logoIcon from "./icons/logoIcon.svg"
import eyeIcon from "./icons/eyeIcon.svg"
import vendorIcon from "./icons/vendorIcon.svg"
import colorEidtIcoon from"./icons/colorEditIcon.svg"
// import locationIcon from "./icons/locationIcon.svg"
import boxes from "./icons/boxes.svg"
import orders from "./icons/orders.svg"
import outlinedEye from "./icons/outlinedEye.svg"
import emptyChatImage from "./images/emptyChatImage.png"
import returnemptyImage from"./images/returnemptyImage.png"
import sendIcon from "./icons/sendIcon.svg"
import sendDocument from "./icons/sendDocument.svg"
import direction from "./icons/direction.svg"
import profilePlaceholder from "./icons/profilePlaceholder.jpg"


import products from "./icons/products.svg";
import chat from "./icons/chat.svg";
import media from "./icons/media.png";
import priceIcon from "./icons/priceIcon.svg";
import dimensions from "./icons/dimensions.svg";
import document from "./icons/document.svg";
import adminIcon from "./icons/adminIcon.svg";
import driverIcon from "./icons/driverIcon.svg";
import ordersIcon from "./icons/ordersIcon.svg";
import productsIcon from "./icons/productsIcon.svg";
import userIcon from "./icons/userIcon.svg";
// import vendorIcon from "./icons/vendorIcon.svg";
import messengerIcon from "./icons/messengerIcon.svg";
import complain from "./icons/complain.svg";
import drive_pdf from "./icons/drive_pdf.svg";
import vendorSidebarIcon from "./icons/vendorSidebarIcon.svg";
import destination from "./icons/destination.svg";
import driver from "./icons/driver.svg";
import store from "./icons/store.svg";
import storeIcon from "./icons/storeIcon.svg";
import success from "./icons/success.svg";

export const dummy = {
  Mask,
};

export const images = {
  Logo,
  loginSideImage,
  Logo2,
  emptyChatImage,
  returnemptyImage
};

export const icons = {
  success,
  storeIcon,
  store,
  driver,
  destination,
  direction,
  vendorSidebarIcon,
  complain,
  messengerIcon,
  adminIcon,
  driverIcon,
  ordersIcon,
  productsIcon,
  userIcon,
  vendorIcon,
  drive_pdf,
  sendDocument,
  profilePlaceholder,
  sendIcon,
  chat,
  outlinedEye,
  orders,
  emailIcon,
  locationOutlined,
  checked,
  redRightArrow,
  blackRightArrow,
  driversInfo,
  phoneIcon,
  locationIcon,
  info,
  dirverIcon,
  dateIcon,
  timeIcon,
  checkedfilled,
  detailsIcon,
  editWhite,
  templateDeleteIcon,
  Usersetting,
  Home,
  User,
  Assistant,
  Courses,
  Setting,
  imageIcon,
  deleteIcon,
  editIcon,
  videoIcon,
  exportIcon,
  plusCircleIcon,
  orangeSearchIcon,
  blackSearchIcon,
  filterIcon,
  AddPersonIcon,
  SourceIcon,
  AttachedIcon,
  EmptyQuestionsIcons,
  QuestionBankIcon,
  Delete,
  Download,
  lockIcon,
  notifications,
  resetPassword,
  errorCircle,
  check,
  location,
  dates,
  fees,
  map,
  details,
  uploadIcon,
  role,
  email,
  phone,
  checkMark,
  box,
  cancelled,
  userInfo,
  routes,
  boxes,
  products,
  media,
  dashboard,
  users,
  giftcards,
  cities,
  gear,
  reportData,
  wrappers,
  occassions,
  complaintsIcon,
  colorEidtIcoon,
  logoIcon,
  eyeIcon,
  dimensions,
  priceIcon,
  document,
};
