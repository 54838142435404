import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Loader from "components/Atoms/SharedComponents/Loader/Loader";
import setAuthToken from "services/modules/utils/handel_api";

import {
  GetWrapsRequest,
  getScrollWrapsRequest,
} from "services/modules/Wrappers/Wraps";
import {
  GetWrapVarintsRequest,
  addWrapVariantRequest,
  deleteWrapVariantRequest,
  getWrapVariantDetailsRequest,
  updateWrapVariantRequest,
} from "services/modules/Wrappers/wrapVariants";
import {
  GetShapeRequest,
  getScrollShapesRequest,
} from "services/modules/Wrappers/shapes";
import { toast } from "react-toastify";
import {
  GetColorsRequest,
  GetScrollColorsRequest,
} from "services/modules/Wrappers/colors";
import WrapsVariantsTemplate from "templates/wrappers/wrapVariants";
function WrapVariants() {
  const token = localStorage.getItem("token");
  setAuthToken(token);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  let [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const type = searchParams.get("type");

  const list = useSelector((state) => state.wrapVariant.wrapVariants);
  const wrapsList = useSelector((state) => state.wrappers.wraps);
  const shapesList = useSelector((state) => state.shapes.shapes);
  const colorsList = useSelector((state) => state.colors.colors);
  const count = useSelector((state) => state.wrapVariant.count);
  const details = useSelector((state) => state.wrapVariant.wrapVariant);
  const load = useSelector((state) => state.wrapVariant.load);
  const filterWraps = useSelector((state) => state.wrappers.filterWraps);
  const filterShapes = useSelector((state) => state.shapes.filterShapes);
  const filterColors = useSelector((state) => state.colors.filterColors);
  const colorsLoad = useSelector((state) => state.colors.load);
  const wrapsLoad = useSelector((state) => state.wrappers.load);
  const shapesLoad = useSelector((state) => state.shapes.load);

  const wrappersCount = useSelector((state) => state.wrappers.listCount);
  const shapesCount = useSelector((state) => state.shapes.listCount);
  const colorsCount = useSelector((state) => state.colors.listCount);
  //permissions
  const currentUser = useSelector(
    (state) => state.authenticate.currentUserProfile
  );
  const permissions = useSelector(
    (state) => state.authenticate.currentUserProfile.permissions.wrap_variants
  );
  const isSuperAdmin = currentUser?.privilege == "super_admin";

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [page, setPage] = useState(1);
  const [filterObj, setFilterObj] = useState(null);

  const handlePageChange = (page) => {
    setPage(page);
    setFilterObj((prev) => {
      return { ...prev, page_number: page };
    });
  };

  const getDetails = async () => {
    dispatch(getWrapVariantDetailsRequest({ wrap_variant_id: id }));
  };

  const getList = async (filterObj) => {
    const requestData = {
      params: {
        page_size: 10,
        page_number: 1,
        keyword: "",
        sort_by: "id",
        ...filterObj,
      },
    };
    dispatch(GetWrapVarintsRequest(requestData));
  };
  // mappedData use for create custom cell to concat multi values or navigate button
  const mappedData = list?.map((data, index) => {
    return {
      ...data,
      wrap: data?.wrap?.name,
    };
  });

  const rowData = (data) => {
    setSelectedRow(data);
  };
  const breadcrumbs = [
    {
      active: true,
      path: "/",
      title: "Home",
    },
    {
      active: !type ? false : true,
      path: "/wrap-variants",
      title: "wrappers/Wrap variants",
    },
    type && {
      active: false,
      title:
        type === "details"
          ? "Wrap Variant Details"
          : id
          ? "Edit Wrap Variant"
          : "New Wrap Variant",
    },
  ];

  const handelSubmit = (data, formik) => {
    const action = () => {
      searchParams.delete("id");
      searchParams.delete("type");
      navigate("/wrap-variants");
      !id && formik?.resetForm();
      toast.success(
        `New wrap variant has been ${id ? "updated" : "created"} successfully`
      );
    };
    const requestData = {
      body: { wrap_variant: { ...formik?.values } },
      action,
    };
    if (id) {
      dispatch(updateWrapVariantRequest({ ...requestData, id }));
    } else {
      //create call
      dispatch(addWrapVariantRequest(requestData));
    }
  };

  const getWraps = (filterObj) => {
    const requestData = {
      params: {
        page_size: 10,
        keyword: "",
        sort_by: "id",
        ...filterObj,
      },
    };
    dispatch(getScrollWrapsRequest(requestData));
  };
  const getShapes = (filterObj) => {
    const requestData = {
      params: {
        page_size: 10,
        keyword: "",
        sort_by: "id",
        ...filterObj,
      },
    };
    dispatch(getScrollShapesRequest(requestData));
  };
  const getColors = (filterObj) => {
    const requestData = {
      params: {
        page_size: 10,
        keyword: "",
        sort_by: "id",
        ...filterObj,
      },
    };
    dispatch(GetScrollColorsRequest(requestData));
  };
  const filterList = [
    {
      type: "filter",
      placeholder: "Wrap",
      onChange: (value) => {
        setFilterObj((s) => {
          return { ...s, wrap_id: value?.value, page_number: 1 };
        });
      },
      onMenuOpen: () => {
        filterWraps?.length == 0 && getWraps({});
      },

      options: filterWraps && filterWraps,
      getList: getWraps,
      applySearchFromRequest: true,
      count: wrappersCount,
    },
    {
      type: "filter",
      placeholder: "Shape",
      onChange: (value) => {
        setFilterObj((s) => {
          return { ...s, shape_id: value?.value, page_number: 1 };
        });
      },
      onMenuOpen: () => {
        filterShapes?.length == 0 && getShapes({});
      },
      options: filterShapes,
      getList: getShapes,
      count: shapesCount,
      applySearchFromRequest: true,
    },
    {
      type: "filter",
      placeholder: "Color",
      onChange: (value) => {
        setFilterObj((s) => {
          return { ...s, color_id: value?.value, page_number: 1 };
        });
      },
      onMenuOpen: () => {
        filterColors?.length == 0 && getColors({});
      },
      options: filterColors && filterColors,
      getList: getColors,
      count: colorsCount,
      applySearchFromRequest: true,
    },
  ];
  const handleDelete = (id) => {
    // const id = selectedRow?.rowData?.id;
    //action to be called after successfull deletion
    const action = () => {
      //if list.length == 1 & page !== 1 -> setPage(page-1) & fetch
      if ((list.length === 1) & (page !== 1)) {
        setPage(page - 1);
        setFilterObj({
          ...filterObj,
          page_number: page - 1,
        });
        getList({ ...filterObj, page_number: page - 1 }); //temporary until the backend sends the data correctly so I can update the state instead of re-fetching
      } else {
        getList(filterObj);
      }
      if (type == "details") {
        navigate(-1);
      }
      toast.success("Wrap variant has been deleted successfully");
    };
    const requestData = {
      id,
      action, //dispatched on success
    };
    dispatch(deleteWrapVariantRequest(requestData));
    setOpenDeleteModal(false);
  };

  //list of actions to be displayed when clicked in the 3 dots
  const actionsList = ({ index, data }) => {
    return [
      {
        condition: () => {
          return true;
        },
        click: () => {
          navigate(`?type=details&id=${data?.id}`);
        },
        text: "View",
      },
      {
        condition: () => {
          return isSuperAdmin || permissions?.can_update;
        },
        click: () => {
          navigate(`?type=form&id=${data?.id}`);
        },
        text: "Edit",
      },
      {
        condition: () => {
          return isSuperAdmin || permissions?.can_delete;
        },
        click: () => {
          setOpenDeleteModal(true);
        },
        text: "Delete",
      },
    ];
  };

  //if you need more buttons in the header beside the add button
  const headerChildren = <></>;

  //if more modals needed, pass them to the modalComponents as below
  const modalComponents = [];

  //listing all tags
  useEffect(() => {
    if (filterObj) getList(filterObj);
  }, [filterObj]);

  //details of a single tag
  useEffect(() => {
    if (id) getDetails();
  }, [id]);
  const options = {
    wraps: {
      options: [details?.wrap, ...filterWraps],
      onOpen: filterWraps?.length <= 1 && getWraps,
      isLoading: wrapsLoad?.loading && wrapsLoad?.type == "wraps-scroll-list",
      getList: getWraps,
      count: wrappersCount,
    },
    shapes: {
      options: [details?.shape, ...filterShapes],
      onOpen: filterShapes?.length <= 1 && getShapes,
      isLoading:
        shapesLoad?.loading && shapesLoad?.type == "shapes-scroll-list",
      getList: getShapes,
      count: shapesCount,
    },
    colors: {
      options: [details?.color, ...filterColors],
      onOpen: filterColors?.length <= 1 && getColors,
      isLoading:
        colorsLoad?.loading && colorsLoad?.type == "colors-scroll-list",
      getList: getColors,
      count: colorsCount,
    },
  };
  console.log(colorsCount,shapesCount, "details");

  return (
    <>
      <Loader open={load?.loading && load?.type == "list"} />
      <WrapsVariantsTemplate
        filterObj={filterObj}
        setFilterObj={setFilterObj}
        data={mappedData}
        count={count}
        paginationPage={page}
        handlePageChange={handlePageChange}
        rowData={rowData}
        details={details}
        breadcrumbs={breadcrumbs}
        filterList={filterList}
        handelSubmit={handelSubmit}
        actionsList={actionsList}
        headerChildren={headerChildren}
        openDeleteModal={openDeleteModal}
        setOpenDeleteModal={setOpenDeleteModal}
        handleDeleteRequest={handleDelete}
        modalComponents={modalComponents}
        wrapsOptionsListArray={wrapsList}
        colorsList={colorsList}
        shapesList={shapesList}
        selectedRow={selectedRow}
        can_create={isSuperAdmin || permissions?.can_create}
        type={type}
        options={options}
      />
    </>
  );
}

export default WrapVariants;
