import { Button, Stack, Typography } from "@mui/material";
import { useThemePalette } from "common/hooks/theme_palette";
import TableUse from "components/Atoms/SharedComponents/CRUD/table-use";
import DeleteModal from "components/Molecules/Models/DeleteModal";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getUserOccasionsRequest } from "services/modules/occasions/usersOccasions";
import dayjs from "dayjs";
import { GetOccasionTypesRequest, GetScrollOccasionTypesRequest } from "services/modules/occasions/occasionsTypes";

export default function CustomerOccasions() {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const list = useSelector((state) => state.userOccasions.userOccasions);
  const count = useSelector((state) => state.userOccasions.count);
  const filterOccation = useSelector((state) => state.occasionsTypes.filterOccation);
  const  occationCount = useSelector((state) => state.occasionsTypes.listCount);
  const { oliveGreen, } = useThemePalette()
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [tableData, setTableData] = useState({});
  const [selectedRow, setSelectedRow] = useState({});
  const [page, setPage] = useState(1);
  let [searchParams] = useSearchParams();
  const id = searchParams.get("id");


  const [filterObj, setFilterObj] = useState(null);

  const columns = [
    { accessor: "id", Header: "#" },
    { accessor: "localized_data.en.name", Header: "Occasion Name(EN)", sort: true },
    {
      accessor: "localized_data.ar.name",
      Header: "Occasion Name(AR)",
      sort: true,
    },
    { accessor: "occasion_type.name", Header: "Occasion Type", sort: true },
    { accessor: "updated_at", Header: "Updated At", sort: true },
  ];

  const actionsList = ({ index, data }) => {
    return [
      {
        condition: () => {
          return true;
        },
        click: () => {
          navigate(`/orders?type=details&id=${data?.id}`);
        },
        text: <Typography color={oliveGreen} >View Order details</Typography>,
      },
    ];
  };
  const getOccasionsTypes = (filterObj) => {
    const requestData = {
      params: {
        page_size: 10, //to get all data
        page_number: 1,
        ...filterObj
      },
    };
    dispatch(GetScrollOccasionTypesRequest(requestData));
  }
  const filterList = [
    {
      type: "filter",
      placeholder: "Occasion Type",
      onChange: (value) => {
        setFilterObj((s) => {
          return { ...s, occasion_type_id: value?.value, page_number: 1 };
        });
      },
      // options: occasionsTypes,
      onMenuOpen: () => {
        filterOccation?.length == 0 && getOccasionsTypes({});
      },
      options: filterOccation && filterOccation,
      getList: getOccasionsTypes,
      count: occationCount,
      applySearchFromRequest: true,
    },
  ];


  const rowData = (data) => {
    setSelectedRow(data);
  };

  const handlePageChange = (page) => {
    setPage(page);
    setFilterObj((prev) => {
      return { ...prev, page_number: page };
    });
  };

  const handleDelete = () => { };


  const mappedData = list?.map((data, index) => {
    return {
      ...data,
      updated_at: <>{dayjs(data?.updated_at).format("DD-MM-YYYY,hA")} </>,
    };
  });

  const headerChildren = []

  const getList = async (filterObj) => {

    const requestData = {
      params: {
        page_size: 10,
        page_number: 1,
        keyword: "",
        sort_by: "id",
        customer_id: id,
        ...filterObj
      },
    };
    dispatch(getUserOccasionsRequest(requestData));
  };

  useEffect(() => {
    if (filterObj) getList(filterObj);
  }, [filterObj]);
  
  useEffect(() => {
    rowData({ ...tableData });
  }, [tableData]);

  return (
    <Stack sx={{ backgroundColor: "white", p: '20px' }}>
      {/* <VariantForm
        open={isOpen}
        setOpen={setIsOpen}
        id={variantId && variantId}
      /> */}
      <DeleteModal
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        handelDeleteRequest={() => {
          handleDelete(tableData?.rowData?.id && tableData?.rowData?.id);
        }}
        handleCancelDelete={() => setOpenDeleteModal(false)}
        customClass="organizations-delete-modal"
        text={[
          `Delete variant`,
          `Are you sure that you want to delete this variant?`,
        ]}
      />
      <TableUse
        list={mappedData}
        columnsData={columns}
        setTableData={setTableData}
        setFilterObj={setFilterObj}
        filterList={filterList}
        headerChildren={headerChildren}
        actionsList={actionsList}
        rowData={rowData}
        openDeleteModal={openDeleteModal}
        setOpenDeleteModal={setOpenDeleteModal}
        handlePageChange={handlePageChange}
        paginationPage={page}
        total_count={count}
      />
    </Stack>
  );
}
