import Types from "./Types";


export const GetChatRoomsSuccess = (payload,data) => ({
  type: Types.GET_CHAT_ROOMS_SUCCESS,
  payload,
  data
});

export const GetChatRoomsLoading = (payload) => ({
  type: Types.CHAT_ROOMS_LOADING,
  payload,
});


export const sendMessageSuccess = (payload) => ({
  type: Types.SEND_MESSAGE_SUCCESS,
  payload,
});


export const visitChatRoomSuccess = (payload) => ({
  type: Types.VISIT_CHAT_ROOM,
  payload,
});

export const createChatRoomSuccess = (payload) => ({
  type: Types.CREATE_CHAT_ROOM_SUCCESS,
  payload,
});

export const searchChatRoomSuccess = (payload) => ({
  type: Types.SEARCH_CHAT_ROOMS_SUCCESS,
  payload,
});

export const searchChatRommsLoading = (payload) => ({
  type: Types.SEARCH_CHAT_ROOMS_LOADING,
  payload
})

export const getChatRoomSuccess = (payload) => ({
  type: Types.SHOW_CHAT_ROOM_SUCCESS,
  payload
})