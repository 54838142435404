import React, { useState } from "react";
import CCrud from "components/Molecules/Crud";
import { initialValues, structure, validationSchema } from "./structure";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Box, Stack } from "@mui/material";
import Breadcrumb from "components/Atoms/SharedComponents/Breadcrumbs/Breadcrumb";
import AddIcon from "@mui/icons-material/Add";
import { detailsData } from "./details";
import { useEffect } from "react";

const ColorsTemplate = ({
  handlePageChange,
  data,
  details,
  count,
  handelSubmit,
  deleteFun,
  filterList,
  actionsList,
  breadcrumbs,
  setFilterObj,
  rowData,
  paginationPage,
  headerChildren,
  modalComponents,
  openDeleteModal,
  setOpenDeleteModal,
  handleDeleteRequest,
  wrapsOptionsListArray,
  selectedRow,
  can_create,
}) => {
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  const id = searchParams.get("id");

  const [supportedLang, setSupportedLang] = useState("en");
  const [wrapId, setWrapId] = useState("en");

  useEffect(() => {
    const filteredData = wrapsOptionsListArray?.find(
      (ele) => ele?.id == selectedRow?.formikData?.values?.wrap_id
    );
    setWrapId(filteredData?.dimensions);
  }, [selectedRow?.formikData?.values?.wrap_id]);

  return (
    <>
      <Box>
        <Stack direction="row" alignSelf="start" justifyContent="start" gap={2} width="100%" mb={1}>
          <Breadcrumb data={breadcrumbs} />
        </Stack>
        <CCrud
          detailsTemplateData={{
            pageTitle: "Color details",
            data: {
              list: detailsData({ data: details }),
            },
          }}
          table={{
            list: data, // data
            listTitle: "Colors", //title
            createBtnTitle: can_create && "New Color", // action
            CreateBtnIcon: AddIcon,
            createBtnFun: () => navigate("?type=form"),
            columns: [
              { accessor: "id", Header: "#" },
              {
                accessor: "localized_data.en.name",
                Header: "Color Name(EN)",
                sort: true,
              },
              { accessor: "localized_data.ar.name", Header: "Color Name(AR)", sort: true },
              { accessor: "presentation", Header: "Presentation", sort: true },
              { accessor: "Actions", Header: "Actions" },
            ],
            isSearch: false,
            searchInputPlaceholder: "Search shape..",
            isChecked: false,
            total_count: count,
            setFilterObj,
            className: "organization-table system-configurations-table",
            paginationPage: paginationPage,
            handlePageChange,
            rowData: rowData,
            filterList,
            className: "main-table",
            actionsList, //if you want to add custom actions list ...
            headerChildren,
            details,
            openDeleteModal,
            setOpenDeleteModal,
            handleDeleteRequest,
          }}
          form={{
            // lw mafesh id add mafesh values -------- lw fe id yb2a edit values
            initialValues: !id ? {} : initialValues(details, supportedLang),
            structure: () => structure(wrapsOptionsListArray, wrapId),
            validationSchema: validationSchema(wrapId),
            deleteFun: deleteFun,
            title: id ? "Edit color" : "Add New color",
            submitTitle: id ? "Update" : "Add",
            onSubmit: handelSubmit,
            className: { form: "main-form", actionButton: "main-form-buttons" },
          }}
          // DetailsComponent={<Details />} //to send details component diff from the default
          modalComponents={modalComponents}
        />
      </Box>
    </>
  );
};

export default ColorsTemplate;
