import { makeFilterString } from "../../utils/Helper";
import {
  GetProductOptionTypeDetails,
  GetProductsOptionTypes,
  GetScrollProductsOptionTypesSuccess,
  ProductsOptionTypesLoading,
  SetProductOptionType,
} from "./Actions";

import { reduxRequest } from "../../../../common/utils/reduxRequest";

export const getProductsOptionTypesRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: ProductsOptionTypesLoading,
    loadingType: "list",
    successFunction: GetProductsOptionTypes,
    url: `/admin/product_options/option_types?${makeFilterString(
      variables?.params
    )}`,
    method: "get",
  };
  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
export const getScrollProductsOptionTypesRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: ProductsOptionTypesLoading,
    loadingType: "filter-list-option-types",
    successFunction: GetScrollProductsOptionTypesSuccess,
    url: `/admin/product_options/option_types?${makeFilterString(
      variables?.params
    )}`,
    method: "get",
  };
  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
export const createProductOptionTypeRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: ProductsOptionTypesLoading,
    loadingType: "add",
    successFunction: SetProductOptionType,
    action: variables?.action,
    url: `/admin/product_options/option_types`,
    method: "post",
    data: variables?.body,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
export const getProductOptionTypeDetailsRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: ProductsOptionTypesLoading,
    loadingType: "details",
    successFunction: GetProductOptionTypeDetails,
    url: `/admin/product_options/option_types/${variables?.option_type_id}`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
export const deleteProductOptionTypeRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: ProductsOptionTypesLoading,
    loadingType: "delete option type",
    action: variables?.action,
    url: `/admin/product_options/option_types/${variables?.id}`,
    method: "delete",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
export const updateProductDetailRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: ProductsOptionTypesLoading,
    loadingType: "update option type",
    url: `/admin/product_options/option_types/${variables?.id}`,
    action: variables?.action,
    data: variables?.body,
    method: "put",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
