import { makeFilterString } from "../../utils/Helper";

import { reduxRequest } from "common/utils/reduxRequest";
import {
  AddOrderDeclinationSuccess,
  GetOrderDeclinationDetails,
  GetOrderDeclinationSuccess,
  OrderDeclinationLoading,
} from "./Action";

export const addOrderDeclinationReasonRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: OrderDeclinationLoading,
    loadingType: "add",
    successFunction: AddOrderDeclinationSuccess,
    action: variables?.action,
    url: `/admin/lookups/order_declination_reasons`,
    method: "post",
    data: variables?.body,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
export const getOrderDeclinationReasonRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: OrderDeclinationLoading,
    loadingType: "list",
    successFunction: GetOrderDeclinationSuccess,
    url: `/admin/lookups/order_declination_reasons?${makeFilterString(
      variables?.params
    )}`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
export const deleteOrderDeclinationRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: OrderDeclinationLoading,
    loadingType: "delete order",
    action: variables?.action,
    url: `/admin/lookups/order_declination_reasons/${variables?.order_declination_reasons}`,
    method: "delete",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const getOrderDeclinationDetailsRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: OrderDeclinationLoading,
    loadingType: "details",
    successFunction: GetOrderDeclinationDetails,
    url: `/admin/lookups/order_declination_reasons/${variables?.order_declination_reason}`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const updateOrderDeclinationRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: OrderDeclinationLoading,
    loadingType: "update",
    url: `/admin/lookups/order_declination_reasons/${variables?.order_declination_reason}`,
    action: variables?.action,
    data: variables?.body,
    method: "put",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
