import { Stack } from "@mui/material";
import TableUse from "components/Atoms/SharedComponents/CRUD/table-use";
import React from "react";

export default function CustomerReviewsTemplate({
  list,
  columns,
  setTableData,
  setFilterObj,
  headerChildren,
  rowData,
  handlePageChange,
  page,
  count,
  title,
}) {
  return (
    <Stack sx={{ backgroundColor: "white" }}>
      <TableUse
        list={list}
        columnsData={columns}
        setTableData={setTableData}
        setFilterObj={setFilterObj}
        headerChildren={headerChildren}
        rowData={rowData}
        handlePageChange={handlePageChange}
        paginationPage={page}
        total_count={count}
        listTitle={title}
      />
    </Stack>
  );
}
