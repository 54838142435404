import { Button, Stack, Box, Typography, Divider } from "@mui/material";
import Loader from "components/Atoms/SharedComponents/Loader/Loader";
import ErrorMessage from "components/Molecules/Form/ErrorMessage";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { OTPRequest, OTPVerify } from "services/modules/authenticate";
import OTPInput, { ResendOTP } from "otp-input-react";
import { icons } from "assets/AssetHelper";
import { useThemePalette } from "common/hooks/theme_palette";
import { AppForm, SubmitButton } from "components/Molecules/Form";
import "./style.css";
import setAuthToken from "services/modules/utils/handel_api";

export default function VerifyOTPForm({
  hideIcon,
  setResetPasswordStep,
  customAction = false,
  email = localStorage.getItem("forgetPasswordEmail"),
  userType = "admin_user",
}) {
  const [OTP, setOTP] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { gray, orange } = useThemePalette();
  const { load } = useSelector((state) => state.authenticate);

  //const email = localStorage.getItem("forgetPasswordEmail");

  const handleSubmit = () => {
    const action = (data) => {
      localStorage.setItem("verification-token", data?.data?.token);
      {
        customAction ? setResetPasswordStep({ action: true, step: 3 }) : navigate("/reset-password");
      }
    };
    const requestData = {
      body: {
        verification_code: OTP,
        user: {
          email,
        },
      },
      action,
    };

    dispatch(OTPVerify({ ...requestData, user_type: userType }));
  };

  const handleResendEmail = () => {
    const requestBody = {
      body: {
        user: {
          email,
        },
      },
    };
    dispatch(OTPRequest({ ...requestBody, user_type: userType }));
  };

  const renderButton = (buttonProps) => {
    return (
        <Typography
            sx={{
              color: buttonProps.remainingTime !== 0 ? "#727272" : orange,
              cursor: buttonProps.remainingTime !== 0 ? " not-allowed" : "pointer",
              fontWeight: "600",
              position: "absolute",
              top: 0,
              right: "70px",
              fontFamily: "Jost",
            }}
            {...buttonProps}
        >
          Resend
        </Typography>
    );
  };

  const renderTime = (remainingTime) => {
    return <Typography>{remainingTime}s</Typography>;
  };

  return (
      <>
        <Loader open={load?.loading} />
        <Stack sx={{ width: "440px" }}>
          <Stack sx={{ gap: 2 }}>
            {!hideIcon&& <Box
                component="img"
                src={icons.resetPassword}
                sx={{ width: "100px" }}
            />}
            <Stack sx={{ gap: 1 }}>
              <Typography fontFamily="jost" fontSize="32px" fontWeight="400">
                Check your email
              </Typography>
              <Typography fontFamily="jost" fontSize="18px" color={gray}>
                We have sent OTP to {email}
              </Typography>
              <Divider sx={{ border: "1px solid #F5F5F5" }} />
            </Stack>
          </Stack>

          <AppForm>
            <Stack sx={{ mt: 8, gap: 4 }}>
              <OTPInput
                  value={OTP}
                  onChange={setOTP}
                  autoFocus
                  OTPLength={6}
                  otpType="number"
                  disabled={false}
                  className="opt-input-container"
                  inputClassName="otp-input"
              />
              <SubmitButton onSubmit={handleSubmit}>DONE</SubmitButton>
              <Stack
                  sx={{
                    color: gray,
                    textAlign: "center",
                    position: "relative",
                  }}
              >
                <Typography fontFamily="Jost">
                  Didn't receive the email?
                </Typography>
                <ResendOTP
                    maxTime={30}
                    renderButton={renderButton}
                    renderTime={renderTime}
                    onResendClick={handleResendEmail}
                    className="reset-otp-container "
                />
              </Stack>
            </Stack>
          </AppForm>
        </Stack>
        {/* <div>
        <input value={OTP} onChange={(e) => setOTP(e.target.value)} />
        <Button onClick={handleSubmit}>submit</Button>
        
      </div> */}
      </>
  );
}
