const wrapsVariantsTypes = {
  // WRAPPERS
  // list
  GET_WRAP_VARIANTS_SUCCESS: "GET_WRAP_VARIANTS_SUCCESS",
  WRAP_VARIANTS_LOADING: "WRAP_VARIANTS_LOADING",
  //details 
  GET_WRAP_VARIANT_DETAILS_SUCCESS: "GET_WRAP_VARIANT_DETAILS_SUCCESS",
  // create
  ADD_WRAP_VARIANT_SUCCESS: "ADD_WRAP_VARIANT_SUCCESS",
  ADD_WRAP_VARIANT_FAILED: "ADD_WRAP_VARIANT_FAILED",
  // Delete
  DELETE_WRAP_VARIANT_SUCCESS: "DELETE_WRAP_VARIANT_SUCCESS",
  DELETE_WRAP_VARIANT_FAILED: "DELETE_WRAP_VARIANT_FAILED",
  // update
  UPDATE_WRAP_VARIANT_SUCCESS: "UPDATE_WRAP_VARIANT_SUCCESS",
  UPDATE_WRAP_VARIANT_FAILED: "UPDATE_WRAP_VARIANT_FAILED",
  GET_SCROLL_WRAP_VARIANT_SUCCESS: "GET_SCROLL_WRAP_VARIANT_SUCCESS",

};

export default wrapsVariantsTypes;
