/* eslint-disable array-callback-return */
import Types from "./Types";

const INIT_STATE = {
  systemConfigs: [],
  systemConfig: {},
  count: 0,
  load: null,
};

export function systemConfigReducer(state = INIT_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case Types.GET_SYSTEM_CONFIG_LOADING: {
      return {
        ...state,
        load: payload,
      };
    }
    case Types.GET_SYSTEM_CONFIGURATION_SUCCESS: {
      return {
        ...state,
        systemConfigs: payload?.data?.system_configurations,
        count: payload?.extra?.total_count,
      };
    }
    case Types.GET_SYSTEM_CONFIGURATION_DETAILS_SUCCESS: {
      return {
        ...state,
        systemConfig: payload?.data?.system_configuration,
      };
    }
    default: {
      return state;
    }
  }
}
