import * as Yup from "yup";

export const initialValues = (data) => {
  console.log(data)
  return {
    ...data,
  };
};
export const structure = () => [
  {
    head: "",
    width: "100%",
    list: [
     {
        name: `key`,
        kind: "input",
        type: "text",
        label: "Key",
        width: "47%",
        placeholder:"",
        disabled:true
      },
      {
        name: `value`,
        kind: "input",
        type: "text",
        label: "Value",
        width: "47%",
        placeholder:"Write value",
      },
      {
        name: `description`,
        kind: "input",
        type: "text",
        label: "Description",
        width: "47%",
        placeholder:"Write description",
      },
    ],
  },
];

export const validationSchema = () =>
  Yup.object({
    value:Yup.string().required("required"),
    // description:Yup.string().required("required"),
  });
