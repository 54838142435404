import { Typography } from "@mui/material";
import * as Yup from "yup";

export const initialValues = (data, optionTypes) => {
  const values = {};

  data?.option_values?.forEach((value) => {
    values[value?.option_type_id] = value?.id;
  });

  const types = {};
  optionTypes.forEach((option) => {
    types[option?.name] = values[option?.id];
  });

  return {
    ...data,
    ...types,
    images: data?.media?.map((image) => image.url),
  };
};

export const structure = (prepareOptionTypes) => {
  let optionTypes = prepareOptionTypes();
  return [
    {
      head: "",
      width: "100%",
      list: [
        {
          width: "100%",
          name: `images`,
          kind: "multiUpload",
          id: "images",
          type: "image",
          label: <Typography sx={{ fontsize: "16px", color: "#9F9F9F" }}>Variant image </Typography>,
        },
        ...optionTypes,
        {
          name: `sku`,
          kind: "input",
          type: "text",
          label: "Sku",
          placeholder: "Sku",
          width: "100%",
        },
        {
          name: `price`,
          kind: "input",
          type: "number",
          label: "Price",
          width: "47%",
          placeholder: "Write price",
          EndAdornment: <p style={{ fontSize: "18px", color: "#1A1A1A" }}>SAR</p>,
        },
        {
          name: `price_after_discount`,
          kind: "input",
          type: "number",
          label: "Price after discount",
          width: "47%",
          placeholder: "Write price",
          EndAdornment: <p style={{ fontSize: "18px", color: "#1A1A1A" }}>SAR</p>,
        },
        {
          name: `width`,
          kind: "input",
          type: "text",
          label: "Width*",
          width: "47%",
          placeholder: "Width",
          EndAdornment: <p style={{ fontSize: "18px", color: "#1A1A1A" }}>cm</p>,
        },
        {
          name: `height`,
          kind: "input",
          type: "text",
          label: "Height*",
          placeholder: "Height",

          width: "47%",
          EndAdornment: <p style={{ fontSize: "18px", color: "#1A1A1A" }}>cm</p>,
        },
        {
          name: `length`,
          kind: "input",
          type: "text",
          label: "Length*",
          placeholder: "Length",
          width: "47%",
          EndAdornment: <p style={{ fontSize: "18px", color: "#1A1A1A" }}>cm</p>,
        },
        {
          name: `weight`,
          kind: "input",
          type: "text",
          label: "Weight*",
          placeholder: "Weight",
          width: "47%",
          EndAdornment: <p style={{ fontSize: "18px", color: "#1A1A1A" }}>gm</p>,
        },
        {
          name: `volume`,
          kind: "input",
          type: "text",
          label: "Volume",
          placeholder: "Volume",
          width: "47%",
          EndAdornment: (
            <p style={{ fontSize: "18px", color: "#1A1A1A" }}>
              m<sup style={{ fontSize: "12px" }}>3</sup>
            </p>
          ),
        },
      ],
    },
  ];
};

export const validationSchema = (optionTypesFilter, test) => {
  let types = {};
  optionTypesFilter?.forEach((option) => {
    types[option?.name] = Yup.string().required("required");
  });
  return Yup.object({
    ...types,
    images: Yup.array(),
    sku: Yup.string(),
    width: Yup.number().required("required"),
    height: Yup.number().required("required"),
    length: Yup.number().required("required"),
    weight: Yup.number().required("required"),
    volume: Yup.number(),
    price: Yup.number(),
    price_after_discount: Yup.number()
      .typeError("Invalid Price")
      .nullable()
      .test("is-greater", "Price After discount must be less than original price", function (value) {
        const { price } = this.parent;
        if (value == null) {
          return true; // Allow empty price_after_discount
        }
        return value <= price;
      }),
  });
};
