import Types from "./Types";

export const CustomerComplaintsLoading=(payload)=>({
    type:Types.CUSTOMER_COMPLAINTS_LOADING,
    payload
})
export const GetCustomerComplaints = (payload) => ({
    type: Types.GET_CUSTOMER_COMPLAINTS_SUCCESS,
    payload,
});
export const GetCustomerComplaintDetails = (payload) => ({
    type: Types.GET_CUSTOMER_COMPLAINT_DETAILS_SUCCESS,
    payload,
});
export const SetCustomerComplaints = (payload) => ({
    type: Types.SET_CUSTOMER_COMPLAINTS_SUCCESS,
    payload,
});
export const UpdateCustomerComplaints = (payload) => ({
    type: Types.UPDATE_CUSTOMER_COMPLAINT_SUCCESS,
    payload,
});
export const DeleteCustomerComplaints = (payload) => ({
    type: Types.DELETE_CUSTOMER_COMPLAINT_SUCCESS,
    payload,
});

