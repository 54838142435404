import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Loader from "components/Atoms/SharedComponents/Loader/Loader";
import { toast } from "react-toastify";
import GiftCardsTemplate from "templates/gift-cards";
import {
  createGiftCardRequest,
  deleteGiftCardRequest,
  getGiftCardDetailsRequest,
  getGiftCardsRequest,
  updateGiftCardRequest,
} from "services/modules/giftCards";
import setAuthToken from "services/modules/utils/handel_api";
import { Typography } from "@mui/material";
import { useThemePalette } from "common/hooks/theme_palette";
import ApproveModel from "components/Molecules/users/model/approveModel";
import RejectionModel from "components/Molecules/users/model/rejectionModel";
import SuspendModel from "components/Molecules/users/model/suspendModel";
import AvailableGiftCardModel from "components/Molecules/users/model/availableGiftCardModel";

function GiftCardsPage() {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  const { backgroundGreen, backgroundRed, textGreen, textRed } =
    useThemePalette();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const type = searchParams.get("type");

  const list = useSelector((state) => state.giftCards.giftCards);
  const count = useSelector((state) => state.giftCards.count);
  const details = useSelector((state) => state.giftCards.giftCard);
  const load = useSelector((state) => state.giftCards.load);

  //permissions
  const currentUser = useSelector(
    (state) => state.authenticate.currentUserProfile
  );
  const permissions = useSelector(
    (state) => state.authenticate.currentUserProfile.permissions.cart_gift_cards
  );
  const isSuperAdmin = currentUser?.privilege == "super_admin";

  const [openActionsModal, setOpenActionsModal] = useState({
    state: false,
    action: false,
    customerId: 0,
  });
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [page, setPage] = useState(1);
  const [filterObj, setFilterObj] = useState(null);

  const handlePageChange = (page) => {
    setPage(page);
    setFilterObj((prev) => {
      return { ...prev, page_number: page };
    });
  };

  const getDetails = async () => {
    dispatch(getGiftCardDetailsRequest({ id }));
  };

  const getList = async (filterObj) => {
    const requestData = {
      params: {
        page_size: 10,
        page_number: 1,
        keyword: "",
        sort_by: "id",
        ...filterObj,
      },
    };
    dispatch(getGiftCardsRequest(requestData));
  };
  // mappedData use for create custom cell to concat multi values or navigate button
  const mappedData = list?.map((data, index) => {
    const isAvailable = data?.is_available;
    return {
      ...data,
      image: (
        <img src={data?.image} alt={data?.name} width="108px" height="108px" />
      ),
      status: (
        <Typography
          sx={{
            bgcolor: isAvailable ? backgroundGreen : backgroundRed,
            color: isAvailable ? textGreen : textRed,
            p: "1px 10px",
            width: "fit-content",
          }}
        >
          {data?.is_available ? "Available" : " Not Available"}
        </Typography>
      ),
    };
  });

  const rowData = (data) => {
    setSelectedRow(data);
  };

  const breadcrumbs = [
    {
      active: true,
      path: "/",
      title: "Home",
    },
    {
      active: !type ? false : true,
      path: "/gift-cards",
      title: "Gift cards",
    },
    type && {
      active: false,
      title:
        type === "details"
          ? "Details"
          : id
          ? "Edit gift card"
          : "New gift card",
    },
  ];
  const handelSubmit = (data, formik) => {
    const {
      image,
      englishCardName,
      arabicCardName,
      price,
      priceAfterDiscount,
    } = data;
    const action = () => {
      searchParams.delete("id");
      searchParams.delete("type");
      navigate("/gift-cards");
      !id && formik?.resetForm();
      // USE TOSTIFY TO CONFIRM ACTION
      toast.success(
        `Gift card has been ${id ? "updated" : "created"} successfully`
      );
    };

    const requestData = {
      body: {
        gift_card: {
          en: {
            name: englishCardName,
          },
          ar: {
            name: arabicCardName,
          },
          price,
          price_after_discount: priceAfterDiscount,
          image,
        },
      },
      action,
    };

    if (id) {
      //update call
      dispatch(updateGiftCardRequest({ ...requestData, id }));
    } else {
      //create call
      dispatch(createGiftCardRequest(requestData));
    }
  };

  const filterList = [];

  const handleDelete = (id) => {
    const action = () => {
      //if list.length == 1 & page !== 1 -> setPage(page-1) & fetch
      if ((list.length === 1) & (page !== 1)) {
        setPage(page - 1);
        setFilterObj({
          ...filterObj,
          page_number: page - 1,
        });
        getList({ ...filterObj, page_number: page - 1 }); //temporary until the backend sends the data correctly so I can update the state instead of re-fetching
      } else {
        getList(filterObj);
      }
      type === "details" && navigate("/gift-cards");
      toast.success("card deleted successfully");
    };
    const requestData = {
      id,
      action, //dispatched on success
    };
    dispatch(deleteGiftCardRequest(requestData));
    setOpenDeleteModal(false);
  };

  //list of actions to be displayed when clicked in the 3 dots
  const actionsList = ({ index, data }) => {
    const isAvailable = data?.is_available;
    return [
      {
        condition: () => {
          return true;
        },
        click: () => {
          navigate(`?type=details&id=${data?.id}`);
        },
        text: "View",
      },
      {
        condition: () => {
          return isSuperAdmin || permissions?.can_update;
        },
        click: () => {
          navigate(`?type=form&id=${data?.id}`);
        },
        text: "Edit",
      },
      {
        condition: () => {
          return isSuperAdmin || permissions?.can_delete;
        },
        click: () => {
          setOpenDeleteModal(true);
        },
        text: "Delete",
      },
      {
        condition: () => {
          return (isSuperAdmin || permissions?.can_update) && !isAvailable;
        },
        click: () => {
          setOpenActionsModal({
            state: true,
            giftCardId: selectedRow?.rowData?.id
              ? selectedRow?.rowData?.id
              : id,
            action: "available",
          });
        },
        text: "Available",
      },
      {
        condition: () => {
          return (isSuperAdmin || permissions?.can_update) && isAvailable;
        },
        click: () => {
          setOpenActionsModal({
            state: true,
            giftCardId: selectedRow?.rowData?.id
              ? selectedRow?.rowData?.id
              : id,
            action: "notAvailable",
          });
        },
        text: "Not Available",
      },
    ];
  };

  //if you need more buttons in the header beside the add button
  const headerChildren = <></>;

  //if more modals needed, pass them to the modalComponents as below
  const modalComponents = [];

  //listing all tags
  useEffect(() => {
    if (filterObj) getList(filterObj);
  }, [filterObj]);

  //details of a single tag
  useEffect(() => {
    if (id) getDetails();
  }, [id]);
  const handleUpdateApprovalStatus = (isAvailable) => {
    const action = () => {
      searchParams.delete("id");
      searchParams.delete("type");
      navigate("/gift-cards");
      getList(filterObj);

      // USE TOSTIFY TO CONFIRM ACTION
      toast.success(`Gift card has been updated successfully`);
    };

    const requestData = {
      body: {
        gift_card: {
          is_available: isAvailable,
        },
      },
      action,
    };
    dispatch(
      updateGiftCardRequest({
        ...requestData,
        id: openActionsModal?.giftCardId,
      })
    );
  };

  return (
    <>
      <Loader open={load?.loading} />
      <GiftCardsTemplate
        filterObj={filterObj}
        setFilterObj={setFilterObj}
        data={mappedData}
        count={count}
        paginationPage={page}
        handlePageChange={handlePageChange}
        rowData={rowData}
        details={details}
        breadcrumbs={breadcrumbs}
        filterList={filterList}
        handelSubmit={handelSubmit}
        actionsList={actionsList}
        headerChildren={headerChildren}
        openDeleteModal={openDeleteModal}
        setOpenDeleteModal={setOpenDeleteModal}
        handleDeleteRequest={handleDelete}
        modalComponents={modalComponents}
        can_create={isSuperAdmin || permissions?.can_create}
      />
      {/* {openActionsModal?.state && openActionsModal?.action == "approved" &&  ( */}
      {openActionsModal?.state && openActionsModal?.action == "available" && (
        <AvailableGiftCardModel
          setOpenModel={setOpenActionsModal}
          openModel={openActionsModal}
          handleUpdate={handleUpdateApprovalStatus}
          type="gift card"
          isAvailable={true}
          title="Available"
          msg="Are you sure this gift card is available"
        />
      )}
      {openActionsModal?.state && openActionsModal?.action == "notAvailable" && (
        <AvailableGiftCardModel
          setOpenModel={setOpenActionsModal}
          openModel={openActionsModal}
          handleUpdate={handleUpdateApprovalStatus}
          type="gift card"
          isAvailable={false}
          title="Not available"
          msg="Are you sure this gift card is not available"
        />
      )}
      {/* // )} */}
      {/* {(openActionsModal?.action == "reject" ||
        openActionsModal?.action == "editReason" ||
        openActionsModal?.action == "viewReason") &&
        openActionsModal?.state && ( */}
      {/* <SuspendModel
        setOpenModel={setOpenActionsModal}
        openModel={openActionsModal}
        userType='gift card'
             isAvailable={fasle}
      /> */}
      {/* )} */}
    </>
  );
}

export default GiftCardsPage;
