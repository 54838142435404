import {
  Stack,
  Typography,
  AccordionDetails,
  AccordionSummary,
  Accordion,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import LocationCard from "components/Molecules/Order/LocationCard";
import { useThemePalette } from "common/hooks/theme_palette";
import { icons } from "assets/AssetHelper";
import ViewDestinationModel from "./models/viewDestinationModel";
import MapDirectionsRenderer from "./Destination";
import { useDispatch } from "react-redux";
import { isOpenViewDestinationModel } from "services/modules/modal/Actions";

export default function RouteDetails({
  route,
  i,
  customerInfo,
  isPending,
  isCanceled,
  expandedAccordion,
  setExpandedAccordion,
}) {
  const { red, orange } = useThemePalette();
  const places = route?.route_steps?.map((item) => ({
    lat: +item?.destination?.lat,
    lng: +item?.destination?.long,
    icon:
      item?.name?.toLowerCase()?.includes("location") &&
      !item?.name?.toLowerCase()?.includes("customer")
        ? icons?.storeIcon
        : icons?.storeIcon,
  }));
  const dispatch = useDispatch();
  return (
    <>
      <Accordion
        key={i}
        sx={{
          boxShadow: "none",
          ".MuiAccordionSummary-content": {
            justifyContent: "space-between",
            px: 2,
          },
          ".MuiAccordionDetails-root": {
            display: "flex",
            justifyContent: "end",
          },
        }}
        onChange={(e, expanded) =>
          expanded ? setExpandedAccordion(i) : setExpandedAccordion(null)
        }
      >
        <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
          <Stack
            direction="row"
            alignItems="center"
            sx={{ width: "25%" }}
            justifyContent={"space-between"}
          >
            <Stack direction="row" alignItems="center">
              <Typography fontWeight="500" fontSize="20px">
                {route?.label}
                {i == 0
                  ? "1.First Route"
                  : i == 1
                  ? "2.Second Route"
                  : "3.Third Route"}
              </Typography>
              <Stack alignItems="center">
                {expandedAccordion == i ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </Stack>
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              sx={{
                background: isPending(route?.status)
                  ? "rgba(255, 204, 0, 0.10)"
                  : isCanceled(route?.status)
                  ? "rgba(217, 30, 24, 0.10)"
                  : "#E1F5E8",
                minWidth: "111px",
                height: "40px",
                px: 2,
              }}
            >
              <Typography
                sx={{
                  color: isPending(route?.status)
                    ? "#EAB308"
                    : isCanceled(route?.status)
                    ? red
                    : "#29B95F",
                  fontWeight: "500",
                  fontSize: "18px",
                  textTransform: "capitalize",
                }}
              >
                {isPending(route?.status) ? "Pending" : route.status}
              </Typography>
            </Stack>
          </Stack>
          {/* ------------ Destination START------------ */}

          <Stack
            direction="row"
            gap="10px"
            alignItems="center"
            justifyContent={"end"}
            onClick={(e) => {
              e.stopPropagation(); // Prevent accordion from expanding
              dispatch(isOpenViewDestinationModel(i));
            }}
          >
            <img
              src={icons?.destination}
              alt="destination icon"
              width={"24px"}
              height={"24px"}
            />
            <Typography color={orange}>View destination</Typography>
          </Stack>
          {/* ------------ Destination END ------------ */}
        </AccordionSummary>
        <AccordionDetails>
          <Stack
            direction="row"
            justifyContent="space-between"
            flexWrap={"wrap !important"}
            width="95%"
            gap={3}
          >
            {route?.route_steps?.map((step, index) => (
              <LocationCard
                key={index}
                step={step}
                data={{
                  ...step,
                  customerInfo,
                  routeNum: i + 1,
                  locationNum: index + 1,
                  isLastLocation: route?.route_steps?.length - 1 == index,
                }}
              />
            ))}
          </Stack>
        </AccordionDetails>
      </Accordion>
      <ViewDestinationModel places={places} index={i} />
    </>
  );
}
