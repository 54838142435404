import { Stack, Box } from "@mui/material";

export default function Media({ media }) {
  return (
    <Stack direction="row" gap={2}>
      {media?.map((media, i) => (
        <Stack
          key={i}
          sx={{
            border: "1px dashed gray",
            borderRadius: "6px",
            width: "210px",
            height: "170px",
            overflow: "hidden",
          }}
        >
          <Stack sx={{ m: 1, height: "90%" }}>
            <Box component="img" src={media?.url} width="100%" height={"100%"} />
          </Stack>
        </Stack>
      ))}
    </Stack>
  );
}
