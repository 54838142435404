import React, { useState } from "react";
import CCrud from "components/Molecules/Crud";
// import { initialValues, validationSchema } from "./structure";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Box, Stack } from "@mui/material";
import Breadcrumb from "components/Atoms/SharedComponents/Breadcrumbs/Breadcrumb";
import AddIcon from "@mui/icons-material/Add";
import { useEffect } from "react";
import OrderDetailsTemplate from "./details";
import OrderDetailsPage from "pages/orders/ordersDetails.page";
import { StoreUser } from "services/modules/utils/constant";

const OrdersTemplate = ({
  handlePageChange,
  data,
  details,
  count,
  handelSubmit,
  deleteFun,
  filterList,
  actionsList,
  breadcrumbs,
  setFilterObj,
  rowData,
  paginationPage,
  headerChildren,
  modalComponents,
  openDeleteModal,
  setOpenDeleteModal,
  handleDeleteRequest,
  selectedRow,
  handleOrderCancel,
  isStore
}) => {
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  const id = searchParams.get("id");

  const [supportedLang, setSupportedLang] = useState("en");

  const [isClickable, setIsClickable] = useState(false);

  useEffect(() => {
    setIsClickable(selectedRow?.formikData?.values?.need_action);
  }, [selectedRow?.formikData?.values?.need_action]);

  let columnData = [
    { accessor: "id", Header: "#" },
    { accessor: "order_number", Header: "Order Number" },
    {
      accessor: "total",
      Header: "Total",
      sort: true,
    },
    {
      accessor: "payment_method.name",
      Header: "Payment method",
    },
    { accessor: "date", Header: "Date", sort: true },
    {
      accessor: "status",
      Header: "Order Status",
    },
    { accessor: "Actions", Header: "Actions" },
  ]
  if (isStore) {
    columnData?.splice(3, 1);
  }
  return (
    <>
      <Box>
        <Stack
          direction="row"
          alignSelf="start"
          justifyContent="start"
          gap={2}
          width="100%"
          mb={1}
        >
          <Breadcrumb data={breadcrumbs} />
        </Stack>
        <CCrud
          table={{
            list: data,
            listTitle: "Orders",
            createBtnTitle: "",
            CreateBtnIcon: AddIcon,
            createBtnFun: () => navigate("?type=form"),
            columns: columnData,
            isSearch: false,
            searchInputPlaceholder: "",
            isChecked: false,
            total_count: count,
            setFilterObj,
            className: "organization-table system-configurations-table",
            paginationPage: paginationPage,
            handlePageChange,
            rowData: rowData,
            filterList,
            className: "main-table",
            actionsList, //if you want to add custom actions list
            headerChildren,
            details,
            openDeleteModal,
            setOpenDeleteModal,
            handleDeleteRequest,
          }}
          form={{
            // initialValues: !id
            //   ? { is_visible: false, need_action: false }
            //   : initialValues(details, supportedLang),
            // structure: () => structure(supportedLang, isClickable),
            // validationSchema: validationSchema,
            deleteFun: deleteFun,
            title: id ? "Edit Featured Ad" : "Add Featured Ad",
            submitTitle: id ? "Update" : "Add",
            onSubmit: handelSubmit,
            className: { form: "main-form", actionButton: "main-form-buttons" },
          }}
          DetailsComponent={<OrderDetailsPage details={details} handleOrderCancel={handleOrderCancel} />} //to send details component diff from the default
          modalComponents={modalComponents}
        />
      </Box>
    </>
  );
};

export default OrdersTemplate;
