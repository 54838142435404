import { Stack, Typography, AccordionDetails, AccordionSummary, Accordion } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

export default function CustomAccordion({ expandedAccordion, setExpandedAccordion, data }) {
  return (
    <Stack gap={2}>
      {data?.map((accordion, i) => (
        <Accordion
          sx={{
            boxShadow: "none",
            ".MuiAccordionSummary-content": {
              justifyContent: "space-between",
            },
            ".MuiAccordionDetails-root": {
              // display: "flex",
            },
            py: 2,
            boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.08)",
          }}
          onChange={(e, expanded) =>
            expanded ? setExpandedAccordion(i) : setExpandedAccordion(null)
          }
        >
          <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
            <Stack direction="row" alignItems="center" gap={2}>
              <Typography fontWeight="500" fontSize="32px">
                {accordion?.label}
              </Typography>
              <Stack alignItems="center">
                {expandedAccordion == i ? (
                  <KeyboardArrowUpIcon fontSize="large" />
                ) : (
                  <KeyboardArrowDownIcon fontSize="large" />
                )}
              </Stack>
            </Stack>
            {/* ------------ HEADER CHILDREN START------------ */}
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              sx={{
                minWidth: "111px",
                height: "40px",
                px: 2,
              }}
            >
              <Typography
                sx={{
                  fontWeight: "500",
                  fontSize: "18px",
                  textTransform: "capitalize",
                }}
              >
                {accordion?.headerChildren}
              </Typography>
            </Stack>
            {/* ------------ HEADER CHILDREN  END ------------ */}
          </AccordionSummary>
          <AccordionDetails sx={{ borderTop: "1px solid  rgba(0, 0, 0, 0.08)" }}>
            <Stack>{accordion?.details}</Stack>
          </AccordionDetails>
        </Accordion>
      ))}
    </Stack>
  );
}
