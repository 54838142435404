/* eslint-disable array-callback-return */
import Types from "./Types";

const INIT_STATE = {
  pushNotifications: [],
  count: 0,
  load: null,
};

export function pushNotificationsReducer(state = INIT_STATE, action) {
  const { type, payload } = action;

  switch (type) {
    case Types.PUSH_NOTIFICATIONS_LOADING: {
      return {
        ...state,
        load: payload,
      };
    }
    case Types.GET_PUSH_NOTIFICATIONS_SUCCESS: {
      return {
        ...state,
        pushNotifications: payload?.data?.notifications,
        count: payload?.extra?.total_count,
      };
    }
    
    default: {
      return state;
    }
  }
}
