/* eslint-disable array-callback-return */
import Types from "./Types";

const INIT_STATE = {
  banners: [],
  banner: {},
  count: 0,
  load: null,
};

export function bannersReducer(state = INIT_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case Types.GET_BANNERS_LOADING: {
      return {
        ...state,
        load: payload,
      };
    }

    case Types.GET_BANNERS_SUCCESS: {
      return {
        ...state,
        banners: payload?.data?.featured_banners,
        count: payload?.extra?.total_count,
      };
    }
    case Types.GET_BANNER_DETAILS_SUCCESS: {
      return {
        ...state,
        banner: payload?.data?.featured_banner,
      };
    }

    default: {
      return state;
    }
  }
}
