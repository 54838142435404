import Types from "./Types";


//! CITIES
export const GetCitiesSuccess = (payload) => ({
  type: Types.GET_CITIES_SUCCESS,
  payload,
});

export const CitiesLoading = (payload) => ({
  type: Types.CITIES_LOADING,
  payload,
});

export const CreateCitySuccess = (payload) => ({
  type: Types.CREATE_CITY_SUCCESS,
  payload,
});

export const GetCityDetailsSuccess = (payload) => ({
  type: Types.GET_CITY_DETAILS_SUCCESS,
  payload,
});

export const UpdateCitySuccess = (payload) => ({
  type: Types.UPDATE_CITY_SUCCESS,
  payload,
});

export const DeleteCitySuccess = (payload) => ({
  type: Types.DELETE_CITY_SUCCESS,
  payload,
});


//! ZONES
export const GetZonesSuccess = (payload) => ({
  type: Types.GET_ZONES_SUCCESS,
  payload,
});

export const CreateZoneSuccess = (payload) => ({
  type: Types.CREATE_ZONE_SUCCESS,
  payload,
});

export const GetZoneDetailsSuccess = (payload) => ({
  type: Types.GET_ZONE_DETAILS_SUCCESS,
  payload,
});

export const UpdateZoneSuccess = (payload) => ({
  type: Types.UPDATE_ZONE_SUCCESS,
  payload,
});

export const DeleteZoneSuccess = (payload) => ({
  type: Types.DELETE_ZONE_SUCCESS,
  payload,
});

//! BETWEEN ZONES FEES
export const GetBetweenZonesFeesSuccess = (payload) => ({
  type: Types.GET_BETWEEN_ZONES_FEES_SUCCESS,
  payload,
});

export const GetBetweenZonesFeeDetailsSuccess = (payload) => ({
  type: Types.GET_BETWEEN_ZONES_FEE_DETAILS_SUCCESS,
  payload,
});
