/* eslint-disable array-callback-return */
import { CadeauToCustomer, CadeauToStore, CustomerToCadeau } from "services/modules/utils/constant";
import Types from "./Types";

const INIT_STATE = {
  returnRequestDetails: {},
  returnRequestItems: [],
  returnRequestList: [],
  cartItems: [],
  employees: [],
  assignedEmplpyee: {},
  numberOfRequests: 0,
  route: {},
  routes: [],
  routeOne: {},
  routeTwo: {},
  routeThree: {},
  routesStatusChanges: [],
  orderStatus: "",
  routeComplain: null,
  driver: null,
  count: 0,
  load: null,
  returnRequestItem: {},
  returnRequestStatus: ''
};

export function returnOrderReducer(state = INIT_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case Types.GET_RETURN_REQUEST_ITEMS_REQUEST: {
      return {
        ...state,
        returnRequestItems: payload?.data?.return_request_items,
      };
    }

    case Types.GET_ITEMS_ORDER_SUCCESS: {
      return {
        ...state,
        cartItems: payload?.data?.cart_items,
        count: payload?.extra?.total_count,
      };
    }
    case Types.ITEMS_ORDER_LOADING: {
      return {
        ...state,
        load: payload,
      };
    }
    case Types.GET_RETURN_ORDER_SUCCESS: {
      return {
        ...state,
        returnRequestList: payload?.data?.return_requests,
        count: payload?.extra?.total_count,
        orderStatus: payload?.data?.return_requests?.status,
      };
    }
    case Types.RETURN_ORDER_LOADING: {
      return {
        ...state,
        load: payload,
      };
    }
    case Types.SET_CREATE_RETURN_SUCCESS: {
      return {
        ...state,
      };
    }

    case Types.GET_RETURN_REQUEST_SUCCES: {
      return {
        ...state,
        returnRequestDetails: payload?.data?.return_request,
        returnRequestItems: payload?.data?.return_request?.return_request_items,
        routes: payload?.data?.return_request?.routes,
        routeOne: payload?.data?.return_request?.routes[0],
        routeTwo: payload?.data?.return_request?.routes[1],
        routeThree: payload?.data?.return_request?.routes[2] && payload?.data?.return_request?.routes[2],
        orderStatus: payload?.data?.return_request?.status,
        routesStatusChanges:
          payload?.data?.return_request?.order_status_changes,
      };
    }

    case Types.GET_EMPLOPYEES_SUCCESS: {
      return {
        ...state,
        employees: payload?.data?.employees,
      };
    }
    case Types.UPDATE_RETURN_REQUEST_ITEM_SUCCESS: {
      return {
        ...state,
        // returnRequestStatus: payload?.data?.return_request_item?.status,
        orderStatus: payload?.data?.return_request_item?.return_request_status,
        returnRequestItems: state?.returnRequestItems.map((item) => {
          if (item?.id === payload?.data?.return_request_item?.id) {
            return payload?.data?.return_request_item;
          }
          return item;
        }),
        // customer:payload?.data?.customer
      };
    }
    case Types.DELETE_RETURN_REQUEST_SUCCESS: {
      return {
        ...state,
        returnRequestList: state?.returnRequestList.filter((item) => {
          if (item?.id === payload?.data?.return_request?.id) {
            return payload?.data?.return_request;
          }
          return item;
        }),
      };
    }
    case Types.SET_ORDER_READY_FOR_DELIVERY: {
      return {
        ...state,
        orderStatus: payload?.data?.return_request?.status,
        routeTwo: payload?.data?.return_request?.routes[1] && payload?.data?.return_request?.routes[1]
      };
    }
    case Types.ASSIGN_TO_EMPLOYEE_SUCCESS: {
      let newRouteOneData = state?.routeOne;
      let newRouteTwoData = state?.routeTwo;
      let newRouteThreeData = state?.routeThree;

      if (payload?.data?.return_route?.leg_type == CustomerToCadeau) {
        newRouteOneData = payload?.data?.return_route;
      } else if (payload?.data?.return_route?.leg_type == CadeauToStore) {
        newRouteTwoData = payload?.data?.return_route;
      } else {
        newRouteThreeData = payload?.data?.return_route;
      }
      return {
        ...state,
        route: payload?.data?.return_route,
        routeOne: newRouteOneData,
        routeTwo: newRouteTwoData,
        routeThree: newRouteThreeData,
        orderStatus: payload?.data?.return_route?.order?.status,
        driver: payload?.data?.route?.driver,
        drivers: {
          driverRouteOne:
            payload?.data?.route?.leg_type == CustomerToCadeau &&
            payload?.data?.route?.driver,
          driverRouteTwo:
            payload?.data?.route?.leg_type == CadeauToStore &&
            payload?.data?.route?.driver,
          driverRouteThree:
            payload?.data?.route?.leg_type == CadeauToCustomer &&
            payload?.data?.route?.driver,
        },
      };
    }
    case Types.UNASSIGN_RETURN_REQUEST_DRIVER_SUCCESS: {
      let newRouteOneData = state?.routeOne;
      let newRouteTwoData = state?.routeTwo;
      let newRouteThreeData = state?.routeThree;

      if (payload?.data?.return_route?.leg_type == CustomerToCadeau) {
        newRouteOneData = payload?.data?.return_route;
      } else if (payload?.data?.return_route?.leg_type == CadeauToStore) {
        newRouteTwoData = payload?.data?.return_route;
      } else {
        newRouteThreeData = payload?.data?.return_route;
      }
      return {
        ...state,
        route: payload?.data?.return_route,
        routeOne: newRouteOneData,
        routeTwo: newRouteTwoData,
        routeThree: newRouteThreeData,
        orderStatus: payload?.data?.return_route?.order?.status,
        driver: payload?.data?.return_route?.driver,
      };
    }
    default: {
      return state;
    }
  }
}
