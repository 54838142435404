import { Stack, Typography } from "@mui/material";
import TableUse from "components/Atoms/SharedComponents/CRUD/table-use";
import CActions from "components/Molecules/Crud/components/actions";
import DeleteModal from "components/Molecules/Models/DeleteModal";
import React, { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getOrdersRequest } from "services/modules/orders";
import dayjs from "dayjs";
import { useThemePalette } from "common/hooks/theme_palette";

export default function DriverOrderHistory() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const list = useSelector((state) => state.orders.orders);
  const count = useSelector((state) => state.orders.count);
  const { oliveGreen } = useThemePalette();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [tableData, setTableData] = useState({});
  const [selectedRow, setSelectedRow] = useState({});
  const [page, setPage] = useState(1);
  let [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const [anchorEl, setAnchorEl] = useState(null);
  const anchorRef = useRef(null);
  const open = Boolean(anchorEl);

  // const orderStatuses = {
  //   assigned: { id: 2, name: "assigned"},
  //   started: { id: 3, name: "started"},
  //   finish_route_assigned: { id: 6, name: "Delivered To Cadeau Center" },
  //   delivered: { id: 8, name: "Delivered To Customer" },
  //   canceled: { id: 9, name: "Canceled" },
  // };
  const [filterObj, setFilterObj] = useState(null);

  const columns = [
    { accessor: "id", Header: "#" },
    { accessor: "order_number", Header: "Order Number" },
    { accessor: "delivery_fees", Header: "Delivery fees", sort: true },

    { accessor: "date", Header: "Date", sort: true },
    {
      accessor: "status",
      Header: "Order Status",
    },
    {
      accessor: "route",
      Header: "Route",
    },
    { accessor: "Actions", Header: "Action" },
  ];

  const actionsList = ({ index, data }) => {
    return [
      {
        condition: () => {
          return true;
        },
        click: () => {
          navigate(`/orders?type=details&id=${data?.id}`);
        },
        text: <Typography color={oliveGreen}>View Order details</Typography>,
      },
    ];
  };

  const filterList = [
    {
      type: "search",
      placeholder: "Search by Order Number",
      onChange: (value) =>
        setFilterObj((s) => {
          return { ...s, keyword: value?.trim(), page_number: 1 };
        }),
        setFilterObj,
    },
    {
      type: "filter",
      placeholder: "Payment methods",
      onChange: (value) => {
        setFilterObj((s) => {
          return { ...s, payment: value?.value, page_number: 1 };
        });
      },
      options: [{ id: 1, name: "Cash" }],
    },
    {
      type: "filter",
      placeholder: "Order Status",
      onChange: (value) => {
        setFilterObj((s) => {
          return { ...s, status: value?.value, page_number: 1 };
        });
      },
      options: [
        { id: "assigned", name: "assigned" },
        { id: "started", name: "started" },
        { id: "collecting", name: "collecting" },
        { id: "collected", name: "collected" },
        { id: "delivered", name: "delivered" },
        { id: "canceled", name: "canceled" },
      ],
    },
  ];

  const rowData = (data) => {
    setSelectedRow(data);
  };

  const handlePageChange = (page) => {
    setPage(page);
    setFilterObj((prev) => {
      return { ...prev, page_number: page };
    });
  };

  const handleDelete = () => {};

  const handleClick = (event, index, data) => {
    anchorEl == index ? setAnchorEl(null) : setAnchorEl(index);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const Actions = (id, elmIndex, data) => {
    return (
      <CActions
        id={id}
        index={elmIndex}
        data={data}
        handleClick={handleClick}
        handleClose={handleClose}
        anchorRef={anchorRef}
        open={open}
        anchorEl={anchorEl}
        actionsList={actionsList}
      />
    );
  };
  const mappedData = list?.map((data, index) => {
    return {
      ...data,
      total: (
        <>
          {data?.amount} {data?.currency?.name}{" "}
        </>
      ),
      date: <>{dayjs(data?.created_at).format("DD-MM-YYYY,hA")} </>,
      delivery_fees: `${data?.delivery_fees} ${data?.currency?.name} `,
      status: <Typography>{data?.assigned_route?.status}</Typography>,
      route: data?.assigned_route?.type == "start" ? "route 1" : "Route 2",
      Actions: Actions(data?.id, index, data),
    };
  });

  const headerChildren = [];

  const getList = async (filterObj) => {
    const requestData = {
      params: {
        page_size: 10,
        page_number: 1,
        keyword: "",
        sort_by: "id",
        driver_id: id,
        driver_order: true,
        ...filterObj,
      },
    };
    dispatch(getOrdersRequest(requestData));
  };

  useEffect(() => {
    if (filterObj) getList(filterObj);
  }, [filterObj]);

  console.log(list);
  useEffect(() => {
    rowData({ ...tableData });
  }, [tableData]);

  return (
    <Stack sx={{ backgroundColor: "white", p: "20px" }}>
      <TableUse
        list={mappedData}
        columnsData={columns}
        setTableData={setTableData}
        setFilterObj={setFilterObj}
        filterList={filterList}
        headerChildren={headerChildren}
        actionsList={actionsList}
        rowData={rowData}
        openDeleteModal={openDeleteModal}
        setOpenDeleteModal={setOpenDeleteModal}
        handlePageChange={handlePageChange}
        paginationPage={page}
        total_count={count}
        path={"orders"}
      />
    </Stack>
  );
}
