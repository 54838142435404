const orderDetailsTypes = {
  //details 
  GET_ORDER_DETAILS_SUCCESS: "GET_ORDER_DETAILS_SUCCESS",
  ORDER_DETAILS_LOADING: "ORDER_DETAILS_LOADING",
  GET_EMPLOPYEES_SUCCESS: 'GET_EMPLOPYEES_SUCCESS',
  ASSIGN_TO_EMPLOYEE_SUCCESS: 'ASSIGN_TO_EMPLOYEE_SUCCESS',
  ASSIGN_TO_FREELANCER_SUCCESS: 'ASSIGN_TO_FREELANCER_SUCCESS',
  UNASSIGN_DRIVER_SUCCESS: 'UNASSIGN_DRIVER_SUCCESS',
  SET_ORDER_READY_FOR_DELIVERY: 'SET_ORDER_READY_FOR_DELIVERY',
  GET_DRIVER_COMPLAINS_DETAILS_SUCCESS: 'GET_DRIVER_COMPLAINS_DETAILS_SUCCESS',
  UPDATE_DRIVER_COMPLAINS_SUCCESS: 'UPDATE_DRIVER_COMPLAINS_SUCCESS',
  GET_FREELANCER_SUCCESS:'GET_FREELANCER_SUCCESS',
  GET_ORDERS_SUCCESS: "GET_ORDERS_SUCCESS",
  CANCEL_ORDER_SUCCESS: "CANCEL_ORDER_SUCCESS",
  GET_ORDER_STATUS_SUCCESS: "GET_ORDER_STATUS_SUCCESS"
}

export default orderDetailsTypes;
