import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Loader from "components/Atoms/SharedComponents/Loader/Loader";

import {
  getZonesRequest,
  createZoneRequest,
  updateZoneRequest,
  getZoneDetailsRequest,
  deleteZoneRequest,
} from "services/modules/cities/zones";
import {
  getCitiesRequest,
  getScrollCitiesRequest,
} from "services/modules/cities/cities";
import GoogleMap from "components/Molecules/Map/GoogleMap";
import ZonesTemplate from "templates/zones";
import { toast } from "react-toastify";

function ZonesPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const type = searchParams.get("type");

  const list = useSelector((state) => state.zones.zones);
  const fulfillmentCenters = useSelector((state) => state.cities.cities);
  const filterCitites = useSelector((state) => state.cities.filterCitites);
  const cititesCount = useSelector((state) => state.cities.listCount);
  const count = useSelector((state) => state.zones.count);
  const details = useSelector((state) => state.zones.zone);
  const load = useSelector((state) => state.zones.load);
  console.log(cititesCount);
  console.log(filterCitites);
  //permissions
  const currentUser = useSelector(
    (state) => state.authenticate.currentUserProfile
  );
  const permissions = useSelector(
    (state) => state.authenticate.currentUserProfile.permissions.zones
  );
  const isSuperAdmin = currentUser?.privilege == "super_admin";

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [page, setPage] = useState(1);
  const [filterObj, setFilterObj] = useState(null);
  const cititesLoading = useSelector((state) => state.cities.load);
  const handlePageChange = (page) => {
    setPage(page);
    setFilterObj((prev) => {
      return { ...prev, page_number: page };
    });
  };

  const getDetails = async () => {
    dispatch(getZoneDetailsRequest({ id }));
  };

  const getList = async (filterObj) => {
    const requestData = {
      params: {
        page_size: 10,
        page_number: 1,
        keyword: "",
        sort_by: "id",
        ...filterObj,
      },
    };
    dispatch(getZonesRequest(requestData));
  };

  // mappedData use for create custom cell to concat multi values or navigate button
  const mappedData = list?.map((data, index) => {
    return {
      ...data,
      is_fulfillment_center: (
        <span variant="outline">
          {data?.is_fulfillment_center ? "yes" : "no"}
        </span>
      ),
    };
  });

  const rowData = (data) => {
    setSelectedRow(data);
  };

  const breadcrumbs = [
    {
      active: true,
      path: "/",
      title: "Home",
    },
    {
      active: !type ? false : true,
      path: "/zones",
      title: "Zones",
    },
    type && {
      active: false,
      title:
        type === "details" ? "Zones details" : id ? "Edit Zone" : "New Zone",
    },
  ];

  const handelSubmit = (data, formik) => {
    console.log(data);
    const action = () => {
      searchParams.delete("id");
      searchParams.delete("type");
      navigate("/zones");
      !id && formik?.resetForm();
      toast.success(
        ` ${id ? "Zone" : "New Zone"} has been ${
          id ? "updated" : "created"
        } successfully`
      );
    };
    const requestData = {
      body: {
        zone: {
          ...data,
          lat: location?.lat,
          long: location?.lng,
        },
      },
      action,
    };
    if (id) {
      //update call
      dispatch(updateZoneRequest({ ...requestData, id }));
    } else {
      //create call
      dispatch(createZoneRequest(requestData));
    }
  };
  const getFulfillmentCenterCities = (filterObj) => {
    const requestData = {
      params: {
        page_size: 10, //to get all data
        page_number: 1,
        keyword: "",
        is_fulfillment_center: true,
        sort_by: "id",
        ...filterObj,
      },
    };
    dispatch(getScrollCitiesRequest(requestData));
  };
  const filterList = [
    {
      type: "filter",
      placeholder: "City",
      onChange: (value) => {
        setFilterObj((s) => {
          return { ...s, city_id: value?.value, page_number: 1 };
        });
      },
      onMenuOpen: () => {
        filterCitites?.length == 0 && getFulfillmentCenterCities({});
      },
      options: filterCitites && filterCitites,
      getList: getFulfillmentCenterCities,
      applySearchFromRequest: true,
      count: cititesCount,
    },
  ];

  const handleDelete = (id) => {
    console.log(id);
    // const id = selectedRow?.rowData?.id;
    //action to be called after successfull deletion
    const action = () => {
      //if list.length == 1 & page !== 1 -> setPage(page-1) & fetch
      if ((list.length === 1) & (page !== 1)) {
        setPage(page - 1);
        setFilterObj({
          ...filterObj,
          page_number: page - 1,
        });
        getList({ ...filterObj, page_number: page - 1 }); //temporary until the backend sends the data correctly so I can update the state instead of re-fetching
      } else {
        getList(filterObj);
      }
      type === "details" && navigate("/zones");
      toast.success("Zone has been deleted successfully");
    };
    const requestData = {
      id,
      action, //dispatched on success
    };
    dispatch(deleteZoneRequest(requestData));
    setOpenDeleteModal(false);
  };

  //list of actions to be displayed when clicked in the 3 dots
  const actionsList = ({ index, data }) => {
    return [
      {
        condition: () => {
          return true;
        },
        click: () => {
          navigate(`?type=details&id=${data?.id}`);
        },
        text: "View",
      },
      {
        condition: () => {
          return isSuperAdmin || permissions?.can_update;
        },
        click: () => {
          navigate(`?type=form&id=${data?.id}`);
        },
        text: "Edit",
      },
      {
        condition: () => {
          return isSuperAdmin || permissions?.can_delete;
        },
        click: () => {
          setOpenDeleteModal(true);
        },
        text: "Delete",
      },
    ];
  };

  //if you need more buttons in the header beside the add button
  const headerChildren = <></>;

  //if more modals needed, pass them to the modalComponents as below
  const modalComponents = [];

  // listing all cities
  useEffect(() => {
    if (filterObj) getList(filterObj);
  }, [filterObj]);

  // details of a single city
  useEffect(() => {
    if (id) getDetails();
  }, [id]);

  // useEffect(() => {
  //   getFulfillmentCenterCities();
  // }, []);

  //this will work as an initial location
  //and will be updated when a place is searched
  const [location, setLocation] = useState({
    lat: 24.7136,
    lng: 46.6753,
  });

  const getCurrentLocation = (lat, lng) => {
    setLocation({ lat, lng });
  };

  const formChildren = () => {
    return (
      <GoogleMap
        getCurrentLocation={getCurrentLocation}
        location={location}
        type={!id ? "add" : "edit"}
        details={details}
      />
    );
  };
  const getCities = (filterObj) => {
    const requestData = {
      params: {
        page_size: 10,
        page_number: 1,
        keyword: "",
        is_fulfillment_center: true,
        sort_by: "id",
        ...filterObj,
      },
    };
    dispatch(getScrollCitiesRequest(requestData));
  };
  const options = {
    cities: {
      options: [details?.google_city, ...filterCitites],
      onOpen: filterCitites?.length <= 1 && getCities,
      isLoading:
        cititesLoading?.loading && cititesLoading?.type == "filter-list-cities",
      getList: getCities,
      count: cititesCount,
    },
  };
  return (
    <>
      <Loader open={load?.loading} />
      <ZonesTemplate
        filterObj={filterObj}
        setFilterObj={setFilterObj}
        data={mappedData}
        count={count}
        paginationPage={page}
        handlePageChange={handlePageChange}
        rowData={rowData}
        details={details}
        breadcrumbs={breadcrumbs}
        filterList={filterList}
        handelSubmit={handelSubmit}
        actionsList={actionsList}
        headerChildren={headerChildren}
        openDeleteModal={openDeleteModal}
        setOpenDeleteModal={setOpenDeleteModal}
        handleDeleteRequest={handleDelete}
        modalComponents={modalComponents}
        formChildren={formChildren()}
        fulfillmentCenters={fulfillmentCenters}
        can_create={isSuperAdmin || permissions?.can_create}
        options={options}
      />
    </>
  );
}

export default ZonesPage;
