import React, { useState } from "react";

import { Box, Button, Stack } from "@mui/material";
import Breadcrumb from "components/Atoms/SharedComponents/Breadcrumbs/Breadcrumb";
import { useNavigate, useSearchParams } from "react-router-dom";
import ModelBox from "../../components/Atoms/Model/Model";
import CCrud from "../../components/Molecules/Crud";
import ForgetPasswordForm from "../../components/Organisms/login/forms/forgetPasswordForm";
import ResetPasswordForm from "../../components/Organisms/login/forms/resetPassword/resetPasswordForm";
import VerifyOTPForm from "../../components/Organisms/login/forms/verifyOtp/verifyOTPForm";
import { initialValues, validationSchema } from "../admin-profile/structure";
import { detailsData } from "./details";
import { structure } from "./structure";
const AdminProfileTemplate = ({ rowData, details, breadcrumbs, handelSubmit, setFilterObj }) => {
    
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const [resetPasswordStep, setResetPasswordStep] = useState({ action: false, step: null });

  const [supportedLang, setSupportedLang] = useState("en");
  const handleResetPassword = () => {
    setResetPasswordStep({ action: true, step: 1 });
  };
  return (
    <>
      <Box>
        <Stack direction="row" alignSelf="start" justifyContent="start" gap={2} width="100%" mb={1}>
          <Breadcrumb data={breadcrumbs} />
        </Stack>
        <CCrud
          detailsTemplateData={{
            pageTitle: "Profile",
            children: (
              <Button
                variant={"outlined"}
                sx={{
                  color: "black",
                  borderColor: "black",
                  background: "white",
                  "&:hover": { borderColor: "black", background: "white" },
                }}
                onClick={handleResetPassword}
              >
                Reset Password
              </Button>
            ),
            deleteBtn: false,
            data: {
              list: detailsData({ data: details }),
            },
          }}
          table={{
            list: null,
            rowData,
            setFilterObj,
          }}
          form={{
            initialValues: !id ? { is_visible: false, need_action: false } : initialValues(details, supportedLang),
            structure: () => structure(supportedLang),
            validationSchema: validationSchema,
            title: "Edit Profile",
            submitTitle: "Update",
            onSubmit: handelSubmit,
            className: { form: "main-form", actionButton: "main-form-buttons" },
          }}
        ></CCrud>

        <ModelBox
          openVar={resetPasswordStep.action}
          closeFunc={() => setResetPasswordStep({ action: false, step: resetPasswordStep.step })}
          title={"Reset Password"}
          titleStyle={{ fontSize: "28px", fontWeight: 500 }}
        >
          {resetPasswordStep.step === 1 ? (
            <ForgetPasswordForm
              hideIcon={true}
              setResetPasswordStep={setResetPasswordStep}
              customAction={true}
              userType={"admin_user"}
            />
          ) : resetPasswordStep.step === 2 ? (
            <VerifyOTPForm
              hideIcon={true}
              setResetPasswordStep={setResetPasswordStep}
              customAction={true}
              userType={"admin_user"}
            ></VerifyOTPForm>
          ) : (
            <ResetPasswordForm
              hideIcon={true}
              setResetPasswordStep={setResetPasswordStep}
              customAction={true}
              userType={"admin_user"}
            ></ResetPasswordForm>
          )}
        </ModelBox>
      </Box>
    </>
  );
};

export default AdminProfileTemplate;
