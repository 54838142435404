import { Button, CircularProgress } from "@mui/material";
import { useThemePalette } from "common/hooks/theme_palette";
import React from "react";

export default function ContainedButton({
  title,
  onClick,
  backgroundColor = "#3FABAE",
  width = "50%",
  fontSize = "18px",
  px = 5,
  load,
  ...props
}) {
  return (
    <Button
      variant="contained"
      onClick={onClick}
      sx={{
        py: 1,
        px: px,
        textTransform: "none",
        display: "flex",
        justifyContent: "center",
        backgroundColor: backgroundColor,
        fontSize: fontSize,
        textAlign: "center",
        width: width,
        "&:hover": {
          backgroundColor: backgroundColor,
        },
      }}
      {...props}
    >
      {load ? (
        <CircularProgress
          sx={{
            color: "white",
            width: "33px !important",
            height: "33px !important",
          }}
        />
      ) : (
        title
      )}
    </Button>
  );
}
