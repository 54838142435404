import { Stack, Typography, Button } from "@mui/material";
import { useThemePalette } from "common/hooks/theme_palette";
import LocationWithQuantity from "./locationWithQuantity";

export default function AddLocation({
  locations,
  handleAddAnotherLocation,
  handleLocationDataChange,
  handleSaveVariantBranch,
  handleDeleteVariantBranch,
  branches,
}) {
  const { orange } = useThemePalette();

  return (
    <Stack
      sx={{
        p: 3,
      }}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography fontWeight="500" fontSize="24px">
          Locations
        </Typography>
        {branches?.length > locations?.length && (
          <Button
            sx={{ color: orange, fontWeight: "500", fontSize: "20px", px: 0 }}
            onClick={handleAddAnotherLocation}
          >
            + Add another location
          </Button>
        )}
      </Stack>
      <Stack gap={2} mt={3}>
        {locations?.map((loc, i) => (
          <LocationWithQuantity
            location={loc}
            locations={locations}
            handleLocationDataChange={handleLocationDataChange}
            handleDeleteVariantBranch={handleDeleteVariantBranch}
            locationOptions={branches}
            handleSaveVariantBranch={handleSaveVariantBranch}
            i={i}
          />
        ))}
      </Stack>
    </Stack>
  );
}
