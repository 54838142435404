import { Button, Stack } from "@mui/material";
import { useThemePalette } from "common/hooks/theme_palette";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import EditProductForm from "components/Organisms/products/editProduct/editProduct";
import ProductVariants from "pages/products/productVariants";
import EditProductTemplate from "templates/products/editProduct";

import { useState } from "react";
import { toast } from "react-toastify";
import { getProductDetailsRequest, updateProductRequest } from "services/modules/products";
import setAuthToken from "services/modules/utils/handel_api";

export default function EditProductPage() {
  const token = localStorage.getItem("token");
  setAuthToken(token);

  const [key, setKey] = useState(true);

  const { oliveGreen, orange } = useThemePalette();
  const refOpenVriantForm = useRef();
  const refsubmit = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const type = searchParams.get("type");

  const details = useSelector((state) => state.products.product);
  const currentUser = useSelector((state) => state.authenticate.currentUserProfile);
  const variantPermissions = useSelector((state) => state.authenticate.currentUserProfile.permissions.variants);
  const isSuperAdmin = currentUser?.privilege == "super_admin";

  const breadcrumbs = [
    {
      active: true,
      path: "/",
      title: "Home",
    },
    {
      active: true,
      path: "/products",
      title: "Products",
    },
    {
      active: false,
      title: type == "edit" ? "Edit product" : "Add product",
    },
  ];

  const handleCancel = (e) => {
    e.stopPropagation();
    navigate("/products");
  };

  const prepareProductData = (data) => {
    const deletedImages = details?.media?.filter((image) => !data?.images.includes(image.url));

    const newImages = [];
    data?.images.forEach((url) => {
      const existsInObjects = details?.media?.some((obj) => obj.url === url);

      if (!existsInObjects) {
        newImages.push(url);
      }
    });

    const images = newImages.length > 0 ? deletedImages.concat(newImages) : deletedImages;

    const media_attributes = images?.map((image) => ({
      id: image?.id,
      media_type: 0,
      url: typeof image === "object" ? image.url : image,
      _destroy: image?.id && true,
    }));

    const genders = {
      1: "male",
      2: "female",
      3: "any",
    };

    const master_attributes = {
      id: data?.master_variant_id,
      media_attributes,
    };

    const option_type_ids = data?.option_types?.map((option) => option?.value);
    const occasion_type_ids = data?.occasion_types?.map((option) => option?.value);
    const category_ids = data?.categories?.map((option) => option?.value);
    // const occasion_types = data?.occasion_types?.map((occasion) => occasion?.label)?.join(", ");
    // const category_names = data?.categories?.map((option) => option?.label)?.join(", ");

    return {
      ...data,
      option_type_ids,
      occasion_type_ids,
      category_ids,
      target_gender: genders[data?.target_gender],
      master_attributes,
    };
  };

  const handleSubmit = (data, formik) => {
    const productData = {
      ...prepareProductData(data),
    };

    // console.log(productData);
    const action = (data) => {
      toast.success("product has been successfully updated");
      //after updating the product
      //we need to check if the user added a new optionType
      //and update the option types in variants
    };

    const requestData = {
      body: {
        product: {
          ...productData,
        },
      },
      action,
    };
    dispatch(updateProductRequest({ ...requestData, id }));
  };

  const detailsHeaderChildren = (
    <Stack direction="row" gap={2}>
      <Button onClick={handleCancel} sx={{ color: "red", border: "1px solid" }}>
        Cancel
      </Button>
      <Button
        onClick={(e) => {
          refsubmit?.current?.click();
          e.stopPropagation();
        }}
        sx={{
          color: "white",
          backgroundColor: oliveGreen,
          "&:hover": {
            backgroundColor: oliveGreen,
          },
        }}
      >
        Update
      </Button>
    </Stack>
  );

  const variantsHeaderChildren =
    isSuperAdmin || variantPermissions?.can_create ? (
      <Button
        onClick={(e) => {
          console.log(e);
          refOpenVriantForm?.current?.click();
          e.stopPropagation();
        }}
        sx={{ color: orange, fontWeight: "500", fontSize: "20px", px: 0 }}
      >
        + Add new variant
      </Button>
    ) : (
      <></>
    );

  const accordionData = [
    {
      label: "Details",
      details: (
        <EditProductForm
          id={id}
          details={details}
          handleCancel={handleCancel}
          handleSubmit={handleSubmit}
          refsubmit={refsubmit}
        />
      ),
      headerChildren: detailsHeaderChildren,
    },
    {
      label: "Variants",
      details: (
        <Stack sx={{ position: "relative" }}>
          <ProductVariants
            isHeaderChildren={true}
            productId={id}
            storeId={details?.store?.id}
            optionTypes={details?.option_types}
            refOpenVriantForm={refOpenVriantForm}
            setKey={setKey}
            isSuperAdmin={isSuperAdmin}
            variantPermissions={variantPermissions}
          />
        </Stack>
      ),
      headerChildren: variantsHeaderChildren,
    },
  ];

  const getDetails = async () => {
    dispatch(getProductDetailsRequest({ id }));
  };

  useEffect(() => {
    if (id) getDetails();
  }, [id, key]);

  return <EditProductTemplate breadcrumbs={breadcrumbs} data={accordionData} type={type} />;
}
