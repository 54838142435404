import { Box, Stack, Typography, Grid } from "@mui/material";
import { icons, images } from "assets/AssetHelper";
import * as dayjs from "dayjs";

export const detailsData = ({ data }) => {
  console.log(data, "DATADAATA");
  return [
    {
      head: "Admin Image",
      icon: icons?.media,
      details: [
        {
          name: (
            <Grid
              Grid
              item
              xs={12}
              sx={{
                border: " 1px solid rgba(0, 0, 0, 0.25)",
                borderRadius: "10px",
                position: "relative",
                minHeight: "200px",
              }}
              onDragOver={(e) => e.preventDefault()}
            >
              <Stack
                justifyContent="center"
                alignItems="center"
                // height='100%'
                minHeight="214px" // Set a fixed height for the stack
              >
                <Box
                  component="img"
                  src={data?.avatar || data?.logo}
                  alt="img"
                  width={"30%"}
                  height={"100%"}
                  maxHeight="214px"
                  sx={{
                    borderRadius: "10px",
                  }}
                />
              </Stack>
            </Grid>
          ),
        },
      ],
    },
    {
      head: "Admin Details",
      icon: icons?.detailsIcon,
      details: [
        {
          label: "Name",
          name: data?.name,
        },
        {
          label: "Role",
          name: data?.role?.name,
        },
        {
          label: "Phone",
          name: data?.phone_number,
        },
        {
          label: "Email",
          name: data?.email,
        },
      ],
    },
    {
      head: "Dates",
      icon: icons.dates,
      details: [
        {
          label: "Created At",
          name: dayjs(data?.created_at).format("DD-MM-YYYY HH:MM a"),
        },
        {
          label: "Updated At",
          name: dayjs(data?.updated_at).format("DD-MM-YYYY HH:MM a"),
        },
        {
          label: "Last Sign In At",
          name: dayjs(data?.last_sign_in).format("DD-MM-YYYY HH:MM a"),
        },
      ],
    },
  ];
};
