import { Button, Divider, Input, Stack, Typography } from "@mui/material";
import { useThemePalette } from "common/hooks/theme_palette";
import TextFieldInput from "components/Atoms/Input/TextField/TextFieldInput";
import ModelBox from "components/Atoms/Model/Model";
import ContainedButton from "components/Atoms/SharedComponents/Buttons/ContainedButton";
import FormHeader from "components/Molecules/Products/Variants/formHeader";
import { useFormik } from "formik";
import React from "react";
import { useDispatch } from "react-redux";

export default function RejectItemModel({
  openModel,
  setOpenModel,
  updateRequestItem,
  itemDetails,
  load
}) {
  const { red } = useThemePalette();
  const dispatch = useDispatch();
  const data = itemDetails?.returnable?.variant;
  const handleSubmit = (values, formik) => {
    const action = () => {
      dispatch(setOpenModel());
      formik?.resetForm();
    };
    const requestData = {
      id: itemDetails?.id,
      body: {
        return_request_item: {
          status: "rejected",
          reason: values?.reason,
        },
      },
      action,
    };

    updateRequestItem({ requestData });
  };
  const formik = useFormik({
    initialValues: { reason: "" },
    onSubmit: async (values) => {
      handleSubmit(values, formik);
    },
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
  });
  return (
    <>
      <ModelBox
        openVar={openModel}
        closeFunc={() => {
          dispatch(setOpenModel(null));
          formik?.resetForm();
        }}
        padding={false}
        isHeaderVisiable={false}
        // maxWidth={"650px"}
      >
        <FormHeader
          handleClose={(_) => {
            dispatch(setOpenModel(null));
            formik?.resetForm();
          }}
          title={"Reject Product"}
          padding={2}
          textStyle={{
            textAlign: "center",
            width: "100%",
            color: red,
          }}
        />
        <Divider />
        <form onSubmit={formik?.handleSubmit}>
          <Stack p={2} gap={4}>
            <Stack direction="row" gap="10px">
              <img
                alt="product img"
                src={data?.image}
                width="89px"
                height={"78px"}
                style={{ borderRadius: "2px" }}
              />
              <Stack>
                <Typography fontSize={"24px"}>{data?.name}</Typography>
                <Stack direction="row" alignItems="center" gap="5px">
                  <Typography fontSize={"24px"} fontWeight="700">
                    {data?.price}
                  </Typography>
                  <Typography fontSize="20px">
                    {data?.currency?.name}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
            <Stack>
              <TextFieldInput
                id={"reason"}
                type={"text"}
                label={"Write Rejection reason"}
                rows={5}
                multiline={true}
                labelStyle={{
                  color: "#565656",
                  fontSize: "18px",
                  fontFamily: "Jost",
                  mb: 2,
                }}
                name="reason"
                placeholder={"Write Rejection reason"}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "transparent",
                    },
                    "&:hover fieldset": {
                      borderColor: "transparent",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "transparent",
                    },
                  },
                }}
                value={formik?.values?.reason || ""}
                onChange={formik?.handleChange}
              />
            </Stack>
          </Stack>
          <Divider />
          <Stack
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
            my={4}
            gap={2}
          >
            <Button
              sx={{
                color: "black",
                border: "1px solid #000000",
              }}
              width={"35%"}
              onClick={_=>{
                dispatch(setOpenModel(null));
                formik?.resetForm();
              }}
            >
              Cancel
            </Button>
            <ContainedButton
              type="submit"
              title="Submit"
              width={"35%"}
              load={load}
            />
          </Stack>
        </form>
      </ModelBox>
    </>
  );
}
