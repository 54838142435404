export function makeFilterString(filter_obj) {
  var filterString = "?";
  Object.keys(filter_obj)?.map(function (key) {
    if (filter_obj[key] || filter_obj[key] === false) {
      filterString += key + "=" + filter_obj[key] + "&";
    }
  });
  if (filterString.substr(filterString.length - 1) === "&") {
    filterString = filterString.slice(0, -1);
  }
  return filterString;
}

export const removeDuplicateObjects = (array, identifier) => {
  const uniqueIdentifiers = [];
  const uniqueObjects = {};

  array?.forEach((obj) => {
    const key = obj?.[identifier];

    if (!uniqueObjects.hasOwnProperty(key)) {
      uniqueObjects[key] = obj;
      uniqueIdentifiers.push(key);
    }
  });

  // Construct the final array in the original order
  const resultArray = uniqueIdentifiers.map((key) => uniqueObjects[key])?.filter((item => item));

  return resultArray;
};

export const handleTabChange = ({ event, newValue, setValue, searchParams, setSearchParams }) => {
  setValue(newValue);
  const currentTab = searchParams.get("tab");

  // Only update if the value changes
  if (newValue !== currentTab) {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set("tab", newValue);
    setSearchParams(newSearchParams);

    // Update the browser history with the new search string
    const newUrl = `${window.location.pathname}?${newSearchParams.toString()}`;
    window.history.pushState({ path: newUrl }, "", newUrl);
  }
};

export const isScrolledToBottom = (e, extra = 0) => {
  const bottom = e.target.scrollHeight - e.target.clientHeight <= e.target.scrollTop + extra;
  return bottom;
};

export const handleMenuScrollToBottom = ({ e, list, getList, count, isLoading }) => {
  const isBottom = isScrolledToBottom(e);
  if (isBottom && getList) {
    if (list?.length < count && !isLoading) {
      /* for edit form, initial values has to be a part of the options so they can be displayed,
       so we add the initial values manually to the options[] 
       This might cause the options.length to -sometimes- equal 11/21/31 not 10/20/30 which will mess the pagination
       that's why we have different logic to handle the page_number */
      let page_number;
      if (Number.isInteger(list?.length / 10)) {
        page_number = Math?.ceil(list?.length / 10 + 1);
      } else {
        page_number = Math?.ceil(list?.length / 10);
      }
      getList({
        page_number,
      });
      return;
    }
  }
};