/* eslint-disable array-callback-return */
import Types from "./Types";

const INIT_STATE = {
  notifications: [],
  countOfUnread: 0,
  FCMToken: null,
  count: 0,
  load: null,
  countLoad: null,
};

export function notificationsReducer(state = INIT_STATE, action) {
  const { type, payload } = action;

  switch (type) {
    case Types.NOTIFICATIONS_LOADING: {
      return {
        ...state,
        load: payload,
      };
    }
    case Types.UNREAD_COUNT_LOADING: {
      return {
        ...state,
        countLoad: payload,
      };
    }
    case Types.GET_NOTIFICATIONS_SUCCESS: {
      const newNotifications = payload?.data?.notifications;
      const all = [...newNotifications, ...state.notifications];
      const uniqueNotifications = Array.from(new Set(all?.map((a) => a.id)))
        .map((id, i) => {
          return all?.find((a) => a.id === id);
        })
        .sort((a, b) => b.id - a.id);

      return {
        ...state,
        notifications: uniqueNotifications,
        count: payload?.extra?.total_count,
      };
    }
    case Types.MARK_AS_READ_SUCCESS: {
      const updatedNotifications = state?.notifications.map((elem) => {
        if (payload?.data?.notification?.id == elem.id) {
          return {
            ...elem,
            is_seen: true,
          };
        }
        return elem;
      });

      return {
        ...state,
        // numOfUnread: state.numOfUnread > 0 ? state.numOfUnread - 1 : 0,
        notifications: updatedNotifications,
      };
    }
    case Types.MARK_ALL_AS_READ_SUCCESS: {
      const updatedNotifications = state.notifications.map((notification) => {
        return {
          ...notification,
          is_seen: true,
        };
      });

      return {
        ...state,
        notifications: updatedNotifications,
        numOfUnread: 0,
      };
    }

    case Types.GET_UNREAD_COUNT_SUCCESS: {
      return {
        ...state,
        countOfUnread: payload?.data?.notifications?.unseen,
      };
    }
    case Types.SET_FCM_TOKEN_SUCCESS: {
      return {
        ...state,
        FCMToken: payload?.token,
      };
    }
    default: {
      return state;
    }
  }
}
