const wrapsVariantsTypes = {
  // WRAPPERS
  // list
  GET_RIBBON_COLORS_SUCCESS: "GET_RIBBON_COLORS_SUCCESS",
  RIBBON_COLORS_LOADING: "RIBBON_COLORS_LOADING",
  //details 
  GET_RIBBON_COLOR_DETAILS_SUCCESS: "GET_RIBBON_COLOR_DETAILS_SUCCESS",
  // create
  ADD_RIBBON_COLOR_SUCCESS: "ADD_RIBBON_COLOR_SUCCESS",
  ADD_RIBBON_COLOR_FAILED: "ADD_RIBBON_COLOR_FAILED",
  // Delete
  DELETE_RIBBON_COLOR_SUCCESS: "DELETE_RIBBON_COLOR_SUCCESS",
  DELETE_RIBBON_COLOR_FAILED: "DELETE_RIBBON_COLOR_FAILED",
  // update
  UPDATE_RIBBON_COLOR_SUCCESS: "UPDATE_RIBBON_COLOR_SUCCESS",
  UPDATE_RIBBON_COLOR_FAILED: "UPDATE_RIBBON_COLOR_FAILED",
  GET_SCROLL_RIBBON_COLOR_SUCCESS:"GET_SCROLL_RIBBON_COLOR_SUCCESS"
};

export default wrapsVariantsTypes;
