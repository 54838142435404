const ProductsOptionTypes= {
    //LOADING
    DELIVERY_TIMES_LOADING:"DELIVERY_TIMES_LOADING",
    //LISTING
    GET_DELIVERY_TIMES_SUCCESS: "GET_DELIVERY_TIMES_SUCCESS",
    //CREATION
    ADD_DELIVERY_TIMES_SUCCESS: "ADD_DELIVERY_TIMES_SUCCESS",
    //DETAILS
    GET_DELIVERY_TIME_DETAILS_SUCCESS: "GET_DELIVERY_TIME_DETAILS_SUCCESS",
    //UPDATE
    UPDATE_DELIVERY_TIME_SUCCESS: "UPDATE_DELIVERY_TIME_SUCCESS",
    //DELETE
    DELETE_DELIVERY_TIME_SUCCESS: "DELETE_DELIVERY_TIME_SUCCESS",
}
export default ProductsOptionTypes