/* eslint-disable array-callback-return */
import Types from "./Types";

const INIT_STATE = {
    suggestedWraps: [],
    suggestedWrap: {},
    count: 0,
    load: null,
    error: null,
};

export function suggestedWrappersReducer(state = INIT_STATE, action) {
    const { type, payload } = action;
    switch (type) {
        case Types.GET_SUGGESTED_WRAPS_SUCCESS: {
            return {
                ...state,
                suggestedWraps: payload?.data?.suggested_wrapper,
                count: payload?.extra?.total_count,
            };
        }
        case Types.SUGGESTED_WRAPS_LOADING: {
            return {
                ...state,
                load: payload,
            };
        }
        case Types.DELETE_SUGGESTED_WRAPS_SUCCESS: {
            return {
                ...state,
            };
        }
        case Types.ADD_SUGGESTED_WRAPS_SUCCESS: {
            return {
                ...state,
            };
        }
        case Types.UPDATE_SUGGESTED_WRAPS_SUCCESS: {
            return {
                ...state,
            };
        }
        case Types.GET_SUGGESTED_WRAPS_DETAILS_SUCCESS: {
            return {
                ...state,
                suggestedWrap: payload?.data?.suggested_wrapper,
            };
        }

        default: {
            return state;
        }
    }
}
