import { Box, Stack } from "@mui/material";
import React from "react";
import UsersInfo from "../usersInfo";
import FollowersData from "./followersData";

export default function CustomerProfileInfo({
  data,
  followers,
  followings,
  justifyContent,
  getFollowingList,
  getFollowersList,
  followingslist,
  followersList,
}) {
  return (
    <Stack direction="row" gap="33px">
      <Box
        component="img"
        src={data?.avatar}
        width="228px"
        height="229px"
        borderRadius="6px"
      />
      <Stack gap="20px" justifyContent={justifyContent || "end"}>
        <UsersInfo data={data} />
        {followers && followings && (
          <FollowersData
            followings={followings}
            followers={followers}
            data={data}
            getFollowingList={getFollowingList}
            getFollowersList={getFollowersList}
            followingslist={followingslist}
            followersList={followersList}
          />
        )}
      </Stack>
    </Stack>
  );
}
