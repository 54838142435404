import Types from "./Types";

export const GetOrderDetailsLoading = (payload) => ({
  type: Types.ORDER_DETAILS_LOADING,
  payload,
});

export const GetOrderDetailsSuccess = (payload) => ({
  type: Types.GET_ORDER_DETAILS_SUCCESS,
  payload,
});

export const GetEmployeesSuccess = (payload) => ({
  type: Types.GET_EMPLOPYEES_SUCCESS,
  payload,
});

export const AssignToEmployeeSuccess = (payload) => ({
  type: Types.ASSIGN_TO_EMPLOYEE_SUCCESS,
  payload,
});

export const UnAssignDriverSuccess = (payload) => ({
  type: Types.UNASSIGN_DRIVER_SUCCESS,
  payload,
});

export const AssignToFreelancerSuccess = (payload) => ({
  type: Types.ASSIGN_TO_FREELANCER_SUCCESS,
  payload,
});


export const SetOrederReadyForDeliverySuccess = (payload) => ({
  type: Types.SET_ORDER_READY_FOR_DELIVERY,
  payload,
});


export const GetDriverComplainsSuccess = (payload) => ({
  type: Types.GET_DRIVER_COMPLAINS_DETAILS_SUCCESS,
  payload,
});

export const UpdateDriverComplainsSuccess = (payload) => ({
  type: Types.UPDATE_DRIVER_COMPLAINS_SUCCESS,
  payload,
});

export const GetFreelancerSuccess = (payload) => ({
  type: Types.GET_FREELANCER_SUCCESS,
  payload,
});

export const GetOrdersSuccess = (payload) => ({
  type: Types.GET_ORDERS_SUCCESS,
  payload,
});

export const CancelOrderSuccess = (payload) => ({
  type: Types.CANCEL_ORDER_SUCCESS,
  payload,
});

export const GetOrderStatusSuccess = (payload) => ({
  type: Types.GET_ORDER_STATUS_SUCCESS,
  payload,
});