import Types from "./Types";

export const OrderDeclinationLoading = (payload) => ({
  type: Types.GET_ORDER_DECLINATION_LOADING,
  payload,
});

export const GetOrderDeclinationSuccess = (payload) => ({
  type: Types.GET_ORDER_DECLINATION_REASONS_SUCCESS,
  payload,
});

export const AddOrderDeclinationSuccess = (payload) => ({
  type: Types.ADD_ORDER_DECLINATION_REASON_SUCCESS,
  payload,
});

export const GetOrderDeclinationDetails = (payload) => ({
  type: Types.GET_ORDER_DECLINATION_REASONS_DETAILS_SUCCESS,
  payload,
});
