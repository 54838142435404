import { icons } from "assets/AssetHelper";
import dayjs from "dayjs";

export const detailsData = ({ data }) => [
  {
    head: "Details",
    icon: icons?.details,
    details: [
      {
        label: "Notification message  (EN)",
        name: data?.localized_data?.en?.message,
      },
      {
        label: "Notification message  (AR)",
        name: data?.localized_data?.ar?.message,
      },
    ],
  },

  {
    head: "Dates",
    icon: icons?.dates,
    details: [
      {
        label: "Created At",
        name: dayjs(data?.sent_at).format("DD-MM-YYYY HH:MM a"),
      },
    ],
  },
];
