import { Stack } from "@mui/material";
import ProductDetailsHeader from "components/Molecules/Products/productDetailsHeader";
import CustomTabs from "components/Organisms/tabs/customTabs";
import VendorDetailsHeader from "../../../components/Molecules/vendor/vendorDetailsHeader";

export default function VendorDetailsTemplate({
  details,
  setOpenDeleteModal,
  tabs,
  selectedTab,
  handleTabChange,
  actionsList,
  approvalStatusOptions,
}) {
  return (
    <Stack>
      <VendorDetailsHeader
        data={details}
        setOpenDeleteModal={setOpenDeleteModal}
        actionsList={actionsList}
        approvalStatusOptions={approvalStatusOptions}
      />
      <CustomTabs
        tabs={tabs}
        selectedTab={selectedTab}
        handleTabChange={handleTabChange}
      />
    </Stack>
  );
}
