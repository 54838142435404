import { Button, CircularProgress } from "@mui/material";
import { useThemePalette } from "common/hooks/theme_palette";
import React from "react";

export default function OutlinedButton({
  title,
  onClick,
  backgroundColor = "#3FABAE",
  width = "50%",
  fontSize = "18px",
  px = 5,
  load,
  color,
  ...props
}) {
  const { orangeTwo } = useThemePalette();
  return (
    <Button
      onClick={onClick}
      variant="outlined"
      sx={{
        py: 1,
        px: 5,
        textTransform: "none",
        textAlign: "center",
        borderColor: color || orangeTwo,
        color: color || orangeTwo,
        "&:hover": {
          backgroundColor: "transparent",
          borderColor: color || orangeTwo,
        },
      }}
      {...props}
    >
      {load ? (
        <CircularProgress
          sx={{
            color: "white",
            width: "33px !important",
            height: "33px !important",
          }}
        />
      ) : (
        title
      )}
    </Button>
  );
}
