import { FormControl, FormHelperText, InputLabel, MenuItem, Typography } from "@mui/material";
import React from "react";
import Select from "@mui/material/Select";
import "./Select.css";

function SelectBox({
  items,
  title,
  value,
  onchange,
  id,
  name,
  error,
  helperText,
  labelStyle,
  defaultValue,
  style,
  disabled,
  className,
  placeholder,
  icon,
  onclick = () => {},
}) {
  // const [value, setValue] = React.useState("");
  // const handleChange = (event) => {
  //   setValue(event.target.value);
  // };

  return (
    <FormControl
      disabled={disabled}
      fullWidth
      sx={{
        ...style,
        color: "red",
        "& label.Mui-focused": {
          color: "white",
        },
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            borderColor: "white",
          },
          "&:hover fieldset": {
            borderColor: "white",
          },
          "&.Mui-focused fieldset": {
            borderColor: "white",
          },
        },
      }}
      error={error ? true : false}
    >
      <Typography gutterBottom sx={{ color: "#565656" }}>
        {title}
      </Typography>
      <InputLabel // Use InputLabel
        htmlFor="demo-simple-select"
        sx={{ color: "#D7D7D7", mt:3.5 }}
      >
        {placeholder}
      </InputLabel>
      {/* <InputLabel id="demo-simple-select-label" sx={{ mt: 3.5 }}>
        {title}
      </InputLabel> */}

      <Select
        labelId="demo-simple-select-label"
        defaultValue={defaultValue}
        // placeholder={placeholder}
        id={id}
        name={name}
        className={className}
        defaultChecked=""
        style={{ color: "black" }}
        value={value || ""}
        label={title}
        onChange={onchange}
        IconComponent={icon && icon}
      >
        <MenuItem value="" disabled>
          {title}
        </MenuItem>
        {items?.map((item) => (
          <MenuItem
            value={item.id}
            key={item.id}
            onClick={(e) => onclick(e, item)}
            style={{ color: item.color }}
          >
            {item.icon ? <img style={{ margin: "0 10px" }} src={item.icon} alt="item icon" /> : ""}{" "}
            {item.name}
          </MenuItem>
        ))}
      </Select>
      {error ? <FormHelperText>{helperText}</FormHelperText> : ""}
    </FormControl>
  );
}

export default SelectBox;
