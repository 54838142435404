import { Stack } from "@mui/material";
import ProductDetailsHeader from "components/Molecules/Products/productDetailsHeader";
import CustomTabs from "components/Organisms/tabs/customTabs";

export default function ProductDetailsTemplate({ details, setOpenDeleteModal, tabs, selectedTab, handleTabChange }) {
  return (
    <Stack>
      <ProductDetailsHeader data={details} setOpenDeleteModal={setOpenDeleteModal} />
      <CustomTabs tabs={tabs} selectedTab={selectedTab} handleTabChange={handleTabChange} />
    </Stack>
  );
}
