import { Box, Divider, Stack, Typography } from "@mui/material";
import { icons } from "assets/AssetHelper";
import { useThemePalette } from "common/hooks/theme_palette";
import dayjs from 'dayjs';
import { onValue, ref } from "firebase/database";
import { useDispatch } from "react-redux";
import { db } from "services/utils/firebase";

export default function ChatItem({ item, visitChatRoom, setFirstMessageCount, setMessagesList }) {
    const { oliveGreen, darkBlue, chatListBg } = useThemePalette()
    let data
    const node = localStorage?.getItem('Messages_node')?.split('/')?.[1]
    const chatId = localStorage.getItem("chatId");

    const extraOptionsRef = ref(db, `extra_options/${node}`);
    onValue(extraOptionsRef, async (snapshot) => {
        data = snapshot.val()
    })

    return <Box key={item?.id}>
        <Stack
            direction='row'
            sx={{ bgcolor: item?.id == chatId && chatListBg }}
            justifyContent='space-between'
            gap='10px'

            width='92%'
            p='20px'
            pr={'20px'}
            className="chat-item"
            onClick={() => visitChatRoom({
                messages_url: item?.messages_url,
                chat_id: item?.id,
                name: item?.name,
                image: item?.image,
            })}
        >
            <Stack width={{ md: '15%' }}>
                <img
                    src={item?.image?.split(',')?.[0] || icons.profilePlaceholder}
                    alt='chat room img'
                    style={{
                        width: "50px",
                        height: "50px",
                        borderRadius: "50%",
                        display: "block",
                        background: "#ddd",
                        border: "1px solid var(--secondary-color)"
                    }}
                />
            </Stack>
            <Stack width={{ md: '60%' }}>
                <Typography fontWeight='500' color={darkBlue} fontSize="15px">
                    {!item?.name ? "Chat Name" : item?.name}
                </Typography>
                <Typography
                    component="p"
                    style={{
                        color: item?.extra_info?.unseen_count > 0 ? "#000" : darkBlue,
                        fontSize: "14px",
                        width: { md: '100%' }
                    }}
                >
                    {item?.extra_info?.message_type == 'image' ? 'photo' : item?.extra_info?.message_type == 'application' ? 'document' : +data?.chat_room_id == item?.id ? data?.last_message?.slice(0, 40) : item?.extra_info?.last_message?.slice(0, 40)}
                </Typography>
            </Stack>
            <Stack justifyContent='space-between' width={{ md: '25%' }} style={{ textAlign: "end" }}>
                <Typography
                    component="p"
                    style={{
                        color: "rgba(0, 0, 0, 0.25)",
                        fontSize: "10px",
                        marginBottom: "5px"
                    }}
                >
                    {dayjs(new Date(item?.extra_info?.last_message_date).toLocaleString()).format("hh:mm A")}
                </Typography>
                <Typography
                    component="p"
                >
                    {item?.extra_info?.unseen_count > 0 && (
                        <span
                            style={{
                                background: oliveGreen,
                                color: "#fff",
                                width: "15px",
                                height: "15px",
                                borderRadius: "50%",
                                textAlign: "center",
                                fontSize: "10px",
                                display: "inline-block",
                                padding: '2px'
                            }}
                        >
                            {item?.extra_info?.unseen_count}
                        </span>
                    )}
                </Typography>
            </Stack>
        </Stack>
        <Divider style={{ width: "100%", color: '#F5F5F5', paddingInline: '10px' }} />
    </Box >
}
