import { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loader from "components/Atoms/SharedComponents/Loader/Loader";
import setAuthToken from "services/modules/utils/handel_api";
import {
  assignToEmployeeRequest,
  showReturnRequest,
  getReturnRequestItemsRequest,
  updateReturnRequestItemRequest,
  deleteReturnRequest,
  unAssignReturnRequestDriverRequest,
  setOrederReadyForDeliveryRequest,
} from "services/modules/orders/return-order";
import { isOpenAssignToEmployee } from "services/modules/modal/Actions";
import { toast } from "react-toastify";
import ReturnRequestDetailsTemplate from "templates/orders/return-order/returnRequestDetailsTemplate";
import DeleteReturnRequestModel from "components/Molecules/Order/returnRequest/model/deleteReturnRequestModel";
import {
  Completed,
  CustomerToCadeau,
  Delivered,
} from "services/modules/utils/constant";
import { updateDriverComplainRequest } from "services/modules/orders";

function ReturnRequestDetailsPage() {
  const token = localStorage.getItem("token");
  setAuthToken(token);
  let [searchParams] = useSearchParams();

  const type = searchParams.get("type");
  const id = searchParams.get("id");
  const requestId = searchParams.get("request_id");

  const details = useSelector(
    (state) => state.returnOrders?.returnRequestDetails
  );
  const returnRequestItems = useSelector(
    (state) => state.returnOrders?.returnRequestItems
  );
  const returnRequestItem = useSelector(
    (state) => state.returnOrders.returnRequestItem
  );
  const dispatch = useDispatch();
  const [filterObj, setFilterObj] = useState(null);
  const employees = useSelector((state) => state.returnOrders.employees);
  const { orderStatus, routeOne, routeTwo, routeThree, routes, load } =
    useSelector((state) => state?.returnOrders);
  const [openResplveModal, setOpenResplveModal] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [dirverRouteOne, setDriverRouteOne] = useState(null);
  const [driverRouteTwo, setDriverRouteTwo] = useState(null);
  const [currentDriver, setCurrentDriver] = useState(null);
  const [key, setKey] = useState(0);
  const [updateComplaintData, setUpdateComplaintData] = useState({});
  const userType = localStorage.getItem("user_type");
  const [openUnAssignModal, setOpenUnAssignModal] = useState(false);
  const routesStatusChanges = useSelector(
    (state) => state?.returnOrders?.routesStatusChanges
  );
  const show_items = useSelector((state) => state?.returnOrders?.show_items);
  const [openActionsModal, setOpenActionsModal] = useState(false);
  const [detailsFlag, setDetailsFlag] = useState(true);
  // ;
  const breadcrumbs = [
    {
      active: true,
      path: "/",
      title: "Home",
    },
    {
      active: true,
      path: "/orders",
      title: "orders",
    },
    {
      active: true,
      path: `/orders?type=details&id=${id}`,
      title: `Order #${id}`,
    },
    {
      active: true,
      path: `/return-request?id=${id}`,
      title: "Return Requests",
    },
    {
      active: true,
      path: `/create-return-request?id=${id}`,
      title: "New Request",
    },
    {
      active: !type ? false : true,
      path: `/orders?type=details&id=${id}`,
      title: `Return Request Details #${requestId}`,
    },
  ];
  const navigate = useNavigate();

  const deleteRequest = () => {
    const action = () => {
      navigate(`/return-request?id=${id}`);
      setOpenActionsModal(false)
    };
    dispatch(deleteReturnRequest({ id: requestId, action }));
  };
  const actionsList = ({ index, data }) => {
    return [
      {
        condition: () => {
          return true;
        },
        click: () => {
          setOpenActionsModal(true);
        },
        text: "Cancel",
      },
    ];
  };

  const getDetails = async () => {
    dispatch(showReturnRequest({ id: requestId, show_items: true }));
  };
  useEffect(() => {
    if (requestId && detailsFlag) {
      setDetailsFlag(false);
      getDetails();
    }
  }, [requestId, detailsFlag]);

  useEffect(() => {
    // lw fe driver fe route 1 w mfesh driver fe route 2
    if (routes && routes[0]?.driver?.id && !routes[1]?.driver) {
      setDriverRouteOne(routes[0]?.driver);
      setCurrentDriver(routes[0]?.driver);
    }
    // lw fe driver fe route 1 w feh driver fe route 2 w el order msh delivered
    else if (
      routes &&
      routes[0]?.driver?.id &&
      routes[1]?.driver?.id
      // && returnOrders?.status != "delivered"
    ) {
      setDriverRouteOne(routes[0]?.driver);
      setDriverRouteTwo(routes[1]?.driver);
      setCurrentDriver(routes[1]?.driver);
    }
  }, [routes]);

  const isRouteOne = (status) => {
    if (
      status == "draft" ||
      status == "driver_assignment" ||
      status == "route_one_in_progress" ||
      status == "items_checked"
    ) {
      return true;
    }
    return false;
  };
  const isRouteTwo = (status) => {
    if (status == "ready_for_delivery") {
      return true;
    }
    return false;
  };
  const isRouteThree = () => {
    if (
      routeThree &&
      (orderStatus == "route_three_in_progress" ||
        routeTwo?.status == Delivered)
    ) {
      return true;
    } else {
      return false;
    }
  };


  const getCurrentRoute = () => {
    return isRouteOne(orderStatus)
      ? routeOne
      : isRouteThree()
      ? routeThree
      : routeTwo;
  };

  const handleAssignEmployee = () => {
    const action = () => {
      toast.success(`Employee assigned successfully`);
      setSelectedEmployee(null);
      dispatch(isOpenAssignToEmployee());
    };
    !selectedEmployee && toast.error(`Please select driver`);
    const requestData = {
      body: {
        id: requestId,
        route_type: getCurrentRoute()?.leg_type,
        driver_type: selectedEmployee?.driver_type,
        driver_id: selectedEmployee?.id,
      },
      action,
    };
    selectedEmployee && dispatch(assignToEmployeeRequest({ ...requestData }));
  };

  const handleUnAssignDriver = ({ driverId }) => {
    const action = () => {
      toast.success(`Driver unassigned successfully`);
      // dispatch(getOrderDetailsRequest({ order_id: id }));
      setOpenUnAssignModal(false);
    };
    const requestData = {
      body: {
        request_id: requestId,
        route_type: getCurrentRoute()?.leg_type,
        driver_id: driverId,
      },
      action,
    };
    dispatch(unAssignReturnRequestDriverRequest({ ...requestData }));
  };

  const setOrderReadyForDelivery = () => {
    const action = () => {
      toast.success(`Return Request Ready for delivery`);
    };
    const requestData = {
      body: {
        request_id: requestId,
      },
      action,
    };
    dispatch(setOrederReadyForDeliveryRequest({ ...requestData }));
  };

  const getReturnRequestItem = () => {
    const requestData = {
      id,
    };
    dispatch(getReturnRequestItemsRequest({ ...requestData }));
  };

  const handleCloseModel = () => {
    dispatch(isOpenAssignToEmployee());
    setSelectedEmployee(null);
  };

  const handleUpdateComplains = ({ complaintId, status }) => {
    const action = () => {
      toast.success(
        `Complain ${status == "open" ? "unresolved" : "resolved"} successfully `
      );
    };
    const requestData = {
      route_complaint_id: complaintId,
      body: {
        route_complaint: {
          status,
        },
      },
      action,
    };
    dispatch(updateDriverComplainRequest({ ...requestData }));
  };

  const updateRequestItem = ({ requestData }) => {
    dispatch(updateReturnRequestItemRequest({ ...requestData }));
  };

  return (
    <>
      <Loader open={load?.loading && load?.type == "details"} />
      <ReturnRequestDetailsTemplate
        id={id}
        details={details}
        routes={routes}
        employees={employees}
        breadcrumbs={breadcrumbs}
        actionsList={actionsList}
        setFilterObj={setFilterObj}
        filterObj={filterObj}
        setSelectedEmployee={setSelectedEmployee}
        selectedEmployee={selectedEmployee}
        handleAssignEmployee={handleAssignEmployee}
        handleCloseModel={handleCloseModel}
        handleUnAssignDriver={handleUnAssignDriver}
        setOrderReadyForDelivery={setOrderReadyForDelivery}
        getReturnRequestItem={getReturnRequestItem}
        dirverRouteOne={dirverRouteOne}
        driverRouteTwo={driverRouteTwo}
        currentDriver={currentDriver}
        key={key}
        orderStatus={orderStatus}
        routeOne={routeOne}
        routeTwo={routeTwo}
        isRouteOne={isRouteOne}
        handleUpdateComplains={handleUpdateComplains}
        setUpdateComplaintData={setUpdateComplaintData}
        updateComplaintData={updateComplaintData}
        openResplveModal={openResplveModal}
        setOpenResplveModal={setOpenResplveModal}
        load={load}
        userType={userType}
        routesStatusChanges={routesStatusChanges}
        show_items={show_items}
        updateRequestItem={updateRequestItem}
        returnRequestItems={returnRequestItems}
        requestId={requestId}
        setOpenActionsModal={setOpenActionsModal}
        routeThree={routeThree}
        isRouteThree={isRouteThree}
        openUnAssignModal={openUnAssignModal}
        setOpenUnAssignModal={setOpenUnAssignModal}
        isRouteTwo={isRouteTwo}
        getCurrentRoute={getCurrentRoute}
      />
      {openActionsModal && (
        <DeleteReturnRequestModel
          setOpenModel={setOpenActionsModal}
          openModel={openActionsModal}
          handleUpdate={deleteRequest}
          load={load?.loading && load?.type == "cancel"}
        />
      )}
    </>
  );
}

export default ReturnRequestDetailsPage;
