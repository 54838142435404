import TableUse from "components/Atoms/SharedComponents/CRUD/table-use";
import { Stack } from "@mui/material";
import DeleteModal from "components/Molecules/Models/DeleteModal";
import VariantForm from "components/Organisms/products/productVariants/variantForm";
import VariantDetails from "components/Organisms/products/productVariants/variantDetails";
import { useSelector } from "react-redux";

export default function ProductVariantTemplate({
  isOpenForm,
  isOpenDetails,
  setIsOpenDetails,
  load,
  details,
  setOpenDeleteModal,
  openDeleteModal,
  handleDelete,
  handleSubmit,
  locations,
  setLocations,
  tableData,
  list,
  columns,
  setTableData,
  setFilterObj,
  filterList,
  headerChildren,
  actionsList,
  rowData,
  handlePageChange,
  page,
  count,
  optionTypesFilter,
  handleVariantFormClose,
  handleSaveVariantBranch,
  handleDeleteVariantBranch,
  branches,
  selectedRow,
  getDetails,
  fetchOptionValues,
  option_values,
  option_values_loading,
}) {
 
  return (
    <>
      <Stack sx={{ backgroundColor: "white" }}>
        <VariantForm
          open={isOpenForm}
          handleSubmit={handleSubmit}
          locations={locations}
          setLocations={setLocations}
          id={selectedRow?.rowData?.id && selectedRow?.rowData?.id}
          details={details}
          optionTypesFilter={optionTypesFilter}
          handleVariantFormClose={handleVariantFormClose}
          handleSaveVariantBranch={handleSaveVariantBranch}
          handleDeleteVariantBranch={handleDeleteVariantBranch}
          branches={branches}
          selectedRow={selectedRow}
          getDetails={getDetails}
          fetchOptionValues={fetchOptionValues}
          option_values={option_values}
          option_values_loading={option_values_loading}
        />
        <VariantDetails
          open={isOpenDetails}
          setOpen={setIsOpenDetails}
          details={details}
          optionTypesFilter={optionTypesFilter}
          load={load}
        />
        <TableUse
          list={list}
          columnsData={columns}
          setTableData={setTableData}
          setFilterObj={setFilterObj}
          filterList={filterList}
          headerChildren={headerChildren}
          actionsList={actionsList}
          rowData={rowData}
          openDeleteModal={openDeleteModal}
          setOpenDeleteModal={setOpenDeleteModal}
          handlePageChange={handlePageChange}
          paginationPage={page}
          total_count={count}
          emptyListMsg="No Result"
        />
        <DeleteModal
          open={openDeleteModal}
          setOpen={setOpenDeleteModal}
          handelDeleteRequest={() => {
            handleDelete(tableData?.rowData?.id && tableData?.rowData?.id);
          }}
          handleCancelDelete={() => setOpenDeleteModal(false)}
          customClass="organizations-delete-modal"
          text={[`Delete variant`, `Are you sure that you want to delete this variant?`]}
        />
      </Stack>
    </>
  );
}
