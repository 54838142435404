import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loader from "components/Atoms/SharedComponents/Loader/Loader";
import { toast } from "react-toastify";
import setAuthToken from "services/modules/utils/handel_api";
import { Box, Stack, Typography } from "@mui/material";
import SuspendModel from "components/Molecules/users/model/suspendModel";
import ActiveModel from "components/Molecules/users/model/activeModel";
import DeleteModel from "components/Molecules/users/model/deleteModel";
import {
  addDriverRequest,
  getDriverDetailsRequest,
  getDriversRequest,
  updateDriverRequest,
} from "services/modules/users/driver";
import FreelancerTemplate from "templates/users/drivers/freelancer";
import { useThemePalette } from "common/hooks/theme_palette";
import { icons } from "assets/AssetHelper";
import ApproveModel from "components/Molecules/users/model/approveModel";
import RejectionModel from "components/Molecules/users/model/rejectionModel";

function FreeLancerPage() {
  const token = localStorage.getItem("token");
  setAuthToken(token);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    gray11,
    backgroundRed,
    backgroundGreen,
    textGreen,
    textRed,
    yellow,
    backgroundYellow,
  } = useThemePalette();
  let [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const type = searchParams.get("type");

  const list = useSelector((state) => state.drivers.drivers);
  const count = useSelector((state) => state.drivers.count);
  const details = useSelector((state) => state.drivers.driver);
  const load = useSelector((state) => state.drivers.load);

  //permissions
  const currentUser = useSelector(
    (state) => state.authenticate.currentUserProfile
  );
  const permissions = useSelector(
    (state) => state.authenticate.currentUserProfile.permissions.drivers
  );
  const isSuperAdmin = currentUser?.privilege == "super_admin";

  const [openActionsModal, setOpenActionsModal] = useState({
    state: false,
    action: false,
    customerId: 0,
  });
  const [rejectionReason, setRejectionReason] = useState();
  const [selectedRow, setSelectedRow] = useState({});
  const [page, setPage] = useState(1);
  const [filterObj, setFilterObj] = useState(null);

  const handlePageChange = (page) => {
    setPage(page);
    setFilterObj((prev) => {
      return { ...prev, page_number: page };
    });
  };

  const getDetails = async () => {
    const requestData = {
      id: id,
    };
    dispatch(getDriverDetailsRequest({ ...requestData }));
  };

  const getList = async (filterObj) => {
    const requestData = {
      params: {
        page_size: 10,
        page_number: 1,
        keyword: "",
        sort_by: "id",
        driver_type: "freelancer",
        ...filterObj,
      },
    };
    dispatch(getDriversRequest(requestData));
  };

  const mappedData = list?.map((data, index) => {
    const isActive = data?.status == "active";
    const isOnline = data?.state == "online";
    const isApproved = data?.profile_status == "approved";
    const isPending = data?.profile_status == "pending_approval";
    return {
      ...data,
      phone_number: `+${data?.country_code}${data?.phone_number}`,
      state: (
        <Stack
          sx={{
            px: 0.9,
            py: 0.2,
            borderRadius: "4px",
            backgroundColor: isOnline ? backgroundGreen : backgroundRed,
            width: "fit-content",
          }}
          justifyContent="center"
          alignItems="center"
        >
          <Typography
            sx={{
              color: isOnline ? textGreen : textRed,
            }}
          >
            {data?.state}
          </Typography>
        </Stack>
      ),
      approval_status: (
        <>
          <Stack
            sx={{
              px: 1.5,
              py: 0.2,
              borderRadius: "4px",
              backgroundColor: isApproved
                ? backgroundGreen
                : isPending
                ? backgroundYellow
                : backgroundRed,
              width: "fit-content",
            }}
            justifyContent="center"
            alignItems="center"
          >
            <Typography
              sx={{
                color: isApproved ? textGreen : isPending ? yellow : textRed,
              }}
              fontSize="16px"
            >
              {isPending ? "Pending" : data?.profile_status}
            </Typography>
          </Stack>
          {data?.rejection_reason && (
            <Stack
              direction="row"
              gap="2px"
              mt="1px"
              sx={{ cursor: "pointer" }}
            >
              <Box component="img" src={icons.outlinedEye} />
              <Typography
                onClick={(_) => {
                  setOpenActionsModal({
                    state: true,
                    customerId: data?.id,
                    action: "viewReason",
                    rejectionReason: data?.rejection_reason,
                  });
                  setRejectionReason(data?.rejection_reason);
                }}
                fontSize="12px"
                color={gray11}
                fontWeight={"500"}
              >
                View Reason
              </Typography>
            </Stack>
          )}
        </>
      ),
      list_profile_status: (
        <Stack
          sx={{
            px: 0.9,
            py: 0.2,
            borderRadius: "4px",
            backgroundColor: isActive ? backgroundGreen : backgroundRed,
            width: "fit-content",
          }}
          justifyContent="center"
          alignItems="center"
        >
          <Typography
            sx={{
              color: isActive ? textGreen : textRed,
            }}
          >
            {data?.status}
          </Typography>
        </Stack>
      ),
    };
  });

  const rowData = (data) => {
    setSelectedRow(data);
  };

  const breadcrumbs = [
    {
      active: true,
      path: "/",
      title: "Home",
    },
    {
      active: !type ? false : true,
      path: "/freelancer-drivers",
      title: "Users/Freelancers drivers",
    },
    type && {
      active: false,
      title:
        type === "details"
          ? "Freelancer driver profile"
          : id
          ? "Edit employee driver"
          : "New employee driver",
    },
  ];

  const handelSubmit = (data, formik) => {
    const action = () => {
      searchParams.delete("id");
      searchParams.delete("type");
      !id && formik?.resetForm();
      toast.success(
        `Employee driver ${id ? "updated" : "added"}  successfully`
      );
      navigate("/employee-drivers");
    };
    const national_id_media_attributes = [
      {
        url: data?.national_id_front,
        media_type: "photo",
      },
      {
        url: data?.national_id_back,
        media_type: "photo",
      },
    ];
    const criminal_status_media_attributes = [
      {
        url: data?.criminal_status_front,
        media_type: "photo",
      },
      {
        url: data?.criminal_status_back,
        media_type: "photo",
      },
    ];
    const driver_licence_media_attributes = [
      {
        url: data?.driver_licence_front,
        media_type: "photo",
      },
      {
        url: data?.driver_licence_back,
        media_type: "photo",
      },
    ];
    const vehicle_licence_media_attributes = [
      {
        url: data?.vehicle_licence_front,
        media_type: "photo",
      },
      {
        url: data?.vehicle_licence_back,
        media_type: "photo",
      },
    ];

    const requestData = {
      body: {
        driver: {
          // ...formik?.values,
          avatar: data.avatar,
          name: data.name,
          national_id: data.national_id,
          email: data.email,
          phone_number: data.phone_number,
          country_id: data.country_id,
          city_id: data.city_id,
          password: data.password,
          gender: data.gender == 0 ? "male" : "female",
          birthdate: data?.birthdate,
          password_confirmation: data.password_confirmation,
          national_id_media_attributes,
          criminal_status_media_attributes,
          driver_licence_media_attributes,
          vehicle_licence_media_attributes,
          profile_status: "approved",
        },
      },
      action,
    };

    const update_national_id_media_attributes = [
      {
        url: data?.national_id_front,
        media_type: "photo",
        id: details?.national_id_media[0]?.id,
      },
      {
        url: data?.national_id_back,
        media_type: "photo",
        id: details?.national_id_media[1]?.id,
      },
    ];
    const update_criminal_status_media_attributes = [
      {
        url: data?.criminal_status_front,
        media_type: "photo",
        id: details?.criminal_status_media[0]?.id,
      },
      {
        url: data?.criminal_status_back,
        media_type: "photo",
        id: details?.criminal_status_media[1]?.id,
      },
    ];
    const update_driver_licence_media_attributes = [
      {
        url: data?.driver_licence_front,
        media_type: "photo",
        id: details?.driver_licence_media[0]?.id,
      },
      {
        url: data?.driver_licence_back,
        media_type: "photo",
        id: details?.driver_licence_media[1]?.id,
      },
    ];
    const update_vehicle_licence_media_attributes = [
      {
        url: data?.vehicle_licence_front,
        media_type: "photo",
        id: details?.vehicle_licence_media[0]?.id,
      },
      {
        url: data?.vehicle_licence_back,
        media_type: "photo",
        id: details?.vehicle_licence_media[1]?.id,
      },
    ];

    const updateRequestData = {
      body: {
        driver: {
          // ...formik?.values,
          avatar: data.avatar,
          name: data.name,
          national_id: data.national_id,
          email: data.email,
          phone_number: data.phone_number,
          country_id: data.country_id,
          city_id: data.city_id,
          password: data.password,
          gender: data.gender == 0 ? "male" : "female",
          birthdate: data?.birthdate,
          password_confirmation: data.password_confirmation,
          national_id_media_attributes: update_national_id_media_attributes,
          criminal_status_media_attributes:
            update_criminal_status_media_attributes,
          driver_licence_media_attributes:
            update_driver_licence_media_attributes,
          vehicle_licence_media_attributes:
            update_vehicle_licence_media_attributes,
          profile_status: data?.profile_status,
        },
      },
      action,
    };

    if (id) {
      //update call
      dispatch(updateDriverRequest({ ...updateRequestData, id }));
    } else {
      //create call
      dispatch(addDriverRequest(requestData));
    }
  };

  const filterList = [
    {
      type: "search",
      placeholder: "Search by  Driver name  ",
      onChange: (value) =>
        setFilterObj((s) => {
          return { ...s, keyword: value?.trim(), page_number: 1 };
        }),
      setFilterObj,
    },
    {
      type: "filter",
      placeholder: "Availability",
      onChange: (value) => {
        setFilterObj((s) => {
          console.log(value);
          return {
            ...s,
            availability_status: value?.label?.toLowerCase(),
            page_number: 1,
          };
        });
      },
      options: [
        { id: 0, name: "Online" },
        { id: 1, name: "Offline" },
      ],
    },
    {
      type: "filter",
      placeholder: "Profile Status",
      onChange: (value) => {
        setFilterObj((s) => {
          return { ...s, status: value?.value, page_number: 1 };
        });
      },
      options: [
        { id: "active", name: "Active" },
        { id: "deactivated", name: "Deactivated" },
        { id: "suspended", name: "Suspended" },
      ],
    },
  ];

  const handleDelete = () => {};
  //list of actions to be displayed when clicked in the 3 dots
  const actionsList = ({ index, data }) => {
    const profileStatus = data?.status;
    const approvelStatus = data?.profile_status;
    const isIncomplete = approvelStatus == "incomplete";
    const isDeleted = data?.status == "deleted";
    console.log(profileStatus);
    return [
      {
        condition: () => {
          return !id;
        },
        click: () => {
          navigate(`?type=details&id=${data?.id}`);
        },
        text: "View profile",
      },
      {
        condition: () => {
          return (
            approvelStatus != "approved" &&
            !isIncomplete &&
            !isDeleted &&
            (isSuperAdmin || permissions?.can_update)
          );
        },
        click: () => {
          setOpenActionsModal({
            state: true,
            customerId: selectedRow?.rowData?.id
              ? selectedRow?.rowData?.id
              : id,
            action: "approved",
          });
        },
        text: "Approve",
      },
      {
        condition: () => {
          return (
            approvelStatus != "rejected" &&
            !isIncomplete &&
            !isDeleted &&
            (isSuperAdmin || permissions?.can_update)
          );
        },
        click: () => {
          // navigate(`?type=details&id=${data?.id}`);
          setOpenActionsModal({
            state: true,
            customerId: selectedRow?.rowData?.id
              ? selectedRow?.rowData?.id
              : id,
            action: "reject",
          });
        },
        text: "reject",
      },
      {
        condition: () => {
          return (
            data?.rejection_reason &&
            !isIncomplete &&
            !isDeleted &&
            (isSuperAdmin || permissions?.can_update)
          );
        },
        click: () => {
          setOpenActionsModal({
            state: true,
            customerId: selectedRow?.rowData?.id
              ? selectedRow?.rowData?.id
              : id,
            action: "editReason",
            rejectionReason: data?.rejection_reason,
          });
          setRejectionReason(data?.rejection_reason);
        },
        text: "Edit Reason",
      },
      {
        condition: () => {
          return (
            profileStatus == "active" &&
            !isIncomplete &&
            !isDeleted &&
            (isSuperAdmin || permissions?.can_update)
          );
        },
        click: () => {
          setOpenActionsModal({
            state: true,
            customerId: selectedRow?.rowData?.id
              ? selectedRow?.rowData?.id
              : id,
            action: "suspended",
          });
        },
        text: "Suspend",
      },
      {
        condition: () => {
          return (
            profileStatus == "suspended" &&
            !isIncomplete &&
            !isDeleted &&
            (isSuperAdmin || permissions?.can_update)
          );
        },
        click: () => {
          setOpenActionsModal({
            state: true,
            customerId: selectedRow?.rowData?.id
              ? selectedRow?.rowData?.id
              : id,
            action: "active",
          });
        },
        text: "Active",
      },
      {
        condition: () => {
          return (
            data?.status != "deleted" &&
            !isIncomplete &&
            !isDeleted &&
            (isSuperAdmin || permissions?.can_delete)
          );
        },
        click: () => {
          setOpenActionsModal({
            state: true,
            customerId: selectedRow?.rowData?.id
              ? selectedRow?.rowData?.id
              : id,
            action: "deleted",
          });
        },
        text: "Delete",
      },
    ];
  };

  //listing all tags
  useEffect(() => {
    if (filterObj) getList(filterObj);
  }, [filterObj]);

  //details of a single tag
  useEffect(() => {
    if (id) getDetails();
  }, [id]);

  const handleUpdateProfileState = () => {
    const action = () => {
      toast.success(
        `Account has been ${openActionsModal?.action} successfully`
      );
    };
    const requestData = {
      body: {
        status: openActionsModal?.action,
      },
      id: openActionsModal?.customerId,
      action,
    };
    dispatch(updateDriverRequest({ ...requestData }));
  };

  const handleUpdateApprovalStatus = () => {
    const action = () => {
      toast.success(
        `Account has been ${openActionsModal?.action} successfully`
      );
    };
    const requestData = {
      body: {
        profile_status: openActionsModal?.action,
      },
      id: openActionsModal?.customerId,
      action,
    };
    dispatch(updateDriverRequest({ ...requestData }));
  };

  const handleRejectionSubmit = () => {
    const action = () => {
      toast.success(`Rejection reason has been send successfully`);
      setOpenActionsModal({ state: false, action: "", customerId: false });
    };
    const requestData = {
      body: {
        driver: {
          profile_status: "rejected",
          rejection_reason: rejectionReason,
        },
      },
      id: openActionsModal?.customerId,
      action,
    };
    dispatch(updateDriverRequest({ ...requestData }));
  };
  return (
    <>
      <Loader open={load?.loading} />
      <FreelancerTemplate
        filterObj={filterObj}
        setFilterObj={setFilterObj}
        data={mappedData}
        count={count}
        paginationPage={page}
        handlePageChange={handlePageChange}
        rowData={rowData}
        details={details}
        breadcrumbs={breadcrumbs}
        filterList={filterList}
        handelSubmit={handelSubmit}
        actionsList={actionsList}
        handleDeleteRequest={handleDelete}
      />
      {openActionsModal?.state && openActionsModal?.action == "active" && (
        <ActiveModel
          setOpenModel={setOpenActionsModal}
          openModel={openActionsModal}
          handleUpdate={handleUpdateProfileState}
          userType={"driver"}
        />
      )}
      {openActionsModal?.state && openActionsModal?.action == "suspended" && (
        <SuspendModel
          setOpenModel={setOpenActionsModal}
          openModel={openActionsModal}
          handleUpdate={handleUpdateProfileState}
          userType={"driver"}
        />
      )}
      {openActionsModal?.state && openActionsModal?.action == "deleted" && (
        <DeleteModel
          setOpenModel={setOpenActionsModal}
          openModel={openActionsModal}
          handleUpdate={handleUpdateProfileState}
          userType={"driver"}
        />
      )}
      {openActionsModal?.state && openActionsModal?.action == "approved" && (
        <ApproveModel
          setOpenModel={setOpenActionsModal}
          openModel={openActionsModal}
          handleUpdate={handleUpdateApprovalStatus}
          userType={"driver"}
        />
      )}
      {(openActionsModal?.action == "reject" ||
        openActionsModal?.action == "editReason" ||
        openActionsModal?.action == "viewReason") &&
        openActionsModal?.state && (
          <RejectionModel
            handleRejectionSubmit={handleRejectionSubmit}
            rejectionReason={rejectionReason}
            setRejectionReason={setRejectionReason}
            setOpenModel={setOpenActionsModal}
            openModel={openActionsModal}
            userType="driver"
          />
        )}
    </>
  );
}

export default FreeLancerPage;
