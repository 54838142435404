import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import DriverComplainHistory from "components/Organisms/users/drivers/employee/driverComplainHistory";
import FreelancerDetailsTemplate from "templates/users/drivers/freelancer/freelancerDetails";
import DriverDetails from "components/Organisms/users/drivers/details";
import DriverOrderHistory from "components/Organisms/users/drivers/driverOrderHistory";
import { getCitiesRequest } from "services/modules/cities";
import { useEffect } from "react";
import { getCountiesRequest } from "services/modules/lookups/countries";
import { useDispatch } from "react-redux";
import { handleTabChange } from "services/modules/utils/Helper";

export default function FreelancerDetailsPage({ details, actionsList }) {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useState(new URLSearchParams(window.location.search));
  const selectedTab = searchParams.get("tab");
  const [value, setValue] = useState(+selectedTab || 0);

  useEffect(() => {
    const citiesRequestData = {
      params: {
        page_size: 1000,
        keyword: "",
        sort_by: "id",
      },
    };
    dispatch(getCitiesRequest(citiesRequestData));
    dispatch(getCountiesRequest(citiesRequestData));
  }, []);
  const tabs = [
    {
      title: "Details",
      children: <DriverDetails details={details} displayDriverDataWithImg={true} />,
    },
    { title: "Orders History", children: <DriverOrderHistory /> },
    { title: "Complaints History", children: <DriverComplainHistory /> },
  ];

  return (
    <FreelancerDetailsTemplate
      details={details}
      selectedTab={value}
      tabs={tabs}
      handleTabChange={(event, newValue) => handleTabChange({ event, newValue, setValue, searchParams, setSearchParams })}
      actionsList={actionsList}
    />
  );
}
