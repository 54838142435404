import { FormControl, FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import React, { useMemo } from "react";
import CFormErrors from "./CFormErrors";
import { useThemePalette } from "common/hooks/theme_palette";

const CRadioBox = ({ label, name, formik, changeAction, options }) => {
  const errorsMsg = formik?.errors[name];
  const value = formik?.values[name];
  const { textGray, orange } = useThemePalette();

  const mainLabelStyle = useMemo(() => {
    return { color: "#797979", fontSize: "18px", fontFamily: "Jost", mb: 2 };
  }, []);

  const radioStyles = {
    "&, &.Mui-checked": {
      color: orange,
    },
    "&:hover": {
      color: orange,
    },
  };
  return (
    <FormControl>
      <Typography sx={mainLabelStyle} id="demo-controlled-radio-buttons-group">
        {label}
      </Typography>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={value ? value : ""}
        onChange={(event) => changeAction(event?.target?.value)}
      >
        {options?.map((option, index) => {
          return (
              <FormControlLabel
                key={index}
                value={option?.value}
                control={<Radio sx={value == option?.value ? radioStyles : { color: textGray }} />}
                label={option?.label}
              />
          );
        })}
      </RadioGroup>
      <CFormErrors errorsMsg={errorsMsg} />
    </FormControl>
  );
};

export default CRadioBox;
