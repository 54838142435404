import { icons, images } from 'assets/AssetHelper'

export const detailsData = ({ data }) => [
    {
        head: 'Details',
        icon: icons?.detailsIcon,
        details: [
            {
                label: 'wrap',
                name: 'data?.name',
            },
            {
                label: 'wrap',
                name: data?.name,
            },
            {
                label: 'wrap',
                name: data?.name,
            },
        ]
    },
    {
        head: 'Dates',
        icon: icons.detailsIcon,
        details: [
            {
                label: 'Created Add',
                name: data?.created_at,
            },
            {
                label: 'Updated At',
                name: data?.updated_at,
            },
        ]
    },
    {
        head: 'Price',
        icon: icons.detailsIcon,
        details: [
            {
                label: 'Created Add',
                name: data?.created_at,
                width: '30%'
            },
            {
                label: 'Updated At',
                name: data?.updated_at,
                width: '30%'
            },
            {
                label: 'Updated At',
                name: data?.updated_at,
                width: '30%'
            },
            {
                label: 'Updated At',
                name: data?.updated_at,
                width: '30%'
            },
        ]
    },
];


