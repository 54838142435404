/* eslint-disable array-callback-return */
import Types from "./Types";

const INIT_STATE = {
  shapes: [],
  filterShapes: [],
  shape: {},
  count: 0,
  listCount: 0,
  load: null,
  error: null,
};

export function shapesReducer(state = INIT_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case Types.GET_SHAPES_SUCCESS: {
      return {
        ...state,
        shapes: payload?.data?.shapes,
        count: payload?.extra?.total_count,
      };
    }
    case Types.SHAPES_LOADING: {
      return {
        ...state,
        load: payload,
      };
    }
    case Types.DELETE_SHAPES_SUCCESS: {
      return {
        ...state,
      };
    }
    case Types.ADD_SHAPES_SUCCESS: {
      return {
        ...state,
      };
    }
    case Types.ADD_SHAPES_FAILED: {
      return {
        ...state,
        error: payload?.response?.data?.message,
      };
    }
    case Types.UPDATE_SHAPES_SUCCESS: {
      return {
        ...state,
      };
    }
    case Types.GET_SHAPES_DETAILS_SUCCESS: {
      return {
        ...state,
        shape: payload?.data?.shape,
      };
    }
    case Types.GET_SCROLL_SHAPES_SUCCESS: {
      return {
        ...state,
        filterShapes: payload?.extra?.page_number == 1
          ? payload?.data?.shapes
          : [...state.filterShapes, ...payload?.data?.shapes],
        listCount: payload?.extra?.total_count,
      };
    }
    default: {
      return state;
    }
  }
}
