import { useThemePalette } from 'common/hooks/theme_palette'
import ConfirmationModal from 'components/Molecules/Models/ConfirmationModal'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { isOpenViewComplain } from 'services/modules/modal/Actions'

export default function ComplainsUpdateModel({ handleUpdateComplains, updateComplaintData, setOpenResplveModal, openResplveModal }) {
    const { oliveGreen, red } = useThemePalette()
    const dispatch = useDispatch()
    return <>
        <ConfirmationModal
            open={openResplveModal}
            setOpen={setOpenResplveModal}
            handleConfirmation={_ => {
                setOpenResplveModal(false)
                handleUpdateComplains({ ...updateComplaintData })
            }}
            handleCancellation={_ => dispatch(isOpenViewComplain())}
            text={[
                updateComplaintData?.status == 'open' ? "Mark complain as unresolved" : "Mark complain as resolved ",
                updateComplaintData?.status == 'open' ? "Are you sure you want to Mark complaint as unresolved?" : "Are you sure you want to Mark complaint as resolved? ",
            ]}
            textStyle={{ color: '#000', fontSize: '32px' }}
            confirmBtnTitle={updateComplaintData?.status == 'open' ? "unesolved" : 'Resolved'}
            btnStyle={{
                backgroundColor: updateComplaintData?.status == 'open' ? red : oliveGreen,
                "&:hover": {
                    backgroundColor: updateComplaintData?.status == 'open' ? red : oliveGreen,
                },
                color: "white",
            }}
        />
    </>
}
