import Types from "./Types";

const INIT_STATE = {
    deliveryTimes:[],
    deliveryTime:{},
    count: 0,
    load: null,
    error: null,
};
export function deliveryTimesReducer(state = INIT_STATE, action) {
    const { type, payload } = action;
    switch (type) {
        case Types.DELIVERY_TIMES_LOADING:{
            return {
                ...state,
                load: payload
            }
        }
        case Types.GET_DELIVERY_TIMES_SUCCESS:{
            return {
                ...state,
                deliveryTimes: payload?.data?.delivery_times,
                count: payload?.extra?.total_count,

            }
        }
        case  Types.GET_DELIVERY_TIME_DETAILS_SUCCESS:{
            return {
                ...state,
                deliveryTime: payload?.data?.delivery_time
            }
        }
        case Types.ADD_DELIVERY_TIMES_SUCCESS:{
            return {
                ...state
            }
        }
        case Types.UPDATE_DELIVERY_TIME_SUCCESS:{
            return {
                ...state
            }
        }
        case Types.DELETE_DELIVERY_TIME_SUCCESS: {
            return {
                ...state
            }
        }
        default:
            return state
    }
}