import Types from "./Types";

export const GetTagsSuccess = (payload) => ({
  type: Types.GET_TAGS_SUCCESS,
  payload,
});

export const GetTagsLoading = (payload) => ({
  type: Types.GET_TAGS_LOADING,
  payload,
});

export const GetTagDetailsSuccess = (payload) => ({
  type: Types.GET_TAG_DETAILS_SUCCESS,
  payload,
});

export const GetScrollVendorsSuccess = (payload) => ({
  type: Types.GET_SCROLL_TAGS_SUCCESS,
  payload,
});
