import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { LayoutId } from "services/modules/utils/constant";

export const ScrollToTop = () => {
  const { key } = useLocation();
  useEffect(() => {
    document
      .querySelector(`#${LayoutId}`)
      .scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [key]);
  return null;
};
