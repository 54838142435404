import { useEffect, useState } from "react";
import { Box, Stack } from "@mui/material";
import Autocomplete from "react-google-autocomplete";
import { GoogleMap, MarkerF, useJsApiLoader } from "@react-google-maps/api";

import { GoogleMapKey } from "services/modules/utils/constant";
import { icons } from "assets/AssetHelper";

const containerStyle = {
  width: "100%",
  height: "500px",
};

export default function Map(props) {
  const { type, getCurrentLocation, location, details ,zoom,setIsUserSearch} = props;
  // console.log(details);

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  });
  // when a place is searched
  const getSearchLocation = (place) => {
    // setIsUserSearch(true);
    getCurrentLocation(
      place.geometry.location.lat(),
      place.geometry.location.lng()
    );
  };

  // changing the marker location
  const changeLocation = (map) => {
    getCurrentLocation(map.latLng.lat(), map.latLng.lng());
  };

  useEffect(() => {
    if (type === "add") {
      // reset the location to Riyad City (default value)
      getCurrentLocation(24.7136, 46.6753);
    } else {
      // set the location to the city's location
      getCurrentLocation(
        parseFloat(details?.lat || 24.7136),
        parseFloat(details?.long || 46.6753)
      );
    }
  }, [details]);
  return (
    <Stack
      sx={{
        mt: 50,
        borderRadius: "6px",
        boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
        overflow: "hidden",
        position: "relative",
        mt: "30px",
      }}
    >
      <Stack
        sx={{
          width: "100%",
          py: 2,
          backgroundColor: "white",
          justifyContent: "center",
        }}
        direction="row"
      >
        <Stack sx={{ width: "60%", position: "relative" }} direction="row">
          <Box
            component={"img"}
            src={icons.location}
            width="25px"
            sx={{ position: "absolute", top: "16px", left: "10px" }}
          />
          <Autocomplete
            className="places-search"
            apiKey={GoogleMapKey}
            onPlaceSelected={(place) => getSearchLocation(place)}
            placeholder="Search City"
            style={{
              padding: "15px 40px",
              border: "1px solid #DCDCDC",
              borderRadius: "6px",
              fontSize: "18px",
              fontFamily: "Jost",
              width: "100%",
              zIndex: 99,
            }}
          />
        </Stack>
      </Stack>
      {!isLoaded ? (
        <h1>Loading...</h1>
      ) : (
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={location}
          zoom={13}
          onClick={(map) => changeLocation(map)}
        >
          <MarkerF position={{ lat: location?.lat, lng: location?.lng }} />
        </GoogleMap>
      )}
    </Stack>
  );
}
