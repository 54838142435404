import { makeFilterString } from "../../utils/Helper";
import {
  GetPushNotificationDetailsSuccess,
  GetPushNotificationsSuccess,
  PushNotificationsLoading,
} from "./Action";

import { reduxRequest } from "common/utils/reduxRequest";

export const getPushNotificationsRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: PushNotificationsLoading,
    successFunction: GetPushNotificationsSuccess,
    loadingType: "list",
    url: `/admin/notifications/notifications${makeFilterString(variables?.params)}`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const createPushNotificationRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: PushNotificationsLoading,
    loadingType: "create",
    action: variables?.action,
    url: `/admin/notifications/notifications${makeFilterString(variables?.params)}`,
    data: variables?.body,
    method: "post",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
