

import { reduxRequest } from "common/utils/reduxRequest";
import {
    AddVendorSuccess,
    DeleteVendorSuccess, GetScrollVendorsSuccess, GetVendorDetailsSuccess,
    GetVendorsLoading,
    GetVendorsSuccess,
    UpdateVendorSuccess
} from "./Actions";
import {makeFilterString} from "../utils/Helper";


//  `taxons/${variables?.categoryId}/filters`+makeFilterString(variables?.params),
export const GetVendorsRequest = ({ ...variables }) => {
    const requestData = {
        isLoading: GetVendorsLoading,
        loadingType: "list",
        successFunction: GetVendorsSuccess,
        url: `/admin/stores${makeFilterString(variables?.params)}`,
        method: "get",
    };

    return async (dispatch) => {
        reduxRequest({
            dispatch,
            ...requestData,
        });
    };
};

export const GetScrollVendorsRequest = ({ ...variables }) => {
    const requestData = {
        isLoading: GetVendorsLoading,
        loadingType: "filter-vendor-list",
        successFunction: GetScrollVendorsSuccess,
        url: `/admin/stores${makeFilterString(variables?.params)}`,
        method: "get",
    };

    return async (dispatch) => {
        reduxRequest({
            dispatch,
            ...requestData,
        });
    };
};


export const deleteVendorRequest = ({ ...variables }) => {
    console.log(variables,'variables')
    const requestData = {
        isLoading: GetVendorsLoading,
        successFunction: DeleteVendorSuccess,
        loadingType: "delete location",
        action: variables?.action,
        url: `/admin/stores/${variables?.id}`,
        method: "delete",
    };

    return async (dispatch) => {
        reduxRequest({
            dispatch,
            ...requestData,
        });
    };
};



export const addVendorRequest = ({ ...variables }) => {
    const requestData = {
        isLoading: GetVendorsLoading,
        loadingType: "add",
        successFunction: AddVendorSuccess,
        action: variables?.action,
        url: `/admin/stores`,
        method: "post",
        data: variables?.body,
    };

    return async (dispatch) => {
        reduxRequest({
            dispatch,
            ...requestData,
        });
    };
};

export const updateVendorRequest = ({ ...variables }) => {
    
    const requestData = {
        isLoading: GetVendorsLoading,
        loadingType: "update vendor",
        successFunction: UpdateVendorSuccess,
        url: `/admin/stores/${variables?.id}`,
        action: variables?.action,
        data: variables?.body,
        method: "put",
    };

    return async (dispatch) => {
        reduxRequest({
            dispatch,
            ...requestData,
        });
    };
};

export const getVendorDetailsRequest = ({ ...variables }) => {
    const requestData = {
        isLoading: GetVendorsLoading,
        loadingType: "details",
        successFunction: GetVendorDetailsSuccess,
        url: `/admin/stores/${variables?.id}`,
        method: "get",
    };

    return async (dispatch) => {
        reduxRequest({
            dispatch,
            ...requestData,
        });
    };
};




