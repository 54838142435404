import Header from "components/Atoms/SharedComponents/Details/header";
import { Stack, Typography } from "@mui/material";
import { useThemePalette } from "common/hooks/theme_palette";
import { useState } from "react";
import { useRef } from "react";
import CActions from "components/Molecules/Crud/components/actions";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

export default function DriverEmoloyeeDetailsHeader({
  data,
  actionsList,
}) {
  const { lightGreen, red } = useThemePalette();
  const [anchorEl, setAnchorEl] = useState(null);
  const anchorRef = useRef(null);
  const open = Boolean(anchorEl);
  const handleClick = (event, index, data) => {
    anchorEl == index ? setAnchorEl(null) : setAnchorEl(index);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const headerChildren = (
    <Stack direction="row" gap={2}>
      <Stack direction="row">
        <Stack direction="row" gap={1}>
          <Typography sx={{ color: "#9C9C9C" }}>Profile status:</Typography>
          {data?.status == "active" ? (
            <Typography textTransform='capitalize' sx={{ color: lightGreen, fontSize: "18px" }}>
              Active
            </Typography>
          ) : (
            <Typography textTransform='capitalize' sx={{ color: red, fontSize: "18px" }}>
              {data?.status}
            </Typography>
          )}
        </Stack>
      </Stack>
      {data?.status != 'deleted' && <Stack>
        <CActions
          id={data?.id}
          index={1}
          data={data}
          handleClick={handleClick}
          handleClose={handleClose}
          anchorRef={anchorRef}
          open={open}
          anchorEl={anchorEl}
          actionsList={actionsList}
        />
      </Stack>}
    </Stack>
  );
  // data.state
  return (
    <Header
      // title={("Employee profile details")}
      title={(
        <Stack width="75%" direction='row' gap='17px' >
          <Typography
            fontSize="32px"
            fontWeight="500"
            fontFamily="Jost"
            gutterBottom
          >
            Employee profile details
          </Typography>
          <Typography
            fontSize="32px"
            fontWeight="500"
            fontFamily="Jost"
            gutterBottom
            color={data?.state == 'online' ? lightGreen : red}
          >
            {data.state == 'offline' && 'Offline'}
            {data.state == 'online' &&
              <Stack direction='row' alignItems='center' gap='3px' >
                <FiberManualRecordIcon />
                Online
              </Stack>
            }
          </Typography>
        </Stack>)
      }
      children={headerChildren}
      deleteBtn={false}
      editBtn={false}
    />
  );
}
