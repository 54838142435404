import { useThemePalette } from "common/hooks/theme_palette";
import { Stack, Button } from "@mui/material";

export default function FormActions({ handleClose, refsubmit }) {
  const { oliveGreen } = useThemePalette();

  return (
    <Stack
      direction="row"
      justifyContent="center"
      gap={5}
      sx={{ borderTop: "1px solid #E2E2E2", py: 3 }}
    >
      <Button
        sx={{
          fontSize: "18px",
          color: "black",
          border: "1px solid",
          px:6
        }}
        onClick={handleClose}
      >
        cancel
      </Button>
      <Button
        ref={refsubmit}
        type="submit"
        sx={{
          backgroundColor: oliveGreen,
          "&:hover": {
            backgroundColor: oliveGreen,
          },
          color: "white",
          fontSize: "18px",
          px:5
          
        }}
      >
        Save Details
      </Button>
    </Stack>
  );
}
