import { icons } from 'assets/AssetHelper'
import * as dayjs from "dayjs";

export const detailsData = ({ data }) => [
    {
        head: 'Details',
        icon: icons?.detailsIcon,
        details: [
            {
                label: 'Occasion Name (EN)',
                name: data?.localized_data?.en?.name,
            },
            {
                label: 'Occasion Name (AR)',
                name: data?.localized_data?.ar?.name,
            },
        ]
    },
    {
        head: 'Dates',
        icon: icons.datesIcons,
        details: [
            {
                label: 'Created AT',
                name: dayjs(data?.created_at).format("DD-MM-YYYY HH:MM a"),
            },
            {
                label: 'Updated At',
                name: dayjs(data?.updated_at).format("DD-MM-YYYY HH:MM a")
            },
        ]
    },
];