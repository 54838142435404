import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loader from "components/Atoms/SharedComponents/Loader/Loader";
import setAuthToken from "services/modules/utils/handel_api";
import OccastionTypesTemplate from "templates/occasions/occaionsTypes";
import {
  GetOccasionTypesRequest,
  addOccasionTypesRequest,
  deleteOccasionTypesRequest,
  getOccasionTypesDetailsRequest,
  updateOccasionTypesRequest,
} from "services/modules/occasions/occasionsTypes";
import { Box } from "@mui/material";
import { useThemePalette } from "common/hooks/theme_palette";
import { toast } from "react-toastify";

function OccasionsTypes() {
  const token = localStorage.getItem("token");
  setAuthToken(token);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { lightGray } = useThemePalette();
  let [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const type = searchParams.get("type");

  const list = useSelector((state) => state.occasionsTypes.occasionTypes);
  const count = useSelector((state) => state.occasionsTypes.count);
  const details = useSelector((state) => state.occasionsTypes.occasionType);
  const load = useSelector((state) => state.occasionsTypes.load);

  //permissions
  const currentUser = useSelector((state) => state.authenticate.currentUserProfile);
  const permissions = useSelector(
    (state) => state.authenticate.currentUserProfile.permissions.occasion_types
  );
  const isSuperAdmin = currentUser?.privilege == "super_admin";

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [page, setPage] = useState(1);
  const [filterObj, setFilterObj] = useState(null);

  const handlePageChange = (page) => {
    setPage(page);
    setFilterObj((prev) => {
      return { ...prev, page_number: page };
    });
  };

  const getDetails = async () => {
    dispatch(getOccasionTypesDetailsRequest({ occasion_type_id: id }));
  };

  const getList = async (filterObj) => {
    const requestData = {
      params: {
        page_size: 10,
        page_number: 1,
        keyword: "",
        sort_by: "id",
        ...filterObj,
      },
    };
    dispatch(GetOccasionTypesRequest(requestData));
  };
  // mappedData use for create custom cell to concat multi values or navigate button
  const mappedData = list?.map((data, index) => {
    return {
      ...data,
      icon: (
        <Box
          sx={{
            border: `1px solid ${lightGray}`,
            borderRadius: "6px",
            width: "fit-content",
            p: "20px 46px",
          }}
        >
          <img src={data?.icon} alt={data?.icon} width="38px" height="38px" />
        </Box>
      ),
      banner: <img src={data?.banner} alt={data?.banner} width="108px" height="108px" />,
    };
  });

  const rowData = (data) => {
    setSelectedRow(data);
  };
  const breadcrumbs = [
    {
      active: true,
      path: "/",
      title: "Home",
    },
    {
      active: !type ? false : true,
      path: "/occasions-types",
      title: "Occasions/Occasions types",
    },
    type && {
      active: false,
      title: type === "details" ? "Occassion details" : id ? "Edit Occasion" : "New Occasion",
    },
  ];

  const handelSubmit = (data, formik) => {
    console.log(formik?.values);
    const action = () => {
      searchParams.delete("id");
      searchParams.delete("type");
      navigate("/occasions-types");
      !id && formik?.resetForm();

      toast.success(`Occasion has been ${id ? "updated" : "created"} successfully`);
    };
    const requestData = {
      body: { occasion_type: { ...formik?.values } },
      action,
    };
    if (id) {
      dispatch(updateOccasionTypesRequest({ ...requestData, id }));
    } else {
      //create call
      dispatch(addOccasionTypesRequest(requestData));
    }
  };

  const filterList = [];
  // console.log(wrapsList?.map(wrap => { name: wrap?.name, id: wrap?.id }));

  const handleDelete = (id) => {
    // const id = selectedRow?.rowData?.id;
    //action to be called after successfull deletion
    const action = () => {
      //if list.length == 1 & page !== 1 -> setPage(page-1) & fetch
      if ((list.length === 1) & (page !== 1)) {
        setPage(page - 1);
        setFilterObj({
          ...filterObj,
          page_number: page - 1,
        });
        getList({ ...filterObj, page_number: page - 1 }); //temporary until the backend sends the data correctly so I can update the state instead of re-fetching
      } else {
        getList(filterObj);
      }

      toast.success(`Occasion has been deleted successfully`);
    };
    const requestData = {
      id,
      action, //dispatched on success
    };
    dispatch(deleteOccasionTypesRequest(requestData));
    setOpenDeleteModal(false);
  };

  //list of actions to be displayed when clicked in the 3 dots
  const actionsList = ({ index, data }) => {
    return [
      {
        condition: () => {
          return true;
        },
        click: () => {
          navigate(`?type=details&id=${data?.id}`);
        },
        text: "View",
      },
      {
        condition: () => {
          return isSuperAdmin || permissions?.can_update;
        },
        click: () => {
          navigate(`?type=form&id=${data?.id}`);
        },
        text: "Edit",
      },
      {
        condition: () => {
          return isSuperAdmin || permissions?.can_delete;
        },
        click: () => {
          setOpenDeleteModal(true);
        },
        text: "Delete",
      },
    ];
  };

  //if you need more buttons in the header beside the add button
  const headerChildren = <></>;

  //if more modals needed, pass them to the modalComponents as below
  const modalComponents = [];

  //listing all tags
  useEffect(() => {
    if (filterObj) getList(filterObj);
  }, [filterObj]);

  //details of a single tag
  useEffect(() => {
    if (id) getDetails();
  }, [id]);

  return (
    <>
      <Loader open={load?.loading && load?.type == "list"} />
      <OccastionTypesTemplate
        filterObj={filterObj}
        setFilterObj={setFilterObj}
        data={mappedData}
        count={count}
        paginationPage={page}
        handlePageChange={handlePageChange}
        rowData={rowData}
        details={details}
        breadcrumbs={breadcrumbs}
        filterList={filterList}
        handelSubmit={handelSubmit}
        actionsList={actionsList}
        headerChildren={headerChildren}
        openDeleteModal={openDeleteModal}
        setOpenDeleteModal={setOpenDeleteModal}
        handleDeleteRequest={handleDelete}
        modalComponents={modalComponents}
        selectedRow={selectedRow}
        can_create={isSuperAdmin || permissions?.can_create}
      />
    </>
  );
}

export default OccasionsTypes;
