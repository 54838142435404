import { makeFilterString } from "../../utils/Helper";
import { reduxRequest } from "common/utils/reduxRequest";
import { AddWrapExtraSuccess, DeleteWrapExtraSuccess, GetScrollWrapExtraSuccess, GetWrapExtraDetailsSuccess, GetWrapExtrasLoading, GetWrapExtrasSuccess, UpdateWrapExtraSuccess } from "./Action";


//  `taxons/${variables?.categoryId}/filters`+makeFilterString(variables?.params),
export const GetWrapExtrasRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: GetWrapExtrasLoading,
    loadingType: "list",
    successFunction: GetWrapExtrasSuccess,
    url: `/admin/wrappers/wrap_extras${makeFilterString(variables?.params)}`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const getScrollWrapExtraRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: GetWrapExtrasLoading,
    loadingType: "wrap-extra-scroll-list",
    successFunction: GetScrollWrapExtraSuccess,
    url: `/admin/wrappers/wrap_extras${makeFilterString(variables?.params)}`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
export const deleteWrapExtraRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: GetWrapExtrasLoading,
    successFunction: DeleteWrapExtraSuccess,
    loadingType: "delete wrap",
    action: variables?.action,
    url: `/admin/wrappers/wrap_extras/${variables?.id}`,
    method: "delete",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};


export const addWrapExtraRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: GetWrapExtrasLoading,
    loadingType: "add",
    successFunction: AddWrapExtraSuccess,
    action: variables?.action,
    url: `/admin/wrappers/wrap_extras`,
    method: "post",
    data: variables?.body,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};




export const updateWrapExtraRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: GetWrapExtrasLoading,
    loadingType: "update shape",
    successFunction: UpdateWrapExtraSuccess,
    // failFunction:AddTagFailed,
    url: `/admin/wrappers/wrap_extras/${variables?.id}`,
    action: variables?.action,
    data: variables?.body,
    method: "put",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};



export const getWrapExtraDetailsRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: GetWrapExtrasLoading,
    loadingType: "details",
    successFunction: GetWrapExtraDetailsSuccess,
    url: `/admin/wrappers/wrap_extras/${variables?.wrap_extra_id}`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};




