import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import Header from "components/Atoms/SharedComponents/Details/header";
import { Avatar, Box, Button, Stack, Tab, Tabs } from "@mui/material";
import { useThemePalette } from "common/hooks/theme_palette";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import CheckIcon from "@mui/icons-material/Check";
import Breadcrumb from "components/Atoms/SharedComponents/Breadcrumbs/Breadcrumb";
import CustomTabPanel from "components/Molecules/Tabs/customTabPanel";
import ProductsSelection from "components/Organisms/orders/returnRequest/productsSelection";
import QuantityAndReason from "components/Organisms/orders/returnRequest/quantityAndReasons";
import { createReturnOrderRequest } from "services/modules/orders/return-order";
import { useFormik } from "formik";
import * as Yup from "yup";
import ContainedButton from "components/Atoms/SharedComponents/Buttons/ContainedButton";

const CreateReturnRequestTemplate = ({
  list,
  breadcrumbs,
  id,
  load,
  details,
  requestId,
}) => {
  const { orangeTwo, oliveGreen } = useThemePalette();
  const navigate = useNavigate();
  const [value, setValue] = useState(0);
  const [isCreateRequest, setIsCreateRequest] = useState(false);
  const [checkedDetails, setCheckedDetails] = useState([]);
  const dispatch = useDispatch();
  const refsubmit = useRef();

  const [formData, setFormData] = useState(
    list?.map(() => ({ quantity: "", reason: "" }))
  );

  const handleChange = (index, field, value) => {
    setFormData((prevData) => {
      const newData = [...prevData];
      newData[index] = { ...newData[index], [field]: value };
      return newData;
    });
    // Update Formik values as well
    formik.setFieldValue(`${field}_${index}`, value);
  };
  const initialValues = formData?.reduce((acc, _, index) => {
    acc[`quantity_${index}`] = "";
    acc[`reason_${index}`] = "";
    return acc;
  }, {});

  const validationSchema = Yup.object().shape(
    checkedDetails &&
      checkedDetails?.reduce((acc, _, index) => {
        acc[`quantity_${index}`] = Yup.number()
          .required("Quantity is required")
          .positive("Quantity must be a positive number")
          .integer("Quantity must be an integer")
          .max(
            checkedDetails[index]?.quantity,
            `Quantity must be equal or less than ${checkedDetails[index]?.quantity}`
          )
          .moreThan(0, "Quantity must be greater than 0");

        acc[`reason_${index}`] = Yup.string();
        return acc;
      }, {})
  );

  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      handleSubmit(values);
    },
    validationSchema,
  });
  const handleSubmit = (data, formik) => {
    // console.log("Submitted data from child:", data);
    const action = (responseData) => {
      navigate(
        `/return-request-details?id=${id}&request_id=${responseData?.data?.return_request?.id}`
      );
      !id && formik?.resetForm();
      // USE TOSTIFY TO CONFIRM ACTION
      toast.success(
        `Return Request has been ${id ? "updated" : "created"} successfully`
      );
    };
    const returnRequestItems = checkedDetails?.map((detail, index) => ({
      quantity: +data[`quantity_${index}`],
      reason: data[`reason_${index}`],
      returnable_id: detail?.id, // Assuming there's an 'id' property in your detail object
      returnable_type: "CartItem",
    }));

    const requestBody = {
      return_request: {
        order_id: +id,
        return_request_items_attributes: returnRequestItems,
      },
    };
    const requestData = {
      body: requestBody,
      action,
    };
    dispatch(createReturnOrderRequest(requestData));
  };

  useEffect(() => {
    if (Object.keys(formik?.errors).length !== 0 && formik?.isSubmitting) {
      const msg = Object.keys(formik?.errors).map(
        (err) => `${err.split("_").join(" ")}  is required`
      );
      toast.error(msg?.join(", "));
    }
  }, [formik?.errors, formik?.isSubmitting]);

  // Function to handle the checked details received from the child component
  const handleCheckboxChange = (checkedDetails) => {
    console.log(checkedDetails, "checkedDetails");
    setCheckedDetails(checkedDetails);
  };

  const handleRequestChange = (event, newValue) => {
    setValue(value + 1);
    if (value === 0) {
      setIsCreateRequest(true);
    }
  };

  useEffect(() => {
    if (requestId && details) {
      handleCheckboxChange(list[0]);
    }
  }, [details, requestId]);

  // console.log(details,requestId,'haga');
  // console.log(checkedDetails, "hagar");

  return (
    <Stack gap="20px" width={"100%"} height={"100%"}>
      <Stack
        direction="row"
        alignSelf="start"
        justifyContent="start"
        gap={2}
        width="100%"
        mb={1}
      >
        <Breadcrumb data={breadcrumbs} />
      </Stack>
      <Header title={`Create New Request`} deleteBtn={false} editBtn={false}>
        <Button
          //  onClick={(_) => setOpenCancelOrderModal(true)}
          onClick={() => navigate(`/return-request?id=${id}`)}
          variant="outlined"
          sx={{
            py: 1,
            px: 5,
            textTransform: "none",
            textAlign: "center",
            borderColor: orangeTwo,
            color: orangeTwo,
            "&:hover": {
              backgroundColor: "transparent",
              borderColor: orangeTwo,
            },
          }}
        >
          Cancel Request
        </Button>
        <ContainedButton
          ref={refsubmit}
          onClick={isCreateRequest ? formik?.handleSubmit : handleRequestChange}
          title={isCreateRequest ? "Create Request" : "Continue"}
          width="fit-content"
          load={
            isCreateRequest &&
            load?.loading &&
            load?.type == "createReturnRequest"
          }
        >
          {isCreateRequest ? "Create Request" : "Continue"}
        </ContainedButton>
      </Header>

      <Stack direction="row">
        <Box
          sx={{
            width: "100%",
            ".MuiTab-root": {
              color: value > 0 ? "black !important" : "#BDBDBD",
              textTransform: "capitalize",
              fontSize: "24px",
              px: 5,
              py: 5,
              fontWeight: "400",
              backgroundColor: value > 0 ? "white" : "#FCFCFC",
            },
            ".Mui-selected": {
              backgroundColor: "white",
              textDecoration: "capitalize",
              color: "black !important",
            },
          }}
        >
          <Tabs
            variant="fullWidth"
            TabIndicatorProps={{
              style: { backgroundColor: oliveGreen, height: "8px" },
            }}
            value={value}
            aria-label="icon position tabs example"
          >
            <Tab
              iconPosition="start"
              label="Select Products"
              disabled
              icon={
                <Avatar sx={{ backgroundColor: oliveGreen }}>
                  {value === 0 ? "1" : <CheckIcon />}
                </Avatar>
              }
            />
            <Tab
              // sx={{ width: "900px" }}
              iconPosition="start"
              label="Add quantity and reasons"
              disabled
              icon={
                value === 0 ? (
                  <Avatar
                    sx={{
                      backgroundColor: "#F3F3F3",
                      color: "#9A9A9A",
                      width: 24,
                      height: 24,
                    }}
                  >
                    2
                  </Avatar>
                ) : (
                  value === 1 && (
                    <Avatar sx={{ backgroundColor: oliveGreen }}>2</Avatar>
                  )
                )
              }
            />
          </Tabs>
        </Box>
      </Stack>
      <CustomTabPanel value={value} index={0}>
        <ProductsSelection
          list={list}
          load={load}
          onCheckboxChange={handleCheckboxChange}
        ></ProductsSelection>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <QuantityAndReason
          details={checkedDetails}
          load={load}
          formData={formData}
          handleChange={handleChange}
          formik={formik}
          // onFormSubmit={handleFormSubmit}
          // onSubmit={handelSubmit}
        />
      </CustomTabPanel>
    </Stack>
  );
};

export default CreateReturnRequestTemplate;
