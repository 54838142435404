import { Box, Button, IconButton, InputBase, Stack, Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useEffect, useState } from "react";
import Search from "components/Atoms/Input/Search/Search";
import { useThemePalette } from "common/hooks/theme_palette";

const Header = ({
  Title,
  isSearch,
  setFilterObj,
  createBtnTitle,
  createBtnFun,
  CreateBtnIcon,
  handlePageChange,
  searchInputPlaceholder,
  children,
}) => {
  const [value, setValue] = useState(null);
  const { oliveGreen } = useThemePalette();
  useEffect(() => {
    const timeId =
      value !== null &&
      setTimeout(() => {
        setFilterObj((data) => {
          return {
            ...data,
            keyword: value,
          };
        });
        handlePageChange(1);
      }, 500);
    return () => clearTimeout(timeId);
  }, [value]);

  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ mt: 2 }}>
      <Stack width="50%">
        <Typography fontSize="32px" fontWeight="500" fontFamily="Jost">
          {Title}
        </Typography>
      </Stack>

      <Stack direction="row" width="50%" gap={2} alignSelf="end" justifyContent="end">
        <>
          {isSearch && (
            <Search
              placeholder={searchInputPlaceholder}
              onChange={(e) => {
                setValue(e.target.value?.trim());
              }}
              setFilterObj={setFilterObj}
            />
          )}
        </>
        {children}
        {createBtnTitle && (
          <Button
            onClick={createBtnFun}
            variant="contained"
            sx={{
              px: 10,
              py: 1,
              textTransform: "none",
              display: "flex",
              position: "relative",
              justifyContent: "space-between",
              backgroundColor: oliveGreen,
              "&:hover": {
                backgroundColor: oliveGreen,
              },
            }}
          >
            {CreateBtnIcon && <CreateBtnIcon sx={{ position: "absolute", left: "20px" }} />}

            <Typography
              sx={{
                fontSize: "18px",
                fontFamily: "Jost",
                fontWeight: "400",
              }}
            >
              {createBtnTitle}
            </Typography>
          </Button>
        )}
      </Stack>
    </Stack>
  );
};

export default Header;

// <Stack flexDirection="row" bgcolor="white" borderRadius="10px">
// <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
//   <SearchIcon />
// </IconButton>
// <InputBase
//   sx={{ ml: 1, width: "100%" }}
//   placeholder={searchInputPlaceholder}
//   type={"text"}
//   size="small"
// onChange={(e) => {
//   setValue(e.target.value);
// }}
// />
// </Stack>
