import { Button, Stack, TextField, Typography } from '@mui/material'
import { useThemePalette } from 'common/hooks/theme_palette'
import React from 'react'
import ModelBox from 'components/Atoms/Model/Model';

export default function RejectionModel({
    rejectionReason,
    setRejectionReason,
    handleRejectionSubmit,
    setOpenModel,
    openModel,
    userType
}) {
    const { oliveGreen } = useThemePalette()

    return (
        <Stack flexDirection={'row'}>
            <ModelBox
                titleStyle={{ fontSize: '32px', textAlign: 'center', fontWeight: 500, width: '100%', color: openModel?.action === 'reject' && 'red' }}
                title={openModel?.action === 'viewReason' ? 'Reject Reason' : openModel?.action === 'editReason' ? 'Edit Rejection Reason' : `Reject ${userType}`}
                openVar={openModel}
                closeFunc={() => {
                    setRejectionReason('')
                    setOpenModel({ state: false, action: '', customerId: false })
                }}
            >
                <Stack gap={3}>
                    {openModel?.action === 'reject' && <Typography fontWeight={500} fontSize={20}>Write Rejection Reason</Typography>}
                    <TextField
                        // InputProps={{ readOnly: openModel?.action === 'viewReason' && true, }}
                        multiline
                        rows={9}
                        value={rejectionReason}
                        className='rejection-input'
                        onChange={e => setRejectionReason(e.target.value)}  >
                    </TextField>

                    <Stack flexDirection={'row'} gap={3} justifyContent={'center'}>
                        <Button variant={'outlined'}
                            sx={{ width: '5%', color: 'black', borderColor: 'black', '&:hover': { color: 'black', borderColor: 'black' } }}
                            onClick={() => {
                                // setOpenRejectionReason(false)
                                setOpenModel({ state: false, action: '', customerId: false })

                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant={'contained'}
                            sx={{ width: '5%', background: oliveGreen, "&:hover": { background: oliveGreen } }}
                            onClick={handleRejectionSubmit}>
                            {openModel?.action === 'viewReason' ? 'Edit' : openModel?.action == 'editReason' ? 'update' : 'Submit'}
                        </Button>
                    </Stack>
                </Stack>
            </ModelBox>
        </Stack>
    )
}
