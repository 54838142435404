import { Stack, Typography } from "@mui/material";

export default function ApprovalStatus({ data }) {
  const approvalStatus = data?.approval_status;

  const orderStatus = {
    available: "Approved",
    unverified: "Pending",
    deleted: "deleted",
    rejected: "Rejected",
  };

  const orderStatusColor = {
    green: "#E1F5E8",
    red: "#FAE3E3",
    yellow: "#F7FFC3",
    lightGreen: "#29B95F",
    lightRed: "#D20000",
    lightYellow: "#FFCC00",
  };

  return (
    <Stack
      sx={{
        p: 0.2,
        // px: 3,
        width: "100px",
        color: approvalStatus === "unverified" && orderStatusColor.lightYellow,
        backgroundColor:
          approvalStatus === "available"
            ? orderStatusColor.green
            : approvalStatus === "unverified"
            ? orderStatusColor.yellow
            : orderStatusColor.red,
      }}
      justifyContent="center"
      direction="row"
    >
      <Typography
        sx={{
          color:
            approvalStatus === "available"
              ? orderStatusColor.lightGreen
              : approvalStatus === "unverified"
              ? orderStatusColor.lighYellow
              : orderStatusColor.lightRed,
        }}
      >
        {orderStatus[approvalStatus]}
      </Typography>
    </Stack>
  );
}
