import Types from "./Types";

const INIT_STATE = {
    productsOptionValues:[],
    productOptionValue:{},
    count: 0,
    load: null,
    error: null,
};
export function productOptionValuesReducer(state = INIT_STATE, action) {
    const { type, payload } = action;
    switch (type) {
        case Types.GET_PRODUCTS_OPTION_VALUES_SUCCESS:{
            return {
                ...state,
                productsOptionValues: payload?.data?.option_values,
                count: payload?.extra?.total_count,

            }
        }
        case  Types.GET_PRODUCTS_OPTION_VALUE_DETAILS_SUCCESS:{
            return {
                ...state,
                productOptionValue: payload?.data?.option_value
            }
        }
        case Types.SET_PRODUCT_OPTION_VALUE_SUCCESS:{
            return {
                ...state
            }
        }
        case Types.UPDATE_PRODUCTS_OPTION_VALUE_SUCCESS:{
            return {
                ...state
            }
        }
        case Types.DELETE_PRODUCTS_OPTION_VALUE_SUCCESS: {
            return {
                ...state
            }
        }
        default:
            return state
    }
}