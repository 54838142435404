import { icons, images } from "assets/AssetHelper";
import dayjs from "dayjs";

export const detailsData = ({ data }) => [
  {
    head: "Detasils",
    icon: icons?.details,
    details: [
      {
        label: "Data",
        name: data?.data,
      },
      {
        label: "Data Id",
        name: data?.data_id,
      },
    ],
  },
 
  {
    head: "Dates",
    icon: icons?.dates,
    details: [
      {
        label: "Created At",
        name: dayjs(data?.created_at).format("DD-MM-YYYY HH:MM a"),
      },
      {
        label: "Updated At",
        name: dayjs(data?.updated_at).format("DD-MM-YYYY HH:MM a"),
      },
    ],
  },
];
