import { useState } from "react";
import Details from "components/Organisms/vendor/details";
import { handleTabChange } from "services/modules/utils/Helper";
import VendorDetailsTemplate from "../../templates/vendor/vendorListing/vendorDetails";
import VendorProductsPage from "./vendorProducts";

export default function VendorDetailsPage({ details, setOpenDeleteModal, actionsList, approvalStatusOptions }) {
  const [searchParams, setSearchParams] = useState(new URLSearchParams(window.location.search));
  const selectedTab = searchParams.get("tab");
  const [value, setValue] = useState(+selectedTab || 0);

  const tabs = [
    { title: "Details", children: <Details details={details} /> },
    {
      title: "Products",
      children: <VendorProductsPage list={details?.variants} />,
    },
  ];

  return (
    <VendorDetailsTemplate
      details={details}
      setOpenDeleteModal={setOpenDeleteModal}
      selectedTab={value}
      tabs={tabs}
      handleTabChange={(event, newValue) => handleTabChange({ event, newValue, setValue, searchParams, setSearchParams })}
      actionsList={actionsList}
      approvalStatusOptions={approvalStatusOptions}
    />
  );
}
