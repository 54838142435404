import Types from "./Types";

export const getReturnRequestItemsSuccess = (payload) => ({
  type: Types.GET_RETURN_REQUEST_ITEMS_REQUEST,
  payload,
});

export const GetReturnOrderLoading = (payload) => ({
  type: Types.RETURN_ORDER_LOADING,
  payload,
});

export const GetReturnOrderSuccess = (payload) => ({
  type: Types.GET_RETURN_ORDER_SUCCESS,
  payload,
});

export const GetItemsOrderSuccess = (payload) => ({
  type: Types.GET_ITEMS_ORDER_SUCCESS,
  payload,
});


export const GetItemsOrderLoading = (payload) => ({
  type: Types.ITEMS_ORDER_LOADING,
  payload,
});

export const GetReturnRequest = (payload) => ({
  type: Types.GET_RETURN_REQUEST_SUCCES,
  payload,
});

export const GetEmployeesSuccess = (payload) => ({
  type: Types.GET_EMPLOPYEES_SUCCESS,
  payload,
});

export const AssignToEmployeeSuccess = (payload) => ({
  type: Types.ASSIGN_TO_EMPLOYEE_SUCCESS,
  payload,
});

export const SetCreateReturnRequest = (payload) => ({
  type: Types.SET_CREATE_RETURN_SUCCESS,
  payload,
});

export const updateReturnRequestItemSuccess = (payload) => ({
  type: Types.UPDATE_RETURN_REQUEST_ITEM_SUCCESS,
  payload,
});


export const deleteReturnRequestSuccess = (payload) => ({
  type: Types.DELETE_RETURN_REQUEST_SUCCESS,
  payload,
});


export const UnAssignReturnRequestDriverSuccess = (payload) => ({
  type: Types.UNASSIGN_RETURN_REQUEST_DRIVER_SUCCESS,
  payload,
});


export const SetOrederReadyForDeliverySuccess = (payload) => ({
  type: Types.SET_ORDER_READY_FOR_DELIVERY,
  payload,
});






