/* eslint-disable react/jsx-no-target-blank */
import { icons } from "assets/AssetHelper";
import dayjs from 'dayjs';
import { isOpenPreviewChatImg } from "services/modules/modal/Actions";
import { useDispatch } from "react-redux";
import TextMessage from "components/Atoms/chat/textMessage";
import DocumentMessage from "components/Atoms/chat/documentMessage";
import ImageMessage from "components/Atoms/chat/imageMessage";
import { Stack, Typography } from "@mui/material";

const MessageItem = ({ type, image, message, time, files }) => {
    const chatImage = localStorage.getItem("chatProfileImg");
    const dispatch = useDispatch()

    return (
        <Stack
            sx={type == 'sent' ?
                { display: "flex", alignItems: 'flex-end', justifyContent: 'end', mb: '20px', width: '100%' }
                :
                { display: "flex", alignItems: 'flex-start', justifyContent: 'flex-start', mb: '20px', width: 'fit-content' }}
        >
            <Stack
                direction='row'
                alignItems={'center'}
                sx={type == 'sent' ?
                    { display: "flex", alignItems: 'flex-end', justifyContent: 'end', width: '100%' }
                    :
                    { display: "flex", alignItems: 'flex-start', justifyContent: 'flex-start', width: 'fit-content' }}
            >
                {type != 'sent' && <img
                    width="35"
                    alt='profile img'
                    height="35"
                    style={{ margin: "0 15px", borderRadius: '50%', border: "1px solid var(--secondary-color)" }}
                    src={chatImage ? chatImage : icons.profilePlaceholder}
                />}
                <Stack
                    className={(type == 'sent' && (files && typeof files === 'string' && files?.includes("pdf"))) ? "sent pdf" : type == 'sent' ? 'sent' : "received"}
                >
                    {message && (
                        <TextMessage message={message} />
                    )}
                    {files && typeof files === 'string' && files?.includes("pdf") ?
                        <DocumentMessage files={files} />
                        :
                        files && <ImageMessage
                            img={files}
                            onclick={_ => dispatch(isOpenPreviewChatImg(files))}
                        />
                    }
                </Stack>
            </Stack>
            <Typography
                style={{
                    width: "95%",
                    textAlign: "end",
                    display: "block",
                    fontSize: "12px",
                    color: "rgba(0, 0, 0, 0.5)",
                    marginTop: "5px",
                    overflowWrap: 'break-word'
                }}
                component="h6"
            >
                {dayjs(new Date(time).toLocaleString()).format("hh:mm A")}
            </Typography>
        </Stack >
    )
};

export default MessageItem;