import { Stack, Box, Typography, Divider } from "@mui/material";
import { icons } from "assets/AssetHelper";

export default function PaymentDetails({ details }) {
  const currency = details?.cart?.currency?.name;
  const deliverFees = details?.cart?.delivery_fees;
  const itemsNum = details?.cart?.cart_items?.length;
  const giftCardPrice = details?.cart?.gift_card?.card?.price_after_discount;
  const vat = details?.cart?.vat_amount;
  const vatPercentage = details?.cart?.vat;
  const amountWithVat = details?.cart?.amount_with_vat;
  const total =
    amountWithVat - ((+giftCardPrice || 0) + +(vat || 0) + +(deliverFees || 0));
  const totalAfterVat = details?.cart?.amount_with_vat;
  const paymentInfo = {
    [`Subtotal ( ${itemsNum} items )`]: total,
    "Gift Card": giftCardPrice,
    [`VAT( ${vatPercentage} % )`]: vat,
    "Delivery fees": deliverFees,
    Total: totalAfterVat,
  };

  const cardStyle = {
    width: "47%",
    backgroundColor: "white",
    p: 3,
    gap: 3,
    borderRadius: "8px",
  };

  return (
    <Stack sx={cardStyle}>
      <Stack direction="row" justifyContent="space-between">
        {/* ----------- CARD HEADER ----------- */}
        <Stack direction="row" alignItems="center" gap={2}>
          <Box component="img" width="46px" src={icons.fees} />
          <Typography fontWeight="500" fontSize="24px">
            Payment Details
          </Typography>
        </Stack>
        <Stack direction="row" alignItems="center">
          <Typography
            sx={{
              px: 2,
              py: 1,
              fontSize: "18px",
              backgroundColor: "#F2F2F2",
              borderRadius: "6px",
              fontWeight: "500",
            }}
          >
            {details?.payment_method?.name}
          </Typography>
        </Stack>
      </Stack>
      <Stack gap={1}>
        {Object.keys(paymentInfo).map((key, i) => (
          <>
            <Stack key={i} direction="row" justifyContent="space-between">
              <Typography sx={{ width: "25%", color: "#969696" }}>
                {key}
              </Typography>
              <Typography sx={{ width: "fit-content" }}>
                {paymentInfo[key]} {paymentInfo[key] ? currency : "-"}
              </Typography>
            </Stack>
            {i == Object.keys(paymentInfo).length - 2 && <Divider />}
          </>
        ))}
      </Stack>
    </Stack>
  );
}
