import {makeFilterString} from "../../utils/Helper";

import {reduxRequest} from "../../../../common/utils/reduxRequest";
import {AddDeliveryTimes, DeliveryTimesLoading, GetDeliveryTimes, GetDeliveryTimesDetails} from "./Action";

export const getDeliveryTimesRequest = ({ ...variables }) => {
    const requestData = {
        isLoading: DeliveryTimesLoading,
        loadingType: "list",
        successFunction: GetDeliveryTimes,
        url: `/admin/lookups/delivery_times?${makeFilterString(
            variables?.params
        )}`,
        method: "get",
    };
    return async (dispatch) => {
        reduxRequest({
            dispatch,
            ...requestData,
        });
    };
}
export const createDeliveryTimesRequest = ({ ...variables }) => {
    const requestData = {
        isLoading:DeliveryTimesLoading ,
        loadingType: "add",
        successFunction: AddDeliveryTimes,
        action: variables?.action,
        url: `/admin/lookups/delivery_times`,
        method: "post",
        data: variables?.body,
    };

    return async (dispatch) => {
        reduxRequest({
            dispatch,
            ...requestData,
        });
    };
};
export const getDeliveryTimesDetailsRequest = ({ ...variables }) => {
    const requestData = {
        isLoading: DeliveryTimesLoading,
        loadingType: "details",
        successFunction: GetDeliveryTimesDetails,
        url: `/admin/lookups/delivery_times/${variables?.id}`,
        method: "get",
    };

    return async (dispatch) => {
        reduxRequest({
            dispatch,
            ...requestData,
        });
    };
};
export const deleteDeliveryTimesRequest = ({ ...variables }) => {
    const requestData = {
        isLoading: DeliveryTimesLoading,
        loadingType: "delete option type",
        action: variables?.action,
        url: `/admin/lookups/delivery_times/${variables?.id}`,
        method: "delete",
    };

    return async (dispatch) => {
        reduxRequest({
            dispatch,
            ...requestData,
        });
    };
};
export const updateDeliveryTimesRequest = ({ ...variables }) => {
    const requestData = {
        isLoading: DeliveryTimesLoading,
        loadingType: "update option type",
        url: `/admin/lookups/delivery_times/${variables?.id}`,
        action: variables?.action,
        data: variables?.body,
        method: "put",
    };

    return async (dispatch) => {
        reduxRequest({
            dispatch,
            ...requestData,
        });
    };
};