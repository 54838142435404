import { Stack, Box, Typography } from "@mui/material";

export default function Thumbnail({ src }) {
  return (
    <Stack
      sx={{
        border: "1px dashed gray",
        borderRadius: "6px",
        width: "210px",
        height: "170px",
        overflow: "hidden",
      }}
    >
      <Stack sx={{ backgroundColor: "#F25500", p: 0.5 }} direction="row" justifyContent="center">
        <Typography sx={{ width: "fit-content", fontSize: "20px", color: "#fff" }}>
          Thumb-nail
        </Typography>
      </Stack>
      <Stack sx={{ m: 1, height: "90%" }}>
        <Box component="img" src={src} width="100%" height={"78%"} />
      </Stack>
    </Stack>
  );
}
