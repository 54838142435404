import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loader from "components/Atoms/SharedComponents/Loader/Loader";
import { toast } from "react-toastify";
import setAuthToken from "services/modules/utils/handel_api";
import { Stack, Typography } from "@mui/material";
import SuspendModel from "components/Molecules/users/model/suspendModel";
import ActiveModel from "components/Molecules/users/model/activeModel";
import DeleteModel from "components/Molecules/users/model/deleteModel";
import EmployeeTemplate from "templates/users/drivers/employee";
import {
  addDriverRequest,
  getDriverDetailsRequest,
  getDriversRequest,
  updateDriverRequest,
} from "services/modules/users/driver";
import {
  getScrollCountiesRequest,
} from "services/modules/lookups/countries";
import { useThemePalette } from "common/hooks/theme_palette";
import dayjs from "dayjs";
import { getScrollCitiesRequest } from "services/modules/cities/cities";

function EmployeePage() {
  const token = localStorage.getItem("token");
  setAuthToken(token);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { backgroundRed, backgroundGreen, orangeTwo, green } =
    useThemePalette();

  let [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const username = searchParams.get("username");
  const type = searchParams.get("type");

  const list = useSelector((state) => state.drivers.drivers);
  const cities = useSelector((state) => state.cities.cities);
  const countries = useSelector((state) => state.countries.countries);
  const count = useSelector((state) => state.drivers.count);
  const details = useSelector((state) => state.drivers.driver);
  const load = useSelector((state) => state.drivers.load);
  const filterCitites = useSelector((state) => state.cities.filterCitites);
  const cititesCount = useSelector((state) => state.cities.listCount);
  const cititesLoading = useSelector((state) => state.cities.load);
  const filterCountries = useSelector(
    (state) => state.countries.filterCountries
  );
  const countrieslaod = useSelector((state) => state.countries.load);
  const countriesCount = useSelector((state) => state.countries.listCount);

  //permissions
  const currentUser = useSelector(
    (state) => state.authenticate.currentUserProfile
  );
  const permissions = useSelector(
    (state) => state.authenticate.currentUserProfile.permissions.drivers
  );
  const isSuperAdmin = currentUser?.privilege == "super_admin";

  const [openActionsModal, setOpenActionsModal] = useState({
    state: false,
    action: false,
    customerId: 0,
  });
  const [selectedRow, setSelectedRow] = useState({});
  const [page, setPage] = useState(1);
  const [filterObj, setFilterObj] = useState(null);

  const handlePageChange = (page) => {
    setPage(page);
    setFilterObj((prev) => {
      return { ...prev, page_number: page };
    });
  };

  const getDetails = async () => {
    const requestData = {
      id: id,
    };
    dispatch(getDriverDetailsRequest({ ...requestData }));
  };


  const getList = async (filterObj) => {
    const requestData = {
      params: {
        page_size: 10,
        page_number: 1,
        keyword: "",
        sort_by: "id",
        driver_type: "employee",
        ...filterObj,
      },
    };
    dispatch(getDriversRequest(requestData));
  };

  const mappedData = list?.map((data, index) => {
    const isActive = data?.status == "active";
    const isOnline = data?.state == "online";
    return {
      ...data,
      is_verified: data?.is_verified ? "yes" : "no",
      profile_status: (
        <Stack
          sx={{
            p: 0.8,
            borderRadius: "4px",
            backgroundColor: isActive ? backgroundGreen : backgroundRed,
            width: "fit-content",
          }}
          justifyContent="center"
          alignItems="center"
        >
          <Typography
            sx={{
              color: isActive ? green : orangeTwo,
            }}
          >
            {data?.status == "active" ? "Approved" : data?.status}
          </Typography>
        </Stack>
      ),
      state: (
        <Stack
          sx={{
            p: 0.8,
            borderRadius: "4px",
            backgroundColor: isOnline ? backgroundGreen : backgroundRed,
            width: "fit-content",
          }}
          justifyContent="center"
          alignItems="center"
        >
          <Typography
            sx={{
              color: isOnline ? green : orangeTwo,
            }}
          >
            {data?.state}
          </Typography>
        </Stack>
      ),
      phone_number: `+${data?.country_code}${data?.phone_number}`,
    };
  });

  const rowData = (data) => {
    setSelectedRow(data);
  };

  const breadcrumbs = [
    {
      active: true,
      path: "/",
      title: "Home",
    },
    {
      active: !type ? false : true,
      path: "/employee-drivers",
      title: "Users/Employee drivers",
    },
    type && {
      active: false,
      title:
        type === "details"
          ? "Employee driver profile"
          : id
          ? "Edit employee driver"
          : "New employee driver",
    },
  ];

  const getUpdateData = (data, action) => {
    const update_national_id_media_attributes = [
      {
        url: data?.national_id_front,
        media_type: "photo",
        id: details?.national_id_media[0]?.id,
      },
      {
        url: data?.national_id_back,
        media_type: "photo",
        id: details?.national_id_media[1]?.id,
      },
    ];
    const update_criminal_status_media_attributes = [
      {
        url: data?.criminal_status_front,
        media_type: "photo",
        id: details?.criminal_status_media[0]?.id,
      },
      {
        url: data?.criminal_status_back,
        media_type: "photo",
        id: details?.criminal_status_media[1]?.id,
      },
    ];
    const update_driver_licence_media_attributes = [
      {
        url: data?.driver_licence_front,
        media_type: "photo",
        id: details?.driver_licence_media[0]?.id,
      },
      {
        url: data?.driver_licence_back,
        media_type: "photo",
        id: details?.driver_licence_media[1]?.id,
      },
    ];
    const update_vehicle_licence_media_attributes = [
      {
        url: data?.vehicle_licence_front,
        media_type: "photo",
        id: details?.vehicle_licence_media[0]?.id,
      },
      {
        url: data?.vehicle_licence_back,
        media_type: "photo",
        id: details?.vehicle_licence_media[1]?.id,
      },
    ];

    return {
      body: {
        driver: {
          // ...formik?.values,
          avatar: data.avatar,
          name: data.name,
          national_id: data.national_id,
          email: data.email,
          phone_number: data.phone_number,
          country_id: data.country_id,
          country_code: "+966",
          city_id: data.city_id,
          password: data.password && data.password,
          gender: data.gender == 0 ? "male" : "female",
          birthdate: dayjs(data?.birthdate).format("D-M-YYYY"),
          password_confirmation:
            data?.password_confirmation && data?.password_confirmation,
          national_id_media_attributes: update_national_id_media_attributes,
          criminal_status_media_attributes:
            update_criminal_status_media_attributes,
          driver_licence_media_attributes:
            update_driver_licence_media_attributes,
          vehicle_licence_media_attributes:
            update_vehicle_licence_media_attributes,
          profile_status: data?.profile_status,
        },
      },
      action,
    };
  };

  const getAddData = (data, action) => {
    const national_id_media_attributes = [
      {
        url: data?.national_id_front,
        media_type: "photo",
      },
      {
        url: data?.national_id_back,
        media_type: "photo",
      },
    ];
    const criminal_status_media_attributes = [
      {
        url: data?.criminal_status_front,
        media_type: "photo",
      },
      {
        url: data?.criminal_status_back,
        media_type: "photo",
      },
    ];
    const driver_licence_media_attributes = [
      {
        url: data?.driver_licence_front,
        media_type: "photo",
      },
      {
        url: data?.driver_licence_back,
        media_type: "photo",
      },
    ];
    const vehicle_licence_media_attributes = [
      {
        url: data?.vehicle_licence_front,
        media_type: "photo",
      },
      {
        url: data?.vehicle_licence_back,
        media_type: "photo",
      },
    ];

    return {
      body: {
        driver: {
          // ...formik?.values,
          avatar: data.avatar,
          name: data.name,
          national_id: data.national_id,
          email: data.email,
          phone_number: data.phone_number,
          country_id: data.country_id,
          country_code: "+966",
          city_id: data.city_id,
          password: data.password,
          gender: data.gender == 1 ? "male" : "female",
          birthdate: dayjs(data?.birthdate).format("D-M-YYYY"),
          password_confirmation: data.password_confirmation,
          national_id_media_attributes,
          criminal_status_media_attributes,
          driver_licence_media_attributes,
          vehicle_licence_media_attributes,
          profile_status: "approved",
        },
      },
      action,
    };
  };

  const handelSubmit = (data, formik) => {
    const action = () => {
      searchParams.delete("id");
      searchParams.delete("type");
      !id && formik?.resetForm();
      toast.success(
        `Employee driver ${id ? "updated" : "added"}  successfully`
      );
      navigate("/employee-drivers");
    };
    if (id) {
      //update call
      dispatch(updateDriverRequest({ ...getUpdateData(data, action), id }));
    } else {
      //create call
      dispatch(addDriverRequest(getAddData(data, action)));
    }
  };

  const filterList = [
    {
      type: "search",
      placeholder: "Search by  Driver name",
      onChange: (value) =>
        setFilterObj((s) => {
          return { ...s, keyword: value?.trim(), page_number: 1 };
        }),
      setFilterObj,
    },
    {
      type: "filter",
      placeholder: "Availability",
      onChange: (value) => {
        setFilterObj((s) => {
          console.log(value);
          return {
            ...s,
            availability_status: value?.label?.toLowerCase(),
            page_number: 1,
          };
        });
      },
      options: [
        { id: 0, name: "Online" },
        { id: 1, name: "Offline" },
      ],
    },
    {
      type: "filter",
      placeholder: "Profile Status",
      onChange: (value) => {
        setFilterObj((s) => {
          return { ...s, status: value?.value, page_number: 1 };
        });
      },
      options: [
        { id: "active", name: "Approved" },
        { id: "suspended", name: "Suspended" },
        { id: "deleted", name: "Deleted" },
      ],
    },
  ];

  const handleDelete = () => {};

  //list of actions to be displayed when clicked in the 3 dots
  const actionsList = ({ index, data }) => {
    const profileStatus = data?.status;
    return [
      {
        condition: () => {
          return !id;
        },
        click: () => {
          navigate(`?type=details&id=${data?.id}`);
        },
        text: "View",
      },
      {
        condition: () => {
          return isSuperAdmin || permissions?.can_update;
        },
        click: () => {
          navigate(`?type=form&id=${data?.id}`);
        },
        text: "Edit",
      },
      {
        condition: () => {
          return (
            profileStatus == "active" &&
            (isSuperAdmin || permissions?.can_update)
          );
        },
        click: () => {
          setOpenActionsModal({
            state: true,
            customerId: selectedRow?.rowData?.id
              ? selectedRow?.rowData?.id
              : id,
            action: "suspended",
          });
        },
        text: "Suspend",
      },
      {
        condition: () => {
          return (
            profileStatus == "suspended" &&
            (isSuperAdmin || permissions?.can_update)
          );
        },
        click: () => {
          setOpenActionsModal({
            state: true,
            customerId: selectedRow?.rowData?.id
              ? selectedRow?.rowData?.id
              : id,
            action: "active",
          });
        },
        text: "Active",
      },
      {
        condition: () => {
          return isSuperAdmin || permissions?.can_update;
        },
        click: () => {
          navigate(`/users/change-password/${data?.id}?driver_type=employee`);
        },
        text: "Change password",
      },
      {
        condition: () => {
          return (
            data?.status != "deleted" &&
            (isSuperAdmin || permissions?.can_delete)
          );
        },
        click: () => {
          setOpenActionsModal({
            state: true,
            customerId: selectedRow?.rowData?.id
              ? selectedRow?.rowData?.id
              : id,
            action: "deleted",
          });
        },
        text: "Delete",
      },
    ];
  };

  //listing all tags
  useEffect(() => {
    if (filterObj) getList(filterObj);
  }, [filterObj]);

  //details of a single tag
  useEffect(() => {
    if (id) getDetails();
  }, [id]);

  const handleUpdateProfileState = () => {
    const action = () => {
      toast.success(
        `Account has been ${openActionsModal?.action} successfully`
      );
    };
    const requestData = {
      body: {
        status: openActionsModal?.action,
      },
      id: openActionsModal?.customerId,
      action,
    };
    dispatch(updateDriverRequest({ ...requestData }));
  };
  const getCities = (filterObj) => {
    const requestData = {
      params: {
        page_size: 10,
        page_number: 1,
        keyword: "",
        is_fulfillment_center: true,
        sort_by: "id",
        ...filterObj,
      },
    };
    dispatch(getScrollCitiesRequest(requestData));
  };
  const getCountries = (filterObj) => {
    const requestData = {
      params: {
        page_size: 10,
        page_number: 1,
        keyword: "",
        is_fulfillment_center: true,
        sort_by: "id",
        ...filterObj,
      },
    };
    dispatch(getScrollCountiesRequest(requestData));
  };
  const options = {
    cities: {
      options: [details?.city, ...filterCitites],
      onOpen: filterCitites?.length <= 1 && getCities,
      isLoading:
        cititesLoading?.loading && cititesLoading?.type == "filter-list-cities",
      getList: getCities,
      count: cititesCount,
    },
    countries: {
      options: [details?.country, ...filterCountries],
      onOpen: filterCountries?.length <= 1 && getCountries,
      isLoading:
        countrieslaod?.loading && countrieslaod?.type == "list-filter-countries",
      getList: getCountries,
      count: countriesCount,
    },
  };    
  return (
    <>
      <Loader open={load?.loading} />
      <EmployeeTemplate
        filterObj={filterObj}
        setFilterObj={setFilterObj}
        data={mappedData}
        count={count}
        paginationPage={page}
        handlePageChange={handlePageChange}
        rowData={rowData}
        details={details}
        breadcrumbs={breadcrumbs}
        filterList={filterList}
        handelSubmit={handelSubmit}
        actionsList={actionsList}
        handleDeleteRequest={handleDelete}
        cities={cities}
        countries={countries}
        can_create={isSuperAdmin || permissions?.can_create}
        formikValues={selectedRow?.formikData?.values}
        options={options}
      />
      {openActionsModal?.state && openActionsModal?.action == "active" && (
        <ActiveModel
          setOpenModel={setOpenActionsModal}
          openModel={openActionsModal}
          handleUpdate={handleUpdateProfileState}
          userType={"driver"}
        />
      )}
      {openActionsModal?.state && openActionsModal?.action == "suspended" && (
        <SuspendModel
          setOpenModel={setOpenActionsModal}
          openModel={openActionsModal}
          handleUpdate={handleUpdateProfileState}
          userType={"driver"}
        />
      )}
      {openActionsModal?.state && openActionsModal?.action == "deleted" && (
        <DeleteModel
          setOpenModel={setOpenActionsModal}
          openModel={openActionsModal}
          handleUpdate={handleUpdateProfileState}
          userType={"driver"}
        />
      )}
    </>
  );
}

export default EmployeePage;
