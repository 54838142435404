import Types from "./Types";


export const GetStoreBranchesSuccess = (payload) => ({
    type: Types.GET_STORE_BRANCHES_SUCCESS,
    payload,
});

export const GetStoreBrancheLoading = (payload) => ({
    type: Types.STORE_BRANCHES_LOADING,
    payload,
});


export const AddStoreBrancheSuccess = (payload) => ({
    type: Types.ADD_STORE_BRANCHES_SUCCESS,
    payload,
});

export const DeleteStoreBrancheSuccess = (payload) => ({
    type: Types.DELETE_STORE_BRANCHES_SUCCESS,
    payload,
});


export const UpdateStoreBrancheSuccess = (payload) => ({
    type: Types.UPDATE_STORE_BRANCHES_SUCCESS,
    payload,
});

export const GetStoreBrancheDetailsSuccess = (payload) => ({
    type: Types.GET_STORE_BRANCHES_DETAILS_SUCCESS,
    payload,
});


