import { makeFilterString } from "../../utils/Helper";
import {
  FeesBetweenZonesLoading,
  GetBetweenZonesFeeDetailsSuccess,
  GetBetweenZonesFeesSuccess,
} from "./Action";

import { reduxRequest } from "common/utils/reduxRequest";

//! BETWEEN ZONES FEES
export const getBetweenZonesFeesRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: FeesBetweenZonesLoading,
    loadingType: "list",
    successFunction: GetBetweenZonesFeesSuccess,
    url: `/admin/lookups/between_zones_fees${makeFilterString(
      variables?.params
    )}`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const createBetweenZonesFeeRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: FeesBetweenZonesLoading,
    loadingType: "create between zone fee",
    action: variables?.action,
    url: `/admin/lookups/between_zones_fees`,
    data: variables?.body,
    method: "post",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const getBetweenZonesFeeDetailsRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: FeesBetweenZonesLoading,
    loadingType: "get zone",
    successFunction: GetBetweenZonesFeeDetailsSuccess,
    action: variables?.action,
    url: `/admin/lookups/between_zones_fees/${variables?.id}`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const updateBetweenZonesFeeRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: FeesBetweenZonesLoading,
    loadingType: "update between zones fee",
    action: variables?.action,
    url: `/admin/lookups/between_zones_fees/${variables?.id}`,
    data: variables?.body,
    method: "put",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const deleteBetweenZonesFeeRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: FeesBetweenZonesLoading,
    loadingType: "delete betweeen zones fee",
    action: variables?.action,
    url: `/admin/lookups/between_zones_fees/${variables?.id}`,
    method: "delete",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
