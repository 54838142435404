const ProductsOptionTypes= {
    //LOADING
    CUSTOMER_COMPLAINTS_LOADING:"CUSTOMER_COMPLAINTS_LOADING",
    //LISTING
    GET_CUSTOMER_COMPLAINTS_SUCCESS: "GET_CUSTOMER_COMPLAINTS_SUCCESS",
    //CREATION
    SET_CUSTOMER_COMPLAINTS_SUCCESS: "SET_CUSTOMER_COMPLAINTS_SUCCESS",
    //DETAILS
    GET_CUSTOMER_COMPLAINT_DETAILS_SUCCESS: "GET_CUSTOMER_COMPLAINT_DETAILS_SUCCESS",
    //UPDATE
    UPDATE_CUSTOMER_COMPLAINT_SUCCESS: "UPDATE_CUSTOMER_COMPLAINT_SUCCESS",
    //DELETE
    DELETE_CUSTOMER_COMPLAINT_SUCCESS: "DELETE_CUSTOMER_COMPLAINT_SUCCESS",
}
export default ProductsOptionTypes