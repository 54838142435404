import {GetAdminUsersSuccess, RolesLoading} from "../../users/Action";
import {makeFilterString} from "../../utils/Helper";
import {reduxRequest} from "../../../../common/utils/reduxRequest";
import {GetUserOccasionDetailsSuccess, GetUserOccasionsLoading, GetUserOccasionsSuccess} from "./Actions";

export const getUserOccasionsRequest = ({ ...variables }) => {
    const requestData = {
        isLoading: GetUserOccasionsLoading,
        loadingType: "list",
        successFunction: GetUserOccasionsSuccess,
        url: `/admin/occasions${makeFilterString(variables?.params)}`,
        method: "get",
    };

    return async (dispatch) => {
        reduxRequest({
            dispatch,
            ...requestData,
        });
    };
};
export const getUserOccasionDetailsRequest = ({ ...variables }) => {
    const requestData = {
        isLoading: GetUserOccasionsLoading,
        loadingType: "details",
        successFunction: GetUserOccasionDetailsSuccess,
        url: `/admin/occasions/${variables?.id}`,
        method: "get",
    };

    return async (dispatch) => {
        reduxRequest({
            dispatch,
            ...requestData,
        });
    };
};