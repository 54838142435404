import { Box, Stack, Typography } from "@mui/material";
import { useThemePalette } from "common/hooks/theme_palette";
import ModelBox from "components/Atoms/Model/Model";
import Loading from "components/Atoms/SharedComponents/Loading/Loading";
import FollowersCart from "components/Molecules/users/customers/followersCart";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { isOpenFollowingModel } from "services/modules/modal/Actions";
import { handleMenuScrollToBottom } from "services/modules/utils/Helper";

export default function ConnectionsModal({
  list,
  openVar,
  getList,
  openFun,
  isLoading,
  count,
  title,
}) {
  const dispatch = useDispatch();
  const { borderColorTwo } = useThemePalette();
  const navigate = useNavigate();
  return (
    <>
      <ModelBox
        openVar={openVar}
        closeFunc={() => dispatch(openFun())}
        title={title}
        titleStyle={{
          fontSize: { lg: "32px", xs: "18px" },
          fontWeight: "500",
          px: { lg: "36px", xs: "15px" },
        }}
        padding={false}
        maxHeight="100%"
        overflowY="hidden"
      >
        <Loading isLoading={isLoading}>
          <Box
            sx={{
              height: list?.length >= 10 ? "400px" : "auto", // Set your desired height here
              overflowY: "scroll",
            }}
            onScroll={(e) =>
              handleMenuScrollToBottom({ e, list, getList, count, isLoading })
            }
          >
            {list?.map((data) => (
              <Stack
                onClick={(_) => {
                  navigate(
                    `?type=details&id=${data?.id}&username=${data?.name}`
                  );
                  dispatch(isOpenFollowingModel());
                }}
                borderBottom={`1px solid ${borderColorTwo}`}
                sx={{ cursor: "pointer" }}
              >
                <FollowersCart data={data} />
              </Stack>
            ))}
            {list?.length == 0 && !isLoading && (
              <Stack p="30px">
                <Typography color={"red"}>No Following Yet</Typography>
              </Stack>
            )}
          </Box>
        </Loading>
      </ModelBox>
    </>
  );
}
