const StoreBranchesTypes = {
    // Vendors
    // list
    GET_STORE_BRANCHES_SUCCESS: "GET_STORE_BRANCHES_SUCCESS",
    STORE_BRANCHES_LOADING: "STORE_BRANCHES_LOADING",
    //details
    GET_STORE_BRANCHES_DETAILS_SUCCESS: "GET_STORE_BRANCHES_DETAILS_SUCCESS",
    // create
    ADD_STORE_BRANCHES_SUCCESS: "ADD_STORE_BRANCHES_SUCCESS",
    // Delete
    DELETE_STORE_BRANCHES_SUCCESS: "DELETE_STORE_BRANCHES_SUCCESS",
    // update
    UPDATE_STORE_BRANCHES_SUCCESS: "UPDATE_STORE_BRANCHES_SUCCESS",
};

export default StoreBranchesTypes;
