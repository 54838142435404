import { PriceChange } from "@mui/icons-material";
import * as Yup from "yup";

export const initialValues = (data) => {
  return {
    ...data,
    ...data?.localized_data,
  }
};

export const structure = (data, wrapId, filteredWraps) => [
  {
    head: "",
    width: "100%",
    list: [
      {
        name: `en.name`,
        kind: "input",
        type: "text",
        label: "Color Name(EN)*",
        width: "47%",
        placeholder: "Write Color Name(EN)"
      },
      {
        name: `ar.name`,
        kind: "input",
        type: "text",
        label: "Color Name(AR)*",
        width: "47%",
        placeholder: "Write Color Name(AR)"
      },
      {
        name: `presentation`,
        kind: "colorInut",
        type: "text",
        label: "Pick a color*",
        width: "47%",
        placeholder: "Pick a color ",
      },
    ],
  },
];


export const validationSchema = (wrapId) =>
  Yup.object({
    en: Yup.object().shape({
      name: Yup.string().required('Required'),
    }),
    ar: Yup.object().shape({
      name: Yup.string().required('Required'),
    }),
    presentation: Yup.string().required('Required'),
  },
  );