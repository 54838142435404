import * as Yup from "yup";

export const initialValues = (data) => {
    console.log(data)
    return {
        ...data,

    };
}

export const structure = () => [

];
export const validationSchema = () =>
    Yup.object({
    });


