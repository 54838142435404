import * as Yup from "yup";
export const initialValues = (data) => {
  return {
    ...data,
    ...data?.localized_data,
  };
};
export const structure = (fulfillmentCenters, options) => [
  {
    head: "",
    width: "100%",
    list: [
      {
        name: "en.name",
        kind: "input",
        type: "text",
        label: "City name(EN)*",
        width: "47%",
        placeholder: "Write the city name",
      },
      {
        name: "ar.name",
        kind: "input",
        type: "text",
        label: "City name(AR)*",
        width: "47%",
        placeholder: "Write the city name",
      },
      {
        name: `fulfillment_center_id`,
        // defaultValue:2,
        kind: "select",
        type: "text",
        label: "Fulfillment Center",
        width: "47%",
        placeholder: "Fulfillment Center",
        ...options?.cities
      },
      {
        name: `delivery_fees`,
        kind: "input",
        type: "number",
        label: "Delivery fees",
        width: "47%",
        placeholder: "Delivery fees",
        EndAdornment: <p style={{ fontSize: "18px", color: "#1A1A1A" }}>SAR</p>,
      },
      {
        name: `is_fulfillment_center`,
        kind: "checkbox",
        type: "text",
        label: "Fulfillment Center",
        width: "47%",
      },
    ],
  },
];

export const validationSchema = () =>
  Yup.object({
    en: Yup.object().shape({
      name: Yup.string().required("Required"),
    }),
    ar: Yup.object().shape({
      name: Yup.string().required("Required"),
    }),
    fulfillment_center_id: Yup.string(),
    delivery_fees: Yup.number(),
    is_fulfillment_center: Yup.boolean(),
  });
