import * as Yup from "yup";

export const initialValues = (data) => {
  return {
    ...data,
    ...data?.localized_data,
    color_id: data?.color?.id,
    shape_id: data?.shape?.id,
    wrap_id: data?.wrap?.id,
    purchasable: data?.purchasable,
    track_inventory: data?.track_inventory
  }
};

export const structure = ( options) => [
  {
    head: "",
    width: "100%",
    list: [
      {
        name: `en.name`,
        kind: "input",
        type: "text",
        label: "Wrap Variant Name(EN)*",
        width: "47%",
        placeholder: "Write variant Name(EN)"
      },
      {
        name: `ar.name`,
        kind: "input",
        type: "text",
        label: "Wrap Variant Name(AR)",
        width: "47%",
        placeholder: "Write variant Name(AR)"
      },
      {
        name: `wrap_id`,
        kind: "select",
        type: "text",
        label: "Wrap*",
        width: "47%",
        placeholder: "Choose wrap",
        ...options?.wraps
      },
      {
        name: `color_id`,
        kind: "select",
        type: "text",
        label: "Color*",
        width: "47%",
        placeholder: "Choose color",
        ...options?.colors
      },
      {
        name: `shape_id`,
        kind: "select",
        type: "text",
        label: "Shape*",
        width: "47%",
        placeholder: "Choose Shape",
        ...options?.shapes
      },
      {
        name: `count_on_hand`,
        kind: "input",
        type: "number",
        label: "Quantity*",
        width: "47%",
        placeholder: "Write Quantity"
      },
      {
        name: `price`,
        kind: "input",
        type: "number",
        label: "Price*",
        width: "47%",
        placeholder: "Write price",
        EndAdornment: (
          <p style={{ fontSize: '18px', color: "#1A1A1A" }} >SAR</p>
        )
      },
      {
        name: `price_after_discount`,
        kind: "input",
        type: "number",
        label: "Price after discount*",
        width: "47%",
        placeholder: "Write price",
        EndAdornment: (
          <p style={{ fontSize: '18px', color: "#1A1A1A" }} >SAR</p>
        )
      },
      {
        name: `purchasable`,
        kind: "checkbox",
        label: "Purchasable",
        width: "fit-content",
      },
      {
        name: `track_inventory`,
        kind: "checkbox",
        label: "Track inventory",
        width: "fit-content",
      },
    ],
  },
];


export const validationSchema = (wrapId) =>
  Yup.object({
    en: Yup.object().shape({
      name: Yup.string().required('Required'),
    }),
    price: Yup.number().required("required"),
    price_after_discount: Yup.number().required("required"),
    count_on_hand: Yup.number().required("required"),
    // // shape_id: Yup.number().required("required"),
    // // wrap_id: Yup.number().required("required"),
    color_id: Yup.number().required("required"),
    wrap_id: Yup.number().required("required"),
    shape_id: Yup.number().required("required"),

  },
  );