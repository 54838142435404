import { Typography } from '@mui/material';
import { icons } from 'assets/AssetHelper'
import * as dayjs from "dayjs";

export const detailsData = ({ data }) => [
    {
        head: 'Details',
        icon: icons?.detailsIcon,
        details: [
            {
                label: 'Shape Name(EN)',
                name: data?.localized_data?.en?.name,
            },
            {
                label: 'Wrap',
                name: (
                    <div style={{ backgroundColor: '#EFEFEF', borderRadius: '25px', width: 'fit-content', padding: '3px 15px' }}>
                        <Typography color='#1A1A1A' fontSize='18px' fontFamily='Jost' >{data?.wrap?.name}</Typography>
                    </div>
                ),
            },
            {
                label: 'Wrap Name(AR)',
                name: data?.localized_data?.ar?.name,
            },
        ]
    },
    {
        head: 'Dimensions',
        icon: icons?.dimensions,
        details: [
            {
                label: 'Weight',
                name: data?.weight || 0,
                width: '30%'
            },
            {
                label: 'Width',
                name: data?.dimensions?.width || 0,
                width: '30%'
            },
            {
                label: 'Height',
                name: data?.dimensions?.height || 0,
                width: '30%'
            },
            {
                label: 'Depth',
                name: data?.dimensions?.length || 0,
                width: '30%'
            },
            {
                label: 'Volume',
                name: data?.volume || 0,
                width: '30%'
            },
        ]
    },
    {
        head: 'Prices',
        icon: icons?.priceIcon,
        details: [
            {
                label: 'Price',
                name: `${data?.price} ${data?.currency?.name}`,
            },
            {
                label: 'Price after discount',
                name: `${data?.price_after_discount} ${data?.currency?.name}`,
            },
        ]
    },
    {
        head: 'Dates',
        icon: icons.datesIcons,
        details: [
            {
                label: 'Created AT',
                name: dayjs(data?.created_at).format("DD-MM-YYYY HH:MM a"),
            },
            {
                label: 'Updated At',
                name: dayjs(data?.updated_at).format("DD-MM-YYYY HH:MM a")
            },
        ]
    },
];