import Types from "./Types";

export const GetDriverComplaintReasonsSuccess = (payload) => ({
    type: Types.GET_DRIVER_COMPLAINT_REASONS_SUCCESS,
    payload,
});

export const AddDriverComplaintReasonSuccess = (payload) => ({
    type: Types.ADD_DRIVER_COMPLAINT_REASON_SUCCESS,
    payload,
});

export const GetDriverComplaintReasonDetailsSuccess = (payload) => ({
    type: Types.GET_DRIVER_COMPLAINT_REASON_DETAILS_SUCCESS,
    payload,
});
export const DriverComplaintReasonsLoading = (payload) => ({
    type: Types.DRIVER_COMPLAINT_REASONS_LOADING,
    payload,
});