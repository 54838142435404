import React, { useState, useRef } from "react";
import Header from "components/Atoms/SharedComponents/Details/header";
import { Button, Stack } from "@mui/material";
import CActions from "components/Molecules/Crud/components/actions";
import ReturnDetailsHead from "components/Organisms/orders/returnDetailsHead";
import Breadcrumb from "components/Atoms/SharedComponents/Breadcrumbs/Breadcrumb";
import AssignToEmpolyee from "components/Organisms/orders/Models/assignToEmpolyee";
import OrderRoutes from "components/Organisms/orders/orderRoutes";
import { useThemePalette } from "common/hooks/theme_palette";
import {
  Completed,
  CustomerToCadeau,
  StoreUser,
} from "services/modules/utils/constant";
import { useNavigate } from "react-router-dom";
import OrderStatus from "components/Organisms/orders/orderStatus";
import RetunableProducts from "components/Organisms/orders/returnRequest/returnableProduct";
import { getEmployeesRequest } from "services/modules/orders/return-order";
import ContainedButton from "components/Atoms/SharedComponents/Buttons/ContainedButton";
import RoutesCard from "components/Organisms/orders/routesCard";
import RouteDriverDetails from "components/Organisms/orders/routesDriverDetails";
import OutlinedButton from "components/Atoms/SharedComponents/Buttons/OutlinedButton";

const ReturnRequestDetailsTemplate = ({
  id,
  data,
  details,
  breadcrumbs,
  routes,
  employees,
  actionsList,
  setFilterObj,
  setSelectedEmployee,
  selectedEmployee,
  handleAssignEmployee,
  handleCloseModel,
  filterObj,
  handleUnAssignDriver,
  setOrderReadyForDelivery,
  returnStatus,
  orderStatus,
  routeOne,
  routeTwo,
  isRouteOne,
  handleUpdateComplains,
  setUpdateComplaintData,
  updateComplaintData,
  openResplveModal,
  setOpenResplveModal,
  sendRangesRequest,
  load,
  handleDeleteRequest,
  openDeleteModal,
  setOpenDeleteModal,
  userType,
  type,
  routesStatusChanges,
  show_items,
  getReturnRequestItem,
  updateRequestItem,
  returnRequestItems,
  requestId,
  setOpenActionsModal,
  routeThree,
  isRouteThree,
  setOpenUnAssignModal,
  openUnAssignModal,
  isRouteTwo,
  getCurrentRoute,
}) => {
  const { orangeTwo } = useThemePalette();
  const routeOneDriverData = [
    {
      title: "Driver details of route 1",
      driver: routeOne?.driver,
      route: routeOne,
    },
  ];
  const routeTwoDriverData = [
    ...routeOneDriverData,
    {
      title: "Driver details of route 2",
      driver: routeTwo?.driver,
      route: routeTwo,
    },
  ];
  const driversData = [
    ...routeTwoDriverData,
    routeThree && {
      title: "Driver details of route 3",
      driver: routeThree?.driver,
      route: routeThree,
    },
  ]?.filter((item) => item);

  const orderSummaryTitle = "Products";
  const adminPossibleStatus = {
    draft: { status: "Processing", step: 1, next: "Assigned" },
    driver_assignment: {
      status: "Assigned",
      step: 2,
      next: "Started",
    },
    route_one_in_progress: {
      status: "Started",
      step: 3,
      next: "Delivered from supplier(s)to Fulfillment",
    },
    items_checked: {
      status: "Delivered from supplier(s)to Fulfillment",
      step: 4,
      next: "Ready for Delivery",
    },
    ready_for_delivery: {
      status: "Ready for Delivery",
      step: 5,
      next: "Assigned Route 2",
    },
    route_two_in_progress: {
      status: "Assigned Route 2",
      step: 6,
      next: "Picked up and in its way to deliver",
    },
    route_three_in_progress: {
      status: "Picked up and in its way to deliver",
      step: 7,
      next: "Delivered",
    },
    completed: { status: "Delivered", step: 8, next: null },
  };
  return (
    <Stack gap="24px">
      <Stack
        direction="row"
        alignSelf="start"
        justifyContent="start"
        gap={2}
        width="100%"
        mb={1}
      >
        <Breadcrumb data={breadcrumbs} />
      </Stack>
      <Header
        title={`Return Request Details ( #${requestId} )`}
        deleteBtn={false}
        editBtn={false}
      >
        {orderStatus !== Completed && (
          <OutlinedButton
            onClick={(_) => setOpenActionsModal(true)}
            title="Cancel Request"
          />
        )}
        {orderStatus == "items_checked" && userType != StoreUser && (
          <ContainedButton
            onClick={(_) => setOrderReadyForDelivery()}
            title="Ready for delivery"
            width="fit-content"
            load={load?.loading && load?.type == "readyForDelivery"}
          />
        )}
      </Header>
      <ReturnDetailsHead
        orderPlaceIn={details?.created_at}
        deliveryIn={details?.delivery_date}
      />
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="stretch"
        gap="24px"
      >
        <Stack width="50%">
          <OrderStatus
            details={details}
            userType={userType}
            routesStatusChanges={routesStatusChanges}
            adminPossibleStatus={adminPossibleStatus}
            title={`Request (#${details?.id})`}
            height={"100% !important"}
          />
        </Stack>

        {/* <RouteOne
          data={routeOne}
          handleUnAssignDriver={handleUnAssignDriver}
          handleUpdateComplains={handleUpdateComplains}
          setUpdateComplaintData={setUpdateComplaintData}
          updateComplaintData={updateComplaintData}
          openResplveModal={openResplveModal}
          setOpenResplveModal={setOpenResplveModal}
          load={load}
          userType={userType}
          displayFreeLancerBtn={false}
        /> */}

        {/* ) 
        : orderStatus == "canceled" &&
          routeOne?.driver &&
          !routeTwo?.driver ? (
          <RouteDriverDetails
            routeOne={routeOne}
            updateComplaintData={updateComplaintData}
            setUpdateComplaintData={setUpdateComplaintData}
            handleUpdateComplains={handleUpdateComplains}
            openResplveModal={openResplveModal}
            setOpenResplveModal={setOpenResplveModal}
            laod={load}
            description="View Routes driver details"
            driver={true}
            driversData={driverData}
          /> */}
        {orderStatus == Completed ? (
          <RouteDriverDetails
            routeOne={routeOne}
            updateComplaintData={updateComplaintData}
            setUpdateComplaintData={setUpdateComplaintData}
            handleUpdateComplains={handleUpdateComplains}
            openResplveModal={openResplveModal}
            setOpenResplveModal={setOpenResplveModal}
            laod={load}
            description="View Routes drivers details"
            driver={true}
            driversData={driversData}
          />
        ) : isRouteOne(orderStatus) ? (
          <RoutesCard
            data={routeOne}
            handleUnAssignDriver={handleUnAssignDriver}
            handleUpdateComplains={handleUpdateComplains}
            setUpdateComplaintData={setUpdateComplaintData}
            updateComplaintData={updateComplaintData}
            openResplveModal={openResplveModal}
            setOpenResplveModal={setOpenResplveModal}
            load={load}
            userType={userType}
            routeNumber={1}
            routeType={routeOne?.leg_type}
            openUnAssignModal={openUnAssignModal}
            setOpenUnAssignModal={setOpenUnAssignModal}
            displayFreeLancerBtn={false}
          />
        ) : isRouteTwo(orderStatus) ? (
          <RoutesCard
            data={routeTwo}
            handleUnAssignDriver={handleUnAssignDriver}
            handleUpdateComplains={handleUpdateComplains}
            setUpdateComplaintData={setUpdateComplaintData}
            updateComplaintData={updateComplaintData}
            openResplveModal={openResplveModal}
            setOpenResplveModal={setOpenResplveModal}
            load={load}
            userType={userType}
            routeTitle={"Route 2"}
            routeType={routeTwo?.leg_type}
            routeNumber={2}
            driversData={routeOneDriverData}
            openUnAssignModal={openUnAssignModal}
            setOpenUnAssignModal={setOpenUnAssignModal}
            displayFreeLancerBtn={false}
          />
        ) : (
          <RoutesCard
            data={routeThree}
            handleUnAssignDriver={handleUnAssignDriver}
            handleUpdateComplains={handleUpdateComplains}
            setUpdateComplaintData={setUpdateComplaintData}
            updateComplaintData={updateComplaintData}
            openResplveModal={openResplveModal}
            setOpenResplveModal={setOpenResplveModal}
            load={load}
            userType={userType}
            routeTitle={"Route 3"}
            routeType={routeTwo?.leg_type}
            routeNumber={3}
            driversData={routeTwoDriverData}
            openUnAssignModal={openUnAssignModal}
            setOpenUnAssignModal={setOpenUnAssignModal}
            displayFreeLancerBtn={false}
          />
        )}
      </Stack>
      {userType != StoreUser && (
        <AssignToEmpolyee
          setFilterObj={setFilterObj}
          employees={employees}
          routeType={getCurrentRoute()?.leg_type}
          routes={routes}
          setSelectedEmployee={setSelectedEmployee}
          selectedEmployee={selectedEmployee}
          handleAssignEmployee={handleAssignEmployee}
          handleCloseModel={handleCloseModel}
          filterObj={filterObj}
          handleUnAssignDriver={handleUnAssignDriver}
          load={load}
          orderStatus={orderStatus}
          isRouteOne={isRouteOne}
          getEmployeesRequest={getEmployeesRequest}
          id={requestId}
        />
      )}

      <Stack gap={4}>
        {userType != StoreUser && (
          <>
            <RetunableProducts
              title={orderSummaryTitle}
              details={details}
              getReturnRequestItem={getReturnRequestItem}
              products={returnRequestItems}
              updateRequestItem={updateRequestItem}
              load={load}
            />
            <Stack>
              <OrderRoutes details={details} />
            </Stack>
          </>
        )}
      </Stack>
    </Stack>
  );
};

export default ReturnRequestDetailsTemplate;
