import { Box } from "@mui/material";
import React, { useState } from "react";
import Select, { components } from "react-select";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { images } from "assets/AssetHelper";
const SearchableSelect = ({
  options,
  onChange,
  value,
  placeholder,
  id,
  name,
  defaultValue,
  sx,
  dropdownStyle,
  styles,
  applySearchFromRequest = false,
  getList,
  count,
  list,
  onMenuOpen,
  ...props
}) => {
  const handelOptions = options?.map((val) => {
    return {
      label: val?.name,
      value: val?.id,
      color: val?.color,
      ...val,
    };
  });
  // colorStyles using to add custom color to each option in list
  // every option must has color property to custom it
  const colorStyles = {
    option: (styles, { data }) => {
      return {
        ...styles,
        color: data.color,
      };
    },
  };
  const controlStyles = {
    control: (provided, state) => ({
      ...provided,
      width: "100%",
      border: "none",
      borderRadius: "6px ",
    }),
  };
  const containerStyles = {
    container: (provided, state) => ({
      ...provided,
      display: "flex",
      height: "100%",
      zIndex: 20,
    }),
  };
  const dropdownStyles = {
    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: "#767676",
      fontSize: "25px",
      scale: "1.2",
      ...dropdownStyle,
    }),
  };

  const indicatorSeparatorStyles = {
    indicatorSeparator: (provided, state) => ({
      ...provided,
      display: "none",
    }),
  };
  const placeholderStyles = {
    placeholder: (provided, state) => ({
      ...provided,
      color: "#1A1A1A",
      fontWeight: "400",
      fontFamily: "Jost",
    }),
  };

  const menuStyles = {
    menu: (provided, state) => ({
      ...provided,
      zIndex: 2,
    }),
  };
  const renderIcon = () => <img src={images.Logo} alt="logo" />;
  const [searchFilterTimeout, setSearchFilterTimeout] = useState(null);

  const handleMenuScrollToBottom = () => {
    if (options?.length < count) {
      getList({
        page_number: Math?.ceil(options?.length / 10 + 1),
      });
      return;
    }
  };

  const handleInputChange = (value, event) => {
    if (searchFilterTimeout) {
      clearTimeout(searchFilterTimeout);
    }
    const timeout = setTimeout(() => {
      if (event?.action == "input-change") getList({ keyword: value });
    }, 500);
    setSearchFilterTimeout(timeout);
  };

  // console.log(getList);

  return (
    <Box sx={{ width: "200px", height: "50px", ...sx }}>
      <Select
        id={id}
        name={name}
        options={handelOptions}
        onChange={onChange}
        value={value}
        placeholder={placeholder}
        isClearable
        defaultValue={defaultValue}
        loadingMessage={() => <p>loading...</p>}
        onKeyDown={(e) => e.stopPropagation()}
        onInputChange={applySearchFromRequest && ((value, event) => handleInputChange(value, event))}
        onMenuScrollToBottom={handleMenuScrollToBottom}
        onMenuOpen={onMenuOpen && onMenuOpen}
        {...props}
        styles={{
          ...placeholderStyles,
          ...menuStyles,
          ...containerStyles,
          ...controlStyles,
          ...dropdownStyles,
          ...indicatorSeparatorStyles,
          ...styles,
        }}
      />
    </Box>
  );
};
export default SearchableSelect;
