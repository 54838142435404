import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import React from "react";
import { Stack } from "@mui/material";
import CFormLabels from "./CFormLabels";
import CFormErrors from "./CFormErrors";
import './phoneInput.css'
const CPhoneInput = ({
  changeAction,
  label,
  formik,
  name,
  defaultValue,
  ...rest
}) => {
  const errorsMsg = formik?.errors[name];
  const value = formik ? formik?.values[name] : defaultValue;
  return (
    <Stack width="100%" gap={'20px'}>
      <CFormLabels label={label} />
      <PhoneInput
        country={"sa"}
        value={value}
        onChange={(value, country, e, formattedValue) =>
          changeAction(value, country, e, formattedValue)
        }
        inputClass="input_class"
        buttonClass='button_class'
        containerClass='container_class'
        dropdownClass='dropdownStyle'
        onlyCountries={["eg", "sa"]}
        {...rest}
      />
      <CFormErrors errorsMsg={errorsMsg} />
    </Stack>
  );
};

export default CPhoneInput;
