import { Stack } from "@mui/material";
import CustomTabs from "components/Organisms/tabs/customTabs";
import CustomerDetailsHeader from "components/Organisms/users/customers/customerDetailsHeader";

export default function CustomerDetailsTemplate({
  details,
  tabs,
  selectedTab,
  handleTabChange,
  actionsList,
}) {
  return (
    <Stack>
      <CustomerDetailsHeader
        data={details}
        actionsList={actionsList}
      />
      <CustomTabs
        tabs={tabs}
        selectedTab={selectedTab}
        handleTabChange={handleTabChange}
      />
    </Stack>
  );
}
