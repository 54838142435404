import { icons, images } from "assets/AssetHelper";
import dayjs from "dayjs";

export const detailsData = ({ data }) => [
  {
    head: "Details",
    icon: icons?.details,
    details: [
      {
        label: "Zone name(EN)",
        name: data?.localized_data?.en?.name,
      },
      {
        label: "Zone name(EN)",
        name: data?.localized_data?.ar?.name,
      },
      {
        label: "City",
        name: data?.google_city?.name,
      },
    ],
  },
  {
    head: "Fees",
    icon: icons?.fees,

    details: [
      {
        label: "Delivery fees",
        name: `${data?.delivery_fees} SAR`,
      },
    ],
  },
  {
    head: "Map details",
    icon: icons?.map,

    details: [
      {
        label: "Lat",
        name: data?.lat,
        width: "30%",
      },
      {
        label: "Long",
        name: data?.long,
        width: "30%",
      },
      {
        label: "Radius",
        name: `${data?.radius} KM`,
        width: "30%",
      },
    ],
  },
  {
    head: "Dates",
    icon: icons?.dates,
    details: [
      {
        label: "Created At",
        name: dayjs(data?.created_at).format("DD-MM-YYYY HH:MM a"),
      },
      {
        label: "Updated At",
        name: dayjs(data?.updated_at).format("DD-MM-YYYY HH:MM a"),
      },
    ],
  },
];
