import * as Yup from "yup";

export const structure = (formHead) => [
  {
    head: formHead,
    width: "100%",
    list: [
      {
        name: `password`,
        kind: "input",
        type: "text",
        label: "Password*",
        width: "47%",
        placeholder:'Write Password'
      },
      {
        name: `confirmPassword`,
        kind: "input",
        type: "text",
        label: "Confirm Password*",
        width: "47%",
        placeholder:'Confirm Password'
      },
    ],
  },
];

export const validationSchema = () =>
  Yup.object({
    password: Yup.string().required("required").min(6, "password can be at least 6 characters"),
    confirmPassword:  Yup.string()
    .required()
    .oneOf([Yup.ref("password"), null], `Passwords don't match`),
  });
