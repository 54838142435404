/* eslint-disable array-callback-return */
import Types from "./Types";

const INIT_STATE = {
  colors: [],
  filterColors: [],
  listCount: 0,
  color: {},
  count: 0,
  load: null,
  error: null,
};

export function colorsReducer(state = INIT_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case Types.GET_COLORS_SUCCESS: {
      return {
        ...state,
        colors: payload?.data?.colors,
        count: payload?.extra?.total_count,
      };
    }
    case Types.COLORS_LOADING: {
      return {
        ...state,
        load: payload,
      };
    }
    case Types.DELETE_COLOR_SUCCESS: {
      return {
        ...state,
      };
    }
    case Types.ADD_COLOR_SUCCESS: {
      return {
        ...state,
      };
    }
    case Types.ADD_COLOR_FAILED: {
      return {
        ...state,
        error: payload?.response?.data?.message,
      };
    }
    case Types.UPDATE_COLOR_SUCCESS: {
      return {
        ...state,
      };
    }
    case Types.GET_COLOR_DETAILS_SUCCESS: {
      return {
        ...state,
        color: payload?.data?.color,
      };
    }
    case Types.GET_SCROLL_COLORS_SUCCESS: {
      return {
        ...state,
        filterColors: payload?.extra?.page_number == 1
          ? payload?.data?.colors
          : [...state.filterColors, ...payload?.data?.colors],
        listCount: payload?.extra?.total_count,
      };
    }
    default: {
      return state;
    }
  }
}
