import { Skeleton } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";

export default function NotificationSkelaton({ num }) {
  function range(start, end) {
    let ans = [];
    for (let i = 0; i < num; i++) {
      ans.push(i);
    }
    return ans;
  }
  return (
    <Stack>
      {range()?.map(() => (
        <Stack direction="row" gap={2} p={2}>
          <Skeleton variant="circular" width={50} height={50} />
          <Stack gap={1}>
            <Skeleton variant="rounded" width="300px" height={60} />
            <Skeleton variant="rounded" width="300px" height={20} />
          </Stack>
        </Stack>
      ))}
    </Stack>
  );
}
