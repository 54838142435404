import {reduxRequest} from "../../../../../common/utils/reduxRequest";
import {makeFilterString} from "../../../utils/Helper";
import {
    AddCustomerComplaintReasonSuccess,
    CustomerComplaintReasonsLoading, GetCustomerComplaintReasonDetailsSuccess, GetCustomerComplaintReasonsSuccess,

} from "./Actions";

export const addCustomerComplaintReasonRequest = ({ ...variables }) => {
    const requestData = {
        isLoading: CustomerComplaintReasonsLoading,
        loadingType: "add",
        successFunction: AddCustomerComplaintReasonSuccess,
        action: variables?.action,
        url: `/admin/complaints/order_complaint_reasons`,
        method: "post",
        data: variables?.body,
    };

    return async (dispatch) => {
        reduxRequest({
            dispatch,
            ...requestData,
        });
    };
};
export const getCustomerComplaintReasonsRequest = ({ ...variables }) => {
    const requestData = {
        isLoading: CustomerComplaintReasonsLoading,
        loadingType: "list",
        successFunction: GetCustomerComplaintReasonsSuccess,
        url: `/admin/complaints/order_complaint_reasons?${makeFilterString(
            variables?.params
        )}`,
        method: "get",
    };

    return async (dispatch) => {
        reduxRequest({
            dispatch,
            ...requestData,
        });
    };
};
export const deleteCustomerComplaintReasonRequest = ({ ...variables }) => {
    const requestData = {
        isLoading: CustomerComplaintReasonsLoading,
        loadingType: "delete order",
        action: variables?.action,
        url: `/admin/complaints/order_complaint_reasons/${variables?.id}`,
        method: "delete",
    };

    return async (dispatch) => {
        reduxRequest({
            dispatch,
            ...requestData,
        });
    };
};

export const getCustomerComplaintReasonDetailsRequest = ({ ...variables }) => {
    const requestData = {
        isLoading: CustomerComplaintReasonsLoading,
        loadingType: "details",
        successFunction: GetCustomerComplaintReasonDetailsSuccess,
        url: `/admin/complaints/order_complaint_reasons/${variables?.customer_complaint_reason}`,
        method: "get",
    };

    return async (dispatch) => {
        reduxRequest({
            dispatch,
            ...requestData,
        });
    };
};

export const updateCustomerComplaintReasonRequest = ({ ...variables }) => {
    const requestData = {
        isLoading: CustomerComplaintReasonsLoading,
        loadingType: "update",
        url: `/admin/complaints/order_complaint_reasons/${variables?.id}`,
        action: variables?.action,
        data: variables?.body,
        method: "put",
    };

    return async (dispatch) => {
        reduxRequest({
            dispatch,
            ...requestData,
        });
    };
};