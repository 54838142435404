import { useState } from "react";
import CustomerDetailsTemplate from "templates/users/customers/customerDetails";
import CustomerDetails from "components/Organisms/users/customers/details";
import CustomerOccasions from "components/Organisms/users/customers/customerOccasions";
import ComplainHistory from "components/Organisms/users/customers/complainHistory";
import OrderHistory from "components/Organisms/users/customers/orderHistory";
import { useSearchParams } from "react-router-dom";
import { handleTabChange } from "services/modules/utils/Helper";

export default function CustomerDetailsPage({ details, actionsList, followers, followings }) {
  const tabs = [
    {
      title: "Details",
      children: <CustomerDetails details={details} followers={followers} followings={followings} />,
    },
    { title: "Occasions", children: <CustomerOccasions /> },
    { title: "Orders History", children: <OrderHistory /> },
    { title: "Complaints History", children: <ComplainHistory /> },
  ];

  const [searchParams, setSearchParams] = useState(new URLSearchParams(window.location.search));
  const selectedTab = searchParams.get("tab");
  const [value, setValue] = useState(+selectedTab || 0);

  return (
    <CustomerDetailsTemplate
      details={details}
      selectedTab={value}
      tabs={tabs}
      handleTabChange={(event, newValue) => handleTabChange({ event, newValue, setValue, searchParams, setSearchParams })}
      actionsList={actionsList}
      followers={followers}
      followings={followings}
    />
  );
}
