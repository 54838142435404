import Types from "./Types";

export const DeliveryTimesLoading=(payload)=>({
    type:Types.DELIVERY_TIMES_LOADING,
    payload
})
export const GetDeliveryTimes = (payload) => ({
    type: Types.GET_DELIVERY_TIMES_SUCCESS,
    payload,
});
export const GetDeliveryTimesDetails = (payload) => ({
    type: Types.GET_DELIVERY_TIME_DETAILS_SUCCESS,
    payload,
});
export const AddDeliveryTimes = (payload) => ({
    type: Types.ADD_DELIVERY_TIMES_SUCCESS,
    payload,
});
export const UpdateDeliveryTimes = (payload) => ({
    type: Types.UPDATE_DELIVERY_TIME_SUCCESS,
    payload,
});
export const DeleteDeliveryTimes = (payload) => ({
    type: Types.DELETE_DELIVERY_TIME_SUCCESS,
    payload,
});

