import { Box, Paper, Stack, Switch, Typography } from '@mui/material'
import React from 'react'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useThemePalette } from 'common/hooks/theme_palette';

export default function OpenRangeSwitch({ title, checked, systemConfig, handleSwitchChange, disabled = false, load, systemConfigId, requesNumber, rangeValue }) {
    const { oliveGreen, green } = useThemePalette()
    return <>
        <Typography
            fontSize='18px'
            fontWeight='600'
            mb='19px'
        >
            {title}
        </Typography>
        <Typography
            fontSize='16px'
            mb='19px'
        >
            FBy clicking the button, you will initiate sending requests to freelancers within a {systemConfig[0]?.value} range
        </Typography>
        <Stack direction='row' justifyContent='space-between' alignItems='center'  >
            <Paper
                elevation={0}
                sx={{
                    width: '178px',
                    height: '48px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    padding: '0 8px',
                    background: checked ? oliveGreen : '#9F9F9F70',
                    position: 'relative', // Keep the arrow and text within the container
                }}
            >
                <Box
                    sx={{
                        width: '49px',
                        height: '42px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        background: checked ? 'white' : '#9F9F9F',
                        position: 'absolute',
                        left: checked ? '139px' : '8px',
                        transition: 'left 0.3s',
                        borderRadius: '8px',
                        zIndex: 0
                    }}
                    className='ranges'
                >
                    <Box>
                        <Switch
                            rangeOneChecked={checked}
                            onChange={handleSwitchChange}
                            color="default"
                            disabled={disabled}
                            sx={{
                                opacity: 0,
                                position: 'absolute',
                                width: '49px',
                                height: '42px',
                                top: 0,
                                left: 0,
                                right: 0,
                                '& .MuiSwitch-thumb': {
                                    width: '49px',
                                    height: '42px',
                                    left: 0,
                                    top: 0,
                                },
                                '& .MuiSwitch-track': {
                                    backgroundColor: '#09c',
                                },
                            }}
                        />
                        <Stack>
                            {checked
                                ?
                                <ArrowBackIosNewIcon
                                    sx={{
                                        color: 'black',
                                        top: '9px',
                                        left: '24%',
                                        position: 'absolute',
                                        zIndex: -1
                                    }}
                                />
                                :
                                <ArrowForwardIosIcon
                                    sx={{
                                        color: 'white',
                                        top: '9px',
                                        right: '28%',
                                        position: 'absolute',
                                        zIndex:  -1
                                    }}
                                />
                            }
                        </Stack>
                    </Box>
                </Box>
                <Typography
                    sx={{
                        position: 'absolute',
                        right: !checked && '25px',
                        transition: 'right 0.3s',
                        color: 'white'
                    }}>
                    {checked ? (load?.type == `range ${systemConfig && systemConfig[0]?.id}` && load?.loading) ? 'Sending...' : 'Requests sent' : 'Send Requests'}
                </Typography>
            </Paper>
            {requesNumber != 0 && requesNumber && <Stack direction='row' alignItems='center' gap='3px' >
                <Typography color={green} fontWeight='500' >{requesNumber}</Typography>
                <Typography fontSize='14px' >
                    {requesNumber > 1 ? 'Requests' : 'Requests'}
                </Typography>
            </Stack>}
        </Stack >
    </>
}
