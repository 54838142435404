import DetailsUse from "components/Atoms/SharedComponents/CRUD/details-use";
import { detailsData } from "templates/users/customers/details";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getFollowersListRequest,
  getFollowingListRequest,
} from "services/modules/users/customers";
import {
  isOpenFollowesModel,
  isOpenFollowingModel,
} from "services/modules/modal/Actions";
import ConnectionsModal from "./ConnectionsModal";

export default function CustomerDetails({ details, followers, followings }) {
  const [addressesToShow, setAddressesToShow] = useState({
    length: 1,
    state: false,
  });
  let [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const dispatch = useDispatch();
  const { followingModel, followesModel } = useSelector((state) => state.modal);

  const followingslist = useSelector((state) => state.customers.followings);
  const followingCount = useSelector((state) => state.customers.followingCount);
  const followingLoad = useSelector((state) => state.customers.load);
  const followingLoading =
    followingLoad?.loading && followingLoad?.type == "get-following";

  const followersCount = useSelector((state) => state.customers.followersCount);
  const followersList = useSelector((state) => state.customers.followers);
  const followesload = useSelector((state) => state.customers.laod);
  const followersLoading =
    followesload?.loading && followesload?.type == "get-followers";

  const getFollowingList = async (filterObj) => {
    const requestData = {
      id: id,
      params: {
        page_size: 10,
        page_number: 1,
        keyword: "",
        sort_by: "id",
        ...filterObj,
      },
    };
    dispatch(getFollowingListRequest({ ...requestData }));
  };
  const getFollowersList = async (filterObj) => {
    const requestData = {
      id: id,
      params: {
        page_size: 10,
        page_number: 1,
        keyword: "",
        sort_by: "id",
        ...filterObj,
      },
    };
    dispatch(getFollowersListRequest({ ...requestData }));
  };

  return (
    <>
      <DetailsUse
        data={{
          deleteBtn: false,
          editBtn: false,
          data: {
            list: detailsData({
              data: details,
              followers,
              followings,
              addressesToShow,
              setAddressesToShow,
              getFollowingList,
              getFollowersList,
              followingslist,
              followersList,
            }),
            checkBox: [
              { label: "Verified", checked: details?.is_verified },
              {
                label: "Not Verified",
                checked: !details?.is_verified,
                icon: true,
              },
            ],
          },
        }}
      />
      {/* --------------- Followers Model ------------------ */}
      <ConnectionsModal
        list={followersList}
        count={followersCount}
        isLoading={followersLoading}
        openVar={followesModel}
        getList={getFollowersList}
        openFun={isOpenFollowesModel}
        title={"Followers"}
      />
      {/* --------------- Following Model ------------------ */}
      <ConnectionsModal
        list={followingslist}
        count={followingCount}
        isLoading={followingLoading}
        openVar={followingModel}
        getList={getFollowingList}
        openFun={isOpenFollowingModel}
        title={"Following"}
      />
    </>
  );
}
