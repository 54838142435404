import { icons } from "assets/AssetHelper";
import dayjs from "dayjs";
import { Box, Stack } from "@mui/material";
import DriverDetailsImages from "components/Molecules/users/drivers/driverDetailsImages";
import CustomerProfileInfo from "components/Molecules/users/customers/customerProfileInfo";

export const detailsData = ({
  data,
  city,
  country,
  displayDriverDataWithImg,
}) => [
  {
    head: "Driver Image",
    icon: icons?.media,
    details: [
      {
        name: displayDriverDataWithImg ? (
          <CustomerProfileInfo
            data={data}
            justifyContent={'start'}
          />
        ) : (
          <Box
            component="img"
            src={data?.avatar}
            width="228px"
            height="229px"
            borderRadius="6px"
          />
        ),
        width: "100%",
      },
    ],
  },
  {
    head: "Driver information",
    icon: icons?.details,
    details: [
      {
        label: "Name",
        name: data?.name,
      },
      {
        label: "Phone",
        name: `+${data?.country_code}${data?.phone_number}`,
      },
      {
        label: "Email Address",
        name: data?.email,
      },
      {
        label: "Birthdate",
        name: dayjs(data?.birthdate).format("DD / MM / YYYY "),
      },
      {
        label: "Gender",
        name: data?.gender,
      },
      {
        label: "National Id",
        name: data?.national_id,
      },
      {
        label: "Country",
        name: country && country[0]?.name,
      },
      {
        label: "City",
        name: city && city[0]?.name,
      },
      {
        label: "License info",
        name:
          data?.driver_type == "freelancer"
            ? "Freelancer Driver"
            : "Employee Driver",
      },
    ],
  },
  {
    head: "Photos",
    icon: icons?.media,
    details: [
      {
        label: "National ID Photo ( Front )",
        name: (
          <DriverDetailsImages
            src={data?.national_id_media && data?.national_id_media[0]?.url}
          />
        ),
      },
      {
        label: "National ID Photo ( Back )",
        name: (
          <DriverDetailsImages
            src={data?.national_id_media && data?.national_id_media[1]?.url}
          />
        ),
      },
      {
        label: "Criminal status ( Front )",
        name: (
          <DriverDetailsImages
            src={
              data?.criminal_status_media && data?.criminal_status_media[0]?.url
            }
          />
        ),
      },
      {
        label: "Criminal status ( Back )",
        name: (
          <DriverDetailsImages
            src={
              data?.criminal_status_media && data?.criminal_status_media[1]?.url
            }
          />
        ),
      },
      {
        label: "Driver License Photo ( Front )",
        name: (
          <DriverDetailsImages
            src={
              data?.driver_licence_media && data?.driver_licence_media[0]?.url
            }
          />
        ),
      },
      {
        label: "Driver License Photo ( Back )",
        name: (
          <DriverDetailsImages
            src={
              data?.driver_licence_media && data?.driver_licence_media[1]?.url
            }
          />
        ),
      },
      {
        label: "Vehicle License Photo ( Front )",
        name: (
          <DriverDetailsImages
            src={data?.vehicle_licence_media && data?.vehicle_licence_media[0]?.url}
          />
        ),
      },
      {
        label: "Vehicle License Photo ( Back )",
        name: (
          <DriverDetailsImages
            src={
              data?.vehicle_licence_media && data?.vehicle_licence_media[1]?.url
            }
          />
        ),
      },
    ],
  },
  {
    head: "Dates",
    icon: icons?.dates,
    details: [
      {
        label: "Created At",
        name: dayjs(data?.created_at).format("DD-MM-YYYY HH:MM a"),
      },
      {
        label: "Updated At",
        name: dayjs(data?.updated_at).format("DD-MM-YYYY HH:MM a"),
      },
      {
        label: "Last Sign In At",
        name: dayjs(data?.last_sign_in_at).format("DD-MM-YYYY HH:MM a"),
      },
    ],
  },
];
