import Types from "./Types";

export const ProductsOptionValuesLoading=(payload)=>({
    type:Types.PRODUCT_OPTION_VALUES_LOADING,
    payload
})
export const GetProductsOptionValues = (payload) => ({
    type: Types.GET_PRODUCTS_OPTION_VALUES_SUCCESS,
    payload,
});
export const GetProductOptionValueDetails = (payload) => ({
    type: Types.GET_PRODUCTS_OPTION_VALUE_DETAILS_SUCCESS,
    payload,
});
export const SetProductOptionValue = (payload) => ({
    type: Types.SET_PRODUCT_OPTION_VALUE_SUCCESS,
    payload,
});
export const UpdateProductOptionValue = (payload) => ({
    type: Types.UPDATE_PRODUCTS_OPTION_VALUE_SUCCESS,
    payload,
});
export const DeleteProductOptionValue = (payload) => ({
    type: Types.DELETE_PRODUCTS_OPTION_VALUE_SUCCESS,
    payload,
});

