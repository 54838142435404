import { Stack } from '@mui/material';
import ModelBox from 'components/Atoms/Model/Model'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { isOpenPreviewChatImg } from 'services/modules/modal/Actions';

export default function ChatImagePreview() {
    const { previewChatImg, chatImgUrl } = useSelector((state) => state.modal);
    const dispatch = useDispatch();
    return <>
        <ModelBox
            openVar={previewChatImg}
            closeFunc={() => dispatch(isOpenPreviewChatImg(null))}
            padding={false}
            isHeaderVisiable={false}
        >
            <Stack justifyContent={'center'} alignItems='center' py='5rem'  >
                <img src={chatImgUrl} alt='files' width='fit-content' />
            </Stack>
        </ModelBox>

    </>
}
