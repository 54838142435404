import { Box, CircularProgress, Stack, } from '@mui/material';
import { useState } from "react";
import InfiniteScroll from 'react-infinite-scroll-component';
import { useThemePalette } from 'common/hooks/theme_palette';
import ChatItemList from 'components/Molecules/Chat/chatItemList';
import SearchChat from 'components/Molecules/Chat/searchChat';

const ChatList = ({
    chatRooms,
    updateChatBox,
    searchChats,
    setSearchFilterObj,
    loading,
    count,
    searchChatRooms,
    createChatRoom,
    isStore,
    visitChatRoom,
    searchCount,
    searchChatRoomsLoading,
    searchChatsFilterObj,
    setIsSearchOpen,
    getChatRoom,
    setFirstMessageCount,
    firstMessageCount,
    isSearchOpen,
    setMessagesList,
    setFilterObj,
    getList,
    filterObj,
    setMessage
}) => {
    const [searchKeywords, setSearchKeywords] = useState("");
    const [hasMore, setHasMore] = useState(true);
    const { oliveGreen } = useThemePalette()
    const [searchFilterTimeout, setSearchFilterTimeout] = useState(null);

    const fetchMoreData = () => {
        if (chatRooms?.length >= count) {
            setHasMore(false);
        } else {
            setHasMore(true);
            getList({...filterObj, page_size: 10, page_number: Math?.floor(chatRooms?.length / 10) + 1 })
        }
    };
    const handleInputChange = (e) => {
        if (searchFilterTimeout) {
            clearTimeout(searchFilterTimeout);
        }
        const timeout = setTimeout(() => {
            setSearchFilterObj((s) => {
                return { ...s, keyword: e };
            });
        }, 500);
        setSearchFilterTimeout(timeout);
    };

    return (
        <Stack
            sx={{ py: !isStore && '16px', boxShadow: '3px 0px 20px 0px rgba(0, 0, 0, 0.03)' }}
            className="chat-list-container"
        >
            {!isStore && <Stack width='90%' justifyContent='start' alignItems='center' sx={{ mb: '25px' }} className="chat-search-input">
                <SearchChat
                    searchChatRooms={searchChatRooms}
                    searchChats={searchChats}
                    setIsSearchOpen={setIsSearchOpen}
                    getChatRoom={getChatRoom}
                    createChatRoom={createChatRoom}
                    searchChatRoomsLoading={searchChatRoomsLoading}
                    handleInputChange={handleInputChange}
                    searchKeywords={searchKeywords}
                    searchCount={searchCount}
                    setSearchFilterObj={setSearchFilterObj}
                    searchChatsFilterObj={searchChatsFilterObj}
                    isSearchOpen={isSearchOpen}
                    setMessage={setMessage}
                />
            </Stack>}
            <Stack className="chat-list-body" id='chat-list-body'  style={{height:700 , overflow:'auto'}} >
                <Box>
                    <InfiniteScroll
                        dataLength={isStore ? 1 : chatRooms?.length}
                        // dataLength={isStore ? 1 : count}
                        next={fetchMoreData}
                        inverse={false}
                        scrollableTarget="chat-list-body"
                        hasMore={hasMore}
                        loader={<></>}
                        scrollThreshold={0.8}
                        height={700}
                    >
                        <ChatItemList
                            chatRooms={chatRooms}
                            updateChatBox={updateChatBox}
                            visitChatRoom={visitChatRoom}
                            setFirstMessageCount={setFirstMessageCount}
                            setMessagesList={setMessagesList}
                        />
                    </ InfiniteScroll>
                </Box>
            </Stack>
            {loading?.type == 'list' && loading?.loading && firstMessageCount != 1 && (
                <Stack justifyContent="center" alignItems="center" sx={{ overflow: "hidden", my: "20px" }}>
                    <CircularProgress sx={{ color: oliveGreen, marginX: "auto" }} size="30px" />
                </Stack>
            )}
        </Stack>
    )
};

export default ChatList;