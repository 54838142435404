import Loader from "components/Atoms/SharedComponents/Loader/Loader";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import {
  getItemsOrderRequest,
  showReturnRequest
} from "services/modules/orders/return-order";
import setAuthToken from "services/modules/utils/handel_api";
import CreateReturnRequestTemplate from "templates/orders/return-order/createReturnRequestTemplate";

function CreateReturnRequestPage() {
  const token = localStorage.getItem("token");
  const dispatch = useDispatch();
  setAuthToken(token);
  let [searchParams] = useSearchParams();
  const type = searchParams.get("type");
  const id = searchParams?.get("id");
  const requestId = searchParams.get("request_id");
  const list = useSelector((state) => state?.returnOrders?.cartItems);
  const load = useSelector((state) => state?.returnOrders?.load);
  const details = useSelector(
    (state) => state.returnOrders?.returnRequestDetails
  );
  const [filterObj, setFilterObj] = useState(null);
  const breadcrumbs = [
    {
      active: true,
      path: "/",
      title: "Home",
    },
    {
      active: true,
      path: "/orders",
      title: "orders",
    },
    {
      active: true,
      path: `/orders?type=details&id=${id}`,
      title: `Order #${id}`,
    },
    {
      active: true,
      path: `/return-request?id=${id}`,
      title: "Return Requests",
    },
    {
      active: !type ? false : true,
      path: `/create-return-request?id=${id}`,
      title: "New Request",
    },
  ];
  const getDetails = async () => {
    id && dispatch(getItemsOrderRequest({ id }));
  };

  useEffect(() => {
    if (id) getDetails();
  }, [id]);
  const getRequestDetails = async () => {
    dispatch(showReturnRequest({ id: requestId, show_items: true }));
  };
  useEffect(() => {
    if (requestId) getRequestDetails();
  }, [requestId]);
  return (
    <>
      <Loader open={load?.loading && load?.type == "items"} />
      <CreateReturnRequestTemplate
        list={list && list}
        breadcrumbs={breadcrumbs}
        id={id}
        load={load}
        details={details}
        requestId={requestId}
      />
    </>
  );
}

export default CreateReturnRequestPage;
