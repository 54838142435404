import React, { useState } from "react";
import CCrud from "components/Molecules/Crud";
import { initialValues, structure, validationSchema } from "./structure";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Box, Button, Stack } from "@mui/material";
import Breadcrumb from "components/Atoms/SharedComponents/Breadcrumbs/Breadcrumb";
import AddIcon from "@mui/icons-material/Add";
import { detailsData } from "./details";

const PushNotificationsTemplate = ({
  handlePageChange,
  data,
  details,
  count,
  handelSubmit,
  deleteFun,
  filterList,
  actionsList,
  breadcrumbs,
  setFilterObj,
  rowData,
  paginationPage,
  headerChildren,
  modalComponents,
  openDeleteModal,
  setOpenDeleteModal,
  handleDeleteRequest,
}) => {
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  const id = searchParams.get("id");

  const [supportedLang, setSupportedLang] = useState("en");
  return (
    <>
      <Box>
        <Stack direction="row" alignSelf="start" justifyContent="start" gap={2} width="100%" mb={1}>
          <Breadcrumb data={breadcrumbs} />
        </Stack>
        <CCrud
          detailsTemplateData={{
            pageTitle: "Push Notification details",
            editBtn: false,
            deleteBtn: false,
            data: {
              images: details?.image && [{ id: 0, img: details?.image }],
              list: detailsData({ data: details }),
            },
          }}
          table={{
            list: data,
            listTitle: "Push Notifications",
            createBtnTitle: "Add push notification",
            CreateBtnIcon: AddIcon,
            createBtnFun: () => navigate("?type=form"),
            columns: [
              { accessor: "id", Header: "#" },
              { accessor: "notificationEN", Header: "Notification message ( EN )" },
              { accessor: "notificationAR", Header: "Notification message ( AR )" },
              { accessor: "sent_at", Header: "Created at" },
              { accessor: "Actions", Header: "Actions" },
            ],
            isSearch: true,
            searchInputPlaceholder: "Search Notifications ",
            isChecked: false,
            total_count: count,
            setFilterObj,
            className: "organization-table system-configurations-table",
            paginationPage: paginationPage,
            handlePageChange,
            rowData: rowData,
            filterList,
            className: "main-table",
            actionsList, //if you want to add custom actions list
            headerChildren,
            details,
            openDeleteModal,
            setOpenDeleteModal,
            handleDeleteRequest,
          }}
          form={{
            initialValues: !id ? {} : initialValues(details, supportedLang),
            structure: () => structure(supportedLang),
            validationSchema: validationSchema,
            deleteFun: deleteFun,
            title: "Add notification",
            submitTitle: "Add",
            onSubmit: handelSubmit,
            className: { form: "main-form", actionButton: "main-form-buttons" },
          }}
          modalComponents={modalComponents}
        />
      </Box>
    </>
  );
};

export default PushNotificationsTemplate;
