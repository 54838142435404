import axios from "axios";
import {
  fileUploadLoading,
  fileUploadOnServerLoading,
  fileUploadSuccess,
  getActiveIndex,
} from "./Action";
import { reduxRequest } from "common/utils/reduxRequest";
import { store } from "services/store";
const token = localStorage.getItem("TOKEN");
export const getPresignedUrlRequest = ({ ...variables }) => {
  const requestData = {
    ...variables,
    isLoading: fileUploadLoading,
    // successFunction: fileUploadSuccess,
    loadingType: "upload",
    url: "/generate_presigned_url",
    method: "post",
    data: variables.body,
    action: (data) => {
      const res = data?.data;
      store.dispatch(
        uploadRequest({
          presigned_url: res[0]?.presigned_url,
          file: variables.file,
          fileUrl: res[0]?.file_url,
          updateData: variables.updateData,
          action: variables.action
        })
      );
    },
  };
  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
export const uploadRequest = ({ ...variables }) => {
  const requestData = {
    ...variables,
    isLoading: fileUploadOnServerLoading,
    loadingType: "uploadOnServer",
    url: variables.presigned_url,
    data: variables.file,
    method: "put",
    action: () => {
      store.dispatch(fileUploadSuccess(variables?.fileUrl));
      variables?.updateData(variables.fileUrl);
      // set token in headers after uploading success
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      delete axios.defaults.headers.common["Content-Type"];
      variables?.action && variables?.action()

    },
  };
  delete axios.defaults.headers.common["Authorization"];
  axios.defaults.headers.common["Content-Type"] = variables?.file?.type;

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};