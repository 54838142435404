import { Stack, Rating, Typography } from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import CustomerReviewsTemplate from "templates/products/customerReviews";
import { useRef } from "react";
import CustomerReviewCard from "components/Organisms/products/customerReview/customerReviewCard";
import { getCustomersReviewsRequest } from "services/modules/products";
import dayjs from "dayjs";

export default function CustomerReviews({ load, details }) {
  const dispatch = useDispatch();

  const list = useSelector((state) => state.products.reviews);
  const count = useSelector((state) => state.products.count);

  const [tableData, setTableData] = useState({});
  const [selectedRow, setSelectedRow] = useState({});
  const [page, setPage] = useState(1);

  let [searchParams] = useSearchParams();
  const id = searchParams.get("id");

  const [filterObj, setFilterObj] = useState(null);

  const columns = [
    { accessor: "id", Header: "#" },
    { accessor: "reviewer_name", Header: "Customer Name", sort: true },
    { accessor: "rating", Header: "Rating", sort: true },
    { accessor: "comment", Header: "Review", sort: true },
    { accessor: "date", Header: "Date", sort: true },
  ];

  const actionsList = ({ index, data }) => [];

  const filterList = [];

  const rowData = (data) => {
    setSelectedRow(data);
  };

  const handlePageChange = (page) => {
    setPage(page);
    setFilterObj((prev) => {
      return { ...prev, page_number: page };
    });
  };

  //review card
  const [anchorEl, setAnchorEl] = useState(null);
  const anchorRef = useRef(null);
  const open = Boolean(anchorEl);
  const handleClick = (event, index, data) => {
    anchorEl == index ? setAnchorEl(null) : setAnchorEl(index);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const mappedData = list?.map((data, index) => {
    return {
      ...data,
      rating: (
        <Rating
          name="read-only"
          value={data?.rating}
          readOnly
          precision={0.5}
          size="small"
        />
      ),
      comment: (
        <Stack direction="row" sx={{ position: "relative" }}>
          <Typography nowrap>{data?.comment?.slice(0, 40)} ....</Typography>
          <CustomerReviewCard
            index={index}
            data={data}
            open={open}
            anchorRef={anchorRef}
            anchorEl={anchorEl}
            handleClose={handleClose}
            handleClick={handleClick}
          />
        </Stack>
      ),
      date: <>{dayjs(data?.created_at).format("DD-MM-YYYY")}</>,
    };
  });

  const headerChildren = (
    <Stack direction="row" alignItems="center" sx={{ mr: 5 }}>
      <Rating
        name="read-only"
        value={details?.avg_rate}
        readOnly
        precision={0.5}
        size="large"
      />
      <Typography fontSize="24px">{details?.avg_rate}</Typography>
      <Typography fontSize="24px">({details?.reviews_count ?? 0})</Typography>
    </Stack>
  );
  const title = <Stack sx={{ px: 3 }}>Customer reviews</Stack>;

  const getList = async (filterObj) => {
    const requestData = {
      params: {
        page_size: 10,
        page_number: 1,
        keyword: "",
        sort_by: "id",
        product_id: id,
        ...filterObj,
      },
    };
    dispatch(getCustomersReviewsRequest(requestData));
  };

  useEffect(() => {
    if (filterObj) getList(filterObj);
  }, [filterObj]);

  useEffect(() => {
    rowData({ ...tableData });
  }, [tableData]);

  return (
    <CustomerReviewsTemplate
      tableData={tableData}
      list={mappedData}
      columns={columns}
      setTableData={setTableData}
      setFilterObj={setFilterObj}
      filterList={filterList}
      headerChildren={headerChildren}
      actionsList={actionsList}
      rowData={rowData}
      handlePageChange={handlePageChange}
      page={page}
      count={count}
      title={title}
    />
  );
}
