import { makeFilterString } from "../../utils/Helper";
import { GetTagsSuccess, GetTagDetailsSuccess, GetTagsLoading, GetScrollVendorsSuccess } from "./Action";

import { reduxRequest } from "common/utils/reduxRequest";

export const getTagsRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: GetTagsLoading,
    loadingType: "list",
    successFunction: GetTagsSuccess,
    url: `/admin/lookups/tags?${makeFilterString(variables?.params)}`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const getScrollTagsRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: GetTagsLoading,
    loadingType: "filter-tags-list",
    successFunction: GetScrollVendorsSuccess,
    url: `/admin/lookups/tags?${makeFilterString(variables?.params)}`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const createTagRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: GetTagsLoading,
    loadingType: "add",
    action: variables?.action,
    url: `/admin/lookups/tags`,
    method: "post",
    data: variables?.body,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const getTagDetailsRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: GetTagsLoading,
    loadingType: "details",
    successFunction: GetTagDetailsSuccess,
    url: `/admin/lookups/tags/${variables?.tag_id}`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const deleteTagRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: GetTagsLoading,
    loadingType: "delete",
    action: variables?.action,
    url: `/admin/lookups/tags/${variables?.id}`,
    method: "delete",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const updateTagRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: GetTagsLoading,
    loadingType: "update",
    url: `/admin/lookups/tags/${variables?.id}`,
    action: variables?.action,
    data: variables?.body,
    method: "put",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
