import { Stack, Typography } from '@mui/material'
import { useThemePalette } from 'common/hooks/theme_palette'
import React from 'react'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';

export default function ViewComplain({ click }) {
    const { graySeven } = useThemePalette()
    return <>
        <Stack sx={{ ml: 'auto', cursor: 'pointer' }} direction='row' gap='4px' onClick={_ => click()} >
            <VisibilityOutlinedIcon />
            <Typography
                color={graySeven}
                fontSize='14px'
                sx={{
                    textDecoration: 'underline',
                }}
            >
                View Complain
            </Typography>
        </Stack>
    </>
}
