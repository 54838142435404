import Types from "./Types";

const INIT_STATE = {
    customerComplaints: [],
    customerComplaint: {},
    count: 0,
    load: null,
    error: null,
};
export function customerComplaintsReducer(state = INIT_STATE, action) {
    const { type, payload } = action;
    switch (type) {
        case Types.GET_CUSTOMER_COMPLAINTS_SUCCESS: {
            return {
                ...state,
                customerComplaints: payload?.data?.order_complaints,
                count: payload?.extra?.total_count,

            }
        }
        case Types.GET_CUSTOMER_COMPLAINT_DETAILS_SUCCESS: {
            return {
                ...state,
                customerComplaint: payload?.data?.order_complaint
            }
        }

        case Types.UPDATE_CUSTOMER_COMPLAINT_SUCCESS: {
            return {
                ...state,
                customerComplaints: state?.customerComplaints.map((customer) => {
                    if (customer.id === payload?.data?.order_complaint?.id) {
                        return payload?.data?.order_complaint;
                    }
                    return customer;
                }),
            }
        }

        default:
            return state
    }
}