import { makeFilterString } from "services/modules/utils/Helper";
import {
  CustomersLoading, DeleteCustomerSuccess, GetCustomerDetailsSuccess, GetCustomersSuccess, GetFollowersSuccess, GetFollowingSuccess, UpdateCustomerSuccess,

} from "./Action";

import { reduxRequest } from "common/utils/reduxRequest";

export const getCustomersRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: CustomersLoading,
    loadingType: "list",
    successFunction: GetCustomersSuccess,
    url: `/admin/customers${makeFilterString(variables?.params)}`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const getCustomerDetailsRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: CustomersLoading,
    loadingType: "details",
    successFunction: GetCustomerDetailsSuccess,
    action: variables?.action,
    url: `/admin/customers/${variables?.id}`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const updateCustomerRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: CustomersLoading,
    loadingType: "update",
    successFunction: UpdateCustomerSuccess,
    action: variables?.action,
    url: `/admin/customers/${variables?.id}`,
    data: variables?.body,
    method: "put",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};


export const deleteCustomerRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: CustomersLoading,
    loadingType: "delete",
    successFunction: DeleteCustomerSuccess,
    action: variables?.action,
    url: `/admin/customers/${variables?.id}`,
    method: "delete",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const getFollowersListRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: CustomersLoading,
    loadingType: "get-followers",
    successFunction: GetFollowersSuccess,
    action: variables?.action,
    url: `/admin/customers/follows/followers/${variables?.id}${makeFilterString(variables?.params)}`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const getFollowingListRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: CustomersLoading,
    loadingType: "get-following",
    successFunction: GetFollowingSuccess,
    action: variables?.action,
    url: `/admin/customers/follows/followings/${variables?.id}${makeFilterString(variables?.params)}`,
    method: "get",
  };
  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

