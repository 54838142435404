import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Loader from "components/Atoms/SharedComponents/Loader/Loader";

import {
  getBetweenZonesFeesRequest,
  createBetweenZonesFeeRequest,
  getBetweenZonesFeeDetailsRequest,
  updateBetweenZonesFeeRequest,
  deleteBetweenZonesFeeRequest,
} from "services/modules/cities/feesBetweenZones";
import {
  getCitiesRequest,
  getScrollCitiesRequest,
} from "services/modules/cities/cities";
import {
  getScrollZonesRequest,
  getZonesRequest,
} from "services/modules/cities/zones";
import BetweenZonesFeesTemplate from "templates/between-zones-fees";
import { toast } from "react-toastify";

function BetweenZonesFeesPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const type = searchParams.get("type");
  const [selectedRow, setSelectedRow] = useState({});

  const cityId = selectedRow?.formikData?.values?.city_id;

  const list = useSelector((state) => state.feesBetweenZones.betweenZonesFees);
  const details = useSelector(
    (state) => state.feesBetweenZones.betweenZonesFee
  );
  const count = useSelector((state) => state.feesBetweenZones.count);
  const load = useSelector((state) => state.feesBetweenZones.load);
  const fulfillmentCenters = useSelector((state) => state.cities.cities);
  const zones = useSelector((state) => state.zones.zones);
  const filterCitites = useSelector((state) => state.cities.filterCitites);
  const cititesCount = useSelector((state) => state.cities.listCount);
  const cititesLoading = useSelector((state) => state.cities.load);
  const zonesCount = useSelector((state) => state.zones.listCount);
  const zonesLoad = useSelector((state) => state.zones.load);

  const filterZones = useSelector((state) => state.zones.filterZones)?.filter(
    (zone) => zone?.google_city?.id == cityId
  );
  //permissions
  const currentUser = useSelector(
    (state) => state.authenticate.currentUserProfile
  );
  const permissions = useSelector(
    (state) =>
      state.authenticate.currentUserProfile.permissions.between_zones_fees
  );
  const isSuperAdmin = currentUser?.privilege == "super_admin";

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [page, setPage] = useState(1);
  const [filterObj, setFilterObj] = useState(null);

  const handlePageChange = (page) => {
    setPage(page);
    setFilterObj((prev) => {
      return { ...prev, page_number: page };
    });
  };

  const getDetails = async () => {
    dispatch(getBetweenZonesFeeDetailsRequest({ id }));
  };

  const getList = async (filterObj) => {
    const requestData = {
      params: {
        page_size: 10,
        page_number: 1,
        keyword: "",
        sort_by: "id",
        ...filterObj,
      },
    };
    dispatch(getBetweenZonesFeesRequest(requestData));
  };

  const getCities = (filterObj) => {
    const requestData = {
      params: {
        page_size: 10, //to get all data
        page_number: 1,
        keyword: "",
        sort_by: "id",
        ...filterObj,
      },
    };
    dispatch(getScrollCitiesRequest(requestData));
  };

  // mappedData use for create custom cell to concat multi values or navigate button
  const mappedData = list?.map((data, index) => {
    return {
      ...data,
    };
  });

  const rowData = (data) => {
    setSelectedRow(data);
  };

  const breadcrumbs = [
    {
      active: true,
      path: "/",
      title: "Home",
    },
    {
      active: !type ? false : true,
      path: "/between-zones-fees",
      title: "Between zones fees",
    },
    type && {
      active: false,
      title:
        type === "details"
          ? "Between zones fees details"
          : id
          ? "Edit Between zones fees"
          : "Add Between zones fees",
    },
  ];

  const handelSubmit = (data, formik) => {
    const action = () => {
      searchParams.delete("id");
      searchParams.delete("type");
      navigate("/between-zones-fees");
      !id && formik?.resetForm();
      toast.success(
        ` ${id ? "City" : "New City"} has been ${
          id ? "updated" : "created"
        } successfully`
      );
    };
    const requestData = {
      body: {
        between_zones_fee: data,
      },
      action,
    };
    if (id) {
      //update call
      dispatch(updateBetweenZonesFeeRequest({ ...requestData, id }));
    } else {
      //create call
      dispatch(createBetweenZonesFeeRequest(requestData));
    }
  };

  const filterList = [
    {
      type: "filter",
      placeholder: "City",
      onChange: (value) => {
        setFilterObj((s) => {
          return { ...s, city_id: value?.value, page_number: 1 };
        });
      },
      onMenuOpen: () => {
        filterCitites?.length == 0 && getCities({});
      },
      options: filterCitites && filterCitites,
      getList: getCities,
      applySearchFromRequest: true,
      count: cititesCount,
    },
  ];

  const handleDelete = (id) => {
    // const id = selectedRow?.rowData?.id;
    //action to be called after successfull deletion
    const action = () => {
      //if list.length == 1 & page !== 1 -> setPage(page-1) & fetch
      if ((list.length === 1) & (page !== 1)) {
        setPage(page - 1);
        setFilterObj({
          ...filterObj,
          page_number: page - 1,
        });
        getList({ ...filterObj, page_number: page - 1 }); //temporary until the backend sends the data correctly so I can update the state instead of re-fetching
      } else {
        getList(filterObj);
      }
      type === "details" && navigate("/between-zones-fees");
      toast.success("Zone has been deleted successfully");
    };
    const requestData = {
      id,
      action, //dispatched on success
    };
    dispatch(deleteBetweenZonesFeeRequest(requestData));
    setOpenDeleteModal(false);
  };

  //list of actions to be displayed when clicked in the 3 dots
  const actionsList = ({ index, data }) => {
    return [
      {
        condition: () => {
          return true;
        },
        click: () => {
          navigate(`?type=details&id=${data?.id}`);
        },
        text: "View",
      },
      {
        condition: () => {
          return isSuperAdmin || permissions?.can_update;
        },
        click: () => {
          navigate(`?type=form&id=${data?.id}`);
        },
        text: "Edit",
      },
      {
        condition: () => {
          return isSuperAdmin || permissions?.can_delete;
        },
        click: () => {
          setOpenDeleteModal(true);
        },
        text: "Delete",
      },
    ];
  };

  //if you need more buttons in the header beside the add button
  const headerChildren = <></>;

  //if more modals needed, pass them to the modalComponents as below
  const modalComponents = [];

  // listing all cities
  useEffect(() => {
    if (filterObj) getList(filterObj);
  }, [filterObj]);

  // details of a single city
  useEffect(() => {
    if (id) getDetails();
  }, [id]);

  // useEffect(() => {
  //   const isEmpty = Object.keys(details)?.length == 0;
  //   !isEmpty && setCityId(details?.city?.id);
  // }, [details]);
  //show zones based on the selected city when adding a new between zone

  console.log(details, "selectedRow?.formikData?.values?.city_id");
  const handleCityChange = (value) => {
    // setCityId(value);
  };
  const getZonesScoll = () => {
    const requestData = {
      params: {
        page_size: 10, //to get all data
        page_number: 1,
        keyword: "",
        city_id: cityId,
      },
    };
    dispatch(getScrollZonesRequest(requestData));
  };
  const options = {
    cities: {
      options: [details?.city, ...filterCitites],
      onOpen: filterCitites?.length <= 1 && getCities,
      isLoading:
        cititesLoading?.loading && cititesLoading?.type == "filter-list-cities",
      getList: getCities,
      count: cititesCount,
    },
    zones: {
      options: [
        cityId == details?.city?.id && details?.destination_zone,
        cityId == details?.city?.id && details?.origin_zone,
        ...filterZones,
      ]?.filter((item) => item),
      onOpen: filterZones?.length <= 1 && getZonesScoll,
      isLoading: zonesLoad?.loading && zonesLoad?.type == "filter-zones-list",
      getList: getZonesScoll,
      count: zonesCount,
    },
  };

  return (
    <>
      <Loader open={load?.loading} />
      <BetweenZonesFeesTemplate
        filterObj={filterObj}
        setFilterObj={setFilterObj}
        data={mappedData}
        count={count}
        paginationPage={page}
        handlePageChange={handlePageChange}
        rowData={rowData}
        details={details}
        breadcrumbs={breadcrumbs}
        filterList={filterList}
        handelSubmit={handelSubmit}
        actionsList={actionsList}
        headerChildren={headerChildren}
        openDeleteModal={openDeleteModal}
        setOpenDeleteModal={setOpenDeleteModal}
        handleDeleteRequest={handleDelete}
        modalComponents={modalComponents}
        fulfillmentCenters={fulfillmentCenters}
        zones={zones}
        can_create={isSuperAdmin || permissions?.can_create}
        handleCityChange={handleCityChange}
        options={options}
        cityId={cityId}
      />
    </>
  );
}

export default BetweenZonesFeesPage;
