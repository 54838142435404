import { Typography } from '@mui/material'
import React from 'react'

export default function TextMessage({ message }) {
    return <>
        <Typography component="p" sx={{
            overflowWrap: 'break-word'
        }}>
            {message}
        </Typography>
    </>
}
