import { Box, Button, Stack, Typography } from "@mui/material";
import { useThemePalette } from "common/hooks/theme_palette";
import React, { useState } from "react";
import { icons } from "../../../../../assets/AssetHelper";
import RejectionModel from "../Model/rejectionModel";
import TextFieldInput from "../../../Input/TextField/TextFieldInput";
import CModel from "../../Model/CModel";

export default function Status({ data, setOpenRejection, status, key }) {
  const {
    backgroundGreen,
    backgroundRed,
    textGreen,
    textRed,
    yellow,
    backgroundYellow,
    grayOne,
    oliveGreen,
  } = useThemePalette();
  return (
    <Stack
      key={key}
      direction="row"
      columnGap="8px"
      alignedItems={"center"}
      mb={-2}
    >
      <Stack gap={1}>
        <Typography
          mt={2}
          width="95px"
          height="24px"
          textAlign={"center"}
          bgcolor={
            status === "active" || status === "approved"
              ? backgroundGreen
              : status === "pending_approval"
              ? backgroundYellow
              : backgroundRed
          }
          color={
            status === "active" || status === "approved"
              ? textGreen
              : status === "pending_approval"
              ? yellow
              : textRed
          }
          fontFamily="Jost"
        >
          {status === "pending_approval"
            ? "Pending"
            : status === "deactivated"
            ? "suspended"
            : status}
        </Typography>
        <Stack direction="row" alignedItems={"start"}>
          {status === "rejected" ? (
            <Typography
              fontSize={12}
              color={grayOne}
              sx={{ cursor: "pointer" }}
              onClick={() => {
                setOpenRejection({
                  action: true,
                  reason: data?.details?.rejection_reason,
                  type: "view",
                });
              }}
            >
              <img src={icons?.eyeIcon} alt={"eye"} /> view reason
            </Typography>
          ) : (
            <br />
          )}
        </Stack>
      </Stack>
    </Stack>
  );
}
