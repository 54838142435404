import { Box, Stack, Typography, Grid } from "@mui/material";
import { icons, images } from "assets/AssetHelper";
import * as dayjs from "dayjs";

export const detailsData = ({ data }) => [
  {
    head: "Images",
    icon: icons?.media,
    details: [
      {
        name: (
          <Grid
            Grid
            item
            xs={12}
            sx={{
              border: " 1px dashed rgba(0, 0, 0, 0.25)",
              borderRadius: "10px",
              position: "relative",
              minHeight: "200px",
            }}
            onDragOver={(e) => e.preventDefault()}
          >
            <Stack
              justifyContent="center"
              alignItems="center"
              // height='100%'
              minHeight="214px" // Set a fixed height for the stack
            >
              <Box
                component="img"
                src={data?.logo}
                alt="img"
                width={"35%"}
                height={"100%"}
                maxHeight="214px"
                sx={{
                  borderRadius: "10px",
                }}
              />
            </Stack>
          </Grid>
        ),
      },
    ],
  },
  {
    head: "Details",
    icon: icons?.detailsIcon,
    details: [
      {
        label: "Vendor Name(EN)",
        name: data?.localized_data?.en?.name,
      },
      {
        label: "Vendor Name(AR)",
        name: data?.localized_data?.ar?.name,
      },
      {
        label: "Email",
        name: data?.details?.email,
      },
      {
        label: "Phone number",
        name: `+${data?.details?.country_code}${data?.details?.phone_number}`,
      },
      {
        label: "Tags",
        name: data?.tags && (
          <Stack direction="row" gap="15px">
            {data?.tags?.split(",")?.map((tag) => (
              <Box borderRadius="4px" border=" 1px solid #E6E6E6" p="5px 14px">
                <Typography>{tag}</Typography>
              </Box>
            ))}
          </Stack>
        ),
      },
    ],
  },
  {
    head: "Locations",
    icon: icons.locationOutlined,
    details: data?.location?.map((location, i) => {
      return {
        name: (
          <Stack backgroundColor={"#FCFCFC"} key={i}>
            <Stack p={3}>
              <Stack gap={2}>
                <Stack>
                  <Typography fontSize="16px" fontFamily="Jost" color={"#9F9F9F"}>
                    City
                  </Typography>
                  <Typography fontSize="20px" fontFamily="Jost">
                    {location?.google_city?.name}
                  </Typography>
                </Stack>
                <Stack>
                  <Typography fontSize="16px" fontFamily="Jost" color={"#9F9F9F"}>
                    Location
                  </Typography>
                  <Typography fontSize="20px" fontFamily="Jost">
                    {location?.location}
                  </Typography>
                </Stack>
                <Stack>
                  <Typography fontSize="16px" fontFamily="Jost" color={"#9F9F9F"}>
                    Detailed Address
                  </Typography>
                  <Typography fontSize="20px" fontFamily="Jost">
                    {location?.detailed_address}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        ),
      };
    }),
  },
];
