import { Stack } from "@mui/material";
import CTable from "../Table/CTable";
import { useState, useEffect } from "react";

function TableUse({
  isChecked = false,
  total_count,
  columnsData = [],
  list = [{ id: 0 }],
  listTitle,
  isSearch,
  searchInputPlaceholder,
  isFilter,
  setFilterObj,
  setTableData,
  createBtnTitle,
  createBtnFun,
  CreateBtnIcon,
  filterList,
  className,
  handlePageChange,
  headerChildren,
  paginationPage,
  emptyListMsg,
  isDetails,
  path,
}) {
  const [checked, setChecked] = useState([false]);
  const [pageNumber, setPageNumber] = useState(1);

  const viewData = (data) => {
    setTableData((prevData) => {
      return {
        ...prevData,
        pageNumber,
        rowData: data,
        checkedData: checked,
      };
    });
  };

  useEffect(() => {
    setTableData((prevData) => {
      return {
        ...prevData,
        checkedData: checked,
      };
    });
  }, [checked]);

  useEffect(() => {
    setFilterObj((data) => {
      return {
        ...data,
        page_number: pageNumber,
      };
    });
  }, [pageNumber]);

  const columns = columnsData;
  // console.log(total_count)
  // console.log(list, "list");
  return (
    <Stack>
      <CTable
        className={className}
        selectedData={viewData}
        isFilter={isFilter}
        filterList={filterList}
        isSearch={isSearch}
        searchInputPlaceholder={searchInputPlaceholder}
        setFilterObj={setFilterObj}
        Data={list}
        Columns={columns}
        Title={listTitle}
        currentpage={pageNumber}
        setPageNumber={setPageNumber}
        totalPage={Math.ceil(total_count / 10)}
        total_count={total_count}
        isChecked={isChecked}
        checked={checked}
        setChecked={setChecked}
        createBtnTitle={createBtnTitle}
        createBtnFun={createBtnFun}
        CreateBtnIcon={CreateBtnIcon}
        handlePageChange={handlePageChange}
        headerChildren={headerChildren}
        paginationPage={paginationPage}
        emptyListMsg={emptyListMsg}
        isDetails={isDetails}
        path={path}
      />
    </Stack>
  );
}
export default TableUse;
