import DetailsUse from "components/Atoms/SharedComponents/CRUD/details-use";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { getProductDetailsRequest } from "services/modules/products";
import { detailsData } from "templates/products/productsListing/details";

export default function Details({ details }) {
  // const dispatch = useDispatch();
  // let [searchParams] = useSearchParams();
  // const id = searchParams.get("id");

  // const getDetails = async () => {
  //   dispatch(getProductDetailsRequest({ id }));
  // };
  // useEffect(() => {
  //   if (id) getDetails();
  // }, [id]);
  return (
    <DetailsUse
      data={{
        deleteBtn: false,
        editBtn: false,
        data: {
          list: detailsData({ data: details }),
          checkBox: [
            { label: "Is Wrappable", checked: details?.is_wrappable },
            { label: "Is Active", checked: details?.is_visible },
          ],
        },
      }}
    />
  );
}
