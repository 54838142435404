import { makeFilterString } from "../utils/Helper";
import {
  GetGiftCardDetailsSuccess,
  GetGiftCardsSuccess,
  GiftCardsLoading,
} from "./Action";

import { reduxRequest } from "common/utils/reduxRequest";

export const getGiftCardsRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: GiftCardsLoading,
    loadingType: "list cards",
    successFunction: GetGiftCardsSuccess,
    url: `/admin/gift_cards${makeFilterString(variables?.params)}`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const createGiftCardRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: GiftCardsLoading,
    loadingType: "create",
    action: variables?.action,
    url: `/admin/gift_cards`,
    data: variables?.body,
    method: "post",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};


export const getGiftCardDetailsRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: GiftCardsLoading,
    loadingType: "get gift card",
    successFunction: GetGiftCardDetailsSuccess,
    action: variables?.action,
    url: `/admin/gift_cards/${variables?.id}`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const updateGiftCardRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: GiftCardsLoading,
    loadingType: "update gift card",
    action: variables?.action,
    url: `/admin/gift_cards/${variables?.id}`,
    data: variables?.body,
    method: "put",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const deleteGiftCardRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: GiftCardsLoading,
    loadingType: "delete gift card",
    action: variables?.action,
    url: `/admin/gift_cards/${variables?.id}`,
    method: "delete",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

