import Types from "./Types";

export const GetGiftCardsSuccess = (payload) => ({
  type: Types.GET_GIFT_CARDS_SUCCESS,
  payload,
});

export const GiftCardsLoading = (payload) => ({
  type: Types.GIFT_CARDS_LOADING,
  payload,
});


export const GetGiftCardDetailsSuccess = (payload) => ({
  type: Types.GET_GIFT_CARD_DETAILS_SUCCESS,
  payload,
});

