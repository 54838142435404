import PageHeader from "components/Molecules/Products/AddProduct/header";

import { useState } from "react";
import CustomAccordion from "components/Molecules/Accordion/customAccordion";

export default function EditVendorTemplate({ breadcrumbs, data, type }) {
    const [expandedAccordion, setExpandedAccordion] = useState(null);

    // console.log("title type is",type);
    return (
      <>
        <PageHeader
          title={type === "edit" ? "Edit Vendor" : "Add Vendor"}
          breadcrumbs={breadcrumbs}
        />
        <CustomAccordion
          expandedAccordion={expandedAccordion}
          setExpandedAccordion={setExpandedAccordion}
          data={data}
        />
      </>
    );
}
