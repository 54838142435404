const wrappersTypes = {
  // WRAPPERS
  // list
  GET_WRAPS_SUCCESS: "GET_WRAPS_SUCCESS",
  WRAPS_LOADING: "GET_WRAPS_LOADING",
  //details
  GET_WRAPS_DETAILS_SUCCESS: "GET_WRAPS_DETAILS_SUCCESS",
  // create
  ADD_WRAPS_SUCCESS: "ADD_WRAPS_SUCCESS",
  // Delete
  DELETE_WRAPS_SUCCESS: "DELETE_WRAPS_SUCCESS",
  // update
  UPDATE_WRAPS_SUCCESS: "UPDATE_WRAPS_SUCCESS",
  GET_SCROLL_WRAPS_SUCCESS: "GET_SCROLL_WRAPS_SUCCESS",
};

export default wrappersTypes;
