import { Button } from "@mui/material";
import { useThemePalette } from "common/hooks/theme_palette";
import { useFormikContext } from "formik";

export default function SubmitButton({
  children = "SUBMIT",
  sx,
  onSubmit,
  ...otherProps
}) {
  const { handleSubmit, isSubmitting } = useFormikContext();
  const { oliveGreen } = useThemePalette();

  return (
    <Button
      type="submit"
      onClick={onSubmit ? onSubmit : handleSubmit}
      variant="contained"
      sx={{
        textTransform: "none",
        fontSize: "20px",
        p: 2,
        backgroundColor: oliveGreen,
        "&:hover": {
          backgroundColor: oliveGreen,
        },
        ...sx,
      }}
      disabled={isSubmitting}
      {...otherProps}
    >
      {children}
    </Button>
  );
}
