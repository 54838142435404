/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GetChatRoomRequest, GetChatRoomsRequest, createChatRoomRequest } from 'services/modules/chat';
import { SendMessage, handleFiles } from 'services/modules/chat/LiveChat';
import { onValue, ref } from "firebase/database";
import { db } from "services/utils/firebase";
import setAuthToken from 'services/modules/utils/handel_api';
import Loading from 'components/Atoms/SharedComponents/Loading/Loading';
import MessengerTemplate from 'templates/chat/messengerTemplate';
import { getAdminUser, getMessages, searchChats, visitChatRoomWhenClick, } from './utils';
import { useSearchParams } from 'react-router-dom';

export default function MessengerPage() {
    const token = localStorage.getItem("token");
    setAuthToken(token);

    const dispatch = useDispatch()
    const { chatRooms, searchChatRooms, load, searchCount, searchChatRoomsLoading } = useSelector((state) => state.chatRooms);
    const { loadFile } = useSelector((state) => state.files);
    const count = useSelector((state) => state.chatRooms.count);
    const [messagesList, setMessagesList] = useState([]);
    const [message, setMessage] = useState("");
    const [FilesToSend, setFilesToSend] = useState(null);
    const isStore = localStorage?.getItem('user_type') == 'Store'
    const [filterObj, setFilterObj] = useState(null);
    let chatId = localStorage.getItem("chatId");
    const uid = localStorage?.getItem('firebase_uid')
    const [chatRoomData, setChatRoomData] = useState()
    const [searchChatsFilterObj, setSearchFilterObj] = useState({
        keyword: "",
        hide_details: true,
        page_size: 10,
    });
    const [isSearchOpen, setIsSearchOpen] = useState(false)
    const [page, setPage] = useState(1)
    const [firstMessageCount, setFirstMessageCount] = useState(0);
    let [searchParams] = useSearchParams();
    const id = searchParams.get("id");
    // get chat rooms list
    const getList = async (filterObj) => {
        const action = (data) => {
            if (!(data?.data?.chat_room?.length >= data?.extra?.total_count)) {
                setFilterObj((prev) => {
                    return {
                        page_size: (data?.extra?.page_number + 1) * data?.extra?.page_size
                    };
                });
            }
        }
        const requestData = {
            params: {
                page_number: page,
                page_size: 10,
                ...filterObj,
            },
            action
        };
        !isStore && dispatch(GetChatRoomsRequest(requestData));
    };

    useEffect(() => {
        !isStore && getList(filterObj)
    }, []);

    useEffect(() => {
        const extraOptionsRef = ref(db, `/users/${uid}`);
        onValue(extraOptionsRef, async (snapshot) => {
            setChatRoomData(snapshot?.val())
        })
    }, [])


    useEffect(() => {
        console.log(firstMessageCount, 'firstMessageCount');
        console.log(!isStore && firstMessageCount == 1);
        if ((!isStore && firstMessageCount == 1)) {
            getList(filterObj)
        }
    }, [isStore, firstMessageCount]);
    useEffect(() => {
        if ((chatId && chatRoomData?.last_chat_room_id != +chatId && !isStore)) {
            getList(filterObj)
        }
    }, [isStore, chatRoomData, chatId]);

    useEffect(() => {
        isStore && getAdminUser({ setFirstMessageCount, setMessagesList, messagesList, dispatch, setMessage })
    }, []);

    useEffect(() => {
        searchChats({ searchChatsFilterObj, dispatch, isSearchOpen })
    }, [searchChatsFilterObj, isSearchOpen])

    useEffect(() => {
        getMessages({ setMessagesList, messagesList });
    }, []);

    const callVisitChatRoom = ({ messages_url, chat_id, name, image }) => {
        visitChatRoomWhenClick({ messages_url, chat_id, name, image, setFirstMessageCount, setMessagesList, messagesList, dispatch, setMessage })
    }

    const getChatRoom = async (id) => {
        const action = (data) => {
            visitChatRoomWhenClick({
                messages_url: data?.data?.chat_room?.messages_url,
                chat_id: data?.data?.chat_room?.id,
                name: data?.data?.chat_room?.name,
                image: data?.data?.chat_room?.image,
                setFirstMessageCount,
                setMessagesList,
                messagesList,
                dispatch,
                setMessage
            })
        }
        const requestData = {
            chatId: id,
            action
        };
        !isStore && dispatch(GetChatRoomRequest(requestData));
    };

    useEffect(() => {
        id && getChatRoom(id)
    }, [id])

    const createChatRoom = ({ userId, userType = 'Store' }) => {
        const action = (data) => {
            visitChatRoomWhenClick({
                messages_url: data?.data?.chat_room?.messages_url,
                chat_id: data?.data?.chat_room?.id,
                name: data?.data?.chat_room?.name,
                image: data?.data?.chat_room?.image,
                setFirstMessageCount,
                setMessagesList,
                messagesList,
                dispatch,
                setMessage
            })
        }
        const requestData = {
            body: {
                chat_room: {
                    chat_members: [
                        {
                            user_id: userId,
                            user_type: userType
                        }
                    ]
                }
            },
            action
        }
        dispatch(createChatRoomRequest({ ...requestData }))
    }


    const sendMessage = async () => {
        await SendMessage({
            message,
            file: FilesToSend,
            sender_id: localStorage.getItem("user_id"),
            dispatch,
            type: FilesToSend?.type,
            chat_id: chatId,
        });
        setFirstMessageCount(firstMessageCount + 1);
        setMessage("");
        setFilesToSend(null);
    };

    const handleFileInput = ({ target: { files } }, resetFun) => {
        const updateData = (data) => {
            setFilesToSend(data)
        }
        handleFiles(files && files[0], dispatch, updateData);
        resetFun()
    };

    return <>
        <Loading isLoading={load?.type == 'create chatroom' && load?.loading && page == 1}>
            <MessengerTemplate
                chatRooms={chatRooms}
                sendMessage={sendMessage}
                setMessage={setMessage}
                messagesList={messagesList}
                message={message}
                handleFileInput={handleFileInput}
                Files={FilesToSend}
                setSearchFilterObj={setSearchFilterObj}
                filterObj={filterObj}
                searchChatRooms={searchChatRooms}
                searchChats={searchChats}
                createChatRoom={createChatRoom}
                isStore={isStore}
                visitChatRoom={callVisitChatRoom}
                count={count}
                getList={getList}
                loading={load}
                searchCount={searchCount}
                searchChatRoomsLoading={searchChatRoomsLoading}
                searchChatsFilterObj={searchChatsFilterObj}
                setIsSearchOpen={setIsSearchOpen}
                getChatRoom={getChatRoom}
                loadFile={loadFile}
                setFirstMessageCount={setFirstMessageCount}
                firstMessageCount={firstMessageCount}
                isSearchOpen={isSearchOpen}
                setMessagesList={setMessagesList}
            />
        </Loading>
    </>
}
