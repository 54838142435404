import { Box, Button, Divider, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import { useThemePalette } from "common/hooks/theme_palette";
import React, { useEffect, useState } from "react";
import { icons } from "../../../../../assets/AssetHelper";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { locationsSchema } from "../../../../../common/utils/schemas";
import { useNavigate, useSearchParams } from "react-router-dom";
import LocationModel from "../Model/locationModel";
import {
  addStoreBranchRequest,
  deleteStoreBranchRequest,
  getStoreBranchesRequest,
  updateStoreBranchRequest,
} from "../../../../../services/modules/vendors/storeBranches";
// import { getCitiesRequest } from "../../../../../services/modules/cities/cities";
import { getTagsRequest } from "../../../../../services/modules/lookups/tags";
import { geocode, RequestType, setKey } from "react-geocode";
import * as Geocode from "react-geocode";
import { GoogleMapKey } from "../../../../../services/modules/utils/constant";
import { getScrollCitiesRequest } from "services/modules/cities/cities";
export default function Locations({
  data,
  id,
  initialData,
  registrationFormik,
  storeBranches,
  refOpenLocationsForm,
  edit = false,
}) {
  const { orange, oliveGreen, red } = useThemePalette();
  const [openLocationModal, setOpenLocationModal] = useState(false);
  const [locationsList, setLocations] = useState(JSON.parse(sessionStorage.getItem("locations")));
  const cities = useSelector((state) => state.cities.cities);
  const [address, setAddress] = useState("");
  const navigate = useNavigate();
  const locations = useSelector((state) => state.storeBranches.storeBranches);
  const citiesStores = registrationFormik?.values?.google_cities_stores || storeBranches;
  const { v4: uuidv4 } = require("uuid");
  const [isEdit, setIsEdit] = useState({ action: false, index: null });
  const dispatch = useDispatch();
  const [location, setLocation] = useState();

  const getCities = (filterObj) => {
    const requestData = {
      params: {
        page_size: 10, //to get all data
        page_number: 1,
        keyword: "",
        sort_by: "id",
        // is_fulfillment_center: true,
        ...filterObj,
      },
    };
    dispatch(getScrollCitiesRequest(requestData));
  };

  useEffect(() => {
    const requestData = {
      params: {
        page_size: 10,
        page_number: 1,
        keyword: "",
        sort_by: "id",
      },
    };
    // dispatch(getCitiesRequest(requestData));
    // dispatch(getTagsRequest(requestData));
  }, []);

  const formik = useFormik({
    initialValues: {
      google_city_id: initialData?.city || 0,
      location: null,
      lat: 24.7136,
      long: 46.6753,
      city: null,
    },
    validationSchema: locationsSchema,
    onSubmit: (values) => {
      values = {
        ...values,
      };

      registrationFormik ? saveBulkLocations() : handleOneByOneLocation();

      !initialData && formik.resetForm();
    },
  });

  const handleOpenLocationModal = () => {
    setOpenLocationModal(true);
  };
  const handleOneByOneLocation = () => {
    const requestData = {
      body: {
        google_cities_store: {
          store_id: id,
          ...formik?.values,
        },
      },
      action: () => {
        const requestData = {
          params: {
            page_size: 30, //to get all data
            page_number: 1,
            store_id: id,
          },
        };
        // dispatch(getStoreBranchesRequest(requestData));
      },
    };
    if (isEdit.action) {
      dispatch(
        updateStoreBranchRequest({
          ...requestData,
          store_id: id,
          google_city_id: formik?.values?.google_city_id,
        })
      );
      setIsEdit({ action: false, index: null });
    } else {
      dispatch(addStoreBranchRequest(requestData));
    }
    setOpenLocationModal(false);
  };

  const saveBulkLocations = () => {
    const locations = registrationFormik?.values?.google_cities_stores || [];
    const newValue = {
      google_city_id: formik?.values?.google_city_id,
      lat: formik?.values.lat,
      long: formik?.values?.long,
      location: formik?.values?.location,
      city: formik?.values?.city,
    };
    if (isEdit.action) {
      const updatedArray = [...registrationFormik?.values?.google_cities_stores];
      updatedArray[isEdit.index] = newValue;
      registrationFormik.setValues({
        ...registrationFormik?.values,
        google_cities_stores: updatedArray,
      });
      setIsEdit({ action: false, index: null });
    } else {
      registrationFormik.setValues({
        ...registrationFormik?.values,
        google_cities_stores: locations.concat(newValue),
      });
    }
    setOpenLocationModal(false);
  };
  const handleRemove = (index) => {
    if (registrationFormik) {
      registrationFormik?.values?.google_cities_stores?.splice(index, 1);
      registrationFormik?.setValues({
        ...registrationFormik?.values,
        google_cities_stores: registrationFormik?.values.google_cities_stores,
      });
    }
    // edit case
    else {
      const requestData = {
        action: () => {
          const requestData = {
            params: {
              page_size: 30, //to get all data
              page_number: 1,
              store_id: id,
            },
          };
          // dispatch(getStoreBranchesRequest(requestData));
        },
      };
      dispatch(
        deleteStoreBranchRequest({
          ...requestData,
          store_id: id,
          google_city_id: locations[index].google_city.id,
        })
      );
    }
  };
  const handleEdit = (index, location) => {
    // setOpenLocationModal({state:true, location});
    setLocation(location);
    setOpenLocationModal(true);
    setIsEdit({ action: true, index });
    if (storeBranches) {
      formik?.setValues({
        google_city_id: storeBranches[index]?.google_city.id,
        lat: storeBranches[index]?.lat,
        long: storeBranches[index]?.long,
        city: storeBranches[index]?.google_city.name,
        location: storeBranches[index]?.location,
      });
    } else {
      const initialValues = registrationFormik?.values?.google_cities_stores[index];
      formik?.setValues({
        google_city_id: initialValues.google_city_id,
        lat: initialValues?.lat,
        long: initialValues?.long,
        city: initialValues?.city,
        location: initialValues?.location,
      });
    }
  };
  return (
    <Stack backgroundColor={"white"} className={!edit && "main-form"} mt={1} p={3}>
      <Stack gap={1} flexDirection={"row"} justifyContent={"space-between"}>
        {!edit && <Typography fontSize={28}>Locations</Typography>}
        <Typography
          color={orange}
          sx={{ cursor: "pointer" }}
          fontSize={20}
          onClick={handleOpenLocationModal}
          ref={refOpenLocationsForm}
        >
          {!edit && "+ Add location"}
        </Typography>
      </Stack>

      {citiesStores?.map((location, index) => {
        return (
          <Box
            sx={{
              boxShadow: "0px 0px 6px 0px #0000001A",
              background: "#FCFCFC",
              mb: 1,
            }}
            p={2}
            key={index}
          >
            <Stack flexDirection={"row"} justifyContent={"space-between"}>
              <Stack flexDirection={"row"} alignItems={"start"} gap={1}>
                <Box component={"img"} src={icons.location} width="25px" />
                <Stack>
                  <Typography fontSize={20} fontWeight={500}>
                    {location?.city || location?.google_city?.name}
                  </Typography>
                  <Typography fontSize={18} fontWeight={400}>
                    {location?.location}
                  </Typography>
                </Stack>
              </Stack>
              <Stack flexDirection={"row"} gap={2}>
                <Box
                  component={"img"}
                  src={icons.templateDeleteIcon}
                  width="17px"
                  sx={{ cursor: "pointer" }}
                  onClick={() => handleRemove(index)}
                />
                <Box component={"img"} src={icons.colorEidtIcoon} width="17px" onClick={() => handleEdit(index, location)} />{" "}
              </Stack>
            </Stack>
          </Box>
        );
      })}
      <LocationModel
        openLocationModal={openLocationModal}
        data={location}
        setOpenLocationModal={setOpenLocationModal}
        formik={formik}
        setLocationData={setLocation}
        id={id}
        getCities={getCities}
      />
    </Stack>
  );
}
