import Types from "./Types";

const INIT_STATE = {
  categories: [],
  filterCategories: [],
  category: {},
  count: 0,
  listCount: 0,
  load: null,
  error: null,
};
export function categoriesReducer(state = INIT_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case Types.CATEGORIES_LOADING: {
      return {
        ...state,
        load: payload,
      };
    }
    case Types.GET_CATEGORIES_SUCCESS: {
      return {
        ...state,
        categories: payload?.data?.categories,
        count: payload?.extra?.total_count,
      };
    }
    case Types.ADD_CATEGORIES_SUCCESS: {
      return {
        ...state,
      };
    }
    case Types.GET_CATEGORIES_DETAILS_SUCCESS: {
      return {
        ...state,
        category: payload?.data?.current_category,
      };
    }
    case Types.UPDATE_CATEGORIES_SUCCESS: {
      return {
        ...state,
      };
    }
    case Types.DELETE_CATEGORIES_SUCCESS: {
      return {
        ...state,
      };
    }
    case Types.GET_SCROLL_CATEGORIES_SUCCESS: {
      return {
        ...state,
        filterCategories:
          payload?.extra?.page_number == 1
            ? payload?.data?.categories
            : [...state.filterCategories, ...payload?.data?.categories],
        listCount: payload?.extra?.total_count,
      };
    }
    default:
      return state;
  }
}
