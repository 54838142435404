import * as Yup from "yup";
export const initialValues = (data) => {
  console.log(data, "details");
  return {
    ...data,
    featuredAdEn: data?.localized_data?.en?.banner,
    featuredAdAr: data?.localized_data?.ar?.banner,
    data: data?.data == "products" ? 1 : 2,
    // data: 1
  };
};
export const structure = (_, isClickable) => [
  {
    head: "",
    width: "100%",
    list: [
      {
        name: `featuredAdEn`,
        kind: "upload",
        type: "image",
        label: "Upload Featured Ad(EN)*",
        placeholder: "test",
        text: "file size no more than",
        width: "47%",
      },
      {
        name: `featuredAdAr`,
        kind: "upload",
        type: "image",
        label: "Upload Featured Ad(AR)*",
        placeholder: "test",
        text: "file size no more than",
        width: "47%",
      },
      {
        name: `need_action`,
        kind: "checkbox",
        type: "text",
        label: "Is Clickable",
        width: "12%",
        placeholder: "",
      },
      {
        name: `is_visible`,
        kind: "checkbox",
        type: "text",
        label: "Is visible",
        width: "70%",
        value: "checked",
        placeholder: "Enter the price",
      },
      isClickable && {
        name: `data`,
        kind: "radioBox",
        label: "Data",
        width: "20%",
        options: [
          { label: "Products", value: 1 },
          { label: "Occasions", value: 2 },
        ],
      },
      isClickable && {
        name: `data_id`,
        kind: "input",
        type: "text",
        label: "Data Id",
        width: "50%",
        placeholder: "Write Data Id",
      },
    ],
  },
];

export const validationSchema = () =>
  Yup.object().shape({
    featuredAdEn: Yup.string().required("required"),
    featuredAdAr: Yup.string().required("required"),
    is_visible: Yup.boolean(),
    need_action: Yup.boolean(),
  });
