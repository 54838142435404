const Types = {
  //! cities
  // list
  GET_CITIES_SUCCESS: "GET_CITIES_SUCCESS",
  // details
  GET_CITY_DETAILS_SUCCESS: "GET_CITY_DETAILS_SUCCESS",
  // create
  CREATE_CITY_SUCCESS: "CREATE_CITY_SUCCESS",
  // Delete
  DELETE_CITY_SUCCESS: "DELETE_CITY_SUCCESS",
  // update
  UPDATE_CITY_SUCCESS: "UPDATE_CITY_SUCCESS",
  //loading
  CITIES_LOADING: "CITIES_LOADING",

  //!Zones
  // list
  GET_ZONES_SUCCESS: "GET_ZONES_SUCCESS",
  // details
  GET_ZONE_DETAILS_SUCCESS: "GET_ZONE_DETAILS_SUCCESS",
  // create
  CREATE_ZONE_SUCCESS: "CREATE_ZONE_SUCCESS",
  // Delete
  DELETE_ZONE_SUCCESS: "DELETE_ZONE_SUCCESS",
  // update
  UPDATE_ZONE_SUCCESS: "UPDATE_ZONE_SUCCESS",

  //!Zones
  // list
  GET_BETWEEN_ZONES_FEES_SUCCESS: "GET_BETWEEN_ZONES_FEES_SUCCESS",
  // details
  GET_BETWEEN_ZONES_FEE_DETAILS_SUCCESS: "GET_BETWEEN_ZONES_FEE_DETAILS_SUCCESS",
  // create
  CREATE_BETWEEN_ZONES_FEE_SUCCESS: "CREATE_BETWEEN_ZONES_FEE_SUCCESS",
  // Delete
  DELETE_BETWEEN_ZONES_FEE_SUCCESS: "DELETE_BETWEEN_ZONES_FEE_SUCCESS",
  // update
  UPDATE_BETWEEN_ZONES_FEE_SUCCESS: "UPDATE_BETWEEN_ZONES_FEE_SUCCESS",

};

export default Types;
