import Types from "./Types";

export const GetVendorsSuccess = (payload) => ({
  type: Types.GET_VENDORS_SUCCESS,
  payload,
});

export const GetVendorsLoading = (payload) => ({
  type: Types.VENDORS_LOADING,
  payload,
});

export const AddVendorSuccess = (payload) => ({
  type: Types.ADD_VENDORS_SUCCESS,
  payload,
});

export const DeleteVendorSuccess = (payload) => ({
  type: Types.DELETE_VENDORS_SUCCESS,
  payload,
});

export const UpdateVendorSuccess = (payload) => ({
  type: Types.UPDATE_VENDORS_SUCCESS,
  payload,
});

export const GetVendorDetailsSuccess = (payload) => ({
  type: Types.GET_VENDORS_DETAILS_SUCCESS,
  payload,
});

export const GetScrollVendorsSuccess = (payload) => ({
  type: Types.GET_SCROLL_VENDORS_SUCCESS,
  payload,
});
