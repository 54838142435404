import { makeFilterString } from "../utils/Helper";
import {
  GetRolesSuccess,
  RolesLoading,
  GetRoleDetailsSuccess,
  CreateRoleSuccess,
  DeleteRoleSuccess,
  UpdateRoleSuccess,
  GetResourcesSuccess,
  GetAdminUsersSuccess,
  CreateAdminUserSuccess,
  GetAdminUserDetailsSuccess,
  UpdateAdminUserSuccess,
  DeleteAdminUserSuccess,
  ChangePasswordSuccess,
  GetUserProfileSuccess,
  GetScrollRolesSuccess
} from "./Action";

import { reduxRequest } from "common/utils/reduxRequest";

//! ADMINS
export const getAdminUsersRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: RolesLoading,
    loadingType: "list",
    successFunction: GetAdminUsersSuccess,
    url: `/admin/auth/${makeFilterString(variables?.params)}`,
    method: "get",
    action: variables?.action
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};


export const deleteAdminUserRequest = ({ ...variables }) => {
  console.log(variables)
  const requestData = {
    isLoading: RolesLoading,
    loadingType: "delete user",
    successFunction: DeleteAdminUserSuccess,
    action: variables?.action,
    url: `/admin/auth/${variables?.id}`,
    method: "delete",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const createAdminUserRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: RolesLoading,
    loadingType: "create user",
    successFunction: CreateAdminUserSuccess,
    action: variables?.action,
    url: `/admin/auth/admin_user/register`,
    data: variables?.body,
    method: "post",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const updateAdminUserRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: RolesLoading,
    loadingType: "update user",
    successFunction: UpdateAdminUserSuccess,
    action: variables?.action,
    url: `/admin/auth/${variables?.id}`,
    data: variables?.body,
    method: "patch",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const getAdminUserDetailsRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: RolesLoading,
    loadingType: "get user",
    successFunction: GetAdminUserDetailsSuccess,
    action: variables?.action,
    url: `/admin/auth/${variables.id}`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const changePasswordRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: RolesLoading,
    loadingType: "change password",
    successFunction: ChangePasswordSuccess,
    url: `/admin/auth/passwords/change_password${makeFilterString(variables?.params)}`,
    data: variables?.body,
    action: variables?.action,

    method: "put",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};


//! ROLES
export const getRolesRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: RolesLoading,
    loadingType: "list",
    successFunction: GetRolesSuccess,
    url: `/admin/user_management/role${makeFilterString(variables?.params)}`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const getScrollRolesRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: RolesLoading,
    loadingType: "filter-roles-list",
    successFunction: GetScrollRolesSuccess,
    url: `/admin/user_management/role${makeFilterString(variables?.params)}`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const deleteRoleRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: RolesLoading,
    loadingType: "delete role",
    successFunction: DeleteRoleSuccess,
    action: variables?.action,
    url: `/admin/user_management/role/${variables?.id}`,
    method: "delete",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const createRoleRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: RolesLoading,
    loadingType: "create role",
    successFunction: CreateRoleSuccess,
    action: variables?.action,
    url: `/admin/user_management/role`,
    data: variables?.body,
    method: "post",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const updateRoleRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: RolesLoading,
    loadingType: "update role",
    successFunction: UpdateRoleSuccess,
    action: variables?.action,
    url: `/admin/user_management/role/${variables?.id}`,
    data: variables?.body,
    method: "put",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const getRoleDataRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: RolesLoading,
    loadingType: "get role",
    successFunction: GetRoleDetailsSuccess,
    action: variables?.action,
    url: `/admin/user_management/role/${variables.role_id}`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

//! RESOURCES
export const getResourcesDataRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: RolesLoading,
    loadingType: "list",
    successFunction: GetResourcesSuccess,
    url: `/admin/user_management/resource/${makeFilterString(variables?.params)}`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
}