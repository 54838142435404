const DriverComplaintReasonsTypes= {
// DRIVER COMPLAINT REASONS
    //LOADING
    DRIVER_COMPLAINT_REASONS_LOADING:"DRIVER_COMPLAINT_REASONS_LOADING",
//list
    GET_DRIVER_COMPLAINT_REASONS_SUCCESS:
        "GET_DRIVER_COMPLAINT_REASONS_SUCCESS",
    //details
    GET_DRIVER_COMPLAINT_REASON_DETAILS_SUCCESS:
        "GET_DRIVER_COMPLAINT_REASON_DETAILS_SUCCESS",
    //create
    ADD_DRIVER_COMPLAINT_REASON_SUCCESS: "ADD_DRIVER_COMPLAINT_REASON_SUCCESS",
    //delete
    DELETE_DRIVER_COMPLAINT_REASON_SUCCESS:
        "DELETE_DRIVER_COMPLAINT_REASON_SUCCESS",
    //update
    UPDATE_DRIVER_COMPLAINT_REASON_SUCCESS:
        "UPDATE_DRIVER_COMPLAINT_REASON_SUCCESS"
}
export default DriverComplaintReasonsTypes