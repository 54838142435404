import * as Yup from "yup";
import { useSelector } from "react-redux";
import details from "../../orders/details";

const isStore = localStorage?.getItem("user_type") == "Store";

export const initialValues = (data, tags, approvalStatusOptions) => {
  const tagsArray = data?.tags?.split(", ");

  const selectedTags = tagsArray?.map((name) => {
    const item = tags.find((tag) => tag.name === name);
    return item && { label: name, value: item.id };
  });
  return {
    ...data,
    ...data?.localized_data,
    phone_number: `${data?.details?.country_code}${data?.details?.phone_number}`,
    country_code: data?.details?.country_code,
    image: data?.logo,
    email: data?.details?.email,
    google_cities_stores: data?.location,
    tags: data?.tag_details?.map((option) => {
      return {
        label: option?.name,
        value: option?.id,
      };
    }),
    is_verified: data?.details?.is_verified,
    is_email_verified: data?.details?.is_email_verified,
    profile_status: data?.details?.profile_status,
  };
};

export let structure = (options) => {
  return [
    {
      head: "",
      width: "100%",
      list: [
        {
          name: `image`,
          kind: "upload",
          type: "image",
          label: "Upload Photo",
          placeholder: "test",
          text: "JPG and PNG  file size no more than",
          width: "47%",
        },
        {
          width: "47%",
        },
        {
          name: `en.name`,
          kind: "input",
          type: "text",
          label: "Vendor Name(EN)*",
          width: "47%",
          placeholder: "Vendor Name (EN)",
        },
        {
          name: `ar.name`,
          kind: "input",
          type: "text",
          label: "Vendor Name(AR)",
          width: "47%",
          placeholder: "Vendor Name(AR)",
        },
        {
          name: `email`,
          kind: "input",
          type: "text",
          label: "Email",
          width: "47%",
          placeholder: "Write Email",
        },

        {
          name: `phone_number`,
          kind: "phone",
          type: "text",
          width: "47%",
          label: "Phone Number*",
          placeholder: "Write phone number",
        },
        {
          name: `password`,
          kind: "input",
          type: "password",
          width: "47%",
          label: "Password*",
          placeholder: "********",
        },
        {
          name: `password_confirmation`,
          kind: "input",
          type: "password",
          width: "47%",
          label: "Confirm Password*",
          placeholder: "********",
        },

        {
          name: `tags`,
          kind: "multiSelect",
          type: "text",
          label: "Tag",
          width: "47%",
          placeholder: "Choose tag",
          ...options?.tags,
        },
        {
          name: `profile_status`,
          kind: "select",
          type: "text",
          label: " Status",
          width: "47%",
          options: [
            { id: "approved", name: "Approved" },
            { id: "pending_approval", name: "Pending" },
            { id: "rejected", name: "Rejected" },
          ],
        },

        {
          name: `is_verified`,
          kind: "checkbox",
          label: "Is Verified",
          width: "fit-content",
          disabled: isStore,
        },
        {
          name: `is_email_verified`,
          kind: "checkbox",
          label: "Is Email verified",
          width: "fit-content",
          disabled: isStore,
        },
      ],
    },
  ];
};

export const validationSchema = (formikValues, id) =>
  Yup.object({
    email: Yup.string().email("Email Not Valid"),
    phone_number: Yup.number().required("Required"),
    password:
      formikValues?.password_confirmation || !id
        ? Yup.string().required("required").min(6, "password can be at least 6 characters")
        : Yup.string().min(6, "password can be at least 6 characters"),
    password_confirmation:
      formikValues?.password || !id
        ? Yup.string()
          .required("required")
          .oneOf([Yup.ref("password"), null], `Passwords don't match`)
        : Yup.string().oneOf([Yup.ref("password"), null], `Passwords don't match`),
    en: Yup.object().shape({
      name: Yup.string().required("Required"),
    }),
    // ar: Yup.object().shape({
    //   name: Yup.string().required("Required"),
    // }),
  });
