import Types from "./Types";

export const ProductsOptionTypesLoading = (payload) => ({
    type: Types.PRODUCT_OPTION_TYPES_LOADING,
    payload
})
export const GetProductsOptionTypes = (payload) => ({
    type: Types.GET_PRODUCTS_OPTION_TYPES_SUCCESS,
    payload,
});
export const GetProductOptionTypeDetails = (payload) => ({
    type: Types.GET_PRODUCTS_OPTION_TYPES_DETAILS_SUCCESS,
    payload,
});
export const SetProductOptionType = (payload) => ({
    type: Types.SET_PRODUCT_OPTION_TYPES_SUCCESS,
    payload,
});
export const UpdateProductOptionType = (payload) => ({
    type: Types.UPDATE_PRODUCTS_OPTION_TYPES_SUCCESS,
    payload,
});
export const DeleteProductOptionType = (payload) => ({
    type: Types.DELETE_PRODUCTS_OPTION_TYPES_SUCCESS,
    payload,
});

export const GetScrollProductsOptionTypesSuccess = (payload) => ({
    type: Types.GET_SCROLL_PRODUCTS_OPTION_TYPES_SUCCESS,
    payload,
});