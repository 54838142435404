import { Stack, Button, Box, CircularProgress } from "@mui/material";
import { useFormik } from "formik";
import { initialValues, validationSchema, structure } from "templates/products/productsListing/structure";
import FormIndex from "components/Atoms/SharedComponents/Forms/main";
import { isScrolledToBottom } from "services/modules/utils/Helper";
import { useDispatch, useSelector } from "react-redux";
import { GetScrollVendorsRequest } from "services/modules/vendors";
import { GetScrollOccasionTypesRequest } from "services/modules/occasions/occasionsTypes";
import { getScrollProductsOptionTypesRequest } from "services/modules/lookups/productOptionTypes";
import { GetScrollCategoriesRequest } from "services/modules/lookups/categories";
import { useThemePalette } from "common/hooks/theme_palette";

export default function EditProductForm({ id, details, refsubmit, handleSubmit }) {
  const isStore = localStorage?.getItem("user_type") == "Store";

  const { oliveGreen } = useThemePalette();
  const dispatch = useDispatch();
  const load = useSelector((state) => state.products.load);

  const occassions = useSelector((state) => state.occasionsTypes.filterOccation);
  const occassionsCount = useSelector((state) => state.occasionsTypes.listCount);
  const occassionsLoading = useSelector((state) => state.occasionsTypes.load);

  const vendors = useSelector((state) => state.vendors.filterVendors);
  const vendorsCount = useSelector((state) => state.vendors.listCount);
  const vendorsLoading = useSelector((state) => state.vendors.load);

  const optionTypes = useSelector((state) => state.productsOptionTypes.filterProductsOptionTypes);
  const optionTypesCount = useSelector((state) => state.productsOptionTypes.listCount);
  const optionTypesLoading = useSelector((state) => state.productsOptionTypes.load);

  const categories = useSelector((state) => state.categories.filterCategories);
  const categoriesCount = useSelector((state) => state.categories.listCount);
  const categoriesLoading = useSelector((state) => state.categories.load);

  const formik = useFormik({
    initialValues: !id ? {} : initialValues(details),
    validationSchema,
    onSubmit: async (values) => {
      handleSubmit(values, formik);
    },
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
  });

  const fetchVendors = (filterObj) => {
    if (isStore) return;
    const requestData = {
      params: {
        page_size: 10,
        page_number: 1,
        hide_details: true,
        ...filterObj,
      },
    };
    dispatch(GetScrollVendorsRequest(requestData));
  };

  const fetchOccassions = (filterObj) => {
    const requestData = {
      params: {
        page_size: 10,
        page_number: 1,
        hide_details: true,
        ...filterObj,
      },
    };
    dispatch(GetScrollOccasionTypesRequest(requestData));
  };

  const fetchOptionTypes = (filterObj) => {
    const requestData = {
      params: {
        page_size: 10,
        page_number: 1,
        hide_details: true,
        ...filterObj,
      },
    };
    dispatch(getScrollProductsOptionTypesRequest(requestData));
  };

  const fetchCategories = (filterObj) => {
    const requestData = {
      params: {
        page_size: 10,
        page_number: 1,
        hide_details: true,
        ...filterObj,
      },
    };
    dispatch(GetScrollCategoriesRequest(requestData));
  };

  const prepareVendorsOptions = () => {
    if (isStore) {
      //to display only the logged in store in the vendors DDL when adding a product
      return [details?.store];
    } else {
      return [details?.store, ...vendors];
    }
  };

  const options = {
    vendors: {
      options: prepareVendorsOptions(),
      onOpen: vendors?.length <= 1 && fetchVendors,
      isLoading: vendorsLoading?.loading,
      getList: fetchVendors,
      count: vendorsCount,
    },
    occassions: {
      options: occassions,
      onOpen: occassions?.length <= 1 && fetchOccassions,
      isLoading: occassionsLoading?.loading,
      getList: fetchOccassions,
      count: occassionsCount,
    },
    optionTypes: {
      options: optionTypes,
      onOpen: optionTypes?.length <= 1 && fetchOptionTypes,
      isLoading: optionTypesLoading?.loading,
      getList: fetchOptionTypes,
      count: optionTypesCount,
    },
    categories: {
      options: categories,
      onOpen: categories?.length <= 1 && fetchCategories,
      isLoading: categoriesLoading?.loading,
      getList: fetchCategories,
      count: categoriesCount,
    },
  };
  // console.log(options?.categories);
  return (
    <Stack sx={{ overflowY: "auto", height: 630, p: 2 }}>
      {((load?.loading && load?.type == "details") || (load?.loading && load?.type == "update")) && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            bottom: "0",
            left: "0",
            right: 0,
            top: 0,
            position: "absolute",
            zIndex: 1000,
            bgcolor: "rgba(255, 255, 255, 0.2)",
            backdropFilter: "blur(0.6px)",
          }}
        >
          <CircularProgress sx={{ color: oliveGreen }} />
        </Box>
      )}
      <FormIndex formStructures={structure(options)} formik={formik} refsubmit={refsubmit}>
        <Button ref={refsubmit} type="submit"></Button>
      </FormIndex>
    </Stack>
  );
}
