import { Box } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useThemePalette } from "common/hooks/theme_palette";
import React from "react";

export default function Loading({ isLoading, children, ...props }) {
  const { oliveGreen } = useThemePalette();
  return (
    <>
      {isLoading && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            bottom: "0",
            left: "0",
            right: 0,
            top: 0,
            position: "absolute",
            zIndex: 1000,
            bgcolor: "rgba(255, 255, 255, 0.2)",
            backdropFilter: "blur(0.6px)",
          }}
        >
          <CircularProgress sx={{ color: oliveGreen }} />
        </Box>
      )}
      {children}
    </>
  );
}
