const Types = {
  //! cities
  GET_CITIES_SUCCESS: "GET_CITIES_SUCCESS",
  GET_CITY_DETAILS_SUCCESS: "GET_CITY_DETAILS_SUCCESS",
  CITIES_LOADING: "CITIES_LOADING",
  GET_SCROLL_CITIES_SUCCESS: "GET_SCROLL_CITIES_SUCCESS",

};

export default Types;
