import { Box, Button, Menu, Stack, Typography } from "@mui/material";
import Notification from "../../Atoms/SharedComponents/Notifications/Notification";
import { useThemePalette } from "common/hooks/theme_palette";
import NotificationSkelaton from "../../Atoms/SharedComponents/Notifications/NotificationSkelaton";

export default function NotificationsList({
  open,
  anchorEl,
  handleClose,
  notifications,
  load,
  handlePagination,
  isFetch,
  notificationTypes,
  handleMarkAllAsRead,
  countOfUnread,
}) {
  const { orange } = useThemePalette();
  return (
    <Box>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        transformOrigin={{
          vertical: 0,
          horizontal: 0,
        }}
        sx={{ left: 0, top: 15, height: 700 }}
      >
        {/* HEADER START */}
        <Stack
          sx={{
            p: 2,
            borderBottom: "1px solid #E2E2E2",
            minWidth: "400px",
            position: "sticky",
            top: 0,
            zIndex: 2,
            backgroundColor: "white",
          }}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography fontSize="24px" fontWeight="500">
            Notifications
          </Typography>
          <Button
            sx={{ p: 0, color: orange }}
            disabled={load?.loading}
            onClick={handleMarkAllAsRead}
          >
            Mark all as read
          </Button>
        </Stack>
        {/* HEADER END */}
        {/* LOADING SKELATON */}
        {load?.type == "list" && <NotificationSkelaton num={1} />}
        {/* LISTING NOTIFICATIONS */}
        <Stack gap={2} >
          {notifications?.map((notification, i) => (
            <Notification
              key={i}
              notification={notification}
              notificationTypes={notificationTypes}
              handleClose={handleClose}
            />
          ))}
        </Stack>
        {/* LOADING SKELATON */}
        {load?.type == "list" && <NotificationSkelaton num={1} />}
        {/* VIEW MORE */}
        {!load?.loading && isFetch && (
          <Stack direction="row" justifyContent="center" p={2}>
            <Button sx={{ color: orange }} onClick={handlePagination}>
              View more
            </Button>
          </Stack>
        )}
      </Menu>
    </Box>
  );
}
