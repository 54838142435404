import { Box, CircularProgress, Stack } from '@mui/material'
import { icons } from 'assets/AssetHelper'
import { useThemePalette } from 'common/hooks/theme_palette'
import FileInput from 'components/Atoms/Input/FileInput/FileInput'
import TextFieldInput from 'components/Atoms/Input/TextField/TextFieldInput'
import ContainedButton from 'components/Atoms/SharedComponents/Buttons/ContainedButton'
import React from 'react'

export default function SendMessage({
    message,
    setMessage,
    sendMessage,
    Files,
    loadFile,
    handleFileInput
}) {
    const { oliveGreen } = useThemePalette()
    return <>

        <Stack width={'100%'} direction='row' justifyContent='space-between' alignItems='center' sx={{ px: '15px', pt: '20px' }} >
            <Stack width='95%' sx={{ position: 'relative' }} >
                <TextFieldInput
                    placeholder="Write Message........."
                    value={message}
                    onchange={(message) => setMessage(message)}
                    onKeyDown={(e) => { if (e.key === "Enter" && (message != "" || Files != null)) sendMessage() }}
                    sx={{
                        bgcolor: '#F4F4F4', borderRadius: '6px', py: '5px', wordBreak: 'break-word',
                        pr: '40px'
                    }}
                    onChange={(message) => setMessage(message?.target?.value)}
                />
                {/* {Files && <Box sx={{ position: 'absolute', left: '12px', top: '13px' }}>
                        <img src={Files?.url} width={'40px'} height='40px' alt='file' />
                    </Box>} */}
                <Stack justifyContent='center' alignItems='center' sx={{ position: 'absolute', right: '12px', top: '13px' }} >
                    <ContainedButton
                        startIcon={
                            (loadFile?.type == "uploadOnServer" && loadFile?.loading) ?
                                <CircularProgress sx={{ color: 'white', width: '24px !important', height: '24px !important' }} />
                                : <img width="24px" height='24px' src={icons.sendIcon} alt='send btn' />
                        }
                        // 
                        classBtn="add-message"
                        onClick={sendMessage}
                        disabled={message == "" && Files == null
                        }
                        px={0}
                        sx={{
                            minWidth: '40px',
                            height: '40px',
                            p: '0 !important',
                            bgcolor: oliveGreen,
                            "&:hover": {
                                backgroundColor: oliveGreen,
                            },
                            '& .MuiButton-startIcon': {
                                m: '0!important',
                            }
                        }}
                    />
                </Stack>
            </Stack>
            <Stack alignItems='center' justifyContent='center' style={{ textAlign: "center", cursor: "pointer" }}>
                <FileInput
                    icon={icons.sendDocument}
                    change={handleFileInput}
                    accept="image/*"
                />
            </Stack>
        </Stack>

    </>
}
