import { red } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";

// A custom theme for this app
const theme = createTheme({
  typography: {
    fontFamily: ["Jost"].join(","),
  },
  palette: {
    primary: {
      main: "#556cd6",
    },
    secondary: {
      main: "#F25500",
    },
    error: {
      main: red.A400,
    },
  },
  theme_palette: {
    orange: "#F25500",
    red: "#D92C32",
    oliveGreen: "#3FABAE",
    green: "#29B95F",
    gray: "#939393",
    grayOne: '#A3A3A3',
    grayBorder: "#E7E7E7",
    textGray: "#1A1A1A",
    lightGray: "#ddd",
    backgroundGray: "#F2F2F2",
    labelColor: "#9F9F9F",
    borderColor: "#F2F2F2",
    offWhite: '#FAFAFA',
    grayTwo: '#7D7D7D',
    grayThree: '#F0F0F0',
    grayfour: '#7E7E7E',
    grayFive: '#E2E2E2',
    graySex: '#EEE',
    blackTwo: '#545454',
    graySeven: '#4A4A48',
    redTwo: '#F55500',
    gray9: '#5A5A5A',
    gray10: '#585858',
    orangeTwo: '#D20000',
    lightGreen: '#09CE7D',
    lightGreenTwo: '#E1F5E8',
    borderColorTwo: '#F4F4F4',
    backgroundGreen: '#E1F5E8',
    textGreen: '#29B95F',
    backgroundRed: '#FAE3E3',
    textRed: '#D20000',
    yellow: "#FFCC00",
    backgroundYellow: "#FCFFE9",
    confirmationGreen: '#29B95F',
    inputBg: '#FBFBFB',
    graySix: "#E3E3E3",
    gray17: '#F3F3F3',
    gray11: '#A3A3A3',
    darkBlue: '#09132C',
    chatListBg: '#F3FAFA',
    darkGray: '#191919',
  },
  overrides: {
    MuiPaper: {
      root: {},
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableRipple: true, // No more ripple, on the whole application !
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          // Some CSS
          borderRadius: "4px",
          "&:hover": {
            //  backgroundColor: 'primary',
          },
          padding: "8px 97px",
          // width:"250px",
          fontFamily: "Jost",
          textTransform: "none",
          fontSize: "18px",
          fontWeight: "400",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          border: "none",
          padding: "15px"
        },
      },
    },
  },
});

export default theme;
