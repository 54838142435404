import { Box, CircularProgress, FormControl, Stack, Typography } from "@mui/material";
import React from "react";
import CFormErrors from "./CFormErrors";
import { useThemePalette } from "common/hooks/theme_palette";
import Select, { StylesConfig } from "react-select";
import { removeDuplicateObjects } from "services/modules/utils/Helper";

const CMultiSelect = ({ options, changeAction, label, formik, name, index, value, ...rest }) => {
  const { labelColor, oliveGreen } = useThemePalette();
  const errorsMsg = formik?.errors[name];
  options = removeDuplicateObjects(options, "id");
  const handelOptions = options?.map((val) => {
    return {
      label: val?.name,
      value: val?.id,
      color: val?.color,
    };
  });
  const valueData = typeof formik?.values[name] != "object" ? handelOptions?.find((val) => val?.value == formik?.values[name]) : formik?.values[name];
  const indexs = {
    0: 10,
    1: 9,
    2: 8,
    3: 7,
    4: 6,
    5: 5,
    6: 4,
    7: 3,
    8: 2,
    9: 1,
    10: 0,
  };
  const defaultStyle = {
    color: labelColor,
    fontSize: "18px",
    fontFamily: "Jost",
    mb: 2,
  };
  const colourStyles = {
    control: (styles, { data, isDisabled, isFocused, isSelected }) => ({
      ...styles,
      backgroundColor: "#FBFBFB",
      padding: "8px",
      borderColor: isFocused ? "transparent" : "transparent",
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,

        color: "black",
        cursor: isDisabled ? "not-allowed" : "default",

        ":active": {
          ...styles[":active"],
        },
      };
    },
    multiValue: (styles, { data }) => {
      return {
        ...styles,
        backgroundColor: "white",
        border: "1px solid #E6E6E6",
        color: "#707070",
        fontSize: "18px",
      };
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: "#707070",
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: "#ccc",
      ":hover": {
        backgroundColor: "#ccc",
        color: "white",
      },
    }),
  };

  const handleMenuScrollToBottom = () => {
    if (options?.length < rest?.count) {
      rest?.getList({
        page_number: Math?.ceil(options?.length / 10 + 1),
      });
      return;
    }
  };
  return (
    <Stack
      w={"100%"}
      p="0"
      justifyContent="center"
      flexDirection={"column"}
      position="relative"
      zIndex={`${100 + indexs[index]} !important`}
      loadingMessage={() => <p>loading...</p>}
      onKeyDown={(e) => e.stopPropagation()}
    >
      <FormControl fullWidth>
        {label && (
          <Typography as="span" sx={defaultStyle}>
            {label}
          </Typography>
        )}

        <Select
          styles={colourStyles}
          id={name}
          name={name}
          options={handelOptions}
          onChange={(value) => changeAction(value)}
          value={valueData || value}
          placeholder={label}
          className="search-select"
          isMulti={true}
          {...rest}
          onMenuOpen={(_) => rest?.onOpen && rest?.onOpen()}
          onMenuScrollToBottom={handleMenuScrollToBottom}
        />
      </FormControl>
      <CFormErrors errorsMsg={errorsMsg} />
    </Stack>
  );
};

export default CMultiSelect;
