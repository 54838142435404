/* eslint-disable array-callback-return */
import Types from "./Types";

const INIT_STATE = {
  ribbonColors: [],
  ribbonColor: {},
  filterRibbonColor: [],
  listCount: 0,
  count: 0,
  load: null,
  error: null,
};

export function ribbonColorsReducer(state = INIT_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case Types.GET_RIBBON_COLORS_SUCCESS: {
      return {
        ...state,
        ribbonColors: payload?.data?.ribbon_colors,
        count: payload?.extra?.total_count,
      };
    }
    case Types.GET_SCROLL_RIBBON_COLOR_SUCCESS: {
      return {
        ...state,
        filterRibbonColor: payload?.extra?.page_number == 1
          ? payload?.data?.ribbon_colors
          : [...state.filterRibbonColor, ...payload?.data?.ribbon_colors],
        listCount: payload?.extra?.total_count,
      };
    }
    case Types.RIBBON_COLORS_LOADING: {
      return {
        ...state,
        load: payload,
      };
    }
    case Types.DELETE_RIBBON_COLOR_SUCCESS: {
      return {
        ...state,
      };
    }
    case Types.ADD_RIBBON_COLOR_SUCCESS: {
      return {
        ...state,
      };
    }
    case Types.ADD_RIBBON_COLOR_FAILED: {
      return {
        ...state,
        error: payload?.response?.data?.message,
      };
    }
    case Types.UPDATE_RIBBON_COLOR_SUCCESS: {
      return {
        ...state,
      };
    }
    case Types.GET_RIBBON_COLOR_DETAILS_SUCCESS: {
      return {
        ...state,
        ribbonColor: payload?.data?.ribbon_color,
      };
    }
    default: {
      return state;
    }
  }
}
