import { makeFilterString } from "../../utils/Helper";
import { GetBannersLoading, GetBannersSuccess, GetBannerDetailsSuccess } from "./Action";

import { reduxRequest } from "common/utils/reduxRequest";

export const getBannersRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: GetBannersLoading,
    loadingType: "list",
    successFunction: GetBannersSuccess,
    url: `/admin/lookups/featured_banners?${makeFilterString(
      variables?.params
    )}`,
    method: "get",
  };
  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const createBannerRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: GetBannersLoading,
    loadingType: "add",
    action: variables?.action,
    url: `/admin/lookups/featured_banners`,
    method: "post",
    data: variables?.body,
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const getBannerDetailsRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: GetBannersLoading,
    loadingType: "details",
    successFunction: GetBannerDetailsSuccess,
    url: `/admin/lookups/featured_banners/${variables?.id}`,
    method: "get",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const deleteBannerRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: GetBannersLoading,
    loadingType: "delete",
    action: variables?.action,
    url: `/admin/lookups/featured_banners/${variables?.id}`,
    method: "delete",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};

export const updateBannerRequest = ({ ...variables }) => {
  const requestData = {
    isLoading: GetBannersLoading,
    loadingType: "update",
    url: `/admin/lookups/featured_banners/${variables?.id}`,
    action: variables?.action,
    data: variables?.body,
    method: "put",
  };

  return async (dispatch) => {
    reduxRequest({
      dispatch,
      ...requestData,
    });
  };
};
