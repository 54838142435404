import React, { useState } from "react";
import CCrud from "components/Molecules/Crud";
import { initialValues, structure, validationSchema } from "../../../components/Organisms/admin-users/forms/structure";
import {
  editInitialValues,
  editStructure,
  editValidationSchema,
} from "../../../components/Organisms/admin-users/forms/editFormStructure";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Box, Stack, Typography } from "@mui/material";
import Breadcrumb from "components/Atoms/SharedComponents/Breadcrumbs/Breadcrumb";
import AddIcon from "@mui/icons-material/Add";
import { icons } from "assets/AssetHelper";
import { detailsData, detailsTemplateData } from "./details";
import { removeDuplicateObjects } from "services/modules/utils/Helper";
const AdminUsersTemplate = ({
  handlePageChange,
  data,
  details,
  count,
  handelSubmit,
  deleteFun,
  filterList,
  actionsList,
  breadcrumbs,
  setFilterObj,
  rowData,
  paginationPage,
  headerChildren,
  modalComponents,
  openDeleteModal,
  setOpenDeleteModal,
  handleDeleteRequest,
  rolesOptions,
  can_create,
  getRoles,
  rolesCount,
  load,
}) => {
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  const id = searchParams.get("id");

  const [supportedLang, setSupportedLang] = useState("en");

  return (
    <>
      <Box>
        <Stack direction="row" alignSelf="start" justifyContent="start" gap={2} width="100%" mb={1}>
          <Breadcrumb data={breadcrumbs} />
        </Stack>
        <CCrud
          detailsTemplateData={{
            pageTitle: "User details",
            data: {
              list: detailsData({ data: details }),
            },
          }}
          table={{
            list: data,
            listTitle: "Admin Users",
            createBtnTitle: can_create && "New user",
            CreateBtnIcon: AddIcon,
            createBtnFun: () => navigate("?type=form"),
            columns: [
              { accessor: "id", Header: "#" },
              { accessor: "name", Header: "User name" },
              { accessor: "email", Header: "Email" },
              { accessor: "phone_number", Header: "Phone Number", sort: true },
              { accessor: "role.name", Header: "Role", sort: true },
              { accessor: "Actions", Header: "Actions" },
            ],
            isSearch: false,
            searchInputPlaceholder: "Search by tag name",
            isChecked: false,
            total_count: count,
            isSort: true,
            setFilterObj,
            className: "organization-table system-configurations-table",
            paginationPage: paginationPage,
            handlePageChange,
            rowData: rowData,
            filterList,
            className: "main-table",
            actionsList, //if you want to add custom actions list
            headerChildren,

            details,
            openDeleteModal,
            setOpenDeleteModal,
            handleDeleteRequest,
          }}
          form={{
            initialValues: !id ? {} : editInitialValues(details, supportedLang),
            structure: !id
              ? () => structure(supportedLang, rolesOptions, getRoles, rolesCount, load)
              : () =>
                  editStructure(
                    supportedLang,
                    [details?.role, ...rolesOptions],
                    getRoles,
                    rolesCount,
                    load
                  ),
            validationSchema: !id ? validationSchema : editValidationSchema,
            deleteFun: deleteFun,
            title: id ? "Edit User" : "New User",
            submitTitle: id ? "Update" : "Add",
            onSubmit: handelSubmit,
            className: { form: "main-form", actionButton: "main-form-buttons" },
          }}
          // DetailsComponent={<Details />} //to send details component diff from the default
          // detailsTemplateData={detailsTemplateData}
          modalComponents={modalComponents}
        />
      </Box>
    </>
  );
};

export default AdminUsersTemplate;
