import { Box, Checkbox, Stack, Typography } from "@mui/material";
import { icons } from "../../../../assets/AssetHelper";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import React from "react";
import ProductListing from "../../../Molecules/Order/returnRequest/productListing";
import Loader from "components/Atoms/SharedComponents/Loader/Loader";
import { useState } from "react";

export default function ProductsSelection({
  list,
  load,
  onCheckboxChange = () => {},
}) {
  const [checkedItems, setCheckedItems] = useState(Array(list?.length).fill(false));

  const handleCheckboxChange = (index) => {
    const newCheckedItems = [...checkedItems];
    newCheckedItems[index] = !newCheckedItems[index];
    setCheckedItems(newCheckedItems);

    // Filter out only the checked items
    const checkedDetails = list?.filter((detail, i) => newCheckedItems[i]);
    console.log(checkedDetails);
    onCheckboxChange(checkedDetails);
  };

  return (
    <div>
      {load?.loading && load?.type == "details" ? (
        <Loader />
      ) : list && list.length > 0 ? (
        <Stack gap={list?.length}>
          {list?.map((data, index) => (
            <Box key={index} backgroundColor={"white"} p={3}>
              <Stack
                flexDirection={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <ProductListing details={data} />
                <Checkbox
                  checked={checkedItems[index]}
                  onChange={() => handleCheckboxChange(index)}
                  sx={{ "& .MuiSvgIcon-root": { fontSize: 40 } }}
                  checkedIcon={
                    <CheckBoxOutlinedIcon sx={{ color: "#09CE7D" }} />
                  }
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              </Stack>
            </Box>
          ))}
        </Stack>
      ) : (
        <Loader open={load?.loading && load?.type == "details"} />
      )}
    </div>
  );
}
