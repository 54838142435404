import Types from "./Types";

const INIT_STATE = {
    driverComplaints:[],
    driverComplaint:{},
    count: 0,
    load: null,
    error: null,
};
export function driverComplaintsReducer(state = INIT_STATE, action) {
    const { type, payload } = action;
    switch (type) {
        case Types.GET_DRIVER_COMPLAINTS_SUCCESS:{
            return {
                ...state,
                driverComplaints: payload?.data?.route_complaints,
                count: payload?.extra?.total_count,

            }
        }
        case  Types.GET_DRIVER_COMPLAINT_DETAILS_SUCCESS:{
            return {
                ...state,
                driverComplaint: payload?.data?.route_complaint
            }
        }

        case Types.UPDATE_DRIVER_COMPLAINT_SUCCESS:{
            return {
                ...state,
                driverComplaints: state?.driverComplaints.map((driver) => {
                    if (driver.id === payload?.data?.route_complaint?.id) {
                        return payload?.data?.route_complaint;
                    }
                    return driver;
                }),
            }
        }
           case Types.DRIVER_COMPLAINTS_LOADING: {
      return {
        ...state,
        load: payload,
      };
    }
        default:
            return state
    }
}