import Types from "./Types";

export const DriverComplaintsLoading=(payload)=>({
    type:Types.DRIVER_COMPLAINTS_LOADING,
    payload
})
export const GetDriverComplaints = (payload) => ({
    type: Types.GET_DRIVER_COMPLAINTS_SUCCESS,
    payload,
});
export const GetDriverComplaintDetails = (payload) => ({
    type: Types.GET_DRIVER_COMPLAINT_DETAILS_SUCCESS,
    payload,
});
export const SetDriverComplaints = (payload) => ({
    type: Types.SET_DRIVER_COMPLAINTS_SUCCESS,
    payload,
});
export const UpdateDriverComplaints = (payload) => ({
    type: Types.UPDATE_DRIVER_COMPLAINT_SUCCESS,
    payload,
});
export const DeleteDriverComplaints = (payload) => ({
    type: Types.DELETE_DRIVER_COMPLAINT_SUCCESS,
    payload,
});

