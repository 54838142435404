const chatRoomTypes = {
  GET_CHAT_ROOMS_SUCCESS: "GET_CHAT_ROOMS_SUCCESS",
  CHAT_ROOMS_LOADING: "CHAT_ROOMS_LOADING",
  SEARCH_CHAT_ROOMS_LOADING: "SEARCH_CHAT_ROOMS_LOADING",
  GET_MESSAGES_SUCCESS: "GET_MESSAGES_SUCCESS",
  SEND_MESSAGE_SUCCESS: "SEND_MESSAGE_SUCCESS",
  VISIT_CHAT_ROOM: "VISIT_CHAT_ROOM",
  CREATE_CHAT_ROOM_SUCCESS: "CREATE_CHAT_ROOM_SUCCESS",
  SEARCH_CHAT_ROOMS_SUCCESS: "SEARCH_CHAT_ROOMS_SUCCESS",
  SHOW_CHAT_ROOM_SUCCESS: "SHOW_CHAT_ROOM_SUCCESS"
};

export default chatRoomTypes;
