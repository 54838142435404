import { makeFilterString } from "../../utils/Helper";

import { reduxRequest } from "../../../../common/utils/reduxRequest";
import {
    CustomerComplaintsLoading, GetCustomerComplaintDetails,
    GetCustomerComplaints,
    SetCustomerComplaints,
    UpdateCustomerComplaints
} from "./Action";

export const getCustomerComplaintsRequest = ({ ...variables }) => {
    const requestData = {
        isLoading: CustomerComplaintsLoading,
        loadingType: "list",
        successFunction: GetCustomerComplaints,
        url: `/admin/complaints/order_complaints?${makeFilterString(variables?.params
        )}`,
        method: "get",
    };
    return async (dispatch) => {
        reduxRequest({
            dispatch,
            ...requestData,
        });
    };
}
export const createCustomerComplaintsRequest = ({ ...variables }) => {
    const requestData = {
        isLoading: CustomerComplaintsLoading,
        loadingType: "add",
        successFunction: SetCustomerComplaints,
        action: variables?.action,
        url: `/admin/complaints/order_complaints`,
        method: "post",
        data: variables?.body,
    };

    return async (dispatch) => {
        reduxRequest({
            dispatch,
            ...requestData,
        });
    };
};
export const getCustomerComplaintsDetailsRequest = ({ ...variables }) => {
    const requestData = {
        isLoading: CustomerComplaintsLoading,
        loadingType: "details",
        successFunction: GetCustomerComplaintDetails,
        url: `/admin/complaints/order_complaints/${variables?.id}`,
        action: variables?.action,
        method: "get",
    };

    return async (dispatch) => {
        reduxRequest({
            dispatch,
            ...requestData,
        });
    };
};
export const deleteCustomerComplaintsRequest = ({ ...variables }) => {
    const requestData = {
        isLoading: CustomerComplaintsLoading,
        loadingType: "delete option type",
        action: variables?.action,
        url: `/admin/complaints/order_complaints${variables?.id}`,
        method: "delete",
    };

    return async (dispatch) => {
        reduxRequest({
            dispatch,
            ...requestData,
        });
    };
};
export const updateCustomerComplaintsRequest = ({ ...variables }) => {
    const requestData = {
        isLoading: CustomerComplaintsLoading,
        loadingType: "update option type",
        successFunction: UpdateCustomerComplaints,
        url: `/admin/complaints/order_complaints/${variables?.id}`,
        action: variables?.action,
        data: variables?.body,
        method: "put",
    };

    return async (dispatch) => {
        reduxRequest({
            dispatch,
            ...requestData,
        });
    };
};