import { Stack, Typography, Box, Dialog, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const CModel = ({ title, children, closeFunc, openVar,titleStyle }) => {
    return (
        <Dialog
            open={openVar}
            onClose={closeFunc}
            PaperProps={{
                sx: {
                    borderRadius: "12px",
                    height: "auto",
                    minWidth: { lg: "492px", md: "800px", sm: "500px" },
                    padding: "20px",
                },
            }}
        >
            <Stack
                onClick={closeFunc}
                position="relative"
                alignItems="flex-end"
                sx={{ cursor: "pointer", mt: "20px" }}
            >
                <Box
                    position="absolute"
                    sx={{
                        bgcolor: "white",
                        padding: "4px",
                        width: "24px",
                        height: "24px",
                        borderRadius: "50%",
                        alignItems: "center",
                        justifyContent: "center",
                        top: "-11px",
                    }}
                >
                    <CloseIcon fontSize="14px" />
                </Box>
            </Stack>
            <Stack
                direction="row"
                alignItems="center"
                justifyContent={"center"}
                mb={2}
                mx={1}
            >
                <Typography component="h2" sx={titleStyle}>{title}</Typography>
            </Stack>
            {children}
        </Dialog>
    );
};

export default CModel;
