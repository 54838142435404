const occasionTypesTypes = {
  // WRAPPERS
  // list
  GET_OCCASIONS_TYPES_SUCCESS: "GET_OCCASIONS_TYPES_SUCCESS",
  OCCASIONS_TYPES_LOADING: "OCCASIONS_TYPES_LOADING",
  //details 
  GET_OCCASIONS_TYPES_DETAILS_SUCCESS: "GET_OCCASIONS_TYPES_DETAILS_SUCCESS",
  // create
  ADD_OCCASIONS_TYPES_SUCCESS: "ADD_OCCASIONS_TYPES_SUCCESS",
  // Delete
  DELETE_OCCASIONS_TYPES_SUCCESS: "DELETE_OCCASIONS_TYPES_SUCCESS",
  // update
  UPDATE_OCCASIONS_TYPES_SUCCESS: "UPDATE_OCCASIONS_TYPES_SUCCESS",
  GET_SCROLL_OCCASIONS_TYPES_SUCCESS: "GET_SCROLL_OCCASIONS_TYPES_SUCCESS",

};

export default occasionTypesTypes;
