const Types = {
  GET_PRODUCTS_SUCCESS: "GET_PRODUCTS_SUCCESS",
  GET_PRODUCT_DETAILS_SUCCESS: "GET_PRODUCT_DETAILS_SUCCESS",
  CLEAR_PRODUCT_DETAILS_SUCCESS:"CLEAR_PRODUCT_DETAILS_SUCCESS",
  PRODUCTS_LOADING: "PRODUCTS_LOADING",
  UPDATE_PRODUCT_SUCCESS: "UPDATE_PRODUCT_SUCCESS",
  CREATE_PRODUCT_SUCCESS: "CREATE_PRODUCT_SUCCESS",

  GET_PRODUCT_VARIANTS_SUCCESS: "GET_PRODUCT_VARIANTS_SUCCESS",
  GET_VARIANT_DETAILS_SUCCESS: "GET_VARIANT_DETAILS_SUCCESS",
  GET_VARIANT_BRANCHES_SUCCESS: "GET_VARIANT_BRANCHES_SUCCESS",
  CREATE_VARIANT_SUCCESS: "CREATE_VARIANT_SUCCESS",
  UPDATE_VARIANT_SUCCESS: "UPDATE_VARIANT_SUCCESS",
  PRODUCT_VARIANTS_LOADING: "PRODUCT_VARIANTS_LOADING",
 

  //variant locations
  CREATE_VARIANT_LOCATION_SUCCESS: "CREATE_VARIANT_LOCATION_SUCCESS",
  UPDATE_VARIANT_LOCATION_SUCCESS: "UPDATE_VARIANT_LOCATION_SUCCESS",
  DELETE_VARIANT_LOCATION_SUCCESS: "DELETE_VARIANT_LOCATION_SUCCESS",
  GET_VARIANT_LOCATIONS: "GET_VARIANT_LOCATIONS",

  GET_CUSTOMERS_REVIEWS_SUCCESS: "GET_CUSTOMERS_REVIEWS_SUCCESS",
  DELETE_PRODUCT_VARIANT_SUCCESS: "DELETE_PRODUCT_VARIANT_SUCCESS",
  GET_SCROLL_BRANCHES_SUCCESS: "GET_SCROLL_BRANCHES_SUCCESS",

  GET_OPTION_VALUES_SUCCESS: "GET_OPTION_VALUES_SUCCESS",
  GET_OPTION_VALUES_LOADING: "GET_OPTION_VALUES_LOADING",
  GET_SCROLL_OPTION_VALUES_SUCCESS: "GET_SCROLL_OPTION_VALUES_SUCCESS",
};

export default Types;
