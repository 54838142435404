/* eslint-disable array-callback-return */
import Types from "./Types";

const INIT_STATE = {
  giftCards: [],
  giftCard: {},
  count: 0,
  load: null,
  error: null,
};

export default function giftCardsReducer(state = INIT_STATE, action) {
  const { type, payload } = action;

  switch (type) {
    case Types.GIFT_CARDS_LOADING: {
      return {
        ...state,
        load: payload,
      };
    }
    case Types.GET_GIFT_CARDS_SUCCESS: {
      return {
        ...state,
        giftCards: payload?.data?.gift_cards,
        count: payload?.extra?.total_count,
      };
    }
    case Types.GET_GIFT_CARD_DETAILS_SUCCESS: {
      return {
        ...state,
        giftCard: payload?.data?.gift_card,
      };
    }
    default: {
      return state;
    }
  }
}