import { useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";

import Loader from "components/Atoms/SharedComponents/Loader/Loader";
import ProductDetails from "components/Organisms/products/details";
import CustomersReviews from "pages/products/customerReviews";
import ProductVariants from "pages/products/productVariants";
import { useSelector } from "react-redux";
import ProductDetailsTemplate from "templates/products/productsListing/productDetails";
import { handleTabChange } from "services/modules/utils/Helper";

export default function ProductsDetailsPage({ details, setOpenDeleteModal }) {
  const currentUser = useSelector((state) => state.authenticate.currentUserProfile);
  const variantPermissions = useSelector((state) => state.authenticate.currentUserProfile.permissions.variants);
  const reviewPermissions = useSelector((state) => state.authenticate.currentUserProfile.permissions.reviews);
  const isSuperAdmin = currentUser?.privilege == "super_admin";
  const status = details?.approval_status;
  const load = useSelector((state) => state.products.load);
  const [searchParams, setSearchParams] = useState(new URLSearchParams(window.location.search));

  const selectedTab = searchParams.get("tab");
  const [value, setValue] = useState(+selectedTab || 0);

  const tabs = [
    {
      title: "Details",
      children: <ProductDetails details={details} />,
    },
    status == "available" &&
      (isSuperAdmin || variantPermissions?.can_read) && {
        title: "Variants",
        children: (
          <ProductVariants
            list={details?.variants}
            optionTypes={details?.option_types}
            storeId={details?.store?.id}
            variantPermissions={variantPermissions}
            isSuperAdmin={isSuperAdmin}
          />
        ),
      },
    (isSuperAdmin || reviewPermissions?.can_read) && {
      title: "Reviews",
      children: <CustomersReviews details={details} />,
    },
  ];

  return (
    <>
      <Loader open={load?.loading && load?.type == "details"} />
      <ProductDetailsTemplate
        details={details}
        setOpenDeleteModal={setOpenDeleteModal}
        selectedTab={value}
        tabs={tabs?.filter((tab) => tab)} // as there is a condition inside the above tabs, that might return false
        handleTabChange={(event, newValue) => handleTabChange({ event, newValue, setValue, searchParams, setSearchParams })}
      />
      {/* {memoizedDetailsTab} */}
    </>
  );
}
