/* eslint-disable array-callback-return */
import Types from "./Types";

const INIT_STATE = {
  drivers: [],
  driver: {},
  count: 0,
  load: null,
};

export default function driverReducer(state = INIT_STATE, action) {
  const { type, payload } = action;

  switch (type) {
    case Types.DRIVERS_LOADING: {
      return {
        ...state,
        load: payload,
      };
    }
    case Types.GET_DRIVERS_SUCCESS: {
      return {
        ...state,
        drivers: payload?.data?.drivers,
        count: payload?.extra?.total_count,
      };
    }
    case Types.GET_DRIVER_DETAILS_SUCCESS: {
      return {
        ...state,
        driver: payload?.data?.driver,
      };
    }
    case Types.UPDATE_DRIVER_SUCCESS: {
      return {
        ...state,
        drivers: state?.drivers?.map((driver) => {
          if (driver.id === payload?.data?.driver?.id) {
            return payload?.data?.driver;
          }
          return driver;
        }),
        driver: payload?.data?.driver
      };
    }
    case Types.DELETE_DRIVER_SUCCESS: {
      return {
        ...state,
      };
    }
    default: {
      return state;
    }
  }
}
