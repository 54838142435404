import { Typography, Stack, Box } from '@mui/material'
import { icons } from 'assets/AssetHelper'
import { useThemePalette } from 'common/hooks/theme_palette'
import React from 'react'
import { useDispatch } from 'react-redux'
import { isOpenDriverDetails } from 'services/modules/modal/Actions'
import DriversDetails from './Models/driversDetails'
import ViewComplainModal from './Models/viewComplainModal'
import { useState } from 'react'
import ComplainsUpdateModel from 'components/Molecules/Orders/complains/complainsUpdateModel'


export default function RouteDriverDetails({
    routeOne,
    routeTwo,
    updateComplaintData,
    setUpdateComplaintData,
    handleUpdateComplains,
    setOpenResplveModal,
    openResplveModal,
    description,
    driver,
    driversData
}) {
    const dispatch = useDispatch()
    const { grayThree } = useThemePalette()
    const [complainId, setComplainId] = useState(null)
    return <Stack width='50%' p='20px' gap='22px' bgcolor='#fff' >
        <Stack justifyContent='space-between' alignItems='center' direction='row'  >
            <Stack direction='row' gap='12px'>
                <Stack
                    bgcolor={grayThree}
                    p='8px'
                    borderRadius='6px'
                >
                    <img
                        alt='driver icon'
                        src={icons?.dirverIcon}
                        width='24px'
                        height='24px'
                    />
                </Stack>
                <Typography
                    fontWeight='500'
                    fontSize={'24px'}
                >
                    Driver info
                </Typography>
            </Stack>
        </Stack>
        <Stack justifyContent='center' alignItems='center' gap='12px' >
            <Box>
                <img
                    src={icons?.driversInfo}
                    alt='driver icon'
                />
            </Box>
            {driver ?
                <Stack direction='row' sx={{ cursor: 'pointer' }} onClick={_ => dispatch(isOpenDriverDetails())}>
                    <Typography fontSize='18px' >{description}  </Typography>
                    <img
                        src={icons?.blackRightArrow}
                        alt='arrow'
                    />
                </Stack> :
                <Stack direction='row'>
                    <Typography fontSize='18px' >{description}  </Typography>
                </Stack>
            }
        </Stack>
        <DriversDetails
            driversData={driversData}
            updateComplaintData={updateComplaintData}
            setUpdateComplaintData={setUpdateComplaintData}
            handleUpdateComplains={handleUpdateComplains}
            setComplainId={setComplainId}
        />
        {complainId &&
            <ViewComplainModal
                complaintId={complainId}
                setOpenResplveModal={setOpenResplveModal}
                openResplveModal={openResplveModal}
                updateComplaintData={updateComplaintData}
                setUpdateComplaintData={setUpdateComplaintData}
                handleUpdateComplains={handleUpdateComplains}
            />
        }
        <ComplainsUpdateModel
            updateComplaintData={updateComplaintData}
            handleUpdateComplains={handleUpdateComplains}
            openResplveModal={openResplveModal}
            setOpenResplveModal={setOpenResplveModal}
        />
    </Stack>
}
