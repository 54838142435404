import { Box, Grid, Stack, Typography } from "@mui/material";
import { useEffect } from "react";
import MessageItem from "components/Molecules/Chat/messageItem";
import { icons } from "assets/AssetHelper";
import ChatImagePreview from "components/Molecules/Chat/chatImagePreview";
import SendMessage from "components/Molecules/Chat/sendMessage";

const ChatBox = ({
    messagesList,
    sendMessage,
    setMessage,
    Files,
    message,
    handleFileInput,
    loadFile,
}) => {
    const chatName = localStorage.getItem("chatName");
    const chatImage = localStorage.getItem("chatProfileImg");

    useEffect(() => {
        var chatbox = document.getElementById('chat-box');
        chatbox.scrollTop = chatbox.scrollHeight;
    }, [<MessageItem />, message]);

    return <>
        <Grid container className="chat-box-container" sx={{ boxShaow: '0px 4px 20px 0px rgba(0, 0, 0, 0.10)' }}>
            <Stack direction='row' sx={{ boxShadow: ' 0px 2px 4px 0px rgba(0, 0, 0, 0.05)', py: '16px', px: '24px', mb: '20px' }} width='100%' className="chat-box-header">
                <Stack direction='row' width='100%' alignItems='center' gap={'16px'} >
                    <Stack
                        justifyContent='center'
                        alignItems='center'
                        width='55px'
                        height='55px'
                        gap='16px'
                        sx={{ border: '1px solid #F6F6F6', borderRadius: '4px' }}
                    >
                        <img
                            src={chatImage || icons.profilePlaceholder}
                            alt='chat img'
                            style={{
                                width: "35px",
                                height: "35px",
                                // borderRadius: "50%",
                                display: "block",
                                background: "#ddd",
                            }}
                        />
                    </Stack>
                    <Stack>
                        <Typography component="h1" style={{ lineHeight: "3" }}>
                            {!chatName ? "Chat Name" : chatName}
                        </Typography>
                    </Stack>
                </Stack>
            </Stack>
            <Stack width={{ lg: '100%' }} id="chat-box" className="chat-box-body">
                {messagesList?.map((messageItem, i) => (
                    // (messageItem?.sender_node_id == localStorage.getItem("Messages_node")) &&
                    <Box key={i}>
                        <MessageItem
                            type={messageItem?.sender_id == localStorage.getItem("user_id") ? "sent" : "received"}
                            message={messageItem?.text}
                            time={messageItem?.created_at}
                            files={messageItem?.file_url}
                        />
                    </Box>
                ))}
            </Stack>
            <SendMessage
                message={message}
                setMessage={setMessage}
                sendMessage={sendMessage}
                Files={Files}
                loadFile={loadFile}
                handleFileInput={handleFileInput}
            />
            <ChatImagePreview />
        </Grid>
    </>
};

export default ChatBox;