import { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";

import Loader from "components/Atoms/SharedComponents/Loader/Loader";
import setAuthToken from "services/modules/utils/handel_api";
import OrdersTemplate from "templates/orders";
import {
  cancelOrderRequest,
  getOrderDetailsRequest,
  getOrdersRequest,
} from "services/modules/orders";
import ConfirmationModal from "components/Molecules/Models/ConfirmationModal";
import { useThemePalette } from "common/hooks/theme_palette";
import { CancelOrderSuccess } from "services/modules/orders/Action";
import { StoreUser } from "services/modules/utils/constant";

function OrdersPage() {
  const { orange } = useThemePalette();
  const token = localStorage.getItem("token");
  setAuthToken(token);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  let [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const type = searchParams.get("type");

  const list = useSelector((state) => state.orders.orders);
  const count = useSelector((state) => state.orders.count);
  const details = useSelector((state) => state.orders.orderDetails);
  const load = useSelector((state) => state.orders.load);

  const [dateFilterValue, setDateFilterValue] = useState(dayjs(new Date()));
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [page, setPage] = useState(1);
  const [filterObj, setFilterObj] = useState(null);
  const userType = localStorage.getItem("user_type");
  const isStore = userType == StoreUser;
  const orderStatuses = {
    processing: { id: 1, name: "Processing" },
    start_route_assigned: { id: 2, name: "start route assigned" },
    started: { id: 3, name: "Started" },
    collected: { id: 4, name: "Collected" },
    ready_for_delivery: { id: 5, name: "Ready for delivery" },
    finish_route_assigned: { id: 6, name: "finish route assigned" },
    enroute: { id: 7, name: "enroute" },
    delivered: { id: 8, name: "Delivered" },
    canceled: { id: 9, name: "Canceled" },
  };
  const storeStatus = {
    processing: { id: 1, name: "Processing" },
    start_route_assigned: { id: 2, name: "start route assigned" },
    started: { id: 3, name: "Started" },
    collected: { id: 4, name: "Collected" },
  };
  const handlePageChange = (page) => {
    setPage(page);
    setFilterObj((prev) => {
      return { ...prev, page_number: page };
    });
  };

  const getDetails = async () => {
    dispatch(
      getOrderDetailsRequest({
        order_id: selectedRow?.rowData?.id ? selectedRow?.rowData?.id : id,
      })
    );
  };

  const getList = async (filterObj) => {
    const requestData = {
      params: {
        page_size: 10,
        page_number: 1,
        keyword: "",
        sort_by: "id",
        // date: dateFilterValue.format("D-M-YYYY"),
        ...filterObj,
      },
    };
    dispatch(getOrdersRequest(requestData));
  };

  // mappedData use for create custom cell to concat multi values or navigate button
  const mappedData = list?.map((data, index) => {
    return {
      ...data,
      total: <>{data?.amount} SAR </>,
      date: <>{dayjs(data?.created_at).format("DD-MM-YYYY,HH a")} </>,
      status: <>{orderStatuses[data?.status]?.name}</>,
    };
  });

  const rowData = (data) => {
    setSelectedRow(data);
  };

  const breadcrumbs = [
    {
      active: true,
      path: "/",
      title: "Home",
    },
    {
      active: !type ? false : true,
      path: "/orders",
      title: "Orders",
    },
    type === "details" && {
      active: false,
      path: "",
      title: "Order details ",
    },
  ];

  const filterList = [
    {
      type: "search",
      placeholder: "Search by Order Number",
      onChange: (value) =>
        setFilterObj((s) => {
          return { ...s, keyword: value?.trim(), page_number: 1 };
        }),
        setFilterObj,
    },

    {
      type: "filter",
      placeholder: "Order Status",
      onChange: (value) => {
        setFilterObj((s) => {
          return { ...s, status: value?.value, page_number: 1 };
        });
      },
      options: Object.values(isStore ? storeStatus : orderStatuses),
    },
    {
      type: "date",
      placeholder: "",
      // value: dateFilterValue,
      onChange: (value) => {
        //if no value
        if (value != "01-01-1970") {
          setFilterObj((s) => {
            return { ...s, date: value, page_number: 1 };
          });
        } else {
          setFilterObj((s) => {
            return { ...s, date: "" };
          });
        }
      },
      sx: {
        backgroundColor: "white",
        ".MuiSvgIcon-root": { color: orange },
      },
    },
  ];
  const filterToAdd = {
    type: "filter",
    placeholder: "Payment methods",
    onChange: (value) => {
      setFilterObj((s) => {
        return { ...s, payment: value?.value, page_number: 1 };
      });
    },
    options: [{ id: 1, name: "Cash" }],
  };
  !isStore && filterList?.splice(1, 0, filterToAdd);

  //list of actions to be displayed when clicked in the 3 dots
  const actionsList = ({ index, data }) => {
    return [
      {
        condition: () => {
          return true;
        },
        click: () => {
          navigate(`?type=details&id=${data?.id}`);
        },
        text: "View",
      },
      {
        condition: () => {
          return data?.status?.props?.children !== "Canceled";
        },
        click: () => {
          setOpenCancelOrderModal(true);
        },
        text: "cancel order",
      },
    ];
  };

  //if you need more buttons in the header beside the add button
  const headerChildren = <></>;

  const handleOrderCancel = () => {
    dispatch(
      cancelOrderRequest({
        id: selectedRow?.rowData?.id ? selectedRow?.rowData?.id : id,
      })
    );
    setOpenCancelOrderModal(false);
  };

  //if more modals needed, pass them to the modalComponents as below
  const [openCancelOrderModal, setOpenCancelOrderModal] = useState(false);
  // db.ref(`users/`).on("value", snapshot => {
  //   const data = snapshot?.val();
  //   console.log(data, window.location.pathname, "data");
  // });

  const cancelOrderModal = () => {
    return (
      <ConfirmationModal
        open={openCancelOrderModal}
        setOpen={setOpenCancelOrderModal}
        text={["are you sure you want to cancel order"]}
        confirmBtnTitle="yes"
        handleConfirmation={handleOrderCancel}
      />
    );
  };
  const modalComponents = [cancelOrderModal];

  //listing all tags
  useEffect(() => {
    if (filterObj) getList(filterObj);
  }, [filterObj]);

  //details of a single tag
  useEffect(() => {
    if (id && pathname?.includes("orders")) getDetails();
  }, [id, pathname]);

  return (
    <>
      <Loader open={load?.loading && load?.type == "list"} />
      <OrdersTemplate
        filterObj={filterObj}
        setFilterObj={setFilterObj}
        data={mappedData}
        count={count}
        details={details}
        paginationPage={page}
        handlePageChange={handlePageChange}
        rowData={rowData}
        breadcrumbs={breadcrumbs}
        filterList={filterList}
        handelSubmit={(_) => void 0}
        actionsList={actionsList}
        headerChildren={headerChildren}
        openDeleteModal={openDeleteModal}
        setOpenDeleteModal={setOpenDeleteModal}
        handleDeleteRequest={(_) => void 0}
        modalComponents={modalComponents}
        selectedRow={selectedRow}
        handleOrderCancel={handleOrderCancel}
        isStore={isStore}
      />
    </>
  );
}

export default OrdersPage;
