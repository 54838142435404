/* eslint-disable array-callback-return */
import Types from "./Types";

const INIT_STATE = {
  wrapExtras: [],
  filterWrapExtra: [],
  wrapExtra: {},
  count: 0,
  listCount: 0,
  load: null,
  error: null,
};

export function wrapExtrasReducer(state = INIT_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case Types.GET_WRAP_EXTRAS_SUCCESS: {
      return {
        ...state,
        wrapExtras: payload?.data?.wrap_extras,
        count: payload?.extra?.total_count,
      };
    }
    case Types.WRAP_EXTRAS_LOADING: {
      return {
        ...state,
        load: payload,
      };
    }
    case Types.GET_SCROLL_WRAP_EXTRA_SUCCESS: {
      return {
        ...state,
        filterWrapExtra: payload?.extra?.page_number == 1
          ? payload?.data?.wrap_extras
          : [...state.filterWrapExtra, ...payload?.data?.wrap_extras],
        listCount: payload?.extra?.total_count,
      };
    }
    case Types.DELETE_WRAP_EXTRA_SUCCESS: {
      return {
        ...state,
      };
    }
    case Types.ADD_WRAP_EXTRA_SUCCESS: {
      return {
        ...state,
      };
    }
    case Types.ADD_WRAP_EXTRA_FAILED: {
      return {
        ...state,
        error: payload?.response?.data?.message,
      };
    }
    case Types.UPDATE_WRAP_EXTRA_SUCCESS: {
      return {
        ...state,
      };
    }
    case Types.GET_WRAP_EXTRA_DETAILS_SUCCESS: {
      return {
        ...state,
        wrapExtra: payload?.data?.wrap_extra,
      };
    }
    default: {
      return state;
    }
  }
}
