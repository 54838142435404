/* eslint-disable array-callback-return */
import Types from "./Types";

const INIT_STATE = {
  customers: [],
  customer: {},
  followings: [],
  followers: [],
  followersCount: 0,
  followingCount: 0,
  count: 0,
  load: null,
};

export default function customersReducer(state = INIT_STATE, action) {
  const { type, payload } = action;

  switch (type) {
    case Types.CUSTOMERS_LOADING: {
      return {
        ...state,
        load: payload,
      };
    }
    case Types.GET_CUSTOMERS_SUCCESS: {
      return {
        ...state,
        customers: payload?.data?.customers,
        count: payload?.extra?.total_count,
      };
    }
    case Types.GET_CUSTOMER_DETAILS_SUCCESS: {
      return {
        ...state,
        customer: payload?.data?.customer,
      };
    }
    case Types.UPDATE_CUSTOMER_SUCCESS: {
      return {
        ...state,
        customers: state?.customers.map((customer) => {
          if (customer.id === payload?.data?.customer?.id) {
            return payload?.data?.customer;
          }
          return customer;
        }),
        customer: payload?.data?.customer
      };
    }
    case Types.DELETE_CUSTOMER_SUCCESS: {
      return {
        ...state,
      };
    }
    case Types.GET_CUSTOMER_FOLLOWERS_LIST: {
      return {
        ...state,
        followers: payload?.extra?.page_number == 1
          ? payload?.data?.followers
          : [...state.followers, ...payload?.data?.followers],
        followersCount: payload?.extra?.total_count,
      };
    }
    case Types.GET_CUSTOMER_FOLLOWING_LIST: {
      return {
        ...state,
        // followings: payload?.data?.followings,
        followings: payload?.extra?.page_number == 1
          ? payload?.data?.followings
          : [...state.followings, ...payload?.data?.followings],
        followingCount: payload?.extra?.total_count,
      };
    }
    case Types.CLEAR_FOLLOWING_LIST: {
      return {
        ...state,
        followings: []
      }
    }
    case Types.CLEAR_FOLLOWERS_LIST: {
      return {
        ...state,
        followers: []
      }
    }
    default: {
      return state;
    }
  }
}
