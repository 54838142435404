import * as Yup from "yup";

export const loginSchema = Yup.object().shape({
    email: Yup.string().required().email().label("Email"),
    password: Yup.string().required().label("Password"),
});


export const resetPasswordSchema = Yup.object().shape({
    password: Yup.string().required().label("Password"),
    confirmPassword: Yup.string()
        .required()
        .oneOf([Yup.ref("password"), null], `Passwords don't match`),
});


export const forgetPasswordSchema = Yup.object().shape({
    email: Yup.string().required().email().label("Email"),
});
export const vendorRegistrationSchema = Yup.object().shape({
    en: Yup.object().shape({
        name: Yup.string().required('Required'),
    }),
    ar: Yup.object().shape({
        name: Yup.string().required('Required'),
    }),
    email: Yup.string().required().email().label("Email"),
    password: Yup.string().required().label("Password"),
    password_confirmation: Yup.string()
        .required()
        .oneOf([Yup.ref("password"), null], `Passwords don't match`),
    phone_number: Yup.string().required().label('Phone number'),
    //google_cities_stores:Yup.array().required().label('Required'),
    tags: Yup.array().required().label('Required')
})
export const locationsSchema = Yup.object().shape({
    google_city_id: Yup.number().required().label('Select City'),
    location: Yup.string().required().label('Required'),
    lat: Yup.string().required(),
    long: Yup.string().required()
})