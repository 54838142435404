import Types from "./Types";


// ----------------- SHAPES START-----------------
export const GetShapesSuccess = (payload) => ({
  type: Types.GET_SHAPES_SUCCESS,
  payload,
});

export const GetShapeLoading = (payload) => ({
  type: Types.SHAPES_LOADING,
  payload,
});


export const AddShapeSuccess = (payload) => ({
  type: Types.ADD_SHAPES_SUCCESS,
  payload,
});

export const AddWrapFailed = (payload) => ({
  type: Types.ADD_SHAPES_FAILED,
  payload,
});


export const DeleteShapeSuccess = (payload) => ({
  type: Types.DELETE_SHAPES_SUCCESS,
  payload,
});

export const DeleteWrapFailed = (payload) => ({
  type: Types.DELETE_SHAPES_FAILED,
  payload,
});

export const UpdateShapesSuccess = (payload) => ({
  type: Types.UPDATE_SHAPES_SUCCESS,
  payload,
});

export const GetShapeDetailsSuccess = (payload) => ({
  type: Types.GET_SHAPES_DETAILS_SUCCESS,
  payload,
});

export const GetScrollShapesSuccess = (payload) => ({
  type: Types.GET_SCROLL_SHAPES_SUCCESS,
  payload,
});

// ----------------- SHAPES END-----------------
