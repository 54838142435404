import * as React from "react";
import { Box, Stack, Typography } from "@mui/material";

import { icons } from "assets/AssetHelper";
import { useThemePalette } from "common/hooks/theme_palette";
import RouteDetails from "components/Molecules/Order/routeDetails";

export default function OrderRoutes({ details }) {
  const [expandedAccordion, setExpandedAccordion] = React.useState(null);
  const routes = details?.routes;
  

  const customerInfo = {
    name: details?.orderer?.customer_name,
    mobile: `+(${details?.orderer?.customer_country_code}) ${details?.orderer?.customer_phone_number}`,
    address: details?.cart?.delivery_address?.full_address ||'No address available',
  };
  const isPending = (status) => {
    return ["generated", "processing_drivers", "pending"].includes(status);
  };
  const isCanceled = (status) => {
    return ["canceled"].includes(status);
  };

  return (
    <Stack bgcolor="white" borderRadius="8px">
      {/* ------------ HEADER START------------ */}
      <Stack direction="row" alignItems="center" gap={1} px={3} py={2}>
        <Box component="img" src={icons.routes} width="48px" />
        <Typography fontSize="24px" fontWeight="500">
          Routes
        </Typography>
      </Stack>
      {/* ------------ HEADER END------------ */}

      {routes?.map((route, i) => (
        <RouteDetails
          i={i}
          route={route}
          customerInfo={customerInfo}
          isPending={isPending}
          isCanceled={isCanceled}
          expandedAccordion={expandedAccordion}
          setExpandedAccordion={setExpandedAccordion}
        />
      ))}
    </Stack>
  );
}
